const ListingETLNew = function (flatData, listingConfigToListingCollectionsMap, listingCollectionComponentToListingCollectionMap) {

    const getParentM1Keys = function (fd, childM2Key) {
        let parentM1Keys = [];
        for (let [m1Key, m1Data] of Object.entries(fd)) {
            for (let m2Key of Object.keys(m1Data)) {
                if (m2Key === childM2Key) {
                    parentM1Keys.push(m1Key);
                }
            }
        }

        return parentM1Keys;
    };

    const makeMaps = function () {
        const listingCollectionToSystemsMap = {};
        const componentToContentGroupMap = {};
        const systemMap = {};
        const listingM1Keys = [];
        const listingToSystemsMap = {};
        const listingToTierMap = {};
        const listingToParentListingsMap = {};
        const listingCollectionToListingConfigTierMap = {};
        const listingConfigToListingsColumnsMap = {};
        const listingConfigToListingsMap = {};
        const listingToListingCollectionsMap = {};
        const listingToListingConfigMap = {};
        const listingCollectionToParentComponentMap = {};

        const fd = flatData;

        for (let [m1Key, m1Data] of Object.entries(fd)) {
            if (m1Key.startsWith('system.')) {
                systemMap[m1Key] = { name: m1Data.display_name };
            }

            if (m1Key.startsWith('listing.')) {
                listingM1Keys.push(m1Key);

                for (let m2Key of Object.keys(m1Data)) {
                    if (m2Key.startsWith('listing.')) {
                        if (!listingToParentListingsMap[m2Key]) {
                            listingToParentListingsMap[m2Key] = [];
                        }

                        listingToParentListingsMap[m2Key].push(m1Key);
                    }
                }
            }

            if (m1Key.startsWith('listing_collection.')) {
                if (!listingCollectionToListingConfigTierMap[m1Key]) {
                    listingCollectionToListingConfigTierMap[m1Key] = {};
                }

                for (let m2Key of Object.keys(m1Data)) {
                    if (m2Key.startsWith('listing_config.')) {
                        let m2Data = fd[m2Key];

                        // T1 listing_config
                        listingCollectionToListingConfigTierMap[m1Key][0] = m2Key;

                        if (!listingConfigToListingCollectionsMap[m2Key]) {
                            listingConfigToListingCollectionsMap[m2Key] = [];
                        }
                        listingConfigToListingCollectionsMap[m2Key].push(m1Key);

                        // T(n) listing_config
                        let i = 0;
                        while (~Object.keys(m2Data).join('').indexOf('listing_config.')) {
                            i++;
                            let childListingConfigM1Key = Object.keys(m2Data).filter((k) => {
                                return k.startsWith('listing_config.');
                            })[0];

                            m2Data = fd[childListingConfigM1Key];
                            listingCollectionToListingConfigTierMap[m1Key][i] = childListingConfigM1Key;

                            if (!listingConfigToListingCollectionsMap[childListingConfigM1Key]) {
                                listingConfigToListingCollectionsMap[childListingConfigM1Key] = [];
                            }
                            listingConfigToListingCollectionsMap[childListingConfigM1Key].push(m1Key);
                        }
                    }
                }
            }

            if (m1Key.startsWith('listing_config.')) {
                if (!listingConfigToListingsColumnsMap[m1Key]) {
                    listingConfigToListingsColumnsMap[m1Key] = {};
                }

                for (let [m2Key, m2Data] of Object.entries(m1Data)) {
                    if (m2Key.startsWith('listing_column.')) {
                        listingConfigToListingsColumnsMap[m1Key][m2Key] = m2Data;
                        listingConfigToListingsColumnsMap[m1Key][m2Key].name = fd[m2Key].display_name;

                        let listingColumnTypeM1Key = Object.keys(fd[m2Key]).filter((k) => {
                            return k.startsWith('listing_column_type.');
                        })[0];

                        listingConfigToListingsColumnsMap[m1Key][m2Key].type = fd[listingColumnTypeM1Key].type;
                        listingConfigToListingsColumnsMap[m1Key][m2Key].fieldType = fd[listingColumnTypeM1Key].display_name;
                    }
                }
            }

            if (m1Key.startsWith('component.')) {
                for (let m2Key of Object.keys(m1Data)) {
                    if (m2Key.startsWith('listing_collection.')) {
                        listingCollectionToParentComponentMap[m2Key] = m1Key;

                        let parents = getParentM1Keys(fd, m1Key), skip = false;
                        while (!~parents.join('').indexOf('system.')) {
                            parents = getParentM1Keys(fd, parents[0]);

                            if (parents.length === 0) {
                                skip = true;
                                break;
                            }
                        }

                        if (skip) {
                            console.warn('SKIP?:', m1Key);
                            continue;
                        }

                        let systemM1Keys = parents.filter((k) => {
                            return k.startsWith('system.');
                        });

                        if (!listingCollectionToSystemsMap[m2Key]) {
                            listingCollectionToSystemsMap[m2Key] = [];
                        }
                        for (let systemM1Key of systemM1Keys) {
                            listingCollectionToSystemsMap[m2Key].push(systemM1Key);
                        }
                    }
                }
            }
        }

        for (let componentM1Key of Object.keys(listingCollectionToParentComponentMap)) {
            for (let bundle of (fd[componentM1Key]?._bundle || [])) {
                if (bundle.attr.type === 'custom_group') {
                    if (!componentToContentGroupMap[componentM1Key]) {
                        componentToContentGroupMap[componentM1Key] = [];
                    }
                    componentToContentGroupMap[componentM1Key].push(bundle);

                }
            }
        }

        for (let listingM1Key of listingM1Keys) {
            let parents = getParentM1Keys(fd, listingM1Key);

            let i = 0, skip = false;
            while (!~parents.join('').indexOf('listing_collection.')) {
                i++;
                parents = getParentM1Keys(fd, parents[0]);

                if (parents.length === 0) {
                    skip = true;
                    break;
                }
            }

            if (skip && fd[listingM1Key]) {
                let cols = Object.keys(fd[listingM1Key])
                    .map((k) => {
                        if (k.startsWith('listing_column')) {
                            let v = fd[listingM1Key][k]
                            if (v) {
                                return JSON.stringify(v);
                            }

                            return null;
                        }
                    })
                    .filter((col) => {
                        if (col) {
                            return col;
                        }
                    });
                console.log('ORPHAN:', listingM1Key, cols);
                continue;
            }

            listingToTierMap[listingM1Key] = i;

            if (!listingToListingCollectionsMap[listingM1Key]) {
                listingToListingCollectionsMap[listingM1Key] = [];
            }

            if (!listingToSystemsMap[listingM1Key]) {
                listingToSystemsMap[listingM1Key] = [];
            }

            for (let parentM1Key of parents) {
                if (parentM1Key.startsWith('listing_collection.')) {
                    let listingCollectionM1Key = parentM1Key;
                    listingToListingCollectionsMap[listingM1Key].push(listingCollectionM1Key);

                    let systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key] || [];
                    for (let systemM1Key of systemM1Keys) {
                        listingToSystemsMap[listingM1Key].push(systemM1Key);
                    }

                    let listingConfigM1Key = listingCollectionToListingConfigTierMap[listingCollectionM1Key][i];
                    listingToListingConfigMap[listingM1Key] = listingConfigM1Key;
                }
            }
        }

        for (let [listingM1Key, listingConfigM1Key] of Object.entries(listingToListingConfigMap)) {
            if (listingConfigM1Key === undefined) {
                continue;
            }

            if (!listingConfigToListingsMap[listingConfigM1Key]) {
                listingConfigToListingsMap[listingConfigM1Key] = {};
            }

            let listing = {
                vals: {},
                systems: listingToSystemsMap[listingM1Key],
                tier: listingToTierMap[listingM1Key],
            };
            if (listingToParentListingsMap[listingM1Key]) {
                listing.parent_listings = listingToParentListingsMap[listingM1Key];
            }

            let cols = listingConfigToListingsColumnsMap[listingConfigM1Key];
            listing.cols = cols;

            for (let [colM1Key, col] of Object.entries(cols)) {
                if (typeof fd?.[listingM1Key]?.[colM1Key] === 'undefined') {
                    listing.vals[col.name] = null;
                } else {
                    let type = col.type;

                    listing.vals[col.name] = fd[listingM1Key][colM1Key][type];
                }
            }

            let listingCollectionM1Keys = listingToListingCollectionsMap[listingM1Key];
            for (let listingCollectionM1Key of listingCollectionM1Keys) {
                let componentM1Key = listingCollectionToParentComponentMap[listingCollectionM1Key];

                if (typeof fd[componentM1Key]?._bundle !== 'undefined') {
                    if (!listing.content_groups) {
                        listing.content_groups = [];
                    }

                    listing.content_groups.push(componentM1Key);
                }
            }

            listingConfigToListingsMap[listingConfigM1Key][listingM1Key] = listing;
        }

        let listings = {
            systems: systemMap,
            content_groups: componentToContentGroupMap,
            listings: {},
            cols: {},
        };

        for (let listingConfigM1Key of Object.keys(listingConfigToListingsMap)) {
            listings.cols = { ...(listings.cols || {}), ...listingConfigToListingsColumnsMap[listingConfigM1Key] };
            listings.listings[listingConfigM1Key] = listingConfigToListingsMap[listingConfigM1Key];
        }

        console.log('listingM1Keys:', listingM1Keys);
        console.log('listingToTierMap:', listingToTierMap);
        console.log('listingToParentListingsMap:', listingToParentListingsMap);
        console.log('listingCollectionToListingConfigTierMap:', listingCollectionToListingConfigTierMap);
        console.log('listingConfigToListingCollectionsMap:', listingConfigToListingCollectionsMap);
        console.log('listingConfigToListingsColumnsMap:', listingConfigToListingsColumnsMap);
        console.log('listingConfigToListingsMap:', listingConfigToListingsMap);
        console.log('listingToListingCollectionsMap:', listingToListingCollectionsMap);
        console.log('listingToListingConfigMap:', listingToListingConfigMap);
        console.log('listingCollectionToParentComponentMap:', listingCollectionToParentComponentMap);

        console.log(listings);

        return {
            listingToTierMap,
            listingToParentListingsMap,
            listingCollectionToListingConfigTierMap,
            listingConfigToListingsMap,
        };
    };

    const makeRawCols = function (listingConfigM1Key) {
        let cols = {};
        for (let m2Key of Object.keys(flatData[listingConfigM1Key])) {
            if (m2Key.startsWith('listing_column.')) {
                cols[m2Key] = makeDefaultColumnValue(m2Key);
            }
        }

        return cols;
    };

    const makeDefaultColumnValue = function (listingColumnM1Key) {
        let columnType;
        for (let m2Key of Object.keys(flatData[listingColumnM1Key])) {
            if (m2Key.startsWith('listing_column_type.')) {
                columnType = flatData[m2Key].type;
                break;
            }
        }

        switch (columnType) {
            case 'string':
            case 'text':
                return '';

            case 'float':
            case 'int':
                return 0;

            case 'bool':
                return false;
        }

        return '';
    };

    const extractMessagingLabels = function () {
        const labels = [];

        Object.keys(flatData).forEach(m1Key => {
            if (m1Key.startsWith("account.")) {
                Object.keys(flatData[m1Key]).forEach(m2Key => {
                    if (m2Key.startsWith("setting.")) {
                        if (flatData[m2Key].name === "messaging_options") {
                            const innerJson = JSON.parse(flatData[m1Key][m2Key].json);
                            innerJson.messaging.options.forEach(option => {
                                labels.push(option.label);
                            });
                        }
                    }
                });
            }
        });
        return labels;
    }

    const getM2Data = function (tree, m1KeyPrefix, m2KeyPrefix, opts = {}) {
        const data = {};
        for (let [m1Key, m1Data] of Object.entries(tree)) {
            if (m1Key.startsWith(m1KeyPrefix)) {
                for (let [m2Key, m2Data] of Object.entries(m1Data)) {
                    if (m2Key.startsWith(m2KeyPrefix)) {
                        let m2Tree = tree[m2Key];
                        let groupKey = m2Tree[opts.groupKey] || m2Key;

                        if (!data[groupKey]) {
                            data[groupKey] = {};
                        }

                        data[groupKey][m1Key] = opts.m1Keys || {};
                    }
                }
            }
        }

        return data;
    }

    const getComponentSettings = function (m1Key) {
        let m1Data = flatData[m1Key];

        let settings = {};
        for (let [m2Key, m2Data] of Object.entries(m1Data)) {
            if (m2Key.startsWith('setting.')) {
                let setting = flatData[m2Key];
                for (let key of Object.keys(setting)) {
                    if (key.startsWith('setting_type.')) {
                        let settingType = flatData[key].type;
                        let settingValue = m2Data[settingType] || null;
                        try {
                            if (settingType === 'json') {
                                settingValue = JSON.parse(m2Data[settingType]);
                            }
                        } catch (err) { }

                        settings[setting.name] = {
                            type: settingType,
                            value: settingValue,
                        };

                        break;
                    }
                }
            }
        }

        return settings;
    }

    const dataType = function (listingColumn) {
        for (let [key, data] of Object.entries(listingColumn)) {
            if (key.startsWith('listing_column_type.')) {
                return flatData[key].display_name;
            }
        }
    }

    const getListing = function (m1Key, listingGroup, listingGroupM1Key, listingConfigTierlist) {
        let listings = [];
        let detailsGrp = [];

        let listingListingConfigM1Key;
        for (let [listingConfigM1Key, listingM1Keys,] of Object.entries(listingConfigToListingsMap)) {
            for (let listingM1Key of Object.keys(listingM1Keys)) {
                if (listingM1Key === m1Key) {
                    listingListingConfigM1Key = listingConfigM1Key;
                    break;
                }
            }

            if (listingListingConfigM1Key) {
                break;
            }
        }
        let rawCols = makeRawCols(listingListingConfigM1Key);

        for (let [m2Key, m2Data] of Object.entries(flatData[m1Key])) {
            if (m2Key.startsWith('listing.')) {
                let listing = getListing(m2Key, listingGroup, listingGroupM1Key, listingConfigTierlist);
                listing.m1Key = m2Key;
                listing.settings = getComponentSettings(listing.m1Key);

                listings.push(listing);
            }

            if (m2Key.startsWith('listing_column.')) {
                let keys = Object.keys(m2Data);
                rawCols[m2Key] = m2Data[keys[0]];

                let displayName = flatData[m2Key].display_name;
                let type = dataType(flatData[m2Key]);
                detailsGrp.push({
                    value: m2Data[keys[0]],
                    type: type,
                    label: displayName,
                    column_uuid: m2Key,
                });
            }
        }

        //get listing parents,
        let rootParent = false
        let parentM1Keys = [];
        let currentChildKey = m1Key
        while (!rootParent) {
            let parentKey = listingToParentListingsMap[currentChildKey] || ''
            if (!parentKey) {
                rootParent = true
                break;
            }

            parentKey = [...new Set(parentKey)];
            parentM1Keys.push(parentKey)
            currentChildKey = parentKey
        }
        //get parent cols		
        let parentCols = {}
        parentM1Keys.forEach(function (parentM1Key) {
            if (parentM1Key.length === 1) {
                for (let [m2Key, m2Data] of Object.entries(flatData[parentM1Key])) {

                    if (m2Key.startsWith('listing_column.')) {
                        let keys = Object.keys(m2Data);
                        parentCols[m2Key] = m2Data[keys[0]];
                    }

                }
            }
        });
        // merge parentCols in to RawCols
        rawCols = Object.assign({}, rawCols, parentCols)

        let colKeys = Object.keys(rawCols)
        let touchAction;
        let tierConfig;

        // get listing tier to listing config tier relation
        let listingTier = listingToTierMap[m1Key]
        let listingConfigTier = listingConfigTierlist[listingTier]
        const listingTierName = flatData[listingConfigTier].display_name;

        for (let [m2Key, m2Data] of Object.entries(flatData[listingGroupM1Key])) {
            if (m2Key === listingConfigTier) {
                tierConfig = {
                    boxActiveLayout: m2Data.active_layout,
                    boxColumnsPerRow: m2Data.number_of_columns,
                    boxStyleDisplay: m2Data.boxstyle_display,
                    iframeUrlLabel: m2Data.iframe_url_label,
                    listingTier,
                    listingTierName,
                    mobileButtonLabel: m2Data.mobile_button_label,
                    mobileButtonOrder: m2Data.mobile_button_order,
                    mobileClickEvent: m2Data.mobile_click_event,
                    mobileDialing: m2Data.mobile_dialing,
                    mobileDisplay: m2Data.mobile_display,
                    mobileWayfinding: m2Data.mobile_wayfinding,
                    showSubNavButton: m2Data.show_sub_nav_button,
                    touchAction: m2Data.touch_action,
                    videoUrlLabel: m2Data.video_url_label,
                }
                touchAction = m2Data.touch_action
            }
        }
        // get only listing column groups associated with this listing tier.
        let tierToListingColumnGroupMap = [];

        for (let listingColumnGroup of listingGroup) {
            if (Object.keys(flatData[listingColumnGroup.m1Key]).includes(listingConfigTier)) {
                tierToListingColumnGroupMap.push(listingColumnGroup);
            }
        }

        // build relavent ColGroups
        let colGroups = Object.keys(rawCols)
            .map((colM1Key) => {
                for (let listingColumGroup of tierToListingColumnGroupMap) {
                    for (let [colGroupName, colObj] of Object.entries(listingColumGroup.columns)) {
                        // console.log( 'column group',colObj.m1Key)
                        if (colM1Key === colObj.m1Key) {
                            let filterData = [];
                            let trimmedFilterData = [];
                            if (colObj.filter) {
                                try {
                                    filterData = JSON.parse(colObj.filter);
                                    trimmedFilterData = filterData.map((filter) => {
                                        return filter.trimStart();
                                    });
                                } catch (err) {
                                    console.error("Failed parsing filterData", err);
                                }
                            }
                            return {
                                m1Key: colM1Key,
                                dataName: colObj.dataName,
                                order: listingColumGroup.order,
                                name: flatData[listingColumGroup.m1Key].display_name,
                                sort: colObj.sort,
                                textAlign: colObj.text_align,
                                width: colObj.width,
                                filter: trimmedFilterData,
                                headerAsLabel: colObj.header_as_label,
                                // TODO: v2 handle grouped columns sort and width
                            };
                        }
                    }
                }

                return false;
            })
            .filter((obj) => {
                if (obj) {
                    return obj;
                }

                return false;
            })
            .map((col) => {
                // TODO: v2 return [col1, col2] as grouped col
                return col;
            });
        // TODO: v1 sort cols based on column group order
        // TODO: v2 sort cols based on column group order and column order

        return {
            settings: getComponentSettings(m1Key),
            rawCols,
            colGroups,
            detailsGrp,
            vals: [],
            listings,
            touchAction,
            tierConfig
        };
    }

    const getListingVals = function (k, listingGroup, obj, n = 0) {
        const colsKey = `${k}_${n}`;
        const cols = listingGroup.cols[colsKey] || [];

        const extractListingValue = (listingValue) => {
            let isJSON = false;
            try {
                JSON.parse(listingValue);
                isJSON = true;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                const parsedValue = JSON.parse(listingValue);
                if (parsedValue?.cc) {
                    const phoneData = parsedValue;
                    const labels = extractMessagingLabels();
                    return labels.includes(phoneData.label)
                        ? `${phoneData.label}-${phoneData.area}-${phoneData.n}`
                        : `${phoneData.area}-${phoneData.n}`;
                }
                if (parsedValue?.email) {
                    const emailData = parsedValue;
                    const labels = extractMessagingLabels();
                    return labels.includes(emailData.label)
                        ? `${emailData.label}-${emailData.email}@${emailData.domain}`
                        : `${emailData.email}@${emailData.domain}`;
                }
            }
            return listingValue === 'undefined' ? '' : listingValue || '';
        };

        const extractColumnData = (col, type = 'type') => {
            const columnData = flatData[col.m1Key];
            if (!columnData) return null;
            for (let m1Key in columnData) {
                if (m1Key.startsWith('listing_column_type.')) {
                    return type === 'type'
                        ? flatData[m1Key].type
                        : flatData[m1Key].display_name;
                }
            }
            return null;
        };

        obj.listings.forEach((listing) => {
            // Recursive call for nested listings
            if (listing.listings?.length > 0) {
                getListingVals(k, listingGroup, listing, n + 1);
            }

            listing.colGroups = [...cols];

            // Extract values, types, and display names in a single loop
            listing.vals = [];
            listing.types = [];
            listing.displayNames = [];
            cols.forEach((col) => {
                const rawValue = String(listing.rawCols[col.m1Key] || '');
                listing.vals.push(extractListingValue(rawValue));
                listing.types.push(extractColumnData(col, 'type'));
                listing.displayNames.push(extractColumnData(col, 'display_name'));
            });
        });
    }

    const getListingGroupCols = function (k, listingGroup, obj, n = 0) {
        let fullColumns = [],
            colsKey = `${k}_${n}`;

        for (let i = 0; i < obj.listings.length; i++) {
            for (let j = 0; j < obj.listings[i].listings.length; j++) {
                getListingGroupCols(k, listingGroup, obj.listings[i], n + 1);
            }

            if (fullColumns.length < obj.listings[i].colGroups.length) {
                fullColumns = [...obj.listings[i].colGroups];
            }
        }

        listingGroup.cols[colsKey] = fullColumns.sort((a, b) => {
            if (a.order > b.order) {
                return 1;
            }

            return -1;
        })
    }

    const getListingCollection = function (m1Key, columnGroups) {
        let m1Data = flatData[m1Key];
        m1Data.m1Key = m1Key;
        let listingConfigTierlist = listingCollectionToListingConfigTierMap[m1Key]

        let listingGroupToParentComponentMap = {};
        for (let [m1Key, m1Data] of Object.entries(flatData)) {
            if (m1Key.startsWith('component.')) {
                for (let m2Key of Object.keys(m1Data)) {
                    if (m2Key.startsWith('listing_group.')) {
                        listingGroupToParentComponentMap[m2Key] = m1Key;
                    }
                }
            }
        }

        let listingGroups = {};
        for (let [listingGroupM1Key, m1Data] of Object.entries(columnGroups)) {
            let listings = [];
            for (let m2Key of Object.keys(flatData[m1Key])) {
                if (m2Key.startsWith('listing.')) {
                    let listing = getListing(m2Key, m1Data, listingGroupM1Key, listingConfigTierlist);
                    listing.m1Key = m2Key;

                    listings.push(listing);
                }
            }

            let parentComponentM1Key = listingGroupToParentComponentMap[listingGroupM1Key];
            listingGroups[listingGroupM1Key] = {
                componentM1Key: parentComponentM1Key,
                settings: getComponentSettings(parentComponentM1Key),
                cols: {},
                listings,
            };
        }

        for (let [k, listingGroup] of Object.entries(listingGroups)) {
            getListingGroupCols(k, listingGroup, listingGroup);
        }
        for (let [k, listingGroup] of Object.entries(listingGroups)) {
            getListingVals(k, listingGroup, listingGroup);
            delete (listingGroups[k].cols);
        }

        return {
            m1Key,
            'listing_groups': listingGroups,
        };
    };

    const getListingGroupComponent = function (m1Key) {
        let m1Data = flatData[m1Key];

        let listingColumnGroups = [];
        for (let [m2Key, m2Data] of Object.entries(flatData[m1Key])) {
            if (m2Key.startsWith('listing_group.')) {
                for (let [key, columnGroup] of Object.entries(flatData[m2Key])) {
                    if (key.startsWith('listing_column_group.')) {
                        columnGroup.m1Key = key;
                        columnGroup.columns = {};

                        let col = {};
                        let displayName;
                        let dataName = '';
                        for (let [k, v] of Object.entries(flatData[key])) {
                            if (k === 'display_name') {
                                displayName = v;
                            }

                            if (k.startsWith('listing_column.')) {
                                col.m1Key = k;
                                for (let [_k, _v] of Object.entries(v)) {
                                    col[_k] = _v;
                                }
                            }
                            if (k.startsWith('listing_config.')) {
                                for (let [configKey, configValue] of Object.entries(flatData[k])) {
                                    if (configKey === 'display_name') {
                                        col.dataName = configValue;
                                    }
                                }
                            }

                        }
                        columnGroup.columns[displayName] = col;
                        listingColumnGroups.push(columnGroup);
                    }
                }
            }
        }

        return {
            settings: getComponentSettings(m1Key),
            'column_groups': listingColumnGroups,
        };
    };

    const getListingCollectionComponent = function (parentComponentM1Key) {
        let listingGroupComponents = {};
        for (let [m2Key, m2Data] of Object.entries(flatData[parentComponentM1Key])) {
            // TODO: check for specific `component_type`!
            if (m2Key.startsWith('component.')) {
                let listingGroupComponent = getListingGroupComponent(m2Key);
                listingGroupComponents[m2Key] = listingGroupComponent;
            }
        }

        let columnGroups = {};
        for (let [m1Key, m1Data] of Object.entries(listingGroupComponents)) {
            for (let m2Key of Object.keys(flatData[m1Key])) {
                if (m2Key.startsWith('listing_group.')) {
                    columnGroups[m2Key] = m1Data.column_groups;
                }
            }
        }

        for (let [m2Key, m2Data] of Object.entries(flatData[parentComponentM1Key])) {
            if (m2Key.startsWith('listing_collection.')) {
                let listingCollection = getListingCollection(m2Key, columnGroups);
                listingCollection.settings = getComponentSettings(parentComponentM1Key);

                return listingCollection;
            }
        }
    }

    const {
        listingToTierMap,
        listingToParentListingsMap,
        listingCollectionToListingConfigTierMap,
        listingConfigToListingsMap,
    } = makeMaps();

    let listingColumns = [];
    for (let m1Key of Object.keys(flatData)) {
        if (m1Key.startsWith('listing_column.')) {
            listingColumns.push(m1Key);
        }
    }
    // console.log(listingColumns);

    let listingColumnToM1GroupMap = {};
    for (let [m1Key, m1Data] of Object.entries(flatData)) {
        if (m1Key.startsWith('listing_config.')) {
            for (let [m2Key, m2Data] of Object.entries(m1Data)) {
                if (m2Key.startsWith('listing_column.')) {
                    listingColumnToM1GroupMap[m2Key] = m2Data;
                    listingColumnToM1GroupMap[m2Key].m1Key = m2Key;
                    listingColumnToM1GroupMap[m2Key].name = m1Data['display_name'];
                }
            }
        }
    }
    for (let [m1Key, m1Data] of Object.entries(flatData)) {
        if (m1Key.startsWith('listing_column_group.')) {
            for (let [m2Key, m2Data] of Object.entries(m1Data)) {
                if (m2Key.startsWith('listing_column.')) {
                    listingColumnToM1GroupMap[m2Key] = m2Data;
                    listingColumnToM1GroupMap[m2Key].m1Key = m2Key;
                    listingColumnToM1GroupMap[m2Key].name = m1Data['display_name'];
                }
            }
        }
    }

    const typedComponents = getM2Data(flatData, 'component.', 'component_type.', {
        groupKey: 'type',
    });

    for (let [m1Key, m1Data] of Object.entries(flatData)) {
        if (m1Key.startsWith('component.')) {
            for (let m2Key of Object.keys(m1Data)) {
                if (m2Key.startsWith('listing_collection.')) {
                    listingCollectionComponentToListingCollectionMap[m1Key] = m2Key;
                }
            }
        }
    }

    const data = {};
    if (typeof typedComponents['listing_collection'] !== 'undefined') {
        Object.keys(typedComponents['listing_collection']).forEach((componentM1Key) => {
            let listingCollection = getListingCollectionComponent(componentM1Key);
            data[listingCollection.m1Key] = listingCollection;
        });
    }

    return data;
}

export default ListingETLNew;