import React, { useEffect, useState, } from 'react';

const TSLogo = (props) => {
	const css = props.CSSModules[props.condor_render_name];
	const [ source, setSource, ] = useState();
	const [ display, setDisplay, ] = useState("none");

	useEffect(() => {
		// Switch logo
		if (props.ts_logo && props.ts_logo_color) {
			let logoUrl = '';
			switch (props.ts_logo_color) {
			// switch (tsLogoColor) {
			case 'White':
				logoUrl = 'https://s3.amazonaws.com/ts-condor-assets/images/logos/tsLogoWhite.svg';
				break;
			case 'WhiteBlue':
				logoUrl = 'https://s3.amazonaws.com/ts-condor-assets/images/logos/tsLogoWhiteBlue.svg';
				break;
			case 'BlackBlue':
				logoUrl = 'https://s3.amazonaws.com/ts-condor-assets/images/logos/tsLogoBlackBlue.svg';
				break;
			case 'Black':
				logoUrl = 'https://s3.amazonaws.com/ts-condor-assets/images/logos/tsLogoBlack.svg';
				break;
			default:
				logoUrl = 'https://s3.amazonaws.com/ts-condor-assets/images/logos/tsLogoWhiteBlue.svg';
			}
	
			setSource(logoUrl);
			setDisplay("");
		} else {
			setDisplay("none");
		}
	}, [props.ts_logo, props.ts_logo_color])

	// Custom classes
	let customClasses = '';
	if (props.custom_classes && props.custom_classes.length > 0) {
		props.custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				customClasses += ` ${element}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (props.modifier_classes && props.modifier_classes.length > 0) {
		props.modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

	return (
		<div id={`tsLogo_${props.component_name}`} className={`tsLogo_container ${css.cont} ${modifierClasses} ${customClasses}`}>
			<img className={css.svg} alt="TouchSource Logo" src={source} style={{display: display}} draggable="false"/>
		</div>
	);

};

export default TSLogo;
