import React, { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import QRCode from 'qrcode.react';
import CategoryControl from '../util/CategoryControl';
import {
	ternFSQTokenENV,
	ternStaticTokenENV,
	ternUserNameENV,
} from '../ENV';

const userName = ternUserNameENV;
const fsqAPIToken = ternFSQTokenENV;

const YAHPinUrl = 'https://ts-condor-assets.s3.amazonaws.com/images/icons/yah-icon.png';
const placePinUrl = 'https://ts-condor-assets.s3.amazonaws.com/images/icons/bluePin.png';

if (!mapboxgl.workerClass) {
	const accessToken = ternStaticTokenENV;
	// eslint-disable-next-line import/no-webpack-loader-syntax
	mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // Wire up loaded worker to be used instead of the default
	mapboxgl.accessToken = accessToken;
}

const markerMap = {};

let timer;

const generateRandomSessionToken = (length = 32) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	for (let i = 0; i < length; i++) {
		  result += characters[Math.floor(Math.random() * characters.length)];
	}
	return result;
}

const clearMarkers = (markers) => {
	if (markers) {
		markers.forEach((marker) => {
			marker.remove();
		});
	}
}

const fetchPlaces = (markerMap, category, radius, latitude, longitude, css) => {
	const sessionToken = generateRandomSessionToken();

	const searchParams = new URLSearchParams({
		query: category,
		types: 'place',
		ll: `${latitude},${longitude}`,
		radius,
		session_token: sessionToken,
	}).toString();

	return fetch(`https://api.foursquare.com/v3/autocomplete?${searchParams}`,	{
		method: 'GET',
		headers: new Headers({
			Accept: 'application/json',
			Authorization: fsqAPIToken,
		}),
	})
	.then((searchResults) => {
		return searchResults.json();
	})
	.then((data) => {
		makeMarkers(markerMap, data, category, css);
	});
}

const makeMarkers = (markerMap, data, category, css) => {
	markerMap[category] = markerMap[category] || [];

	data.results.forEach((result) => {
		const amenityMarkerDiv = document.createElement('div');
		amenityMarkerDiv.style.backgroundImage = `url(${placePinUrl})`;
		amenityMarkerDiv.style.width = '25px';
		amenityMarkerDiv.style.height = '41px';
		amenityMarkerDiv.style.transform = 'scale(0.95)';

		const {
			latitude: markerLat,
			longitude: markerLng,
		} = result.place.geocodes.main;

		const placeNameToSearch = `${result.place.name}, ${result.place.location.formatted_address}`;

		const qrCode = <QRCode
			bgColor={'white'} // Color of the bright squares
			fgColor={'black'} // Color of the dark squares
			level="M" // QR Error correction level: L, M, Q, H
			style={{border: `5px solid white`}} // Size in pixels
			value={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(placeNameToSearch)}`}
			renderAs={'svg'}
		/>;

		const popup = `
			<div class="amenitiesMapPopup" style="color:black;">
				<div class="amenitiesMapPlaceName ${css.amenitiesMapPlaceName}"><b>${result.place.name}</b></div>
				<div class="amenitiesMapPlaceAddress ${css.amenitiesMapPlaceAddress}">${result.place.location.formatted_address}</div>
				<div class="amenitiesMapPhone ${css.amenitiesMapPhone}">${444}</div>
				<div class="amenitiesMapQRCode ${css.amenitiesMapQRCode}">${renderToStaticMarkup(qrCode)}</div>
			</div>
		`;

		const amenityPopup = new mapboxgl.Popup({ offset: 25 }).setHTML(popup);

		const amenityMarker = new mapboxgl.Marker(amenityMarkerDiv).setLngLat([markerLng, markerLat]).setPopup(amenityPopup);

		markerMap[category].push(amenityMarker);
	});
}

const setMarkers = (map, markers) => {
	markers.forEach((amenityMarker) => {
		amenityMarker.addTo(map).setOffset([0, -20]);
	});
};

const showCategory = (map, markerMap, radius, category, latitude, longitude, css) => {
	if (markerMap[category]) {
		setMarkers(map, markerMap[category]);
	} else {
		fetchPlaces(markerMap, category, radius, latitude, longitude, css)
		.then(() => {
			setMarkers(map, markerMap[category]);
		});
	}
};

const createMap = (latitude, longitude, zoom, styleId, css, component_name, map, setMap, setResetTimer) => {
	if (map) {
		map.remove();
	}

	const newMap = new mapboxgl.Map({
		container: `dynamicMapAmenities_${component_name}`, // container ID
		style: `mapbox://styles/${userName}/${styleId}`, // style URL
		center: [longitude, latitude], // starting position [lng, lat]
		maxBounds: [[longitude - 4, latitude - 4], [longitude + 4, latitude + 4]],
		zoom, // starting zoom
		minZoom: zoom - 3,
		maxZoom: zoom + 3,
		pitchWithRotate: false,
		dragRotate: false,
		preserveDrawingBuffer: false,
		touchPitch: false,
		touchZoomRotate: false,
	});

	newMap.on('load', () => {
		document.querySelectorAll('.mapboxgl-control-container a').forEach((anchor) => {
			anchor.addEventListener("click", (event) => {
				event.preventDefault();
			});
		});

		const YAHMarkerDiv = document.createElement('div');
		YAHMarkerDiv.style.backgroundImage = `url(${YAHPinUrl})`;
		YAHMarkerDiv.style.width = '25px';
		YAHMarkerDiv.style.height = '41px';

		markerMap['YAH'] = new mapboxgl.Marker(YAHMarkerDiv);

		markerMap['YAH'].setLngLat([longitude, latitude]).addTo(newMap).setOffset([0, -20]);

		setMap(newMap);
	});

	newMap.on('error', (error) => {
		const mapTimeout = 15000;
		console.error(`Error loading map. Will retry in ${mapTimeout}ms.`, error);
		setTimeout(() => {
			createMap(latitude, longitude, zoom, styleId, css, component_name, map, setMap, setResetTimer);
		}, mapTimeout);
	});

	newMap.on('move', () => {
		setResetTimer(Date.now());
	});

	newMap.on('mousemove', () => {
		setResetTimer(Date.now());
	});
};

const DynamicMapAmenities = (props) => {
	const {
		dynamic_map_zoom: zoom,
		dynamic_map_width: width,
		dynamic_map_height: height,
		dynamic_map_categories: categories,
		static_map_style_id: styleId,
		places_search_radius,
		custom_classes,
		modifier_classes,
		component_name,
		navProps: {
			idleTimeoutSeconds,
		},
	} = props;

	const css = props.CSSModules[props.condor_render_name];
	const [ map, setMap, ] = useState();
	const [ category, setCategory, ] = useState('');
	const [ nextCategory, setNextCategory, ] = useState('');
	const [ categoryControl, setCategoryControl, ] = useState();
	const [ resetTimer, setResetTimer, ] = useState();
	const [ resetCategoryControl, setResetCategoryControl, ] = useState();

	const latitude = props.dynamic_map_latitude || props.latitude || 0;
	const longitude = props.dynamic_map_longitude || props.longitude || 0;

	useEffect(() => {
		createMap(latitude, longitude, zoom, styleId, css, component_name, map, setMap, setResetTimer);
		setResetTimer(Date.now());
	}, []);
	
	useEffect(() => {
		if (category === '') {
			return;
		}

		if (map !== undefined) {
			showCategory(map, markerMap, places_search_radius, category, latitude, longitude, css);
		}
	}, [category, ]);

	useEffect(() => {
		if (nextCategory === '') {
			return;
		}

		clearMarkers(markerMap[category]);

		if (nextCategory !== category) {
			setCategory(nextCategory);
			setNextCategory('');
		} else {
			setCategory('');
			setNextCategory('');
		}
	}, [nextCategory, ]);

	useEffect(() => {
		if (map !== undefined) {
			if (categoryControl) {
				map.removeControl(categoryControl);
			}

			clearMarkers(markerMap[category]);

			const newCategoryControl = new CategoryControl({
				categories,
				category,
				setNextCategory,
			});

			map.addControl(newCategoryControl, 'bottom-right');
			setCategoryControl(newCategoryControl);
		}
	}, [ map, categories, latitude, longitude, resetCategoryControl, ]);
	
	useEffect(() => {
		if (!map) {
			return;
		}

		map.setZoom(zoom);
		map.setMaxZoom(zoom + 3);
		map.setMinZoom(zoom - 3);
	}, [zoom, ]);

	useEffect(() => {
		if (!map) {
			return;
		}

		map.setStyle(`mapbox://styles/${userName}/${styleId}`);
	}, [ styleId, ]);

	useEffect(() => {
		if (!map) {
			return;
		}

		map.setCenter([longitude, latitude]);
		map.setMaxBounds([[longitude - 4, latitude - 4], [longitude + 4, latitude + 4]]);
		markerMap['YAH'].setLngLat([longitude, latitude]).addTo(map).setOffset([0, -20]);
	}, [longitude, latitude, ]);

	useEffect(() => {
		if (timer) {
			clearTimeout(timer);
		}

		timer = setTimeout(() => {
			if (!map) {
				return;
			}

			map.setZoom(zoom);
			map.setCenter([longitude, latitude]);
			setResetCategoryControl(Date.now());

		}, idleTimeoutSeconds * 1000);
	}, [resetTimer, ]);
	
	// Custom classes
	let customClasses = '';
	if (custom_classes && custom_classes.length > 0) {
		custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				customClasses += ` ${element}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (modifier_classes && modifier_classes.length > 0) {
		modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

	return (
		<div
			className={`${modifierClasses} ${customClasses} ${css.dynamicMapAmenitiesContainer}`}
			key={`img${props.keyNum}`}
			style={{
				width: `100%`,
				height: `100%`,
			}}
		>
			<div
				id={`dynamicMapAmenities_${component_name}`}
				style={{
					width: `100%`,
					height: `100%`,
				}}
			>
			</div>
		</div>
	);
};

export default DynamicMapAmenities;
