import React, {} from 'react';

const GenerativeArt = (props) => {
    const {
        custom_classes,
        modifier_classes,
        height,
        width,
        generative_art_scenes,
        generative_art_lat,
        generative_art_lon,
        generative_art_duration,
        generative_art_moods,
        generative_art_delay,
        generative_art_card,
        generative_art_specs,
    } = props;

	// Custom classes
	let customClasses = '';
	if (custom_classes && custom_classes.length > 0) {
		custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				customClasses += ` ${element}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (modifier_classes && modifier_classes.length > 0) {
		modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

    let src = "ac9/index.html";
    src += `?scenes=${generative_art_scenes}`;
    src += `&lat=${generative_art_lat}`;
    src += `&lon=${generative_art_lon}`;
    src += `&dur=${generative_art_duration}`;
    src += `&moods=${generative_art_moods}`;
    src += `&delay=${generative_art_delay}`;
    src += `&card=${generative_art_card}`;
    src += `&specs=${generative_art_specs}`;

    let containertStyle = {
        height: height ? `${height}px` : '100%',
        width: width ? `${width}px` : '100%',
        overflow: "hidden",
        position: "relative",
    }

    let iframeStyle = {
        position: "absolute",
        left: "-2px",
        top: "-2px",
        width: "calc(100% + 4px)",
        height: "calc(100% + 4px)",
    }

    return (
        <div
            style={containertStyle}
        >
            <iframe
                src={src}
                sandbox="allow-same-origin allow-scripts"
                title="Generative Art"
                style={iframeStyle}
                frameborder="0"
                scrolling="no"
            />
        </div>
    );
};

export default GenerativeArt;
