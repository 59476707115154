import React from 'react';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import LocationControl from '../util/LocationControl';
import {
	heightENV,
	latENV,
	lonENV,
	ternStaticTokenENV,
	ternUserNameENV,
	widthENV,
	zlENV,
	zoomENV,
} from '../ENV';

const userName = ternUserNameENV;

if (!mapboxgl.workerClass) {
	const accessToken = ternStaticTokenENV;
	// eslint-disable-next-line import/no-webpack-loader-syntax
	mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // Wire up loaded worker to be used instead of the default
	mapboxgl.accessToken = accessToken;
}

class LegacyMapTraffic extends React.Component {
	constructor(props) {
		super(props);

		const roosterLat = latENV;
		const roosterLon = lonENV;
		const roosterZoom = zoomENV;
		const roosterZoomShort = zlENV;

		this.state = {
			css:                         props.CSSModules[props.condor_render_name],
			map:                         null,
			category:                    '',
			nextCategory:                '',
			resetTimer:                  Date.now(),
			results:                     null,
			centerLat:                   Number(roosterLat) || 0,
			centerLng:                   Number(roosterLon) || 0,
			zoom:                        Number(roosterZoom) || Number(roosterZoomShort) || 13,
			idleTimeoutSeconds:          60,
			places_search_radius:        3000,
			styleId:                     'cl6wborf8000j14l2hvv6kab9',
			traffic_line_color_low:      'green',
			traffic_line_color_moderate: 'yellow',
			traffic_line_color_heavy:    'orange',
			traffic_line_color_severe:   'red',
			traffic_line_width:          2.1,
			traffic_line_before_layer:   'road-label-simple',
		};

		this.containerId = `dynamicMapTraffic_${props.component_name}`;
		this.resizeTimer = null;
		this.resizeInterval = null;
		this.resizeObserver = null;
	}

	componentDidMount() {
		console.log('LegacyMapTraffic componentDidMount');

		this.createMap();
		
		this.resizeInterval = setInterval(() => {
			// this.resizeThrottle();
		}, 800);
	}

	componentWillUnmount() {
		console.log('LegacyMapTraffic componentWillUnmount');

		clearTimeout(this.timer);
		clearTimeout(this.resizeTimer);
		clearInterval(this.resizeInterval);
		this.resizeObserver = null;

		const {
			map,
		} = this.state;

		if (map) {
			map.remove();
		}
	}

	resizeThrottle() {
		clearTimeout(this.resizeTimer);

		this.resizeTimer = setTimeout(() => {
			// this.resize();
		}, 150);
	}

	resize() {
		const {
			css,
			map,
		} = this.state;

		if (!map) {
			return;
		}

		map.resize();

		const componentContainer = document.querySelector(`.${css.legacyDynamicMapTrafficContainer}`);
		console.log('LegacyMapTraffic resize componentContainer:', {
			height: componentContainer.clientHeight,
			width: componentContainer.clientWidth,
		});
	}

	createMap() {
		const {
			map,
			styleId,
			centerLat,
			centerLng,
			zoom,
			traffic_line_color_low,
			traffic_line_color_moderate,
			traffic_line_color_heavy,
			traffic_line_color_severe,
			traffic_line_width,
			traffic_line_before_layer,
			css,
		} = this.state;

		if (map) {
			map.remove();
		}

		// clearing the container before making a new map
		const mapContainer = document.querySelector(`#${this.containerId}`)
		if (!mapContainer) {
			return;
		}

		const parentContainer = mapContainer.parentElement;
		
		mapContainer.innerHTML = '';

		this.resizeObserver = null;
		this.resizeObserver = new ResizeObserver(() => {
			this.resize();
		});

		this.resizeObserver.observe(parentContainer);

		const boundOffset = 3;

		const newMap = new mapboxgl.Map({
			container:             this.containerId, // container ID
			style:                 `mapbox://styles/${userName}/${styleId}`, // style URL
			center:                [ centerLng, centerLat, ], // starting position [lng, lat]
			maxBounds:             [[ centerLng - boundOffset, centerLat - boundOffset, ], [ centerLng + boundOffset, centerLat + boundOffset, ],],
			zoom, // starting zoom
			minZoom:               zoom - 3,
			maxZoom:               zoom + 3,
			preserveDrawingBuffer: true,
			interactive:           false,
			attributionControl:    false,
			logoPosition:          'top-left',
		});

		newMap.on('load', () => {
			console.log('LegacyMapTraffic load');
			
			const marker = document.createElement('div');
			marker.style.backgroundImage = `url('https://ts-condor-assets.s3.amazonaws.com/images/icons/yah-icon.png')`;
			marker.style.width = '25px';
			marker.style.height = '41px';

			new mapboxgl.Marker(marker).setLngLat([ centerLng, centerLat, ]).addTo(newMap).setOffset([ 0, -20, ]);

			this.setState({
				map: newMap,
			});

			const locationControl = new LocationControl({
				originalLat:  centerLat,
				originalLng:  centerLng,
				originalZoom: zoom,
			});
			newMap.addControl(locationControl, 'bottom-left');

			const attributionControl = new mapboxgl.AttributionControl();
			newMap.addControl(attributionControl, 'top-left');

			newMap.addLayer({
				id:     'traffic',
				type:   'line',
				source: {
					type: 'vector',
					url:  'mapbox://mapbox.mapbox-traffic-v1',
				},
				'source-layer': 'traffic',
				paint:          {
					'line-color': [
						'match',
						[ 'get', 'congestion', ],
						[ 'low', ],
						traffic_line_color_low,
						[ 'moderate', ],
						traffic_line_color_moderate,
						[ 'heavy', ],
						traffic_line_color_heavy,
						[ 'severe', ],
						traffic_line_color_severe,
						'black',
					],
					'line-width': traffic_line_width,
				},
			}, traffic_line_before_layer);

			document.querySelectorAll('.mapboxgl-control-container a').forEach((anchor) => {
				anchor.addEventListener('click', (e) => {
					e.preventDefault();
				});
			});

			document.querySelectorAll('a.mapboxgl-ctrl-logo').forEach((anchor) => {
				const componentContainer = document.querySelector(`.${css.legacyDynamicMapTrafficContainer}`);
				anchor.addEventListener('click', (e) => {
					e.preventDefault();
					console.log('LegacyMapTraffic componentContainer:', {
						height: componentContainer.clientHeight,
						width: componentContainer.clientWidth,
					});
					// newMap.resize();
				});
			});

			newMap.resize();

			window.addEventListener('resize', () => {
				// this.resizeThrottle();
			});
		});

		newMap.on('idle', function() {
			// newMap.resize()
		});

		newMap.on('error', (error) => {
			const mapTimeout = 15000;
			console.error(`Error loading map. Will retry in ${mapTimeout}ms.`, error);
			setTimeout(() => {
				this.createMap();
			}, mapTimeout);
		});

		newMap.on('move', () => {
			this.resetTimer();
		});

		newMap.on('render', () => {
			// this.resizeThrottle();
		});
	}

	resetMap() {
		const {
			map,
			centerLng,
			centerLat,
			zoom,
		} = this.state;

		map.flyTo({
			center:   [ centerLng, centerLat, ],
			zoom,
			duration: 900,
		});
	}

	resetTimer() {
		const {
			idleTimeoutSeconds,
		} = this.state;

		clearTimeout(this.timer);

		this.timer = setTimeout(() => {
			this.resetMap();
		}, idleTimeoutSeconds * 1000);
	}

	render() {
		const {
			custom_classes,
			modifier_classes,
			keyNum,
		} = this.props;

		const {
			css,
		} = this.state;

		// Custom classes
		let customClasses = '';
		if (custom_classes && custom_classes.length > 0) {
			custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (modifier_classes && modifier_classes.length > 0) {
			modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		let componentContainerStyles = {
			width: '100%',
			height: '100%',
		};

		if (!!heightENV && !!widthENV) {
			componentContainerStyles = {
				height: `${heightENV}px`,
				width: `${widthENV}px`,
			};
		}

		return (
			<div
				className={`${modifierClasses} ${customClasses} ${css.legacyDynamicMapTrafficContainer}`}
				key={`img${keyNum}`}
				style={componentContainerStyles}
			>
				<div className={css.legacyMapLoadingText}>
					Loading/Sizing Map...
				</div>
				<div
					id={this.containerId}
					style={{
						width:  `100%`,
						height: `100%`,
						minHeight: `500px`,
						minWidth: `500px`,
					}}
				>
				</div>
			</div>
		);
	}
}

export default LegacyMapTraffic;
