import React from 'react';

const TextBox = (props) => {
	const css = props.CSSModules[props.condor_render_name];

	// Custom classes
	let customClasses = '';
	if (props.custom_classes && props.custom_classes.length > 0) {
		props.custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				customClasses += ` ${element}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (props.modifier_classes && props.modifier_classes.length > 0) {
		props.modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

	return (
		<div id={`textBox_${props.component_name}`} className={`textBox_container ${css.cont} ${modifierClasses} ${customClasses}`}>
			{props.text}
		</div>
	);

};

export default TextBox;
