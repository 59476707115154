function defaultSorter(a, b) {
	let orderA = a.order || a?.data?.order || a?.props?.order || a?.options?.providerOrder || 0;
	let orderB = b.order || b?.data?.order || b?.props?.order || b?.options?.providerOrder || 0;

	// Sort alphabetically by src if order is the same
	orderA = Number(orderA);
	orderB = Number(orderB);

	if (orderA === orderB) {
		let descA = a?.data?.description || '';
		let descB = b?.data?.description || '';

		// fallback to component_name which is the UUID
		if (descA === descB) {
			descA = a.providerID || a?.props?.component_name || '';
			descB = b.providerID || b?.props?.component_name || '';
		}

		if (descA > descB) {
			return 1;
		}

		return -1;
	}

	if (orderA > orderB) {
		return 1;
	}

	return -1;
}

const makeNextSplitSlides = (currSplitIndex, slides, limit) => {
	if (slides.length === 0 || limit <= 0) {
		console.error('Slides array is empty or limit is invalid.');
		return [currSplitIndex, slides];
	}

	// Adjust the limit to not exceed the number of slides
	limit = Math.min(limit, slides.length);

	// Initialize 'skip' property to true for all slides and create a deep copy
	const nextSlides = slides.map((slide) => {
		return {...slide, skip: true};
	});

	// Normalize currSplitIndex to ensure it's within bounds
	// Adjust currSplitIndex for negative values to start from the beginning
	if (currSplitIndex === -1) {
		currSplitIndex = 0;
	} else {
		currSplitIndex = (((currSplitIndex + 1) % slides.length) + slides.length) % slides.length;
	}

	// Calculate the starting index for the new set of slides to be shown
	// let startMarkIndex = (currSplitIndex + limit) % slides.length;
	// Mark the new set of slides as not skipped
	let newCurrSplitIndex;
	for (let i = 0; i < limit; i++) {
		let index = (currSplitIndex + i) % slides.length;
		nextSlides[index].skip = false;

		// console.log('BROOD::INDEX:', slides.length, newCurrSplitIndex, index);
		newCurrSplitIndex = index;
	}

	// Calculate newCurrSplitIndex for the next call, advancing by 'limit'
	// let newCurrSplitIndex = (startMarkIndex + limit) % slides.length;
	return [newCurrSplitIndex, nextSlides];
};

export {
	defaultSorter,
	makeNextSplitSlides
};