import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/modal.css';

const IFrameComp = (props) => {
	const css = props.CSSModules['Iframe'];
	const { t } = useTranslation();
	const [activeButton, setActiveButton] = useState('');
	
	useEffect(() => {
		if (props.setHasIframeComponent) {
			props.setHasIframeComponent(true)
		}
	}, []);

	// Custom classes
	let customClasses = '';
	if (props.custom_classes && props.custom_classes.length > 0) {
		props.custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				customClasses += ` ${element}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (props.modifier_classes && props.modifier_classes.length > 0) {
		props.modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

	const inputEl = useRef(null);

	const handleClose = () => {
		if (props.handleClose) {
			props.handleClose('iframe');
		} else {
			let animationStartPhase = props.navProps.animationStartPhase
			props.navEvents('setPhaseSetMenu', animationStartPhase, false);
		}
	}

	const goBack = () => {
		window.history.back()
	};

	const goForward = () => {
		window.history.forward()
	};

	const home = () => {
		inputEl.current.src = props.source
	};

	const handleDown = (buttonId) => {
		setActiveButton(buttonId);
	}

	const handleUp = () => {
		setActiveButton('');
	}
	
	const {
		isTouch,
		disable_interactivity,
		disable_mask,
	} = props;

	const iframeContainer = () => {
		let isOnline = navigator.onLine;

		if (!isTouch) {
			// static systems
			
			let hasScrolling = (disable_interactivity && disable_mask) ? false : true
			let scrollbarClass = hasScrolling ? '' : css.noScrollbar;

			let iframeStyle = {
				borderWidth: '0',
			}

			return (
				<iframe
					className={`${css.iframe} iframe ${customClasses} ${scrollbarClass}`}
					id={`iframe_${props.component_name}`}
					src={props.source}
					sandbox='allow-same-origin allow-scripts'
					ref={inputEl}
					style={iframeStyle}
					scrolling={hasScrolling ? 'yes' : 'no'}
				/>
			);
		}

		let innerContainerClasses = 'modal-iframe';
		let iframeClasses = '';
		let iframeUI = null;
		let iframeStyle = {
			overflow: 'hidden',
			borderWidth: '0'
		};
		let disableCloseButton = '';

		if (disable_mask) {
			innerContainerClasses += ' hideMask';
			disableCloseButton = 'disableCloseButton';
		}
		
		if (disable_interactivity) {
			innerContainerClasses += ` ${css?.disableInteractivity}`;
		}

		let hasSource = props.source !== 'no source'


		if (!disable_interactivity) {
			iframeStyle.overflow = 'auto';
			iframeClasses += css?.iframeTouch;

			iframeUI = (
				<div className={`iframeUi ${css?.iframeUi}`}>
					<div className={`navButtonContainer ${css?.navButtonContainer}`}>
						{hasSource &&
							<>
							<button 
								className={`modalButton iframeButtonBack ${css?.navBtn} ${activeButton === 'back' ? css?.activeButton : ''}`} 
								type='button' 
								onMouseDown={() => {
									handleDown('back');
									props.navEvents('btnDebouncer', goBack);
								}}
								onMouseUp={handleUp}
								onMouseLeave={handleUp}
								onTouchStart={() => {
									handleDown('back');
									props.navEvents('btnDebouncer', goBack);
								}}
								onTouchEnd={handleUp}
								onPointerDown={() => {
									handleDown('back');
									props.navEvents('btnDebouncer', goBack);
								}}
								onPointerUp={handleUp}
								onPointerLeave={handleUp}
							>
								{t('backLabel')}
							</button>
							<button 
								className={`modalButton iframeButtonForward ${css?.navBtn} ${activeButton === 'forward' ? `${css?.activeButton} activeButton` : ''}`} 
								type='button' 
								onMouseDown={() => {
									handleDown('forward');
									props.navEvents('btnDebouncer', goForward);
								}}
								onMouseUp={handleUp}
								onMouseLeave={handleUp}
								onTouchStart={() => {
									handleDown('forward');
									props.navEvents('btnDebouncer', goForward);
								}}
								onTouchEnd={handleUp}
								onPointerDown={() => {
									handleDown('forward');
									props.navEvents('btnDebouncer', goForward);
								}}
								onPointerUp={handleUp}
								onPointerLeave={handleUp}
							>
								{t('forwardLabel')}
							</button>
							<button 
								className={`modalButton iframeButtonHome ${css?.navBtn} ${activeButton === 'home' ? `${css?.activeButton} activeButton` : ''}`} 
								type='button' 
								onMouseDown={() => {
									handleDown('home');
									props.navEvents('btnDebouncer', home);
								}}
								onMouseUp={handleUp}
								onMouseLeave={handleUp}
								onTouchStart={() => {
									handleDown('home');
									props.navEvents('btnDebouncer', home);
								}}
								onTouchEnd={handleUp}
								onPointerDown={() => {
									handleDown('home');
									props.navEvents('btnDebouncer', home);
								}}
								onPointerUp={handleUp}
								onPointerLeave={handleUp}
							>
								{t('homeLabel')}
							</button>
							</>
						}				
						<button 
							className={`modalButton iframeButtonClose ${css?.navBtn} ${activeButton === 'close' ? css?.activeButton : ''} ${css?.[disableCloseButton]}`} 
							type='button' 
							onMouseDown={() => {
								handleDown('close');
								props.navEvents('btnDebouncer', handleClose);
							}}
							onMouseUp={handleUp}
							onMouseLeave={handleUp}
							onTouchStart={() => { 
								handleDown('close');
								props.navEvents('btnDebouncer', handleClose);
							}}
							onTouchEnd={handleUp}
							onPointerDown={() => { 
								handleDown('close');
								props.navEvents('btnDebouncer', handleClose);
							}}
							onPointerUp={handleUp}
							onPointerLeave={handleUp}
						>
							{t('closeLabel')}
						</button>
					</div>
				</div>
			);
		}

		let hasScrolling = (disable_interactivity && disable_mask) ? false : true
		let scrollbarClass = hasScrolling ? '' : css.noScrollbar;

		if (!isOnline) {
			return (
				<div className={`${innerContainerClasses}`}>
					<div className={`${css?.iframe} iframe ${css?.offlineIframe} ${iframeClasses} ${scrollbarClass}`} style={iframeStyle}>
						We're sorry, but it seems there is no internet connection at the moment. Please try again later. Thank you for your understanding and patience.
					</div>
					{iframeUI}
				</div>
			)
		}

		return (
			<div className={`${innerContainerClasses}`}>
				{ hasSource ? (
					<iframe
						className={`${css?.iframe} iframe ${iframeClasses} ${customClasses} ${scrollbarClass}`}
						id={`iframe_${props.component_name}`}
						src={props.source}
						sandbox='allow-same-origin allow-scripts'
						ref={inputEl}
						style={iframeStyle}
						scrolling={hasScrolling ? 'yes' : 'no'}
					></iframe>
				) : (
					<div className="iframe">
						There is no URL for this Listing
					</div>
				)}
				{iframeUI}
			</div>
		);
	};



	return (
		<>
			{iframeContainer()}
		</>
	)

};

export default IFrameComp;
