import React, { Fragment } from 'react';

const WeatherIcon = (props) => {
	let icon = '';
	let viewBox = 100;

	switch (props.icon) {
		case 'Clear':
		case 'Sunny':
		case 'Fair':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M50,1027.3c-14.9,0-27.1,12.2-27.1,27.1c0,14.9,12.2,27.1,27.1,27.1s27.1-12.2,27.1-27.1
						C77.1,1039.4,64.9,1027.3,50,1027.3z M50,1031.4c12.7,0,22.9,10.2,22.9,22.9c0,12.7-10.2,22.9-22.9,22.9s-22.9-10.2-22.9-22.9
						C27.1,1041.7,37.3,1031.4,50,1031.4z"/>
						<path d="M50,1004.4c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v10.4c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-10.4C52.1,1005.3,51.2,1004.4,50,1004.4C50,1004.4,50,1004.4,50,1004.4z"/>
						<path d="M85.4,1016.9c-0.5,0-1.1,0.2-1.4,0.6l-8.3,8.3c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l8.3-8.3c0.8-0.8,0.8-2.1,0-2.9C86.5,1017.1,85.9,1016.8,85.4,1016.9z"/>
						<path d="M14.6,1016.9c-1.2,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l8.3,8.3c0.8,0.8,2.1,0.9,2.9,0.1
						c0.8-0.8,0.9-2.1,0.1-2.9c0,0,0,0-0.1-0.1l-8.3-8.3C15.7,1017.1,15.1,1016.9,14.6,1016.9z"/>
						<path d="M50,1089.8c-1.1,0-2.1,1-2.1,2.1v10.4c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-10.4
						C52.1,1090.7,51.2,1089.8,50,1089.8C50,1089.8,50,1089.8,50,1089.8z"/>
						<path d="M77.1,1079.4c-1.2,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l8.3,8.3c0.8,0.8,2.1,0.9,2.9,0.1
						c0.8-0.8,0.9-2.1,0.1-2.9c0,0,0,0-0.1-0.1l-8.3-8.3C78.2,1079.6,77.6,1079.4,77.1,1079.4z"/>
						<path d="M22.9,1079.4c-0.5,0-1.1,0.2-1.4,0.6l-8.3,8.3c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l8.3-8.3c0.8-0.8,0.8-2.1,0-2.9C24,1079.6,23.4,1079.3,22.9,1079.4z"/>
						<path d="M87.5,1052.3c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h10.4c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H87.5z"/>
						<path d="M2.1,1052.3c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h10.4c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H2.1z"/>
					</g>
				</svg>;
			break;
		case 'Cloudy':
		case 'Mostly Cloudy':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M57.6,1020.2c7.9,2.9,13.6,9.6,16,17.5c0.8,0,1.5-0.1,2.2-0.1l0,0c2.3,0.1,4.6,0.5,6.8,1.3c11.8,4.2,19,16.3,17,28.8
						c-1.9,12.1-11.9,21.1-24,21.9c-10.7,0.5-21.6,0.2-30.8,0.2c-8.3-0.4-15.5-5.6-18.5-13.2c-0.4-1.1-0.8-2.3-1-3.5h-5.4
						c0,0-0.1,0-0.1,0c-8.3-0.4-15.5-5.8-18.4-13.7c-2.9-7.9-1-16.8,4.9-22.7c5.6-5.6,13.7-6.3,20.9-3.9c5-8.3,13.7-13.9,22.9-13.8
						C52.6,1019,55.4,1019.4,57.6,1020.2L57.6,1020.2z M50.6,1023L50.6,1023c-8.1-0.3-16.3,4.9-20.6,12.8c-0.1,0.2-0.2,0.4-0.4,0.5
						l-0.3,0.3c-0.6,0.6-1.5,0.7-2.2,0.4c-6.3-2.6-13.3-2.1-17.9,2.6c-4.7,4.7-6.3,11.9-3.9,18.3c2.4,6.3,8.1,10.6,14.7,11h5
						c0,0,0,0,0-0.1c0-0.6,0.1-1.2,0.1-1.8c0,0,0-0.1,0-0.1c0.2-1.9,0.8-3.8,1.5-5.6c1.1-2.6,2.7-4.6,4.6-6.6c5.6-5.4,13.7-6.1,20.9-3.7
						c3.1-5,7.7-8.9,12.9-11.1c1.4-0.6,2.9-1.1,4.3-1.5c-2.1-6.5-6.8-11.8-13.2-14.2C54.3,1023.4,52.5,1023.1,50.6,1023L50.6,1023z
						M75.7,1041.7c-1.2-0.1-2.3,0.1-3.4,0.2c-1.7,0.2-3.3,0.7-4.9,1.3c-5.2,2.2-9.4,6-12.4,10.8c-0.9,1.4-1.5,1.7-3,1.3
						c-6.1-1.9-12.5-2-17.1,1.7c-0.3,0.2-0.6,0.5-0.9,0.8c0,0,0,0,0,0c-3.3,3.2-4.8,7.4-4.9,11.9c0,0.3,0,0.7,0,1c0.1,1.5,0.5,2.9,1,4.4
						c2.3,6,8.1,10.2,14.8,10.5c9.9,0,19.8,0,29.7,0c10.5-0.3,19.2-8.1,20.9-18.5c1.6-10.5-4.4-20.7-14.3-24.2
						C79.4,1042.2,77.4,1041.9,75.7,1041.7L75.7,1041.7z"/>
					</g>
				</svg>;
			break;
		case 'Dust storm':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M77.1,1048.1c-0.3,0-0.5,0.1-0.8,0.1c-15.2,5.7-30.6,5-46.5,0c-1.1-0.4-2.3,0.2-2.7,1.3
						c-0.4,1.1,0.2,2.3,1.3,2.7c0,0,0.1,0,0.1,0c16.5,5.3,33,6.1,49.2,0c1.1-0.4,1.6-1.6,1.3-2.7C78.7,1048.7,78,1048.1,77.1,1048.1
						L77.1,1048.1z"/>
						<path d="M25,1058.5c-1.1,0-2.1,0.9-2.1,2.1c0,1,0.7,1.9,1.7,2.1c11.2,2.2,26.6,3.4,38.4,0c1.1-0.3,1.8-1.4,1.5-2.5
						c-0.3-1.1-1.4-1.8-2.5-1.5c-0.1,0-0.1,0-0.2,0c-10.6,3.1-25.6,2.1-36.4,0C25.3,1058.6,25.2,1058.5,25,1058.5L25,1058.5z"/>
						<path d="M25,1068.9c-1.2,0-2.1,0.9-2.1,2c0,0.9,0.6,1.8,1.5,2c9.3,2.9,22,2.8,30.4,0.2c1.1-0.3,1.7-1.5,1.4-2.6
						c-0.3-1.1-1.5-1.7-2.6-1.4c-7.2,2.3-19.7,2.4-27.9-0.2C25.5,1069,25.3,1068.9,25,1068.9L25,1068.9z"/>
						<path d="M31.2,1079.4c-1.1,0-2.1,0.9-2.1,2.1c0,0.9,0.6,1.8,1.5,2c7,2,11.5,3.6,26,0c1.1-0.2,1.8-1.3,1.6-2.5
						c-0.2-1.1-1.3-1.9-2.5-1.6c-0.1,0-0.1,0-0.2,0c-14.1,3.5-16.7,2.1-23.8,0C31.7,1079.4,31.5,1079.4,31.2,1079.4L31.2,1079.4z"/>
						<path d="M54.2,1089.8c-0.4,0-0.8,0.1-1.1,0.3c-4.9,3-11.7,1.5-17-0.2c-1.1-0.4-2.3,0.2-2.7,1.3
						c-0.4,1.1,0.2,2.3,1.3,2.7c0,0,0.1,0,0.1,0c5.5,1.8,13.6,3.9,20.4-0.2c1-0.6,1.3-1.9,0.7-2.8C55.6,1090.2,54.9,1089.8,54.2,1089.8
						L54.2,1089.8z"/>
						<path d="M41.7,1100.2c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0H50c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H41.7z"/>
						<path d="M39.4,1004.4c-0.2,0-0.5,0-0.7,0.1c-4.2,0.3-7.8,2.9-9.5,6.6c-1.2-0.3-2.4-0.8-3.7-0.7c0,0,0,0,0,0c-1.2,0-2.5,0.3-3.7,0.7
						c-6.4,2.3-10.2,8.8-9.2,15.6c0.9,6,5.6,10.6,11.3,11.7c2.3,2.6,5.9,4.1,10,4.1c0,0,0,0,0,0c1.7,0,3.3-0.4,4.8-0.8
						c2.3,1.5,5.1,2.3,8.2,2.3c4,0,7.5-1.4,9.8-3.9c0.6,0.1,1.2,0.1,1.7,0.1c0,0,0,0,0,0c1.4,0,2.7-0.2,4-0.5c1.9,0.7,3.9,1.3,6,1.3
						c0,0,0,0,0,0c4,0,7.8-1.2,10.2-3.8l-0.8,0.6c5.6-2,9.6-6.9,9.6-12.4c0-5.7-4.1-10.5-9.8-12.4c-1.5-3.5-4.2-6.4-8-7.8
						c-1.2-0.4-2.5-0.7-3.7-0.7c-4.9-0.2-9.2,2.6-12,6.6c-1.5-0.4-3.1-0.6-4.6-0.4C47.5,1006.9,43.7,1004.4,39.4,1004.4L39.4,1004.4z
						M39.4,1008.5C39.4,1008.5,39.4,1008.5,39.4,1008.5c3.1,0,5.8,2,6.8,4.9c0.3,1,1.4,1.7,2.5,1.4c1.6-0.4,3.4-0.2,5.2,0.5
						c0.7,0.3,1.6,0.1,2.2-0.4l0.2-0.2c0.2-0.1,0.3-0.3,0.4-0.5c2-3.5,5.7-5.7,9.3-5.6c0.8,0,1.6,0.2,2.5,0.5c2.9,1,5.1,3.4,6,6.2
						c0.2,0.7,0.8,1.2,1.4,1.3c4.6,1.3,7.6,4.9,7.6,8.8c0,3.6-2.6,7-6.8,8.5c-0.3,0.1-0.6,0.3-0.8,0.6c-1.2,1.3-4,2.4-7.1,2.4
						c-1.9,0-3.8-0.4-5.2-1.1c-0.4-0.2-0.9-0.3-1.4-0.1c-1.1,0.3-2.2,0.4-3.4,0.4c-0.7,0-1.3,0-2-0.1c-0.8-0.1-1.6,0.3-2.1,1
						c-0.8,1.4-3.9,2.9-7.5,2.9c-2.7,0-5.2-0.8-6.6-1.9c-0.6-0.5-1.3-0.6-2-0.3c-1.3,0.5-2.8,0.7-4.4,0.7c-3.6,0-6.7-1.6-7.5-2.9
						c-0.3-0.5-0.9-0.9-1.5-1c-4.2-0.6-7.6-3.9-8.3-8.3c-0.7-4.8,2-9.4,6.4-11c0.8-0.3,1.6-0.4,2.5-0.5c1.3,0,2.7,0.2,4,0.8
						c1.1,0.5,2.3,0,2.7-1.1c0-0.1,0.1-0.2,0.1-0.3C33.2,1010.8,36.1,1008.5,39.4,1008.5L39.4,1008.5z M53.3,1011.7
						c-0.1,0.2-0.3,0.2-0.4,0.4L53.3,1011.7L53.3,1011.7L53.3,1011.7z"/>
					</g>
				</svg>;
			break;
		case 'Hail':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M75,1077.3c-1.2,0-2.1,1-2.1,2.1l0,0v8.3c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-8.3
						C77.1,1078.2,76.2,1077.3,75,1077.3C75,1077.3,75,1077.3,75,1077.3z"/>
						<path d="M75,1091.9c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S78.4,1091.9,75,1091.9z M75,1096
						c1.2,0,2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1C72.9,1096.9,73.8,1096,75,1096z"/>
						<path d="M41.6,1077.3c-1.2,0-2.1,1-2.1,2.1l0,0v8.3c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-8.3
						C43.8,1078.2,42.8,1077.3,41.6,1077.3C41.7,1077.3,41.7,1077.3,41.6,1077.3z"/>
						<path d="M41.7,1091.9c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S45.1,1091.9,41.7,1091.9z M41.7,1096
						c1.2,0,2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1C39.6,1096.9,40.5,1096,41.7,1096z"/>
						<path d="M58.3,1071c-1.2,0-2.1,1-2.1,2.1l0,0v8.3c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-8.3
						C60.4,1072,59.5,1071,58.3,1071C58.3,1071,58.3,1071,58.3,1071z"/>
						<path d="M58.3,1085.6c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S61.8,1085.6,58.3,1085.6z M58.3,1089.8
						c1.2,0,2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1C56.2,1090.7,57.2,1089.8,58.3,1089.8z"/>
						<path d="M25,1071c-1.2,0-2.1,1-2.1,2.1h0v8.3c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-8.3
						C27.1,1072,26.2,1071,25,1071C25,1071,25,1071,25,1071z"/>
						<path d="M25,1085.6c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S28.4,1085.6,25,1085.6z M25,1089.8
						c1.2,0,2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1C22.9,1090.7,23.8,1089.8,25,1089.8z"/>
						<path d="M42.4,1005.6c-7.9,2.9-13.6,9.6-16,17.5c-0.8,0-1.5-0.1-2.2-0.1l0,0c-2.3,0.1-4.6,0.5-6.8,1.3c-11.8,4.2-19,16.3-17,28.8
						c1.9,12.1,11.9,21.1,24,21.9c10.7,0.5,21.6,0.2,30.8,0.2c8.3-0.4,15.5-5.6,18.5-13.2c0.4-1.1,0.8-2.3,1-3.5h5.4c0,0,0.1,0,0.1,0
						c8.3-0.4,15.5-5.8,18.4-13.7c2.9-7.9,1-16.8-4.9-22.7c-5.6-5.6-13.7-6.3-20.9-3.9c-5-8.3-13.7-13.9-22.9-13.8
						C47.4,1004.4,44.6,1004.9,42.4,1005.6L42.4,1005.6z M49.4,1008.4L49.4,1008.4c8.1-0.3,16.3,4.9,20.6,12.8c0.1,0.2,0.2,0.4,0.4,0.5
						l0.3,0.3c0.6,0.6,1.5,0.7,2.2,0.4c6.3-2.6,13.3-2.1,17.9,2.6c4.7,4.7,6.3,11.9,3.9,18.3c-2.4,6.3-8.1,10.6-14.7,11h-5
						c0,0,0,0,0-0.1c0-0.6-0.1-1.2-0.1-1.8c0,0,0-0.1,0-0.1c-0.2-1.9-0.8-3.8-1.5-5.6c-1.1-2.6-2.7-4.6-4.6-6.6
						c-5.6-5.4-13.7-6.1-20.9-3.7c-3.1-5-7.7-8.9-12.9-11.1c-1.4-0.6-2.9-1.1-4.3-1.5c2.1-6.5,6.8-11.8,13.2-14.2
						C45.7,1008.9,47.5,1008.5,49.4,1008.4L49.4,1008.4z M24.3,1027.1L24.3,1027.1c1.1,0,2.2,0,3.2,0.2c0.1,0,0.1,0,0.2,0.1
						c1.7,0.2,3.3,0.7,4.9,1.3c5.2,2.2,9.6,5.9,12.4,10.8c0.1,0.2,0.2,0.4,0.4,0.5l0.3,0.3l0,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
						c0,0,0,0,0.1,0l0,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.2,0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
						c0,0,0,0,0,0c0.4,0.1,0.8,0.1,1.2-0.1c5.9-2.3,12.5-2,17.1,1.7c0.3,0.2,0.6,0.5,0.9,0.8c0,0,0,0,0,0c3.3,3.2,4.8,7.4,4.9,11.9
						c0,0.3,0,0.7,0,1c-0.1,1.5-0.5,2.9-1,4.4c-2.3,6-8.1,10.2-14.8,10.5H25.5c-0.1,0-0.1,0-0.2,0c-10.5-0.3-19.2-8.1-20.9-18.5
						c-1.6-10.5,4.4-20.7,14.3-24.2C20.6,1027.6,22.4,1027.2,24.3,1027.1L24.3,1027.1z"/>
					</g>
				</svg>;
			break;
		case 'Haze':
		case 'Smoke':
			icon =
				<svg version="1.1" viewBox="0 0 300 300" fill={props.lineColor}>
					<path d="m220.5 182.3h-141.29c-3.24 0-5.86-2.62-5.86-5.86 0-42.18 34.32-76.5 76.5-76.5s76.5 34.32 76.5 76.5c0.01 3.24-2.61 5.86-5.85 5.86zm-135.16-11.71h129.05c-2.97-32.99-30.77-58.93-64.52-58.93s-61.57 25.94-64.53 58.93z" /><path d="m149.86 77.14c-3.24 0-5.86-2.62-5.86-5.86v-29.11c0-3.24 2.62-5.86 5.86-5.86s5.86 2.62 5.86 5.86v29.11c0 3.24-2.63 5.86-5.86 5.86z" /><path d="m284.03 182.3h-29.11c-3.24 0-5.86-2.62-5.86-5.86s2.62-5.86 5.86-5.86h29.11c3.24 0 5.86 2.62 5.86 5.86s-2.62 5.86-5.86 5.86z" /><path d="m44.1 182.3h-29.11c-3.24 0-5.86-2.62-5.86-5.86s2.62-5.86 5.86-5.86h29.11c3.24 0 5.86 2.62 5.86 5.86s-2.63 5.86-5.86 5.86z" /><path d="m269.48 211.25h-156.52c-3.24 0-5.86-2.62-5.86-5.86s2.62-5.86 5.86-5.86h156.52c3.24 0 5.86 2.62 5.86 5.86s-2.63 5.86-5.86 5.86z" /><path d="m92.83 211.25h-48.73c-3.24 0-5.86-2.62-5.86-5.86s2.62-5.86 5.86-5.86h48.73c3.24 0 5.86 2.62 5.86 5.86s-2.63 5.86-5.86 5.86z" /><path d="m131.95 236.94h-102.41c-3.24 0-5.86-2.62-5.86-5.86s2.62-5.86 5.86-5.86h102.41c3.24 0 5.86 2.62 5.86 5.86s-2.62 5.86-5.86 5.86z" /><path d="m202.84 236.94h-51.2c-3.24 0-5.86-2.62-5.86-5.86s2.62-5.86 5.86-5.86h51.2c3.24 0 5.86 2.62 5.86 5.86s-2.62 5.86-5.86 5.86z" /><path d="m244.51 263.68h-151.68c-3.24 0-5.86-2.62-5.86-5.86s2.62-5.86 5.86-5.86h151.68c3.24 0 5.86 2.62 5.86 5.86-0.01 3.24-2.63 5.86-5.86 5.86z" /><path d="m228.45 103.55c-1.5 0-3-0.57-4.14-1.72-2.29-2.29-2.29-5.99 0-8.28l20.58-20.58c2.29-2.29 5.99-2.29 8.28 0s2.29 5.99 0 8.28l-20.58 20.58c-1.15 1.15-2.64 1.72-4.14 1.72z" /><path d="m71.71 103.55c-1.5 0-3-0.57-4.14-1.72l-20.58-20.57c-2.29-2.29-2.29-5.99 0-8.28s5.99-2.29 8.28 0l20.58 20.58c2.29 2.29 2.29 5.99 0 8.28-1.14 1.14-2.64 1.71-4.14 1.71z" />
				</svg>;
			break;
		case 'Heavy Fog':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M49.4,1006.4C49.4,1006.4,49.3,1006.5,49.4,1006.4c-0.2,0-0.4,0.1-0.5,0.1c-2.1,0.1-4.2,0.5-6.3,1.2
						c-7.9,2.9-13.6,9.6-16,17.5c-0.8,0-1.7-0.1-2.4-0.1c-2.3,0-4.6,0.5-6.8,1.3c-11.8,4.2-19,16.3-17,28.7c1,11,11.3,22.2,24.9,22.1
						c10.2-0.1,20,0,30,0c8.3-0.4,15.5-5.6,18.5-13.1c0.4-1.2,0.8-2.3,1-3.5h5.5c0,0,0.1,0,0.1,0c8.3-0.4,15.5-5.8,18.4-13.7
						c2.9-7.9,1-16.7-4.9-22.7c-5.6-5.6-13.7-6.3-20.9-3.9c-5-8.3-13.7-13.9-22.9-13.8C49.9,1006.5,49.6,1006.4,49.4,1006.4L49.4,1006.4
						z M49.5,1010.6L49.5,1010.6c8.1-0.3,16.2,4.9,20.5,12.8c0.1,0.2,0.2,0.4,0.4,0.5l0.3,0.3c0.6,0.6,1.5,0.7,2.2,0.4
						c6.3-2.6,13.2-2.1,17.9,2.6c4.7,4.7,6.3,11.9,3.9,18.3c-2.4,6.3-8.1,10.6-14.7,11H75c-0.1-3.4-1-6.7-2.7-9.7
						c-0.9-1.6-2-3.1-3.4-4.5c-3.5-3.4-8-4.9-12.7-5c-2.7-0.1-5.5,0.4-8.2,1.2c-3.2-5-7.8-8.9-13.1-11.1c-0.2-0.1-0.5-0.2-0.7-0.3
						c-0.3-0.1-0.6-0.2-0.9-0.4c-0.8-0.3-1.6-0.5-2.5-0.7c2.1-6.5,6.8-11.9,13.2-14.3C45.8,1011,47.7,1010.7,49.5,1010.6L49.5,1010.6z
						M24.6,1029.3c9.7,0.2,16.4,6.2,20.3,12.3c0.9,1.4,1.8,1.5,2.9,1.2c6.5-2.1,13.4-2,18,2.5c4.7,4.5,6.3,11.3,3.9,17.3
						c-2.3,6-8.1,10.2-14.8,10.5c-9.9,0-19.8,0-29.7,0c-10.5-0.3-19.2-8.1-20.8-18.5c-1.6-10.5,4.4-20.6,14.3-24.1
						C20.6,1029.8,22.8,1029.3,24.6,1029.3L24.6,1029.3z"/>
						<path d="M22.9,1016.9c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h24.9c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0C47.9,1016.9,22.9,1016.9,22.9,1016.9z"/>
						<path d="M54.2,1064.8c-1.2,0-2.1,0.9-2.1,2.1s0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h24.9c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H54.2z"/>
						<path d="M6.3,1098.1c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h33.3c1.2,0,2.1-0.9,2.1-2.1
						s-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0C39.6,1098.1,6.3,1098.1,6.3,1098.1z"/>
						<path d="M10.4,1089.8c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h79.1c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H10.4z"/>
						<path d="M54.2,1081.4c-1.2,0-2.1,0.9-2.1,2.1s0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h39.5c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0C93.7,1081.4,54.2,1081.4,54.2,1081.4z"/>
					</g>
				</svg>;
			break;
		case 'Heavy Rain':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M20.8,1012.7c-0.5,0-1.1,0.2-1.4,0.6L9,1023.7c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C21.9,1012.9,21.4,1012.7,20.8,1012.7z"/>
						<path d="M43.7,1012.7c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C44.8,1012.9,44.3,1012.7,43.7,1012.7z"/>
						<path d="M66.6,1012.7c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C67.8,1012.9,67.2,1012.7,66.6,1012.7z"/>
						<path d="M89.5,1012.7c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C90.7,1012.9,90.1,1012.7,89.5,1012.7z"/>
						<path d="M31.2,1035.6c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C32.3,1035.8,31.8,1035.6,31.2,1035.6L31.2,1035.6z"/>
						<path d="M54.1,1035.6c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C55.3,1035.8,54.7,1035.6,54.1,1035.6L54.1,1035.6z"/>
						<path d="M77,1035.6c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C78.2,1035.8,77.6,1035.6,77,1035.6L77,1035.6z"/>
						<path d="M20.8,1058.5c-0.5,0-1.1,0.2-1.4,0.6L9,1069.5c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C21.9,1058.7,21.4,1058.5,20.8,1058.5z"/>
						<path d="M43.7,1058.5c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C44.8,1058.7,44.3,1058.5,43.7,1058.5z"/>
						<path d="M66.6,1058.5c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C67.8,1058.7,67.2,1058.5,66.6,1058.5z"/>
						<path d="M89.5,1058.5c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C90.7,1058.7,90.1,1058.5,89.5,1058.5z"/>
						<path d="M31.2,1081.4c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C32.3,1081.7,31.8,1081.4,31.2,1081.4z"/>
						<path d="M54.1,1081.4c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C55.3,1081.7,54.7,1081.4,54.1,1081.4z"/>
						<path d="M77,1081.4c-0.5,0-1.1,0.2-1.4,0.6l-10.4,10.4c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l10.4-10.4c0.8-0.8,0.8-2.1,0-2.9C78.2,1081.7,77.6,1081.4,77,1081.4z"/>
					</g>
				</svg>;
			break;
		case 'Rain & Snow':
		case 'Heavy Snow':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M14.6,1014.8c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C16.7,1015.7,15.8,1014.8,14.6,1014.8C14.6,1014.8,14.6,1014.8,14.6,1014.8z"/>
						<path d="M20.8,1018.9c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8s1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C22.4,1019.4,21.6,1018.9,20.8,1018.9z"/>
						<path d="M8.3,1018.9c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C9,1019,8.6,1018.9,8.3,1018.9L8.3,1018.9z"/>
						<path d="M37.5,1004.4c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C39.6,1005.3,38.7,1004.4,37.5,1004.4C37.5,1004.4,37.5,1004.4,37.5,1004.4z"/>
						<path d="M43.7,1008.5c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C45.3,1009,44.5,1008.5,43.7,1008.5z"/>
						<path d="M31.2,1008.5c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C31.9,1008.6,31.5,1008.5,31.2,1008.5L31.2,1008.5z"/>
						<path d="M60.4,1014.8c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C62.5,1015.7,61.6,1014.8,60.4,1014.8C60.4,1014.8,60.4,1014.8,60.4,1014.8z"/>
						<path d="M66.7,1018.9c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8s1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C68.2,1019.4,67.5,1018.9,66.7,1018.9z"/>
						<path d="M54.1,1018.9c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C54.8,1019,54.5,1018.9,54.1,1018.9L54.1,1018.9z"/>
						<path d="M83.3,1004.4c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C85.4,1005.3,84.5,1004.4,83.3,1004.4C83.3,1004.4,83.3,1004.4,83.3,1004.4z"/>
						<path d="M89.6,1008.5c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C91.1,1009,90.4,1008.5,89.6,1008.5z"/>
						<path d="M77,1008.5c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C77.7,1008.6,77.4,1008.5,77,1008.5L77,1008.5z"/>
						<path d="M14.6,1085.6c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C16.7,1086.6,15.8,1085.6,14.6,1085.6C14.6,1085.6,14.6,1085.6,14.6,1085.6z"/>
						<path d="M20.8,1089.8c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C22.4,1090.2,21.6,1089.8,20.8,1089.8L20.8,1089.8z"/>
						<path d="M8.3,1089.8c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1L9.3,1090C9,1089.9,8.6,1089.8,8.3,1089.8z"/>
						<path d="M37.5,1075.2c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C39.6,1076.2,38.7,1075.2,37.5,1075.2C37.5,1075.2,37.5,1075.2,37.5,1075.2z"/>
						<path d="M43.7,1079.4c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C45.3,1079.8,44.5,1079.4,43.7,1079.4L43.7,1079.4z"/>
						<path d="M31.2,1079.4c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C31.9,1079.4,31.5,1079.4,31.2,1079.4z"/>
						<path d="M60.4,1085.6c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C62.5,1086.6,61.6,1085.6,60.4,1085.6C60.4,1085.6,60.4,1085.6,60.4,1085.6z"/>
						<path d="M66.7,1089.8c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C68.2,1090.2,67.5,1089.8,66.7,1089.8L66.7,1089.8z"/>
						<path d="M54.1,1089.8c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C54.8,1089.9,54.5,1089.8,54.1,1089.8z"/>
						<path d="M83.3,1075.2c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C85.4,1076.2,84.5,1075.2,83.3,1075.2C83.3,1075.2,83.3,1075.2,83.3,1075.2z"/>
						<path d="M89.6,1079.4c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C91.1,1079.8,90.4,1079.4,89.6,1079.4L89.6,1079.4z"/>
						<path d="M77,1079.4c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9s0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C77.7,1079.4,77.4,1079.4,77,1079.4z"/>
						<path d="M14.6,1050.2c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C16.7,1051.2,15.8,1050.2,14.6,1050.2C14.6,1050.2,14.6,1050.2,14.6,1050.2z"/>
						<path d="M20.8,1054.4c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C22.4,1054.8,21.6,1054.4,20.8,1054.4L20.8,1054.4z"/>
						<path d="M8.3,1054.4c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C9,1054.4,8.6,1054.4,8.3,1054.4z"/>
						<path d="M37.5,1039.8c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C39.6,1040.7,38.7,1039.8,37.5,1039.8C37.5,1039.8,37.5,1039.8,37.5,1039.8z"/>
						<path d="M43.7,1043.9c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C45.3,1044.4,44.5,1043.9,43.7,1043.9L43.7,1043.9z"/>
						<path d="M31.2,1043.9c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C31.9,1044,31.5,1043.9,31.2,1043.9z"/>
						<path d="M60.4,1050.2c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C62.5,1051.2,61.6,1050.2,60.4,1050.2C60.4,1050.2,60.4,1050.2,60.4,1050.2z"/>
						<path d="M66.7,1054.4c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C68.2,1054.8,67.5,1054.4,66.7,1054.4L66.7,1054.4z"/>
						<path d="M54.1,1054.4c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9s0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C54.8,1054.4,54.5,1054.4,54.1,1054.4z"/>
						<path d="M83.3,1039.8c-1.1,0-2.1,1-2.1,2.1v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C85.4,1040.7,84.5,1039.8,83.3,1039.8C83.3,1039.8,83.3,1039.8,83.3,1039.8z"/>
						<path d="M89.6,1043.9c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8s1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C91.1,1044.4,90.4,1043.9,89.6,1043.9L89.6,1043.9z"/>
						<path d="M77,1043.9c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9s0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C77.7,1044,77.4,1043.9,77,1043.9z"/>
					</g>
				</svg>;
			break;
		case 'Hot':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M27.1,1018.9c-5.7,0-10.4,4.7-10.4,10.4c0,15,0,30,0,45.1c-5.3,4.4-7.5,11.5-5.5,18.1c2.2,7,8.6,11.9,15.9,11.9
						c7.3,0,13.7-4.8,15.9-11.9c2-6.6-0.2-13.8-5.5-18.1c0-15,0-30,0-45C37.5,1023.6,32.8,1018.9,27.1,1018.9L27.1,1018.9z M27.1,1023.1
						c3.5,0,6.2,2.8,6.2,6.2c0,15.6,0,31.3,0,46.9l0.9,0.6c4.5,3.2,6.4,9,4.8,14.3c-1.6,5.3-6.5,8.9-11.9,8.9c-5.5,0-10.3-3.6-11.9-8.9
						c-1.6-5.3,0.3-11.2,4.8-14.4l0.9-0.6c0-15.6,0-31.3,0-46.9C20.8,1025.9,23.6,1023.1,27.1,1023.1z"/>
						<path d="M27.1,1079.4c-4.6,0-8.3,3.8-8.3,8.3s3.8,8.3,8.3,8.3s8.3-3.8,8.3-8.3S31.7,1079.4,27.1,1079.4z M27.1,1083.5
						c2.3,0,4.2,1.8,4.2,4.2c0,2.3-1.8,4.2-4.2,4.2s-4.2-1.8-4.2-4.2C22.9,1085.4,24.8,1083.5,27.1,1083.5z"/>
						<path d="M27.1,1027.3c-1.2,0-2.1,1-2.1,2.1l0,0v52c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-52
						C29.2,1028.2,28.3,1027.3,27.1,1027.3C27.1,1027.3,27.1,1027.3,27.1,1027.3L27.1,1027.3z"/>
						<path d="M66.7,1014.8c-6.9,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5s12.5-5.6,12.5-12.5C79.2,1020.4,73.5,1014.8,66.7,1014.8
						z M66.7,1018.9c4.6,0,8.3,3.7,8.3,8.3c0,4.6-3.7,8.3-8.3,8.3s-8.3-3.7-8.3-8.3C58.3,1022.7,62,1018.9,66.7,1018.9z"/>
						<path d="M66.6,1004.4c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v4.1c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-4.1C68.8,1005.3,67.9,1004.4,66.6,1004.4C66.7,1004.4,66.7,1004.4,66.6,1004.4z"/>
						<path d="M83.3,1010.6c-0.5,0-1.1,0.2-1.4,0.6l-2,2c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l2-2c0.8-0.8,0.8-2.1,0-2.9C84.4,1010.8,83.9,1010.6,83.3,1010.6z"/>
						<path d="M50,1010.6c-1.1,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l2,2c0.8,0.8,2.1,0.9,2.9,0.1
						c0.8-0.8,0.9-2.1,0.1-2.9c0,0,0,0-0.1-0.1l-2-2C51.1,1010.8,50.6,1010.6,50,1010.6z"/>
						<path d="M66.6,1041.9c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v4.1c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-4.1C68.8,1042.8,67.9,1041.9,66.6,1041.9C66.7,1041.9,66.7,1041.9,66.6,1041.9z"/>
						<path d="M45.9,1025.2c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0H50c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H45.9z"/>
						<path d="M83.4,1025.2c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h4.1c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H83.4z"/>
						<path d="M81.2,1037.7c-1.1,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l2,2c0.8,0.8,2.1,0.9,2.9,0.1
						c0.8-0.8,0.9-2.1,0.1-2.9c0,0,0,0-0.1-0.1l-2-2C82.3,1037.9,81.8,1037.7,81.2,1037.7z"/>
						<path d="M52,1037.7c-0.5,0-1.1,0.2-1.4,0.6l-2,2c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l2-2c0.8-0.8,0.8-2.1,0-2.9C53.2,1037.9,52.6,1037.7,52,1037.7z"/>
					</g>
				</svg>;
			break;
		case 'Ice':
		case 'Frost':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M33.3,1012.7c-5.7,0-10.4,4.6-10.4,10.4c0,15,0,30,0,45.1c-5.3,4.4-7.5,11.5-5.5,18.1c2.2,7,8.6,11.9,15.9,11.9
						c7.3,0,13.7-4.8,15.9-11.9c2-6.6-0.2-13.8-5.5-18.1c0-15,0-30,0-45C43.8,1017.4,39.1,1012.7,33.3,1012.7z M33.3,1016.9
						c3.5,0,6.2,2.8,6.2,6.2c0,15.6,0,31.3,0,46.9l0.9,0.6c4.5,3.2,6.4,9,4.8,14.3c-1.6,5.3-6.5,9-11.9,9c-5.5,0-10.3-3.6-11.9-8.9
						c-1.6-5.3,0.3-11.2,4.8-14.4l0.9-0.6c0-15.6,0-31.3,0-46.9C27.1,1019.6,29.9,1016.9,33.3,1016.9L33.3,1016.9z"/>
						<path d="M33.3,1073.1c-4.6,0-8.3,3.8-8.3,8.3s3.8,8.3,8.3,8.3s8.3-3.8,8.3-8.3S37.9,1073.1,33.3,1073.1z M33.3,1077.3
						c2.3,0,4.2,1.8,4.2,4.2c0,2.3-1.8,4.2-4.2,4.2c-2.3,0-4.2-1.8-4.2-4.2C29.2,1079.1,31,1077.3,33.3,1077.3z"/>
						<path d="M33.3,1060.6c-1.1,0-2.1,1-2.1,2.1v12.4c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-12.4
						C35.4,1061.6,34.5,1060.6,33.3,1060.6C33.3,1060.6,33.3,1060.6,33.3,1060.6z"/>
						<path d="M58.3,1010.6c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-14.5C60.4,1011.6,59.5,1010.6,58.3,1010.6C58.3,1010.6,58.3,1010.6,58.3,1010.6L58.3,1010.6z"/>
						<path d="M52,1014.8c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1L53,1015C52.7,1014.9,52.4,1014.8,52,1014.8z"/>
						<path d="M64.6,1014.8c-0.3,0-0.7,0.1-0.9,0.2l-12.5,6.2c-1,0.5-1.4,1.8-0.9,2.8c0.5,1,1.8,1.4,2.8,0.9l12.5-6.2
						c1-0.5,1.5-1.7,1-2.8C66.1,1015.2,65.4,1014.8,64.6,1014.8L64.6,1014.8z"/>
						<path d="M75,1023.1c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C77.1,1024.1,76.2,1023.1,75,1023.1C75,1023.1,75,1023.1,75,1023.1z"/>
						<path d="M68.7,1027.3c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C69.4,1027.4,69.1,1027.3,68.7,1027.3z"/>
						<path d="M81.2,1027.3c-0.3,0-0.7,0.1-0.9,0.2l-12.5,6.2c-1,0.5-1.4,1.8-0.9,2.8c0.5,1,1.8,1.4,2.8,0.9l12.5-6.2
						c1-0.5,1.5-1.7,1-2.8C82.8,1027.7,82,1027.3,81.2,1027.3L81.2,1027.3z"/>
					</g>
				</svg>;
			break;
		case 'Light Fog':
		case 'Mist':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M63.5,1027.3c-9.8-0.1-19,5.7-24.3,14.4c-7.6-2.6-16.2-1.8-22.1,4c-6.2,6.1-8.3,15.4-5.2,23.6c3.1,8.2,10.7,13.8,19.5,14.2
						c0,0,0.1,0,0.1,0H63c0.3,0,0.6-0.1,0.9-0.2c12.8-0.8,23.3-10.6,25.3-23.7c2.1-13.4-5.5-26.5-17.9-31.1h0
						C68.5,1027.7,66,1027.3,63.5,1027.3L63.5,1027.3z M64,1031.4L64,1031.4c2,0.1,4,0.4,5.9,1.2c10.6,3.8,17,15,15.2,26.5
						c-1.8,11.5-11.1,19.9-22.2,20.3c-10.5,0-21,0-31.4,0c-7.1-0.4-13.2-4.9-15.8-11.5c-2.5-6.6-0.9-14.1,4.2-19.1
						c4.9-4.9,12.4-5.4,19.2-2.7c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.3-0.1,0.4-0.2
						c0.6-0.3,0.9-0.7,1.2-1.1C46.7,1036.5,55.4,1031,64,1031.4L64,1031.4z"/>
						<path d="M60.4,1039.7c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h37.4c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H60.4z"/>
						<path d="M2.1,1054.3c-1.2,0-2.1,0.9-2.1,2.1s0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h37.4c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0C39.6,1054.3,2.1,1054.3,2.1,1054.3z"/>
						<path d="M52.1,1068.9c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2,2.1c0,0,0,0,0.1,0h37.4c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2-2.1c0,0,0,0-0.1,0H52.1z"/>
					</g>
				</svg>;
			break;
		case 'Drizzle':
		case 'Light Rain':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M63.5,1006.4c-9.8-0.1-19,5.7-24.3,14.4c-7.6-2.6-16.2-1.8-22.1,4c-6.2,6.1-8.3,15.4-5.2,23.6c3.1,8.2,10.7,13.8,19.5,14.2
						c10.8-0.1,22,0,31.8,0c13.1-0.4,24.1-10.5,26.2-23.8c2.1-13.4-5.5-26.5-17.9-31.1h0C68.4,1006.7,66.2,1006.4,63.5,1006.4
						L63.5,1006.4z M64,1010.5L64,1010.5c2,0.1,4,0.4,5.9,1.2c10.6,3.8,17,15,15.2,26.5c-1.8,11.5-11.1,19.9-22.2,20.3
						c-10.4,0.1-21,0-31.5,0c-7.1-0.4-13.2-4.9-15.8-11.5c-2.5-6.6-0.9-14.1,4.2-19.1c5-4.9,12.4-5.4,19.2-2.7c0.8,0.3,1.6,0.1,2.2-0.4
						c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.3-0.3,0.4-0.5C46.6,1015.7,55.4,1010.2,64,1010.5L64,1010.5z"/>
						<path d="M31.2,1068.9c-0.7,0-1.3,0.4-1.6,0.9c0,0-1.1,1.6-2.2,3.4c-0.5,0.9-1.1,2-1.5,3c-0.4,1-0.8,1.9-0.8,3.1
						c0,3.4,2.8,6.2,6.2,6.2s6.3-2.8,6.3-6.2c0-1.2-0.4-2.1-0.8-3.1c-0.4-1-1-2-1.5-3c-1.1-1.9-2.2-3.4-2.2-3.4
						C32.6,1069.3,31.9,1068.9,31.2,1068.9z M31.2,1074.9c0.1,0.2,0.1,0.2,0.3,0.4c0.5,0.9,1,1.8,1.3,2.6c0.3,0.8,0.5,1.5,0.5,1.4
						c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1c0,0.1,0.1-0.7,0.5-1.4c0.3-0.8,0.8-1.7,1.3-2.6
						C31.1,1075.1,31.1,1075.2,31.2,1074.9z"/>
						<path d="M68.7,1068.9c-0.7,0-1.3,0.4-1.6,0.9c0,0-1.1,1.6-2.2,3.4c-0.5,0.9-1.1,2-1.5,3c-0.4,1-0.8,1.9-0.8,3.1
						c0,3.4,2.8,6.2,6.3,6.2c3.4,0,6.2-2.8,6.2-6.2c0-1.2-0.4-2.1-0.8-3.1c-0.4-1-1-2-1.5-3c-1.1-1.9-2.2-3.4-2.2-3.4
						C70.1,1069.3,69.4,1068.9,68.7,1068.9z M68.8,1074.9c0.1,0.2,0.1,0.2,0.3,0.4c0.5,0.9,1,1.8,1.3,2.6c0.3,0.8,0.5,1.5,0.5,1.4
						c0,1.2-0.9,2.1-2.1,2.1s-2.1-0.9-2.1-2.1c0,0.1,0.1-0.7,0.5-1.4c0.3-0.8,0.8-1.7,1.3-2.6C68.6,1075.1,68.6,1075.2,68.8,1074.9z"/>
						<path d="M49.9,1085.6c-0.7,0-1.3,0.4-1.6,0.9c0,0-1.1,1.6-2.2,3.4c-0.5,0.9-1.1,2-1.5,3c-0.4,1-0.8,1.9-0.8,3.1
						c0,3.4,2.8,6.3,6.2,6.3c3.4,0,6.2-2.8,6.2-6.3c0-1.2-0.4-2.1-0.8-3.1c-0.4-1-1-2-1.5-3c-1.1-1.9-2.2-3.4-2.2-3.4
						C51.3,1085.9,50.6,1085.6,49.9,1085.6z M50,1091.6c0.1,0.2,0.1,0.2,0.3,0.4c0.5,0.9,1,1.8,1.3,2.6c0.3,0.8,0.5,1.5,0.5,1.4
						c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1c0,0.1,0.1-0.7,0.5-1.4c0.3-0.8,0.8-1.7,1.3-2.6C49.9,1091.8,49.9,1091.8,50,1091.6z
						"/>
					</g>
				</svg>;
			break;
		case 'Partly Cloudy':
		case 'Partly Sunny':
		case 'Overcast':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M48,1039.8c-0.2,0-0.4,0-0.7,0.1c-8.7-0.1-16.9,5-21.6,12.7c-6.8-2.2-14.4-1.6-19.8,3.6c-5.6,5.5-7.4,13.7-4.6,21
						c2.8,7.3,9.7,12.2,17.5,12.6c11.2,0,21.2-0.1,29.4-0.1c12.1-0.6,20.6-9.5,22.4-21.1c1.8-11.9-4.9-23.5-16.1-27.6
						C52.3,1040.3,50.1,1039.9,48,1039.8L48,1039.8z M47.8,1044L47.8,1044c1.8,0.1,3.5,0.4,5.2,1c9.3,3.3,14.9,13,13.4,23
						c-1.5,10-9.7,17.3-19.5,17.6c-9.3,0-18.6,0-27.9,0c-6.2-0.3-11.6-4.2-13.8-10c-2.2-5.7-0.8-12.2,3.7-16.5
						c4.3-4.2,10.8-4.5,16.8-2.3c1.1,0.4,2.2-0.2,2.5-0.7C34.1,1047.1,40.8,1043.9,47.8,1044L47.8,1044z"/>
						<path d="M67.9,1033.6c-5.8,0.5-10.9,3.8-13.4,9.4c-1.3,2.6,2.8,4.4,3.8,1.7c2.5-5.7,8.5-8.2,14.3-6.5c5.8,1.7,9.5,7.6,8.6,13.8
						c-0.9,6.2-6,10.7-12,10.7c-2.8,0-2.8,4.2,0,4.2c8.1,0,15-6.1,16.2-14.3c1.1-8.2-3.7-16-11.5-18.4
						C71.8,1033.6,69.8,1033.4,67.9,1033.6L67.9,1033.6z"/>
						<path d="M68.7,1018.9c-1.1,0-2.1,1-2.1,2.1v6.2c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-6.2
						C70.8,1019.9,69.9,1019,68.7,1018.9C68.8,1018.9,68.7,1018.9,68.7,1018.9z"/>
						<path d="M89.5,1027.3c-0.5,0-1.1,0.2-1.4,0.6L84,1032c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C90.7,1027.5,90.1,1027.3,89.5,1027.3L89.5,1027.3z"/>
						<path d="M85.4,1064.8c-1.1,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l4.1,4.1c0.8,0.8,2.1,0.9,2.9,0.1
						c0.8-0.8,0.9-2.1,0.1-2.9c0,0,0,0-0.1-0.1l-4.1-4.1C86.5,1065,86,1064.8,85.4,1064.8z"/>
						<path d="M91.7,1046c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h6.2c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H91.7z"/>
						<path d="M47.9,1027.3c-1.1,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l4.1,4.1c0.8,0.8,2.1,0.9,2.9,0.1
						c0.8-0.8,0.9-2.1,0.1-2.9c0,0,0,0-0.1-0.1l-4.1-4.1C49,1027.5,48.5,1027.3,47.9,1027.3z"/>
					</g>
				</svg>;
			break;
		case 'Rain & Wind':
		case 'Rain':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M62.6,1014.8c-0.2,0-0.4,0-0.7,0.1c-8.7-0.1-16.9,5-21.6,12.7c-6.8-2.2-14.4-1.6-19.8,3.6c-5.6,5.5-7.4,13.7-4.6,21
						c2.8,7.3,9.7,12.3,17.5,12.6c0,0,0.1,0,0.1,0h28.1c12.9,0,21.7-11,23.5-21.2c1.8-11.9-4.9-23.5-16.1-27.6
						C66.9,1015.3,64.7,1014.9,62.6,1014.8L62.6,1014.8z M62.4,1019L62.4,1019c1.8,0.1,3.5,0.4,5.2,1c9.3,3.3,14.9,13,13.4,23
						c-1.5,10-9.7,17.3-19.5,17.6c-9.3,0-18.6,0-27.9,0c-6.2-0.3-11.6-4.2-13.8-10c-2.2-5.7-0.8-12.2,3.7-16.5
						c4.3-4.2,10.8-4.5,16.8-2.3c1.4,0.5,2.2-0.1,2.9-1.2C47.4,1023.6,54.8,1018.7,62.4,1019L62.4,1019z"/>
						<path d="M35.4,1071c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C36.5,1071.2,35.9,1071,35.4,1071z"/>
						<path d="M52,1071c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1l4.1-4.1
						c0.8-0.8,0.8-2.1,0-2.9C53.2,1071.2,52.6,1071,52,1071z"/>
						<path d="M68.7,1071c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C69.8,1071.2,69.3,1071,68.7,1071L68.7,1071z"/>
						<path d="M85.4,1071c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C86.5,1071.2,85.9,1071,85.4,1071z"/>
						<path d="M18.7,1071c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C19.8,1071.2,19.3,1071,18.7,1071z"/>
						<path d="M43.7,1085.6c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C44.8,1085.8,44.3,1085.6,43.7,1085.6z"/>
						<path d="M60.4,1085.6c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C61.5,1085.8,60.9,1085.6,60.4,1085.6z"/>
						<path d="M77,1085.6c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C78.2,1085.8,77.6,1085.6,77,1085.6L77,1085.6z"/>
						<path d="M27,1085.6c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C28.2,1085.8,27.6,1085.6,27,1085.6z"/>
					</g>
				</svg>;
			break;
		case 'Rain Showers':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M52.1,1060.6c-1.2,0-2.1,1-2.1,2.1l0,0v33.4c0,0,0,0,0,0c0.1,4.5,3.8,8.2,8.4,8.2c4.5,0,8.3-3.8,8.3-8.3
						c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1c0,0,0,0,0,0.1c0,2.3-1.8,4.1-4.1,4.1c-2.3,0-4.2-1.8-4.2-4.1v-33.4
						C54.2,1061.6,53.3,1060.6,52.1,1060.6C52.1,1060.6,52.1,1060.6,52.1,1060.6L52.1,1060.6z"/>
						<path d="M22.9,1058.5c-8.7,0-16,6.2-16.6,14.4c-0.1,1.1,0.7,2.2,1.9,2.3c1.1,0.1,2.2-0.7,2.3-1.9c0,0,0,0,0-0.1
						c0.4-5.9,5.8-10.5,12.5-10.5c6.7,0,12.1,4.7,12.5,10.5c0.1,1.1,1.1,2,2.2,1.9c1.1-0.1,2-1.1,1.9-2.2c0,0,0,0,0,0
						C38.9,1064.7,31.6,1058.5,22.9,1058.5z"/>
						<path d="M52.1,1058.5c-8.7,0-16,6.2-16.6,14.4c-0.1,1.1,0.7,2.2,1.9,2.3c1.1,0.1,2.2-0.7,2.3-1.9c0,0,0,0,0-0.1
						c0.4-5.9,5.8-10.5,12.5-10.5c6.7,0,12.1,4.7,12.5,10.5c0.1,1.1,1.1,2,2.2,1.9c1.1-0.1,2-1.1,1.9-2.2c0,0,0,0,0,0
						C68.1,1064.7,60.7,1058.5,52.1,1058.5z"/>
						<path d="M81.2,1058.5c-8.7,0-16,6.2-16.6,14.4c-0.1,1.1,0.7,2.2,1.9,2.3c1.1,0.1,2.2-0.7,2.3-1.9c0,0,0,0,0-0.1
						c0.4-5.9,5.8-10.5,12.5-10.5c6.7,0,12.1,4.7,12.5,10.5c0.1,1.1,1.1,2,2.2,1.9c1.1-0.1,2-1.1,1.9-2.2c0,0,0,0,0,0
						C97.3,1064.7,89.9,1058.5,81.2,1058.5z"/>
						<path d="M52.1,1029.4c-24.1,0-44.1,19.1-45.8,43.6c-0.1,1.1,0.7,2.2,1.9,2.3s2.2-0.7,2.3-1.9c0-0.1,0-0.1,0-0.2
						c1.5-22.4,19.7-39.7,41.6-39.7c21.9,0,40.1,17.3,41.7,39.7c0,1.2,1,2.1,2.2,2c1.1,0,2.1-1,2-2.2c0,0,0-0.1,0-0.1
						C96.2,1048.4,76.2,1029.3,52.1,1029.4L52.1,1029.4z"/>
						<path d="M52.1,1023.1c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v6.2c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-6.2
						C54.2,1024.1,53.3,1023.1,52.1,1023.1C52.1,1023.1,52.1,1023.1,52.1,1023.1z"/>
						<path d="M8.3,1004.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C9.4,1004.6,8.9,1004.3,8.3,1004.4L8.3,1004.4z"/>
						<path d="M33.3,1004.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C34.4,1004.6,33.9,1004.3,33.3,1004.4L33.3,1004.4z"/>
						<path d="M58.3,1004.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C59.4,1004.6,58.9,1004.3,58.3,1004.4L58.3,1004.4z"/>
						<path d="M83.3,1004.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C84.4,1004.6,83.9,1004.3,83.3,1004.4L83.3,1004.4z"/>
						<path d="M20.8,1012.7c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C21.9,1012.9,21.4,1012.7,20.8,1012.7L20.8,1012.7z"/>
						<path d="M45.8,1012.7c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C46.9,1012.9,46.4,1012.7,45.8,1012.7L45.8,1012.7z"/>
						<path d="M70.8,1012.7c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C71.9,1012.9,71.4,1012.7,70.8,1012.7L70.8,1012.7z"/>
						<path d="M95.8,1012.7c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C96.9,1012.9,96.4,1012.7,95.8,1012.7L95.8,1012.7z"/>
						<path d="M8.3,1021c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1l4.1-4.1
						c0.8-0.8,0.8-2.1,0-2.9C9.4,1021.2,8.9,1021,8.3,1021L8.3,1021z"/>
						<path d="M33.3,1021c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C34.4,1021.2,33.9,1021,33.3,1021L33.3,1021z"/>
						<path d="M83.3,1021c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C84.4,1021.2,83.9,1021,83.3,1021L83.3,1021z"/>
						<path d="M18.7,1029.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C19.8,1029.6,19.3,1029.3,18.7,1029.4L18.7,1029.4z"/>
						<path d="M95.8,1029.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C96.9,1029.6,96.4,1029.3,95.8,1029.4L95.8,1029.4z"/>
					</g>
				</svg>;
			break;
		case 'Snow Showers':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M50,1060.6c-1.2,0-2.1,1-2.1,2.1l0,0v33.4c0,0,0,0,0,0c0.1,4.5,3.8,8.2,8.4,8.2c4.5,0,8.3-3.8,8.3-8.3
						c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1c0,0,0,0,0,0.1c0,2.3-1.8,4.1-4.1,4.1c-2.3,0-4.2-1.8-4.2-4.1v-33.4
						C52.1,1061.6,51.2,1060.6,50,1060.6C50,1060.6,50,1060.6,50,1060.6L50,1060.6z"/>
						<path d="M20.8,1058.5c-8.7,0-16,6.2-16.7,14.4c-0.1,1.1,0.7,2.2,1.9,2.3c1.1,0.1,2.2-0.7,2.3-1.9c0,0,0,0,0-0.1
						c0.4-5.9,5.8-10.5,12.5-10.5c6.7,0,12.1,4.7,12.5,10.5c0.1,1.1,1.1,2,2.2,1.9c1.1-0.1,2-1.1,1.9-2.2c0,0,0,0,0,0
						C36.9,1064.7,29.5,1058.5,20.8,1058.5z"/>
						<path d="M50,1058.5c-8.7,0-16,6.2-16.7,14.4c-0.1,1.1,0.7,2.2,1.9,2.3c1.1,0.1,2.2-0.7,2.3-1.9c0,0,0,0,0-0.1
						c0.4-5.9,5.8-10.5,12.5-10.5c6.7,0,12.1,4.7,12.5,10.5c0.1,1.1,1.1,2,2.2,1.9c1.1-0.1,2-1.1,1.9-2.2c0,0,0,0,0,0
						C66,1064.7,58.7,1058.5,50,1058.5z"/>
						<path d="M79.2,1058.5c-8.7,0-16,6.2-16.7,14.4c-0.1,1.1,0.7,2.2,1.9,2.3c1.1,0.1,2.2-0.7,2.3-1.9c0,0,0,0,0-0.1
						c0.4-5.9,5.8-10.5,12.5-10.5c6.7,0,12.1,4.7,12.5,10.5c0.1,1.1,1.1,2,2.2,1.9c1.1-0.1,2-1.1,1.9-2.2c0,0,0,0,0,0
						C95.2,1064.7,87.8,1058.5,79.2,1058.5z"/>
						<path d="M50,1029.4c-24.1,0-44.1,19.1-45.8,43.6c-0.1,1.1,0.7,2.2,1.9,2.3c1.1,0.1,2.2-0.7,2.3-1.9c0-0.1,0-0.1,0-0.2
						c1.5-22.4,19.7-39.7,41.7-39.7c21.9,0,40.1,17.3,41.7,39.7c0,1.1,1,2.1,2.2,2c1.2,0,2.1-1,2-2.2c0,0,0-0.1,0-0.1
						C94.1,1048.4,74.1,1029.3,50,1029.4L50,1029.4z"/>
						<path d="M50,1023.1c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v6.2c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-6.2C52.1,1024.1,51.2,1023.1,50,1023.1C50,1023.1,50,1023.1,50,1023.1z"/>
						<path d="M6.2,1004.4c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v10.4c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-10.4C8.4,1005.3,7.4,1004.4,6.2,1004.4C6.3,1004.4,6.2,1004.4,6.2,1004.4z"/>
						<path d="M2,1006.4c-1.1,0-2,1-2,2.2c0,0.6,0.3,1.2,0.9,1.6l8.3,6.2c0.9,0.7,2.2,0.5,2.9-0.4c0.7-0.9,0.5-2.2-0.4-2.9
						l-8.3-6.2C3,1006.6,2.5,1006.4,2,1006.4z"/>
						<path d="M10.4,1006.4c-0.5,0-0.9,0.1-1.3,0.4l-8.3,6.2c-0.9,0.7-1.1,2-0.4,2.9c0.7,0.9,2,1.1,2.9,0.4l0,0l8.3-6.2
						c0.9-0.7,1.1-2,0.5-2.9C11.7,1006.8,11.1,1006.5,10.4,1006.4z"/>
						<path d="M72.9,1014.8c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v10.4c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-10.4C75,1015.7,74.1,1014.8,72.9,1014.8C72.9,1014.8,72.9,1014.8,72.9,1014.8z"/>
						<path d="M68.7,1016.9c-1.1,0.1-2,1-2,2.2c0,0.6,0.3,1.2,0.9,1.6l8.3,6.2c0.9,0.7,2.2,0.5,2.9-0.4
						c0.7-0.9,0.5-2.2-0.4-2.9l-8.3-6.2C69.6,1017,69.2,1016.8,68.7,1016.9z"/>
						<path d="M77.1,1016.9c-0.5,0-0.9,0.1-1.3,0.4l-8.3,6.2c-0.9,0.7-1.1,2-0.4,2.9c0.7,0.9,2,1.1,2.9,0.4l8.3-6.2
						c0.9-0.7,1.1-2,0.5-2.9C78.4,1017.2,77.8,1016.9,77.1,1016.9L77.1,1016.9z"/>
						<path d="M27.1,1014.8c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v10.4c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-10.4C29.2,1015.7,28.3,1014.8,27.1,1014.8C27.1,1014.8,27.1,1014.8,27.1,1014.8z"/>
						<path d="M22.8,1016.9c-1.1,0.1-2,1-2,2.2c0,0.6,0.3,1.2,0.9,1.6l8.3,6.2c0.9,0.7,2.2,0.5,2.9-0.4
						c0.7-0.9,0.5-2.2-0.4-2.9l-8.3-6.2C23.8,1017,23.3,1016.8,22.8,1016.9z"/>
						<path d="M31.3,1016.9c-0.5,0-0.9,0.1-1.3,0.4l-8.3,6.2c-0.9,0.7-1.1,2-0.4,2.9c0.7,0.9,2,1.1,2.9,0.4l8.3-6.2
						c0.9-0.7,1.1-2,0.5-2.9C32.5,1017.2,31.9,1016.9,31.3,1016.9L31.3,1016.9z"/>
						<path d="M6.2,1025.2c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v10.4c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-10.4C8.4,1026.2,7.4,1025.2,6.2,1025.2C6.3,1025.2,6.2,1025.2,6.2,1025.2z"/>
						<path d="M2,1027.3c-1.1,0.1-2,1-2,2.2c0,0.6,0.3,1.2,0.9,1.6l8.3,6.2c0.9,0.7,2.2,0.5,2.9-0.4c0.7-0.9,0.5-2.2-0.4-2.9
						l0,0l-8.3-6.2C3,1027.4,2.5,1027.3,2,1027.3L2,1027.3z"/>
						<path d="M10.4,1027.3c-0.5,0-0.9,0.1-1.3,0.4l-8.3,6.2c-0.9,0.7-1.1,2-0.4,2.9c0.7,0.9,2,1.1,2.9,0.4l0,0l8.3-6.2
						c0.9-0.7,1.1-2,0.5-2.9C11.7,1027.6,11.1,1027.3,10.4,1027.3L10.4,1027.3z"/>
						<path d="M93.7,1025.2c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v10.4c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-10.4C95.9,1026.2,94.9,1025.2,93.7,1025.2C93.8,1025.2,93.7,1025.2,93.7,1025.2z"/>
						<path d="M89.5,1027.3c-1.1,0.1-2,1-2,2.2c0,0.6,0.3,1.2,0.9,1.6l8.3,6.2c0.9,0.7,2.2,0.5,2.9-0.4
						c0.7-0.9,0.5-2.2-0.4-2.9l0,0l-8.3-6.2C90.5,1027.4,90,1027.3,89.5,1027.3L89.5,1027.3z"/>
						<path d="M97.9,1027.3c-0.5,0-0.9,0.1-1.3,0.4l-8.3,6.2c-0.9,0.7-1.1,2-0.4,2.9c0.7,0.9,2,1.1,2.9,0.4l8.3-6.2
						c0.9-0.7,1.1-2,0.5-2.9C99.2,1027.6,98.6,1027.3,97.9,1027.3L97.9,1027.3z"/>
						<path d="M93.7,1004.4c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v10.4c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-10.4C95.9,1005.3,94.9,1004.4,93.7,1004.4C93.8,1004.4,93.7,1004.4,93.7,1004.4z"/>
						<path d="M89.5,1006.4c-1.1,0-2,1-2,2.2c0,0.6,0.3,1.2,0.9,1.6l8.3,6.2c0.9,0.7,2.2,0.5,2.9-0.4
						c0.7-0.9,0.5-2.2-0.4-2.9l0,0l-8.3-6.2C90.5,1006.6,90,1006.4,89.5,1006.4z"/>
						<path d="M97.9,1006.4c-0.5,0-0.9,0.1-1.3,0.4l-8.3,6.2c-0.9,0.7-1.1,2-0.4,2.9c0.7,0.9,2,1.1,2.9,0.4l0,0l8.3-6.2
						c0.9-0.7,1.1-2,0.5-2.9C99.2,1006.8,98.6,1006.5,97.9,1006.4z"/>
						<path d="M50,1004.4c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v10.4c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-10.4C52.1,1005.3,51.2,1004.4,50,1004.4C50,1004.4,50,1004.4,50,1004.4z"/>
						<path d="M45.7,1006.4c-1.1,0-2,1-2,2.2c0,0.6,0.3,1.2,0.9,1.6l8.3,6.2c0.9,0.7,2.2,0.5,2.9-0.4
						c0.7-0.9,0.5-2.2-0.4-2.9l0,0l-8.3-6.2C46.7,1006.6,46.2,1006.4,45.7,1006.4z"/>
						<path d="M54.2,1006.4c-0.5,0-0.9,0.1-1.3,0.4l-8.3,6.2c-0.9,0.7-1.1,2-0.4,2.9c0.7,0.9,2,1.1,2.9,0.4l0,0l8.3-6.2
						c0.9-0.7,1.1-2,0.5-2.9C55.5,1006.8,54.8,1006.5,54.2,1006.4z"/>
					</g>
				</svg>;
			break;
		case 'Freezing Drizzle':
		case 'Sleet':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M14.6,1058.5c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C16.7,1059.5,15.8,1058.5,14.6,1058.5C14.6,1058.5,14.6,1058.5,14.6,1058.5z"/>
						<path d="M8.3,1062.7c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C9,1062.8,8.7,1062.7,8.3,1062.7z"/>
						<path d="M20.8,1062.7c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C22.4,1063.2,21.6,1062.7,20.8,1062.7z"/>
						<path d="M35.5,1060.6c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9c1,0.6,2.3,0.3,2.9-0.7c0,0,0,0,0,0
						l6.2-10.4c0.6-1,0.3-2.3-0.7-2.9C36.2,1060.7,35.8,1060.6,35.5,1060.6z"/>
						<path d="M50,1058.5c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C52.1,1059.5,51.2,1058.5,50,1058.5C50,1058.5,50,1058.5,50,1058.5z"/>
						<path d="M43.7,1062.7c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C44.4,1062.8,44.1,1062.7,43.7,1062.7z"/>
						<path d="M56.2,1062.7c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C57.8,1063.2,57,1062.7,56.2,1062.7z"/>
						<path d="M70.9,1060.6c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9c1,0.6,2.3,0.3,2.9-0.7c0,0,0,0,0,0
						l6.2-10.4c0.6-1,0.3-2.3-0.7-2.9C71.6,1060.7,71.2,1060.6,70.9,1060.6z"/>
						<path d="M85.4,1058.5c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C87.5,1059.5,86.6,1058.5,85.4,1058.5C85.4,1058.5,85.4,1058.5,85.4,1058.5z"/>
						<path d="M79.1,1062.7c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C79.8,1062.8,79.5,1062.7,79.1,1062.7z"/>
						<path d="M91.6,1062.7c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C93.2,1063.2,92.4,1062.7,91.6,1062.7z"/>
						<path d="M18.8,1081.4c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9c1,0.6,2.3,0.3,2.9-0.7c0,0,0,0,0,0
						l6.2-10.4c0.6-1,0.3-2.3-0.7-2.9C19.5,1081.6,19.2,1081.5,18.8,1081.4z"/>
						<path d="M33.3,1079.4c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C35.4,1080.3,34.5,1079.4,33.3,1079.4C33.4,1079.4,33.3,1079.4,33.3,1079.4z"/>
						<path d="M27.1,1083.5c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C27.7,1083.6,27.4,1083.5,27.1,1083.5z"/>
						<path d="M39.6,1083.5c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C41.1,1084,40.4,1083.5,39.6,1083.5z"/>
						<path d="M54.2,1081.4c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9c1,0.6,2.3,0.3,2.9-0.7c0,0,0,0,0,0
						l6.2-10.4c0.6-1,0.3-2.3-0.7-2.9C54.9,1081.6,54.6,1081.5,54.2,1081.4z"/>
						<path d="M68.7,1079.4c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C70.8,1080.3,69.9,1079.4,68.7,1079.4C68.8,1079.4,68.7,1079.4,68.7,1079.4z"/>
						<path d="M62.4,1083.5c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C63.1,1083.6,62.8,1083.5,62.4,1083.5z"/>
						<path d="M75,1083.5c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C76.5,1084,75.8,1083.5,75,1083.5z"/>
						<path d="M89.6,1081.4c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9c1,0.6,2.3,0.3,2.9-0.7c0,0,0,0,0,0
						l6.2-10.4c0.6-1,0.3-2.3-0.7-2.9C90.3,1081.6,90,1081.5,89.6,1081.4z"/>
						<path d="M10.4,1012.7c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C12.5,1013.7,11.6,1012.7,10.4,1012.7C10.4,1012.7,10.4,1012.7,10.4,1012.7z"/>
						<path d="M4.1,1016.9c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C4.8,1016.9,4.5,1016.9,4.1,1016.9L4.1,1016.9z"/>
						<path d="M16.7,1016.9c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C18.2,1017.3,17.5,1016.9,16.7,1016.9L16.7,1016.9z"/>
						<path d="M33.4,1014.8c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9c1,0.6,2.3,0.3,2.9-0.7c0,0,0,0,0-0.1
						l6.2-10.4c0.6-1,0.3-2.3-0.7-2.9C34.1,1014.9,33.8,1014.8,33.4,1014.8L33.4,1014.8z"/>
						<path d="M50,1012.7c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C52.1,1013.7,51.2,1012.7,50,1012.7C50,1012.7,50,1012.7,50,1012.7z"/>
						<path d="M43.7,1016.9c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C44.4,1016.9,44.1,1016.9,43.7,1016.9L43.7,1016.9z"/>
						<path d="M56.2,1016.9c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C57.8,1017.3,57,1016.9,56.2,1016.9L56.2,1016.9z"/>
						<path d="M73,1014.8c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9c1,0.6,2.3,0.3,2.9-0.7c0,0,0,0,0-0.1
						l6.2-10.4c0.6-1,0.3-2.3-0.7-2.9C73.7,1014.9,73.3,1014.8,73,1014.8L73,1014.8z"/>
						<path d="M89.5,1012.7c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C91.7,1013.7,90.7,1012.7,89.5,1012.7C89.6,1012.7,89.6,1012.7,89.5,1012.7z"/>
						<path d="M83.3,1016.9c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9s0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C84,1016.9,83.6,1016.9,83.3,1016.9L83.3,1016.9z"/>
						<path d="M95.8,1016.9c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C97.3,1017.3,96.6,1016.9,95.8,1016.9L95.8,1016.9z"/>
						<path d="M14.7,1037.7c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9c1,0.6,2.3,0.3,2.9-0.7c0,0,0,0,0-0.1
						l6.2-10.4c0.6-1,0.3-2.3-0.7-2.9C15.4,1037.8,15,1037.7,14.7,1037.7z"/>
						<path d="M31.2,1035.6c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C33.4,1036.6,32.4,1035.6,31.2,1035.6C31.3,1035.6,31.2,1035.6,31.2,1035.6L31.2,1035.6z"/>
						<path d="M25,1039.8c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1L26,1040C25.7,1039.9,25.3,1039.8,25,1039.8z"/>
						<path d="M37.5,1039.8c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C39,1040.2,38.3,1039.8,37.5,1039.8z"/>
						<path d="M54.2,1037.7c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9c1,0.6,2.3,0.3,2.9-0.7c0,0,0,0,0-0.1
						l6.2-10.4c0.6-1,0.3-2.3-0.7-2.9C54.9,1037.8,54.6,1037.7,54.2,1037.7L54.2,1037.7z"/>
						<path d="M70.8,1035.6c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C72.9,1036.6,72,1035.6,70.8,1035.6C70.8,1035.6,70.8,1035.6,70.8,1035.6L70.8,1035.6z"/>
						<path d="M64.5,1039.8c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.8c0.5-1,0.2-2.3-0.8-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C65.2,1039.9,64.9,1039.8,64.5,1039.8z"/>
						<path d="M77.1,1039.8c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C78.6,1040.2,77.9,1039.8,77.1,1039.8z"/>
						<path d="M93.8,1037.7c-0.8,0-1.5,0.4-1.9,1l-6.2,10.4c-0.6,1-0.3,2.3,0.7,2.9s2.3,0.3,2.9-0.7c0,0,0,0,0-0.1l6.2-10.4
						c0.6-1,0.3-2.3-0.7-2.9C94.5,1037.8,94.1,1037.7,93.8,1037.7L93.8,1037.7z"/>
					</g>
				</svg>;
			break;
		case 'Flurries':
		case 'Blowing Snow':
		case 'Snow Flurries':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M85.4,1062.7c-0.5,0-1.1,0.2-1.4,0.6l-4.2,4.2c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l2.7-2.7l3.3,3.3l-4.2,4.2H27.1c-0.6,0-1.1,0.2-1.5,0.6l-6.2,6.3c-0.8,0.8-0.8,2.1,0,2.9l6.2,6.2
						c0.4,0.4,0.9,0.6,1.5,0.6h49.1l2.1,2.1l-2.7,2.7c-0.8,0.8-0.8,2.1,0,2.9c0.8,0.8,2.1,0.8,2.9,0c0,0,0,0,0,0l4.2-4.2
						c0.8-0.8,0.8-2.1,0-2.9c0,0,0,0,0,0l-4.2-4.2c-0.4-0.4-0.9-0.6-1.5-0.6H27.9l-4.2-4.2l4.2-4.2h57.5c0.6,0,1.1-0.2,1.5-0.6l6.2-6.2
						c0.8-0.8,0.8-2.1,0-2.9l-6.2-6.3C86.5,1062.9,85.9,1062.7,85.4,1062.7L85.4,1062.7z"/>
						<path d="M93.8,1077.3c-1.2,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l2.7,2.7l-2.7,2.7c-0.8,0.8-0.9,2.1-0.1,2.9
						s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9l-4.1-4.1C94.9,1077.5,94.3,1077.3,93.8,1077.3z"/>
						<path d="M22.9,1041.9c-0.5,0-1.1,0.2-1.4,0.6l-4.2,4.2c-0.8,0.8-0.9,2.1-0.1,2.9s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1
						l2.7-2.7l3.3,3.3l-4.2,4.2H2.1c-1.2,0-2.1,0.9-2.1,2.1s0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h20.8c0.6,0,1.1-0.2,1.5-0.6l6.2-6.2
						c0.8-0.8,0.8-2.1,0-2.9l-6.2-6.2C24,1042.1,23.4,1041.9,22.9,1041.9L22.9,1041.9z"/>
						<path d="M29.1,1019c-2.2,0.1-4.3,0.5-6.5,1.2c-11.2,4-17.8,14.2-16.1,27.6c1.3,10,11.3,21.2,23.5,21.2c9.4,0,18.8,0,28.2,0
						c7.8-0.4,14.7-5.4,17.5-12.6c2.8-7.3,1-15.5-4.6-21c-5.3-5.2-13-5.8-19.8-3.6c-4.8-7.6-12.9-12.7-21.6-12.7
						C29.6,1019,29.3,1018.9,29.1,1019L29.1,1019z M29.2,1023.1L29.2,1023.1c7.6-0.3,15.3,4.5,19.3,11.6c0.1,0.2,0.2,0.4,0.4,0.5
						l0.3,0.3l0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1h0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1l0,0
						c0,0,0,0,0.1,0c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0h0.2c0.2,0,0.4-0.1,0.6-0.1
						c5.9-2.4,12.5-1.9,16.8,2.3c4.4,4.3,5.9,10.8,3.7,16.5c-2.2,5.7-7.6,9.6-13.8,10c-9.3,0-18.7,0-27.9,0c-9.8-0.3-18-7.7-19.5-17.6
						c-1.5-10,4.1-19.7,13.4-23C25.8,1023.5,27.5,1023.2,29.2,1023.1L29.2,1023.1z"/>
						<path d="M91.6,1018.9c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C93.8,1019.9,92.8,1019,91.6,1018.9C91.7,1018.9,91.7,1018.9,91.6,1018.9z"/>
						<path d="M97.9,1023.1c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C99.4,1023.6,98.7,1023.1,97.9,1023.1z"/>
						<path d="M85.4,1023.1c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C86.1,1023.2,85.7,1023.1,85.4,1023.1L85.4,1023.1z"/>
						<path d="M68.7,1008.5c-1.1,0-2.1,1-2,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2,2.1c1.1,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-14.5C70.8,1009.5,69.9,1008.5,68.7,1008.5C68.7,1008.5,68.7,1008.5,68.7,1008.5z"/>
						<path d="M74.9,1012.7c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C76.5,1013.1,75.7,1012.7,74.9,1012.7z"/>
						<path d="M62.4,1012.7c-1.1,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C63.1,1012.8,62.8,1012.7,62.4,1012.7L62.4,1012.7z"/>
					</g>
				</svg>;
			break;
		case 'Snow':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M57.6,1005.6c7.9,2.9,13.6,9.6,16,17.5c0.8,0,1.5-0.1,2.2-0.1l0,0c2.3,0.1,4.6,0.5,6.8,1.3c11.8,4.2,19,16.3,17,28.8
						c-1.9,12.1-11.9,21.1-24,21.9c-10.7,0.5-21.6,0.2-30.8,0.2c-8.3-0.4-15.5-5.6-18.5-13.2c-0.4-1.1-0.8-2.3-1-3.5h-5.4
						c0,0-0.1,0-0.1,0c-8.3-0.4-15.5-5.8-18.4-13.7c-2.9-7.9-1-16.8,4.9-22.7c5.6-5.6,13.7-6.3,20.9-3.9c5-8.3,13.7-13.9,22.9-13.8
						C52.6,1004.4,55.4,1004.9,57.6,1005.6L57.6,1005.6z M50.6,1008.4L50.6,1008.4c-8.1-0.3-16.3,4.9-20.6,12.8
						c-0.1,0.2-0.2,0.4-0.4,0.5l-0.3,0.3c-0.6,0.6-1.5,0.7-2.2,0.4c-6.3-2.6-13.3-2.1-17.9,2.6c-4.7,4.7-6.3,11.9-3.9,18.3
						c2.4,6.3,8.1,10.6,14.7,11h5c0,0,0,0,0-0.1c0-0.6,0.1-1.2,0.1-1.8c0,0,0-0.1,0-0.1c0.2-1.9,0.8-3.8,1.5-5.6
						c1.1-2.6,2.7-4.6,4.6-6.6c5.6-5.4,13.7-6.1,20.9-3.7c3.1-5,7.7-8.9,12.9-11.1c1.4-0.6,2.9-1.1,4.3-1.5c-2.1-6.5-6.8-11.8-13.2-14.2
						C54.3,1008.9,52.5,1008.5,50.6,1008.4L50.6,1008.4z M75.7,1027.1L75.7,1027.1c-1.1,0-2.2,0-3.2,0.2c-0.1,0-0.1,0-0.2,0.1
						c-1.7,0.2-3.3,0.7-4.9,1.3c-5.2,2.2-9.6,5.9-12.4,10.8c-0.1,0.2-0.2,0.4-0.4,0.5l-0.3,0.3l0,0c0,0-0.1,0.1-0.1,0.1
						c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0l0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
						c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.4,0.1-0.8,0.1-1.2-0.1c-5.9-2.3-12.5-2-17.1,1.7c-0.3,0.2-0.6,0.5-0.9,0.8c0,0,0,0,0,0
						c-3.3,3.2-4.8,7.4-4.9,11.9c0,0.3,0,0.7,0,1c0.1,1.5,0.5,2.9,1,4.4c2.3,6,8.1,10.2,14.8,10.5h29.5c0.1,0,0.1,0,0.2,0
						c10.5-0.3,19.2-8.1,20.9-18.5c1.6-10.5-4.4-20.7-14.3-24.2C79.4,1027.6,77.6,1027.2,75.7,1027.1L75.7,1027.1z"/>
						<path d="M66.6,1093.9c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C67.8,1094.2,67.2,1093.9,66.6,1093.9L66.6,1093.9z"/>
						<path d="M33.3,1093.9c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C34.4,1094.2,33.9,1093.9,33.3,1093.9L33.3,1093.9z"/>
						<path d="M54.1,1077.3c-0.6,0-1.2,0.3-1.6,0.8l-10.4,12.5c-0.7,0.9-0.6,2.2,0.3,2.9c0.5,0.4,1.1,0.6,1.7,0.5l7-1.2
						l-6.8,8.2c-0.8,0.9-0.7,2.2,0.2,2.9c0.9,0.8,2.2,0.7,2.9-0.2c0,0,0-0.1,0.1-0.1l10.4-12.5c0.7-0.9,0.6-2.2-0.3-2.9
						c-0.5-0.4-1.1-0.6-1.7-0.5l-7,1.2l6.8-8.2c0.7-0.9,0.6-2.2-0.2-2.9C55.2,1077.4,54.7,1077.3,54.1,1077.3z"/>
						<path d="M16.6,1093.9c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C17.8,1094.2,17.2,1093.9,16.6,1093.9z"/>
						<path d="M83.3,1093.9c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C84.4,1094.2,83.9,1093.9,83.3,1093.9L83.3,1093.9z"/>
						<path d="M87.4,1079.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C88.6,1079.6,88,1079.3,87.4,1079.4L87.4,1079.4z"/>
						<path d="M70.8,1079.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C71.9,1079.6,71.4,1079.3,70.8,1079.4L70.8,1079.4z"/>
						<path d="M37.4,1079.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C38.6,1079.6,38,1079.3,37.4,1079.4L37.4,1079.4z"/>
						<path d="M20.8,1079.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C21.9,1079.6,21.4,1079.3,20.8,1079.4L20.8,1079.4z"/>
					</g>
				</svg>;
			break;
		case 'Thunderstorm':
		case 'Thunderstorms':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M57.6,1005.6c7.9,2.9,13.6,9.6,16,17.5c0.8,0,1.5-0.1,2.2-0.1l0,0c2.3,0.1,4.6,0.5,6.8,1.3c11.8,4.2,19,16.3,17,28.8
						c-1.9,12.1-11.9,21.1-24,21.9c-10.7,0.5-21.6,0.2-30.8,0.2c-8.3-0.4-15.5-5.6-18.5-13.2c-0.4-1.1-0.8-2.3-1-3.5h-5.4
						c0,0-0.1,0-0.1,0c-8.3-0.4-15.5-5.8-18.4-13.7c-2.9-7.9-1-16.8,4.9-22.7c5.6-5.6,13.7-6.3,20.9-3.9c5-8.3,13.7-13.9,22.9-13.8
						C52.6,1004.4,55.4,1004.9,57.6,1005.6L57.6,1005.6z M50.6,1008.4L50.6,1008.4c-8.1-0.3-16.3,4.9-20.6,12.8
						c-0.1,0.2-0.2,0.4-0.4,0.5l-0.3,0.3c-0.6,0.6-1.5,0.7-2.2,0.4c-6.3-2.6-13.3-2.1-17.9,2.6c-4.7,4.7-6.3,11.9-3.9,18.3
						c2.4,6.3,8.1,10.6,14.7,11h5c0,0,0,0,0-0.1c0-0.6,0.1-1.2,0.1-1.8c0,0,0-0.1,0-0.1c0.2-1.9,0.8-3.8,1.5-5.6
						c1.1-2.6,2.7-4.6,4.6-6.6c5.6-5.4,13.7-6.1,20.9-3.7c3.1-5,7.7-8.9,12.9-11.1c1.4-0.6,2.9-1.1,4.3-1.5c-2.1-6.5-6.8-11.8-13.2-14.2
						C54.3,1008.9,52.5,1008.5,50.6,1008.4L50.6,1008.4z M75.7,1027.1L75.7,1027.1c-1.1,0-2.2,0-3.2,0.2c-0.1,0-0.1,0-0.2,0.1
						c-1.7,0.2-3.3,0.7-4.9,1.3c-5.2,2.2-9.6,5.9-12.4,10.8c-0.1,0.2-0.2,0.4-0.4,0.5l-0.3,0.3l0,0c0,0-0.1,0.1-0.1,0.1
						c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0l0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
						c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.4,0.1-0.8,0.1-1.2-0.1c-5.9-2.3-12.5-2-17.1,1.7c-0.3,0.2-0.6,0.5-0.9,0.8c0,0,0,0,0,0
						c-3.3,3.2-4.8,7.4-4.9,11.9c0,0.3,0,0.7,0,1c0.1,1.5,0.5,2.9,1,4.4c2.3,6,8.1,10.2,14.8,10.5h29.5c0.1,0,0.1,0,0.2,0
						c10.5-0.3,19.2-8.1,20.9-18.5c1.6-10.5-4.4-20.7-14.3-24.2C79.4,1027.6,77.6,1027.2,75.7,1027.1L75.7,1027.1z"/>
						<path d="M66.6,1093.9c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C67.8,1094.2,67.2,1093.9,66.6,1093.9L66.6,1093.9z"/>
						<path d="M33.3,1093.9c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C34.4,1094.2,33.9,1093.9,33.3,1093.9L33.3,1093.9z"/>
						<path d="M54.1,1077.3c-0.6,0-1.2,0.3-1.6,0.8l-10.4,12.5c-0.7,0.9-0.6,2.2,0.3,2.9c0.5,0.4,1.1,0.6,1.7,0.5l7-1.2
						l-6.8,8.2c-0.8,0.9-0.7,2.2,0.2,2.9c0.9,0.8,2.2,0.7,2.9-0.2c0,0,0-0.1,0.1-0.1l10.4-12.5c0.7-0.9,0.6-2.2-0.3-2.9
						c-0.5-0.4-1.1-0.6-1.7-0.5l-7,1.2l6.8-8.2c0.7-0.9,0.6-2.2-0.2-2.9C55.2,1077.4,54.7,1077.3,54.1,1077.3z"/>
						<path d="M16.6,1093.9c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C17.8,1094.2,17.2,1093.9,16.6,1093.9z"/>
						<path d="M83.3,1093.9c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C84.4,1094.2,83.9,1093.9,83.3,1093.9L83.3,1093.9z"/>
						<path d="M87.4,1079.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C88.6,1079.6,88,1079.3,87.4,1079.4L87.4,1079.4z"/>
						<path d="M70.8,1079.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C71.9,1079.6,71.4,1079.3,70.8,1079.4L70.8,1079.4z"/>
						<path d="M37.4,1079.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C38.6,1079.6,38,1079.3,37.4,1079.4L37.4,1079.4z"/>
						<path d="M20.8,1079.4c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C21.9,1079.6,21.4,1079.3,20.8,1079.4L20.8,1079.4z"/>
					</g>
				</svg>;
			break;
		case 'Windy':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M8.3,1022.1c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v74.9c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-74.9C10.4,1023,9.5,1022.1,8.3,1022.1C8.3,1022.1,8.3,1022.1,8.3,1022.1L8.3,1022.1z"/>
						<path d="M2.1,1097.1c-1.2,0-2.1,0.9-2.1,2.1s0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h12.4c1.2,0,2.1-0.9,2.1-2.1
						s-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H2.1z"/>
						<path d="M24.9,1030.4c-1.1,0-2,1-2,2.1v33.3c0,1.2,0.9,2.1,2.1,2.1c0.1,0,0.2,0,0.3,0l50-8.3c1-0.2,1.7-1,1.7-2.1
						v-16.7c0-1-0.7-1.9-1.7-2.1l-50-8.3C25.2,1030.4,25.1,1030.4,24.9,1030.4L24.9,1030.4z M27.1,1034.9l45.8,7.6v13.1l-45.8,7.6
						V1034.9z"/>
						<path d="M41.6,1034.6c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v24.9c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-24.9C43.8,1035.5,42.9,1034.6,41.6,1034.6C41.7,1034.6,41.7,1034.6,41.6,1034.6L41.6,1034.6z"/>
						<path d="M58.3,1036.7c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v20.8c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-20.8C60.4,1037.6,59.5,1036.7,58.3,1036.7C58.3,1036.7,58.3,1036.7,58.3,1036.7L58.3,1036.7z"/>
						<path d="M24.9,1030.4c-0.5,0-1.1,0.2-1.4,0.6l-16.6,16.6c-0.8,0.8-0.8,2.1,0,2.9l16.6,16.6c2,2,5-1,2.9-2.9l-15.1-15.1
						l15.2-15.2C27.8,1032.7,26.8,1030.4,24.9,1030.4L24.9,1030.4z"/>
						<path d="M8.3,1007.5c-4.6,0-8.3,3.8-8.3,8.3s3.8,8.3,8.3,8.3s8.3-3.8,8.3-8.3S12.9,1007.5,8.3,1007.5z M8.3,1011.7
						c2.3,0,4.2,1.8,4.2,4.2s-1.8,4.2-4.2,4.2s-4.2-1.8-4.2-4.2S6,1011.7,8.3,1011.7z"/>
						<path d="M85.4,1063.7c-0.5,0-1.1,0.2-1.4,0.6l-4.2,4.2c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l2.7-2.7l3.3,3.3l-4.2,4.2H27.1c-0.6,0-1.1,0.2-1.5,0.6l-6.2,6.3c-0.8,0.8-0.8,2.1,0,2.9l6.2,6.2
						c0.4,0.4,0.9,0.6,1.5,0.6h49.1l2.1,2.1l-2.7,2.7c-0.8,0.8-0.8,2.1,0,2.9s2.1,0.8,2.9,0l4.2-4.2c0.8-0.8,0.8-2.1,0-2.9c0,0,0,0,0,0
						l-4.2-4.2c-0.4-0.4-0.9-0.6-1.5-0.6H27.9l-4.2-4.2l4.2-4.2h57.5c0.6,0,1.1-0.2,1.5-0.6l6.2-6.2c0.8-0.8,0.8-2.1,0-2.9l-6.2-6.2
						C86.5,1063.9,85.9,1063.7,85.4,1063.7z"/>
						<path d="M93.8,1078.3c-1.2,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l2.7,2.7l-2.7,2.7c-0.8,0.8-0.9,2.1-0.1,2.9
						s2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9l-4.1-4.1C94.9,1078.5,94.3,1078.3,93.8,1078.3z"/>
						<path d="M66.6,1007.5c-0.5,0-1.1,0.2-1.4,0.6l-6.2,6.2c-0.8,0.8-0.8,2.1,0,2.9l6.2,6.2c0.4,0.4,0.9,0.6,1.5,0.6h20.8
						c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H67.5l-4.2-4.2l3.3-3.3l2.7,2.7c0.8,0.8,2.1,0.9,2.9,0.1
						c0.8-0.8,0.9-2.1,0.1-2.9c0,0,0,0-0.1-0.1l-4.2-4.2C67.7,1007.7,67.2,1007.5,66.6,1007.5L66.6,1007.5z"/>
					</g>
				</svg>;
			break;
		case 'Wintry Mix':
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M64.6,1085.6c-1.2,0-2.1,1-2.1,2.1l0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1v-14.5
						C66.7,1086.6,65.8,1085.6,64.6,1085.6C64.6,1085.6,64.6,1085.6,64.6,1085.6L64.6,1085.6z"/>
						<path d="M58.3,1089.8c-1.2,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1l-12.4-6.2C59,1089.9,58.6,1089.8,58.3,1089.8z"/>
						<path d="M70.8,1089.8c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C72.4,1090.2,71.6,1089.8,70.8,1089.8z"/>
						<path d="M33.3,1085.6c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v14.5c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-14.5C35.4,1086.6,34.5,1085.6,33.3,1085.6C33.3,1085.6,33.3,1085.6,33.3,1085.6L33.3,1085.6z"/>
						<path d="M27,1089.8c-1.2,0-2.1,1-2,2.1c0,0.8,0.5,1.5,1.2,1.8l12.4,6.2c1,0.5,2.3,0.2,2.8-0.9c0.5-1,0.2-2.3-0.9-2.8
						c0,0-0.1,0-0.1-0.1L28,1090C27.7,1089.9,27.4,1089.8,27,1089.8z"/>
						<path d="M39.6,1089.8c-0.3,0-0.6,0.1-0.9,0.2l-12.4,6.2c-1,0.5-1.5,1.7-1,2.8c0.5,1,1.7,1.5,2.8,1c0,0,0.1,0,0.1-0.1
						l12.4-6.2c1-0.5,1.5-1.7,1-2.8C41.1,1090.2,40.4,1089.8,39.6,1089.8z"/>
						<path d="M20.9,1081.4c-0.7,0-1.3,0.3-1.7,0.9l-6.2,8.3c-0.7,0.9-0.5,2.2,0.4,2.9c0.9,0.7,2.2,0.5,2.9-0.4c0,0,0,0,0,0
						l6.2-8.3c0.7-0.9,0.5-2.2-0.4-2.9C21.8,1081.6,21.3,1081.5,20.9,1081.4z"/>
						<path d="M54.2,1081.4c-0.7,0-1.3,0.3-1.7,0.9l-6.2,8.3c-0.7,0.9-0.5,2.2,0.4,2.9c0.9,0.7,2.2,0.5,2.9-0.4c0,0,0,0,0,0
						l6.2-8.3c0.7-0.9,0.5-2.2-0.4-2.9C55.1,1081.6,54.7,1081.5,54.2,1081.4z"/>
						<path d="M85.5,1081.4c-0.7,0-1.3,0.3-1.7,0.9l-6.2,8.3c-0.7,0.9-0.5,2.2,0.4,2.9c0.9,0.7,2.2,0.5,2.9-0.4c0,0,0,0,0,0
						l6.2-8.3c0.7-0.9,0.5-2.2-0.4-2.9C86.3,1081.6,85.9,1081.5,85.5,1081.4z"/>
						<path d="M57.6,1007.7c7.9,2.9,13.6,9.6,16,17.5c0.8,0,1.5-0.1,2.2-0.1l0,0c2.3,0.1,4.6,0.5,6.8,1.3c11.8,4.2,19,16.3,17,28.8
						c-1.9,12.1-11.9,21.1-24,21.9c-10.7,0.5-21.6,0.2-30.8,0.2c-8.3-0.4-15.5-5.6-18.5-13.2c-0.4-1.1-0.8-2.3-1-3.5h-5.4
						c0,0-0.1,0-0.1,0c-8.3-0.4-15.5-5.8-18.4-13.7c-2.9-7.9-1-16.8,4.9-22.7c5.6-5.6,13.7-6.3,20.9-3.9c5-8.3,13.7-13.9,22.9-13.8
						C52.6,1006.5,55.4,1006.9,57.6,1007.7L57.6,1007.7z M50.6,1010.5L50.6,1010.5c-8.1-0.3-16.3,4.9-20.6,12.8
						c-0.1,0.2-0.2,0.4-0.4,0.5l-0.3,0.3c-0.6,0.6-1.5,0.7-2.2,0.4c-6.3-2.6-13.3-2.1-17.9,2.6c-4.7,4.7-6.3,11.9-3.9,18.3
						c2.4,6.3,8.1,10.6,14.7,11h5c0,0,0,0,0-0.1c0-0.6,0.1-1.2,0.1-1.8c0,0,0-0.1,0-0.1c0.2-1.9,0.8-3.8,1.5-5.6
						c1.1-2.6,2.7-4.6,4.6-6.6c5.6-5.4,13.7-6.1,20.9-3.7c3.1-5,7.7-8.9,12.9-11.1c1.4-0.6,2.9-1.1,4.3-1.5c-2.1-6.5-6.8-11.8-13.2-14.2
						C54.3,1010.9,52.5,1010.6,50.6,1010.5L50.6,1010.5z M75.7,1029.2L75.7,1029.2c-1.1,0-2.2,0-3.2,0.2c-0.1,0-0.1,0-0.2,0.1
						c-1.7,0.2-3.3,0.7-4.9,1.3c-5.2,2.2-9.6,5.9-12.4,10.8c-0.1,0.2-0.2,0.4-0.4,0.5l-0.3,0.3l0,0c0,0-0.1,0.1-0.1,0.1
						c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0l0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
						c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.4,0.1-0.8,0.1-1.2-0.1c-5.9-2.3-12.5-2-17.1,1.7c-0.3,0.2-0.6,0.5-0.9,0.8c0,0,0,0,0,0
						c-3.3,3.2-4.8,7.4-4.9,11.9c0,0.3,0,0.7,0,1c0.1,1.5,0.5,2.9,1,4.4c2.3,6,8.1,10.2,14.8,10.5c9.9,0,19.8,0,29.7,0
						c10.5-0.3,19.2-8.1,20.9-18.5c1.6-10.5-4.4-20.7-14.3-24.2C79.4,1029.6,77.6,1029.3,75.7,1029.2L75.7,1029.2z"/>
					</g>
				</svg>;
			break;
		default:
			icon =
				<svg
					version="1.1"
					viewBox={`0 0 ${viewBox} ${viewBox}`}
					fill={props.lineColor}>
					<filter  width="200%" height="200%">
						<feGaussianBlur in="SourceAlpha" stdDeviation="3" />
						<feOffset dx="0" dy="4" result="offsetblur" />
						<feComponentTransfer>
							<feFuncA type="linear" slope="0.05" />
						</feComponentTransfer>
						<feMerge>
							<feMergeNode />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>
					<g transform="translate(0,-1004.3622)">
						<path d="M50,1064.8c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v33.3c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0-0.1
						v-33.3C52.1,1065.7,51.2,1064.8,50,1064.8C50,1064.8,50,1064.8,50,1064.8z"/>
						<path d="M29.2,1083.5c-1.2,0-2.1,0.9-2.1,2c0,1.2,0.9,2.1,2,2.1c0,0,0,0,0.1,0H75c1.2,0,2.1-0.9,2.1-2
						c0-1.2-0.9-2.1-2-2.1c0,0,0,0-0.1,0H29.2z"/>
						<path d="M68.7,1075.2c-1.2,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l6.8,6.8l-6.8,6.8c-0.8,0.8-0.9,2.1-0.1,2.9
						c0.8,0.8,2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1l8.3-8.3c0.8-0.8,0.8-2.1,0-2.9l-8.3-8.3C69.8,1075.4,69.3,1075.2,68.7,1075.2z"/>
						<path d="M20.8,1075.2c-1.2,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l6.8,6.8l-6.8,6.8c-0.8,0.8-0.9,2.1-0.1,2.9
						c0.8,0.8,2.1,0.9,2.9,0.1c0,0,0,0,0.1-0.1l8.3-8.3c0.8-0.8,0.8-2.1,0-2.9l-8.3-8.3C21.9,1075.4,21.4,1075.2,20.8,1075.2z"/>
						<path d="M75,1039.8H50H25c-1.2,0-2.1,0.9-2.1,2.1c0,14.9,12.2,27.1,27.1,27.1c14.9,0,27.1-12.2,27.1-27.1
						C77.1,1040.7,76.1,1039.8,75,1039.8L75,1039.8z M72.5,1043.9c-1.1,11.6-10.6,20.8-22.5,20.8c-11.9,0-21.4-9.2-22.5-20.8H50
						L72.5,1043.9L72.5,1043.9z"/>
						<path d="M68.7,1014.8c-4.6,0-8.3,3.8-8.3,8.3v18.7c0,1.2,0.9,2.1,2.1,2.1H75c1.2,0,2.1-0.9,2.1-2.1v-18.7
						C77.1,1018.5,73.3,1014.8,68.7,1014.8z M68.7,1018.9c2.3,0,4.2,1.8,4.2,4.2v16.7h-8.3v-16.7C64.6,1020.8,66.4,1018.9,68.7,1018.9z"
						/>
						<path d="M68.7,1008.5c-1.2,0-2.1,1-2.1,2.1c0,0,0,0,0,0v4.1c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1
						c0,0,0,0,0-0.1v-4.1C70.8,1009.5,69.9,1008.5,68.7,1008.5C68.8,1008.5,68.7,1008.5,68.7,1008.5z"/>
						<path d="M62.5,1010.6c-1.2,0-2.1,0.9-2.1,2.1c0,0.6,0.2,1.1,0.6,1.5l4.1,4.1c0.8,0.8,2.1,0.9,2.9,0.1
						c0.8-0.8,0.9-2.1,0.1-2.9c0,0,0,0-0.1-0.1l-4.1-4.1C63.6,1010.8,63.1,1010.6,62.5,1010.6z"/>
						<path d="M74.9,1010.6c-0.5,0-1.1,0.2-1.4,0.6l-4.1,4.1c-0.8,0.8-0.9,2.1-0.1,2.9c0.8,0.8,2.1,0.9,2.9,0.1
						c0,0,0,0,0.1-0.1l4.1-4.1c0.8-0.8,0.8-2.1,0-2.9C76.1,1010.8,75.5,1010.6,74.9,1010.6z"/>
						<path d="M75,1025.2c-1.2,0-2.1,0.9-2.1,2.1s0.9,2.1,2.1,2.1c0,0,0,0,0.1,0h6.2c1.2,0,2.1-0.9,2.1-2.1
						c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0-0.1,0H75z"/>
						<path d="M41.6,1048.1c-1.1,0-2.1,1-2.1,2.1c0,5.7,4.7,10.4,10.4,10.4c5.7,0,10.4-4.6,10.4-10.4c0-1.2-0.9-2.1-2-2.1
						c-1.2,0-2.1,0.9-2.1,2c0,0,0,0,0,0.1c0,3.5-2.8,6.2-6.3,6.2c-3.5,0-6.2-2.8-6.2-6.2C43.8,1049.1,42.8,1048.1,41.6,1048.1
						C41.7,1048.1,41.7,1048.1,41.6,1048.1L41.6,1048.1z"/>
						<path d="M18.8,1035.6c-1.2,0-2.1,0.9-2.1,2.1c0,0.7,0.4,1.4,1,1.8l6.2,4.1c0.9,0.7,2.2,0.4,2.9-0.5
						c0.7-0.9,0.4-2.2-0.5-2.9c0,0,0,0-0.1,0l-6.2-4.1C19.6,1035.7,19.2,1035.6,18.8,1035.6z"/>
						<path d="M18.7,1027.3c-1.1,0.1-2,1-2,2.2c0,0.6,0.3,1.2,0.9,1.6l16.6,12.5c0.9,0.7,2.2,0.5,2.9-0.4
						c0.7-0.9,0.5-2.2-0.4-2.9L20,1027.7C19.6,1027.4,19.2,1027.3,18.7,1027.3z"/>
						<path d="M18.7,1018.9c-1.2,0-2.1,1-2,2.1c0,0.6,0.3,1.2,0.8,1.6l27,20.8c0.9,0.7,2.2,0.5,2.9-0.4
						c0.7-0.9,0.5-2.2-0.4-2.9l-27-20.8C19.7,1019.1,19.2,1018.9,18.7,1018.9z"/>
					</g>
				</svg>;

	}

	return (
		<Fragment >
			{icon}
		</Fragment>
	);
};


export default WeatherIcon;