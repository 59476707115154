import React from 'react';

import {
	defaultSorter
} from 'helper/Brood.js';

import {
	broncoURLENV
} from '../ENV';

class PeacockProviderComponent extends React.Component {
	static providerName = 'peacock';

	constructor(props) {
		super(props);

		this.state = {
			slides:             [],
			lastProofOfPlayURL: '',
			// -1 because we calculate from the 0 index
			split:              (props.limit - 1),

			adsCount: 0,
			popCount: 0
		};

		this.providerID = `PROVIDER_${props.component_name}`;
	}

	componentDidMount() {
		const {
			slideshow
			// limit
		} = this.props;

		// don't initialize if we're not in a slideshow
		if (!slideshow) {
			return;
		}

		this.fetchSlides();
		this.updateInterval = setInterval(() => {
			this.fetchSlides();
		}, 1000 * 60);
	}

	componentWillUnmount() {
		clearInterval(this.updateInterval);
	}

	componentDidUpdate(prevProps) {
		const {
			count,
			last_update
		} = this.props;

		if (prevProps.count !== count || prevProps.last_update !== last_update) {
			this.fetchSlides();
		}
	}

	makeRequestBody() {
		const {
			peacock_size,
			dmn_operator,
			peacock_media_type,
			peacock_allow_audio: allow_audio,
			peacock_static_duration,
			peacock_duration_min,
			peacock_duration_max,
			peacock_max_file_size_bytes,
			project_number,
			directory_number,
			limit
		} = this.props;

		const [
			width,
			height
		] = (peacock_size || '0x0')
			.split('x')
			.map((n) => {
				return Number(n);
			});

		return {
			allow_audio:         allow_audio, // wether audio is allowed or not
			height:              height, // the height of the screen section
			max_duration:        peacock_duration_max, // the maximum duration of video assets
			max_file_size_bytes: peacock_max_file_size_bytes, // maximum file size in bytes
			min_duration:        peacock_duration_min, // the minumum duration of video assets
			provider:            dmn_operator,
			static_duration:     peacock_static_duration, // the duration of static assets
			supported_media:     peacock_media_type.split(','),
			venue_id:            `${project_number}-${directory_number}`, // highly recommended
			width:               width, // the width of the screen section

			limit: limit
		};
	}


	async fetchSlides(updateProvider = true) {
		if (this.fetching) {
			return;
		}

		this.fetching = true;

		const {
			limit,
			slideshow,
			peacock_allow_audio: allow_audio
		} = this.props;

		const {
			slides,
			lastProofOfPlayURL
		} = this.state;

		let {
			adsCount
		} = this.state;

		const nonExpiredSlides = slides.filter((slide) => {
			if (this.expired(slide)) {
				return false;
			}

			return true;
		});

		if (nonExpiredSlides.length >= limit) {
			this.fetching = false;

			return;
		}

		const body = this.makeRequestBody();

		// try to always have a buffer of #limit of slides
		if (body.limit > nonExpiredSlides.length) {
			body.limit = (limit - nonExpiredSlides.length);
		}

		const newSlides = [];
		try {
			const fetchURL = `${broncoURLENV}/peacock?tlc=${window._getEnv('CONDOR_COMPONENT')}`;
			const response = await fetch(fetchURL, {
				body:    JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'POST'
			});

			const data = await response.json();

			const ads = [];
			for (const ad of data.ads) {
				ads.push(ad);
			}

			for (const slide of ads) {
				slide.data = {
					...slide,
					src: slide.asset_url,
					alt: slide.description
				};

				slide.options = {
					duration: (Number(slide.length_in_seconds) * 1000)
				};

				// slide.asset_id can be duplicate, slide.id is unique
				slide.slideID = `${slide.asset_id}_${slide.id}`;
				slide.type = slide.mime_type.split('/')[0];
				slide.allowAudio = allow_audio;

				slide.provider = this;
				newSlides.push(slide);
			}

			const existingSlideIDs = nonExpiredSlides.map((slide) => {
				return slide.slideID;
			});

			const mergedSlides = [...nonExpiredSlides];
			for (let i = 0; i < newSlides.length; i++) {
				const newSlide = newSlides[i];
				if (existingSlideIDs.includes(newSlide.slideID)) {
					// same ad, but things like proof_of_play_url might have changed
					mergedSlides[existingSlideIDs.indexOf(newSlide.slideID)] = newSlides[i];
					continue;
				}

				mergedSlides.push(newSlide);
				adsCount++;
			}
			mergedSlides.sort(defaultSorter);

			if (mergedSlides.length > 0 && mergedSlides[0].proof_of_play_url === lastProofOfPlayURL) {
				// debugger;
				mergedSlides.push(mergedSlides.shift());
			}

			this.setState({
				slides:   mergedSlides,
				// -1 because we calculate from the 0 index
				split:    (limit - 1),
				adsCount: adsCount
			}, () => {
				if (!updateProvider) {
					return;
				}

				const newProvider = this.makeProvider(mergedSlides);
				slideshow.updateProvider(newProvider);
			});
		} catch (err) {
			console.error('BROOD::PEACOCK::ERROR_fetchSlides:', err);
			debugger;
		}

		this.fetching = false;
	}

	makeProvider(slides = []) {
		const {
			providerID,
			limit: maxSlides,
			order: providerOrder,
			sort: sortType,
			transition_in: transitionIn,
			transition_out: transitionOut,
			isTouch
			// count, ???
		} = this.props;

		// Allow autoplay in chrome
		// let isMuted = ~window.location.href.indexOf('muted=1');

		return {
			providerID:   providerID,
			providerType: 'ads',
			options:      {
				maxSlides:     maxSlides,
				providerOrder: providerOrder,
				sortType:      sortType || 'sequential'
			},
			skipper:             this.skipper.bind(this),
			beforeLastSlide:     this.beforeLastSlide.bind(this),
			afterLastSlide:      this.afterLastSlide.bind(this),
			providerProofOfPlay: this.providerProofOfPlay.bind(this),
			slides,
		};
	}

	expired(currSlide) {
		const now = new Date();
		const expiresAt = new Date(currSlide.data.lease_expiry * 1000);

		return (now > expiresAt);
	}

	skipper(currSlide) {
		const {
			slides
		} = this.state;

		// currSlide is the brood slide, not the peacock slide
		const statefulCurrSlide = slides.find((slide) => {
			return slide.slideID === currSlide.slideID;
		});
		if (!statefulCurrSlide) {
			return true;
		}

		return false;
	}

	beforeLastSlide(slides = []) {
		console.log('BROOD::PEACOCK::beforeLastSlide:', slides);
	}

	afterLastSlide(_slides = []) {
		console.log('BROOD::PEACOCK::afterLastSlide:', _slides);

		const {
			slideshow
		} = this.props;

		const {
			slides
		} = this.state;

		const newProvider = this.makeProvider(slides);
		slideshow.updateProvider(newProvider);
	}

	beforeNext(currSlide) {
		console.log('BROOD::PEACOCK::beforeNext:', currSlide);
	}

	afterNext(prevSlides, prevSlide, currSlide) {
		console.log('BROOD::PEACOCK::afterNext:', prevSlides, prevSlide, currSlide);

		const {
			lastProofOfPlayURL
		} = this.state;

		if (lastProofOfPlayURL === prevSlide.proof_of_play_url) {
			debugger;
		}

		const {
			slides
		} = this.state;

		let prevSlideIndex = slides.findIndex((slide) => {
			return slide.slideID === prevSlide.slideID;
		});
		if (~prevSlideIndex) {
			slides.splice(prevSlideIndex, 1);

			this.setState({
				slides: slides
			}, () => {
				// each time we remove an ad we should
				// try to fetch ads up to our limit again
				const updateProvider = false;
				this.fetchSlides(updateProvider);
			});
		}
	}

	async providerProofOfPlay(slide) {
		let host = window._getEnv('BROOD_POP_URL');
		if (!host) {
			host = window._getEnv('BRONCO_URL');
		}

		const fetchURL = `${host}/peacock_pop?tlc=${window._getEnv('CONDOR_COMPONENT')}`;

		const body = {
			slide_id:          slide.slideID,
			proof_of_play_url: slide.proof_of_play_url,
			display_time:      `${Math.round(Date.now() / 1000)}`
		};

		return fetch(fetchURL, {
			body:    JSON.stringify(body),
			headers: {'Content-Type': 'application/json'},
			method:  'POST'
		})
			.then((res) => {
				if (res.status !== 204) {
					if (res.status !== 200) {
						console.error('BROOD::PEACOCK::ERROR_afterNext:');
						console.error(`providerProofOfPlay() failed with status: ${res.status}`);
					}
				}

				// 200 = success / 400 = Lease Already Spent
				if (!(res.status === 200 || res.status === 400)) {
					throw new Error('Unexpected Proof of Play response');
				}

				return res.json();
			})
			.then((data) => {
				console.log('BROOD::PEACOCK::PROOF_OF_PLAY_RES:', JSON.stringify(data), slide.slideID);

				this.setState({
					lastProofOfPlayURL: slide.proof_of_play_url,
					popCount:           this.state.popCount + 1
				}, () => {
					console.log('BROOD::PEACOCK::COUNTS:', this.state.adsCount, this.state.popCount);
				});

				// important for chaining in Brood
				return true;
			})
			.catch((err) => {
				console.error('BROOD::PEACOCK::ERROR_fetchProofOfPlayURL:', err);
			});
	}

	render() {
		return null;
	}
}

export default PeacockProviderComponent;