
import React, { useState, useEffect } from 'react';
import '../styles/loader.css'; 

const messages = [
	"Preparing your experience...",
	"Gathering data and content...",
]

const LoadingScreen = () => {
	const [index, setIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((prevIndex) => (prevIndex + 1) % messages.length);
		}, 3000); // Change every 3 seconds

		return () => clearInterval(interval);
	}, []);

	const currentMessage = messages[index];

	return (
		<div className="loading-screen">
			<img className="loading-logo" src="https://ts-condor-assets.s3.us-east-1.amazonaws.com/images/logos/tsLogoWhite.svg" alt="Loading..." />
			<div className="loader-message">{currentMessage}</div>
		</div>
	);
};

export default LoadingScreen;


