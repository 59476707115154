import React, { PureComponent } from 'react';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import '../styles/modal.css';
import { Document, Page, pdfjs } from 'react-pdf';

class PdfComponent extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
            activeButton: '',
			pdfString: '',
			numPages: null,
			pageNumber: 1,
			source: "",
			display: "none",
            loading: true,
            css: props.CSSModules["PdfComponent"],
		}
	}

    componentDidMount() {
        this.setFileData();
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevState.loading !== this.state.loading){
            this.buildReader();
        }
    }

    setFileData = () => {
        this.setState({
            source: this.props.source,
            loading: false,
        });

        if (this.props.source && this.props.source !== '') {
            this.setState({
                display: "",
            });
        } else {
            this.setState({
                display: "none",
            });
        }
	}
  

    onDocumentLoadSuccess = ({numPages}) => {
        this.setState({
            numPages: numPages,
        });
    }

    changePage = (offset) => {
        this.setState({
            pageNumber: this.state.pageNumber + offset,
        });
    }

    previousPage = () => {
        if (this.state.pageNumber && this.state.pageNumber > 1) {
            this.changePage(-1);
        }
    }

    nextPage = () => {
        if (this.state.numPages && this.state.pageNumber < this.state.numPages) {
            this.changePage(1);
        }
    }

    buildReader = () => {
        let base64String;
        let reader = new FileReader();

        fetch(this.state.source)
        .then((response) => {
            return response.blob();
        })
        .then((blob) => {
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                base64String = reader.result;
                this.setState({
                    pdfString: base64String.substr(base64String.indexOf(',') + 1),
                });
            };
        });
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose('pdf');
        } else {
            let animationStartPhase = this.props.navProps.animationStartPhase;
            this.props.navEvents('setPhaseSetMenu', animationStartPhase, false);
        }
    }

    handleDown = (buttonId) => {
        this.setState({
            activeButton: buttonId
        })
	}

	handleUp = () => {
        this.setState({
            activeButton: ''
        })
	}

    render() {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

        let css = this.state.css;

        let customClasses = '';
        if (this.props.custom_classes && this.props.custom_classes.length > 0) {
            this.props.custom_classes.forEach((element) => {
                if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
                    customClasses += ` ${element}`;
                }
            });
        }

        return (
            <div className={`modal-main ${customClasses}`}>
                <Document className={css?.documentContainer}
                    file={`data:application/pdf;base64,${this.state.pdfString}`}
                    options={{ workerSrc: "/pdf.worker.js" }}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                >
                    <Page className={css?.documentContainer} pageNumber={this.state.pageNumber} />
                </Document>
                <div className={`pdfUi ${css?.pdfUi}`}>
                    <p className={`pageCountContainer ${css?.pageCountContainer}`}>
                        Page {this.state.pageNumber || (this.state.numPages ? 1 : "--")} of {this.state.numPages || "--"}
                    </p>
                    <div className={`navButtonContainer ${css?.navButtonContainer}`}>
                        <button 
                            className={`modalButton pdfButtonPrevious ${css?.navBtn} ${this.state.activeButton === 'previous' ? css?.activeButton : ''}`}
                            type="button"
                            disabled={!this.state.pageNumber || this.state.pageNumber <= 1}
                            onMouseDown={() => {
                                this.handleDown('previous');
                                this.props.navEvents('btnDebouncer', this.previousPage);
                            }}
                            onMouseUp={this.handleUp}
                            onMouseLeave={this.handleUp}
                            onTouchStart={() => {
                                this.handleDown('previous');
                                this.props.navEvents('btnDebouncer', this.previousPage);
                            }}
                            onTouchEnd={this.handleUp}
                            onPointerDown={() => {
                                this.handleDown('previous');
                                this.props.navEvents('btnDebouncer', this.previousPage);
                            }}
                            onPointerUp={this.handleUp}
                            onPointerLeave={this.handleUp}
                        >
                            Previous
                        </button>

                        <button
                            className={`modalButton pdfButtonNext ${css?.navBtn} ${this.state.activeButton === 'next' ? css?.activeButton : ''}`}
                            type="button"
                            disabled={!this.state.numPages || this.state.pageNumber >= this.state.numPages}
                            onMouseDown={() => {
                                this.handleDown('next');
                                this.props.navEvents('btnDebouncer', this.nextPage);
                            }}
                            onMouseUp={this.handleUp}
                            onMouseLeave={this.handleUp}
                            onTouchStart={() => {
                                this.handleDown('next');
                                this.props.navEvents('btnDebouncer', this.nextPage);
                            }}
                            onTouchEnd={this.handleUp}
                            onPointerDown={() => {
                                this.handleDown('next');
                                this.props.navEvents('btnDebouncer', this.nextPage);
                            }}
                            onPointerUp={this.handleUp}
                            onPointerLeave={this.handleUp}
                        >
                            Next
                        </button>

                        <button
                            className={`modalButton pdfButtonClose ${css?.navBtn} ${this.state.activeButton === 'close' ? css?.activeButton : ''}`}
                            type="button" 
                            onMouseDown={() => {
                                this.handleDown('close');
                                this.props.navEvents('btnDebouncer', this.handleClose);
                            }}
                            onMouseUp={this.handleUp}
                            onMouseLeave={this.handleUp}
                            onTouchStart={() => {
                                this.handleDown('close');
                                this.props.navEvents('btnDebouncer', this.handleClose);
                            }}
                            onTouchEnd={this.handleUp}
                            onPointerDown={() => {
                                this.handleDown('close');
                                this.props.navEvents('btnDebouncer', this.handleClose);
                            }}
                            onPointerUp={this.handleUp}
                            onPointerLeave={this.handleUp}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PdfComponent;
