import React, { Component, useEffect, useState, } from 'react';
import { CSSTransition } from 'react-transition-group'

class LayoutGroup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			css: props.CSSModules[props.condor_render_name],
			unmountSetting: true,
		};
	}

	componentDidMount() {
		// prevents all components showing on load
		let unmountSetting = this.props.unmount_group;
		if (!this.props.unmount_group && (unmountSetting !== undefined && unmountSetting !== null && unmountSetting !== '')) {
			console.log(`Changing ${this.props.custom_tlc_builder_name} from ${unmountSetting} to false`);
			setTimeout(() => {
				this.setState({
					unmountSetting: false
				});
			}, 2000);
		}
	}

	displayCondition() {
		if (
			(Array.isArray(this.props.show_on_phase) && this.props.show_on_phase.length === 0) ||
			this.props.show_on_phase === undefined ||
			this.props.show_on_phase === null ||
			(this.props.show_on_phase && this.props.show_on_phase.includes(this.props.navProps.animationPhase))
		) {
			return true;
		}
		return false;
	}

	// Layout classes - CSS Modules
	makeClasses(classArray) {
		let css = this.state.css;
		let classes = '';
		if (Array.isArray(classArray) && classArray.length > 0) {
			classArray.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					classes += `${css[element]} `;
				}
			});
		}
		return classes;
	}

	fadeOnMenu(menuActive) {
		let classes = '';
		if (menuActive === true && this.displayCondition() === true) {
			classes = 'mainMenuOpen'
		}
		return classes;
	}

	adaAction(adaActive) {
		let classes = '';
		if (adaActive === true && this.displayCondition() === true) {
			classes = 'adaActive'
		}
		return classes;
	}

	expandComponentList() {
		const componentList = [];
		this.props.condor_component_list.forEach(component => {
			let componentCopy = {...component};
			componentCopy['props'] = {...component['props']};
			componentCopy['props']['showPhase'] = this.displayCondition();
			componentList.push(componentCopy);
		});
		return componentList;
	}

	render() {
		let css = this.state.css;
		
		// Custom classes
		let customGlobalClasses = '';
		if (this.props.custom_classes && this.props.custom_classes.length > 0) {
			this.props.custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customGlobalClasses += ` ${element}`;
				}
			});
		}
	
		return (
			<CSSTransition
				in={this.displayCondition()}
				appear={this.displayCondition()}
				timeout={820}
				unmountOnExit={this.state.unmountSetting}
				classNames='contentFade'>
					<div id={`layoutGrp${this.props.component_name}`} className={`${customGlobalClasses} ${this.fadeOnMenu(this.props.navProps.showMenu)} ${this.adaAction(this.props.navProps.adaActive)} ${this.makeClasses(this.props.condor_layout_classes)} ${css[this.props.theme_variation]}`}>
						{this.expandComponentList()}
					</div>
			</CSSTransition>
		);

	}

};

export default LayoutGroup;