import React, {
	useRef,
} from 'react';
import {
	renderToStaticMarkup,
} from 'react-dom/server';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import QRCode from 'qrcode.react';
import LocationControl from '../util/LocationControl';
import {
	legacyFetchPlaces,
} from '../util/places';
import {
	heightENV,
	latENV,
	lonENV,
	ternStaticTokenENV,
	ternUserNameENV,
	widthENV,
	zoomENV,
} from '../ENV';

const userName = ternUserNameENV;

const YAHPinUrl = 'https://ts-condor-assets.s3.amazonaws.com/images/icons/yah-icon.png';
const placePinUrl = 'https://ts-condor-assets.s3.amazonaws.com/images/icons/bluePin.png';

if (!mapboxgl.workerClass) {
	const accessToken = ternStaticTokenENV;
	// eslint-disable-next-line import/no-webpack-loader-syntax
	mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // Wire up loaded worker to be used instead of the default
	mapboxgl.accessToken = accessToken;
}

class LegacyMapAmenities extends React.Component {
	constructor(props) {
		super(props);

		const roosterLat = latENV;
		const roosterLon = lonENV;
		const roosterZoom = zoomENV;

		this.state = {
			css:                  props.CSSModules[props.condor_render_name],
			map:                  null,
			category:             '',
			nextCategory:         '',
			results:              null,
			centerLat:            Number(roosterLat) || 0,
			centerLng:            Number(roosterLon) || 0,
			zoom:                 Number(roosterZoom) || 13,
			idleTimeoutSeconds:   60,
			places_search_radius: 3000,
			styleId:              'cl6wborf8000j14l2hvv6kab9',
			listingRefs:          null,
			scrollPaddingTop:     0,
		};

		this.horizScrollRef = React.createRef();
		this.resultSet = {};
		this.markerSet = {};
		this.timer = null;
		this.mapRef = React.createRef();

		this.containerId = `dynamicMapAmenities_${props.component_name}`;
		this.resizeTimer = null;
		this.resizeInterval = null;
		this.resizeObserver = null;
	}

	componentDidMount() {
		console.log('LegacyMapAmenities componentDidMount');

		this.createMap();
		
		this.resizeInterval = setInterval(() => {
			// this.resizeThrottle();
		}, 800);
	}

	componentWillUnmount() {
		console.log('LegacyMapAmenities componentWillUnmount');

		clearTimeout(this.timer);
		clearTimeout(this.resizeTimer);
		clearInterval(this.resizeInterval);
		this.resizeObserver = null;

		const {
			map,
		} = this.state;

		if (map) {
			map.remove();
		}
	}

	resizeThrottle() {
		clearTimeout(this.resizeTimer);

		this.resizeTimer = setTimeout(() => {
			// this.resize();
		}, 150);
	}

	resize() {
		const {
			css,
			map,
		} = this.state;

		if (!map) {
			return;
		}

		map.resize();

		const componentContainer = document.querySelector(`.${css.legacyDynamicMapAmenitiesContainer}`);
		console.log('LegacyMapAmenities resize componentContainer:', {
			height: componentContainer.clientHeight,
			width: componentContainer.clientWidth,
		});
	}

	resetMap() {
		const {
			map,
		} = this.state;

		if (!map) {
			return;
		}

		this.reCenterMap();

		this.setState({
			results:      null,
			category:     '',
			nextCategory: '',
		}, () => {
			this.clearMarkers();
		});
	}

	clearMarkers() {
		Object.values(this.markerSet).forEach((markerArray) => {
			markerArray.forEach((marker) => {
				marker.remove();
			});
		});
	}

	clearPopups() {
		Object.values(this.markerSet).forEach((markerArray) => {
			markerArray.forEach((marker) => {
				if (marker.getPopup().isOpen()) {
					marker.togglePopup();
				}
			});
		});
	}

	createMap() {
		const {
			map,
			styleId,
			centerLat,
			centerLng,
			zoom,
			css,
		} = this.state;

		if (map) {
			map.remove();
		}

		// clearing the container before making a new map
		const mapContainer = document.querySelector(`#${this.containerId}`)
		if (!mapContainer) {
			return;
		}

		const parentContainer = mapContainer.parentElement;

		mapContainer.innerHTML = '';

		this.resizeObserver = null;
		this.resizeObserver = new ResizeObserver(() => {
			this.resize();
		});

		this.resizeObserver.observe(parentContainer);

		const newMap = new mapboxgl.Map({
			container:             this.containerId, // container ID
			style:                 `mapbox://styles/${userName}/${styleId}`, // style URL
			center:                [ centerLng, centerLat, ], // starting position [lng, lat]
			maxBounds:             [[ centerLng - 4, centerLat - 4, ], [ centerLng + 4, centerLat + 4, ],],
			zoom, // starting zoom
			minZoom:               zoom - 3,
			maxZoom:               zoom + 3,
			preserveDrawingBuffer: false,
			interactive:           false,
			attributionControl:    false,
			logoPosition:          'top-left',
		});

		newMap.on('load', () => {
			console.log('LegacyMapAmenities load');

			const YAHMarkerDiv = document.createElement('div');
			YAHMarkerDiv.style.backgroundImage = `url(${YAHPinUrl})`;
			YAHMarkerDiv.style.backgroundRepeat = 'no-repeat';
			YAHMarkerDiv.style.backgroundSize = 'cover';
			YAHMarkerDiv.style.width = '28px';
			YAHMarkerDiv.style.height = '45px';

			const YAHMarker = new mapboxgl.Marker(YAHMarkerDiv);

			YAHMarker.setLngLat([ centerLng, centerLat, ]).addTo(newMap).setOffset([ 0, -20, ]);

			this.setState({
				map: newMap,
			});

			const locationControl = new LocationControl({
				originalLat:       centerLat,
				originalLng:       centerLng,
				originalZoom:      zoom,
				centerMapCallback: this.resetMap.bind(this),
			});

			newMap.addControl(locationControl, 'bottom-left');

			const attributionControl = new mapboxgl.AttributionControl();
			newMap.addControl(attributionControl, 'top-left');

			document.querySelectorAll('.mapboxgl-control-container a').forEach((anchor) => {
				anchor.addEventListener('click', (e) => {
					e.preventDefault();
				});
			});

			document.querySelectorAll('a.mapboxgl-ctrl-logo').forEach((anchor) => {
				const componentContainer = document.querySelector(`.${css.legacyDynamicMapAmenitiesContainer}`);
				anchor.addEventListener('click', (e) => {
					e.preventDefault();
					console.log('LegacyMapAmenities componentContainer:', {
						height: componentContainer.clientHeight,
						width: componentContainer.clientWidth,
					});
					// newMap.resize();
				});
			});

			newMap.resize();

			window.addEventListener('resize', () => {
				// this.resizeThrottle();
			});

		});

		newMap.on('idle', function() {
			// newMap.resize()
		});

		newMap.on('error', (error) => {
			const mapTimeout = 15000;
			console.error(`Error loading map. Will retry in ${mapTimeout}ms.`, error);
			setTimeout(() => {
				this.createMap();
			}, mapTimeout);
		});

		newMap.on('move', () => {
			this.resetTimer();
		});

		newMap.on('render', () => {
			// this.resizeThrottle();
		});
	}

	async selectCategory(nextCategory) {
		const {
			category,
			places_search_radius,
			centerLat,
			centerLng,
		} = this.state;

		let nextResults = null;

		if (!this.resultSet[nextCategory]) {
			this.resultSet[nextCategory] = [];
			nextResults = await legacyFetchPlaces(nextCategory, places_search_radius, centerLat, centerLng);
			this.resultSet[nextCategory] = nextResults;

			if (nextResults) {
				this.makeMarkers(nextResults, nextCategory);
			}
		} else {
			nextResults = this.resultSet[nextCategory];
		}

		if (category !== nextCategory) {
			this.clearMarkers();
		} else {
			this.clearPopups();
		}

		this.reCenterMap();

		this.setState({
			category: nextCategory,
			results:  nextResults,
		}, () => {
			this.showMarkers(nextCategory);
		});
	}

	setScrollPaddingTop(value, contHeight) {
		let newValue = this.state.scrollPaddingTop + value;
		if (newValue < 0) {
			newValue = 0;
		}
		if (newValue > (contHeight - 50)) {
			return;
		}
		this.setState({
			scrollPaddingTop: newValue,
		});
	}

	resetScrollPaddingTop() {
		this.setState({
			scrollPaddingTop: 0,
		});
	}

	reCenterMap() {
		const {
			map,
			centerLng,
			centerLat,
			zoom,
		} = this.state;

		if (!map) {
			return;
		}

		map.flyTo({
			center:   [ centerLng, centerLat, ],
			zoom,
			duration: 900,
		});
	}

	makeMarkers(results, category) {
		const {
			css,
		} = this.state;

		this.markerSet[category] = this.markerSet[category] || [];

		this.markerSet[category] = results.map((result) => {
			const amenityMarkerDiv = document.createElement('div');
			amenityMarkerDiv.style.backgroundImage = `url(${placePinUrl})`;
			amenityMarkerDiv.style.width = '25px';
			amenityMarkerDiv.style.height = '41px';
			amenityMarkerDiv.style.transform = 'scale(0.95)';

			const {
				latitude: markerLat,
				longitude: markerLng,
			} = result.geocodes.main;

			const placeNameToSearch = `${result.name}, ${result.location.formatted_address}`;

			const qrCode = <QRCode
				bgColor={'white'} // Color of the bright squares
				fgColor={'black'} // Color of the dark squares
				level="M" // QR Error correction level: L, M, Q, H
				style={{
					border: `5px solid white`,
				}} // Size in pixels
				value={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(placeNameToSearch)}`}
				renderAs={'svg'}
			/>;

			const popup = `
				<div class="amenitiesMapPopup" style="color:black;">
					<div class="amenitiesMapPlaceName ${css.amenitiesMapPlaceName}"><b>${result.name}</b></div>
					<div class="amenitiesMapPlaceAddress ${css.amenitiesMapPlaceAddress}">${result.location.formatted_address}</div>
					<div class="amenitiesMapQRCode ${css.amenitiesMapQRCode}">${renderToStaticMarkup(qrCode)}</div>
				</div>
			`;

			const amenityPopup = new mapboxgl.Popup({
				offset: 25,
			}).setHTML(popup);

			const amenityMarker = new mapboxgl.Marker(amenityMarkerDiv).setLngLat([ markerLng, markerLat, ]).setPopup(amenityPopup);

			return amenityMarker;
		});
	}

	showMarkers(category) {
		const {
			map,
		} = this.state;

		if (!map || !this.markerSet[category]) {
			return;
		}

		this.markerSet[category].forEach((marker) => {
			marker.addTo(map).setOffset([ 0, -20, ]);
		});
	}

	resetTimer() {
		const {
			idleTimeoutSeconds,
		} = this.state;

		clearTimeout(this.timer);

		this.timer = setTimeout(() => {
			this.resetMap();
		}, idleTimeoutSeconds * 1000);
	}

	render() {
		const {
			custom_classes,
			modifier_classes,
			keyNum,
		} = this.props;

		const {
			css,
			map,
			results,
			category,
			zoom,
		} = this.state;

		let categories = 'Restaurant';
		categories += '|Cafe, Coffee, and Tea House';
		categories += '|Lodging';
		categories += '|Bank';
		categories += '|Fuel Station';
		categories += '|Convenience Store';
		categories += '|ATM';
		categories += '|Grocery Store';
		categories += '|Pharmacy';
		categories += '|Shopping Mall';
		categories += '|Gym and Studio';
		categories += '|Health and Beauty Service';
		categories += '|Spa';
		categories += '|Florist';
		categories += '|Gift Store';
		categories += '|Hospital';
		categories += '|Post Office';
		categories += '|Rail Station';
		categories += '|Car Wash and Detail';
		categories += '|Spiritual Center';
		categories += '|Library';
		categories += '|Park';
		categories += '|Museum';
		categories += '|Movie Theater';
		categories += '|Bar';
		categories += '|Night Club';
		categories += '|Liquor Store';
		categories += '|Bakery';
		categories += '|Bookstore';
		categories += '|Dry Cleaner';
		categories += '|Laundry Service';
		categories += '|Clothing Store';
		categories += '|Computers and Electronics Retail';
		categories += '|Hardware Store';

		// Custom classes
		let customClasses = '';
		if (custom_classes && custom_classes.length > 0) {
			custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (modifier_classes && modifier_classes.length > 0) {
			modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		const scrollHorizontally = (direction) => {
			if (this.horizScrollRef?.current) {
				let cont = this.horizScrollRef.current;
				let amount = 378;
				if (direction === 'right') {
					amount = -378;
				}
				cont.scrollLeft += amount;
			}
		};

		let componentContainerStyles = {
			width: '100%',
			height: '100%',
		};

		if (!!heightENV && !!widthENV) {
			componentContainerStyles = {
				height: `${heightENV}px`,
				width: `${widthENV}px`,
			};
		}

		return (
			<div
				className={`${css.legacyDynamicMapAmenitiesContainer} ${modifierClasses} ${customClasses}`}
				style={componentContainerStyles}
				key={`img${keyNum}`}
			>
				<ResultsBar
					map={map}
					zoom={zoom}
					results={results}
					category={category}
					css={css}
					markerSet={this.markerSet}
					clearPopups={this.clearPopups.bind(this)}
					scrollPaddingTop={this.state.scrollPaddingTop}
					setScrollPaddingTop={this.setScrollPaddingTop.bind(this)}
				/>
				<div className={css.legacyMapLoadingText}>
					Loading/Sizing Map...
				</div>
				<div
					id={this.containerId}
					style={{
						width:  `calc(100% - 300px)`,
						height: `calc(100% - 68px)`,
					}}
				>
				</div>
				<div className='categorySelectMenu'>
					<div className='scrollLeft' onClick={() => {
						scrollHorizontally('right');
					}}>
						<svg className="horizScrollLeft" x="0px" y="0px" viewBox="0 0 50 50">
							<path d="M25.9,10L14.1,25l11.8,15H18L6.2,25L18,10H25.9z "></path>
							<path d="M40.7,10L28.9,25l11.8,15h-7.9L21.1,25l11.8-15 H40.7z"></path>
						</svg>
					</div>
					<div className='scrollContainer' ref={this.horizScrollRef}>
						<CategoryBar
							map={map}
							categories={categories}
							category={category}
							selectCategory={this.selectCategory.bind(this)}
							resetScrollPaddingTop={this.resetScrollPaddingTop.bind(this)}
						/>
					</div>
					<div className='scrollRight' onClick={() => {
						scrollHorizontally('left');
					}}>
						<svg className="horizScrollRight" x="0px" y="0px" viewBox="0 0 50 50">
							<path d="M22.4,40l11.8-15L22.4,10h7.9 l11.8,15L30.3,40H22.4z"></path>
							<path d="M7.5,40l11.8-15L7.5,10h7.9 l11.8,15L15.4,40H7.5z"></path>
						</svg>
					</div>
				</div>
			</div>
		);
	}
}


const generateIcon = (category) => {
	let src = '';

	switch (category) {
	case 'ATM':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_atm.png';
		break;
	case 'Bank':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_bank.png';
		break;
	case 'Bar or Pub':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_bar.png';
		break;
	case 'Cafe, Coffee, and Tea House':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_cafe.png';
		break;
	case 'Fuel Station':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_gas_station.png';
		break;
	case 'Lodging':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_lodging.png';
		break;
	case 'Pharmacy':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_pharmacy.png';
		break;
	case 'Restaurant':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_restaurant.png';
		break;
	case 'Train Station':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_train_station.png';
		break;
	case 'Florist':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_florist.png';
		break;
	case 'Convenience Store':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_convenience_store.png';
		break;
	case 'Grocery Store':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_grocery_or_supermarket.png';
		break;
	case 'Shopping Mall':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_shopping_mall.png';
		break;
	case 'Gym and Studio':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_gym.png';
		break;
	case 'Health and Beauty Service':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_beauty_salon.png';
		break;
	case 'Spa':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_spa.png';
		break;
	case 'Gift Store':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_gift_store.png';
		break;
	case 'Hospital':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_hospital.png';
		break;
	case 'Post Office':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_post_office.png';
		break;
	case 'Rail Station':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_train_station.png';
		break;
	case 'Car Wash and Detail':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_car_wash.png';
		break;
	case 'Spiritual Center':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_place_of_worship.png';
		break;
	case 'Library':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_library.png';
		break;
	case 'Park':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_park.png';
		break;
	case 'Museum':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_museum.png';
		break;
	case 'Movie Theater':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_movie_theater.png';
		break;
	case 'Bar':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_bar.png';
		break;
	case 'Night Club':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_night_club.png';
		break;
	case 'Liquor Store':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_liquor_store.png';
		break;
	case 'Bakery':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_bakery.png';
		break;
	case 'Bookstore':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_book_store.png';
		break;
	case 'Dry Cleaner':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_dry_cleaner.png';
		break;
	case 'Laundry Service':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_laundry.png';
		break;
	case 'Clothing Store':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_clothing_store.png';
		break;
	case 'Computers and Electronics Retail':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_electronics_store.png';
		break;
	case 'Hardware Store':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_hardware_store.png';
		break;


	default:
		src = '';
		break;
	}

	return src;
};

const generateLabel = (category) => {
	let label = '';

	switch (category) {
	case 'Restaurant':
		label = 'Restaurant';
		break;
	case 'Cafe, Coffee, and Tea House':
		label = 'Café';
		break;
	case 'Lodging':
		label = 'Lodging';
		break;
	case 'Bank':
		label = 'Bank';
		break;
	case 'Fuel Station':
		label = 'Gas Station';
		break;
	case 'Convenience Store':
		label = 'Convenience Store';
		break;
	case 'ATM':
		label = 'ATM';
		break;
	case 'Grocery Store':
		label = 'Grocery';
		break;
	case 'Pharmacy':
		label = 'Pharmacy';
		break;
	case 'Shopping Mall':
		label = 'Shopping Mall';
		break;
	case 'Gym and Studio':
		label = 'Gym';
		break;
	case 'Health and Beauty Service':
		label = 'Health & Beauty';
		break;
	case 'Spa':
		label = 'Spa';
		break;
	case 'Gift Store':
		label = 'Gifts';
		break;
	case 'Hospital':
		label = 'Hospital';
		break;
	case 'Post Office':
		label = 'Post Office';
		break;
	case 'Rail Station':
		label = 'Rail Station';
		break;
	case 'Car Wash and Detail':
		label = 'Car Wash';
		break;
	case 'Spiritual Center':
		label = 'Spiritual Center';
		break;
	case 'Library':
		label = 'Library';
		break;
	case 'Park':
		label = 'Park';
		break;
	case 'Museum':
		label = 'Museum';
		break;
	case 'Movie Theater':
		label = 'Movie Theater';
		break;
	case 'Bar':
		label = 'Bar';
		break;
	case 'Night Club':
		label = 'Night Club';
		break;
	case 'Liquor Store':
		label = 'Liquor Store';
		break;
	case 'Bakery':
		label = 'Bakery';
		break;
	case 'Bookstore':
		label = 'Bookstore';
		break;
	case 'Dry Cleaner':
		label = 'Dry Cleaner';
		break;
	case 'Laundry Service':
		label = 'Laundry';
		break;
	case 'Clothing Store':
		label = 'Clothing Store';
		break;
	case 'Computers and Electronics Retail':
		label = 'Electronics Store';
		break;
	case 'Hardware Store':
		label = 'Hardware Store';
		break;
	default:
		label = category;
		break;
	}

	return label;
};

const ResultsBar = (props) => {
	const {
		map,
		zoom,
		results,
		category,
		css,
		markerSet,
		clearPopups,
		scrollPaddingTop,
		setScrollPaddingTop,
	} = props;

	const vertScrollRef = useRef(null);

	const returnListingResults = () => {
		const resultsArr = results.map((result, index) => {
			return (
				<div
					className="resultsRow"
					key={`categoryListing_${index}`}
					onClick={() => {
						clearPopups(category);

						setTimeout(() => {
							clearPopups(category);
							markerSet[category][index].togglePopup();
						}, 800);

						const lnglat = markerSet[category][index].getLngLat();
						map.flyTo({
							center:   [ lnglat.lng, lnglat.lat, ],
							zoom,
							duration: 900,
						});
					}}
				>
					<div className="listingId">
						<div className="listingNumber">
							<div className="idBox">{index + 1}</div>
						</div>
					</div>
					<div className="listingName">{result.name}</div>
				</div>
			);
		});

		return <div
			className={css.listResults}
			style={{
				paddingTop: scrollPaddingTop,
			}}
			ref={vertScrollRef}
		>
			{resultsArr}
		</div>;
	};

	const scrollVertically = (direction) => {
		if (vertScrollRef?.current) {
			let cont = vertScrollRef.current;
			let startScrollPos = cont.scrollTop;
			let contHeight = cont.clientHeight;

			let amount = 300;
			if ((direction === 'up' && startScrollPos === 0) || (direction === 'up' && parseInt(cont.style.paddingTop) > 0)) {
				setScrollPaddingTop(100, contHeight); // add ADA padding
			} else if (direction === 'down' && parseInt(cont.style.paddingTop) > 0) {
				setScrollPaddingTop(-100, contHeight); // reduce ADA padding
			} else if (direction === 'up') {
				amount = -300;
				cont.scrollTop += amount;
			} else if (direction === 'down') {
				cont.scrollTop += amount;
			}
		}
	};

	return (
		<div className="amenResults">
			<div className="amenResultsList">
				<div className="listingHeader">{props.category}</div>

				{(!results) &&
					<div className="listingInfo">Please Choose a Category From the Buttons Below</div>
				}
				{(results?.length === 0) &&
					<div className="listingInfo noResults">No Results Found</div>
				}
				{(results?.length > 0) &&
					returnListingResults(results, category, map)
				}
				<div className={css.vertScrollBtns}>
					<div className={css.scrollDown} onClick={() => {
						scrollVertically('down');
					}}>
						<div className={css.scrollText}>SCROLL DOWN</div>
						<svg className={css.scrollSvg} x="0px" y="0px" viewBox="0 0 47 31">
							<path d="M3,2l20.5,13.5L44,2v11.5L23.5,27L3,13.5V2z"></path>
						</svg>
					</div>
					<div className={css.scrollUp}  onClick={() => {
						scrollVertically('up');
					}}>
						<div className={css.scrollText}>SCROLL UP</div>
						<svg className={css.scrollSvg} x="0px" y="0px" viewBox="0 0 47 31">
							<path d="M44,27L23.5,13.5L3,27V15.5L23.5,2 L44,15.5V27z"></path>
						</svg>
					</div>
				</div>

			</div>
		</div>
	);
};

const CategoryBar = (props) => {
	const {
		categories,
		selectCategory,
		resetScrollPaddingTop,
	} = props;

	return (
		<div className='amenitiesMapSelector'>
			{categories.split('|').map((category) => {
				return (
					<div className='radioOption'
						key={`categoryBar_${generateLabel(category)}`}
					>
						<input
							type='radio'
							name='amenitiesCategorySelector'
							className='radio'
							onMouseDown={(e) => {
								e.target.willUncheck = e.target.checked;
							}}
							onClick={(e) => {
								resetScrollPaddingTop();
								if (e.target.willUncheck) {
									e.target.checked = false;
								}
								selectCategory(category);
							}}
						/>
						<label
							className='radioText'
						>
							<img src={generateIcon(category)} />
							<span>{generateLabel(category)}</span>
						</label>
					</div>
				);
			})}
		</div>
	);
};

export default LegacyMapAmenities;
