import React from 'react';


const TextList = (props) => {
	const css = props.CSSModules[props.condor_render_name];

	// Custom classes
	let customClasses = '';
	if (props.custom_classes && props.custom_classes.length > 0) {
		props.custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				customClasses += ` ${element}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (props.modifier_classes && props.modifier_classes.length > 0) {
		props.modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

	let textList = props.text_list;
	if (customClasses.includes('alpha')) {
		textList = textList.sort();
	}

	let listStyle = {
		listStyle: `${props.decoration}`
	}

	let listItems = [];
	if (textList) {
		listItems = textList.map((item) => {
			return (
				<li key={item}>{item}</li>
			);
		});
	}


	return (
		<ul id={`textList_${props.component_name}`} className={`textList_container ${css.cont} ${modifierClasses} ${customClasses}`} style={listStyle}>
			{listItems}
		</ul>
	);

};

export default TextList;
