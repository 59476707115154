import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';

class IdleTimerPrompt extends Component {
	constructor(props) {
		super(props);

		this.state = {
			css: props.CSSModules["IdleTimerPrompt"],
		};
	}

	componentDidMount() { }

	render() {
		const {
			css
		} = this.state;
		// For translation
		const { t } = this.props;
		return (
			<CSSTransition
				in={this.props.showIdlePrompt}
				appear={this.props.showIdlePrompt}
				unmountOnExit={true}
				timeout={800}
				classNames='contentFade'>
					<div className={css.promptContainer} onClick={() => {this.props.clearShowIdlePrompt()}}>
                		 <button className={css.promptBtn}>{t("idleTouchToContinueLabel")}</button>
           			 </div>	
			</CSSTransition>
		);
	}
}

export default withTranslation()(IdleTimerPrompt);