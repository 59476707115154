import { broncoURLENV } from "../ENV";

const legacyCategoryMap = {
    'Restaurant': 13065,
    'Cafe, Coffee, and Tea House': 13032,
    'Lodging': 19009,
    'Bank': 11045,
    'Fuel Station': 19007,
    'Convenience Store': 17029,
    'ATM': 11044,
    'Grocery Store': 17069,
    'Pharmacy': 17035,
    'Shopping Mall': 17114,
    'Gym and Studio': 18021,
    'Health and Beauty Service': 11061,
    'Spa': 11073,
    'Florist': 17056,
    'Gift Store': 17089,
    'Hospital': 15014,
    'Post Office': 12075,
    'Rail Station': 19047,
    'Car Wash and Detail': 11011,
    'Spiritual Center': 12098,
    'Library': 12080,
    'Park': 16032,
    'Museum': 10027,
    'Movie Theater': 10024,
    'Bar': 13003,
    'Night Club': 10032,
    'Liquor Store': 17076,
    'Bakery': 13002,
    'Bookstore': 17018,
    'Dry Cleaner': 11066,
    'Laundry Service': 11069,
    'Clothing Store': 17043,
    'Computers and Electronics Retail': 17023,
    'Hardware Store': 17090,
};
		
export const legacyFetchPlaces = async (legacyCategory, radius, latitude, longitude, limit = 20) => {
    const category = `${legacyCategoryMap[legacyCategory]}`;
   
    let results;

    const body = JSON.stringify({
        categories: category,
        latitude,
        longitude,
        radius,
        limit,
    });

    try {
        const url = `${broncoURLENV}/fsq_search`;
        const response = await fetch(url,	{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body,
        });

        const data = await response.json();
        
        if (data?.results) {
            results = data.results.sort((resultA, resultB) => {
				if (resultA.name > resultB.name) {
					return 1;
				}
                return -1;
			});
        } else {
            throw `no results from '/search' request for category ${category}`;
        }
    } catch(error) {
        console.error(error);
    } finally {
        return results;
    }
};

export const fetchPlaces = async (category, radius, latitude, longitude, limit = 20) => {
    let results;

    const body = JSON.stringify({
        categories: category,
        latitude,
        longitude,
        limit,
        radius,
    });

    try {
        const url = `${broncoURLENV}/fsq_search`;
        const response = await fetch(url,	{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body,
        });

        const data = await response.json();
        
        if (data?.results) {
            results = data.results.sort((resultA, resultB) => {
				if (resultA.name > resultB.name) {
					return 1;
				}
                return -1;
			});
        } else {
            throw `no results from '/search' request for category ${category}`;
        }
    } catch(error) {
        console.error(error);
    } finally {
        return results;
    }
};