/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
class DynamicMessage extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            containerWidth:  '',
            css:             props.CSSModules[props.condor_render_name],
            currentItem:     0,
            previousItem:    '',
            runningInterval: 0,
            nextItem:        1,
            numMessages:     0,
            wipe:            '',
            refresh:         false
        };
    
        this.startMessageTimer = this.startMessageTimer.bind(this);
        this.stopMessageTimer = this.stopMessageTimer.bind(this);
        this.animatedScrollerTimer1 = this.animatedScrollerTimer1.bind(this);
        this.animatedScrollerTimer2 = this.animatedScrollerTimer2.bind(this);
		this.divRef = React.createRef();
    }

    componentDidMount() {
		this.stopMessageTimer();
        console.log(`props`, this.props);
        setTimeout(() => {
            this.initialize();
        }, 1500);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.animatedScroller && JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
			    this.stopMessageTimer();
			    this.initialize();
		}
			
		if (this.props.animatedScroller === true && (prevProps.animation_type !== this.props.animation_type || prevProps.animatedScrollerTimer !== this.props.animatedScrollerTimer
			    || prevProps.message_list !== this.props.message_list)) {
            this.setState({
                refresh: true
            });
        }
    
        if (prevProps.cycleCount !== this.props.cycleCount && this.props.animatedScroller === true) {
            this.cycleAnimatedScroller();
        }
    }

    initialize = () => {
		this.stopMessageTimer();
        let numMessages = 0;
        let duration = this.props.transition_time * 1000;
    
        if (this.props.message_list && Array.isArray(this.props.message_list)) {
            numMessages =this.props.message_list.length;
            let previousItem = 2;
            if (previousItem > numMessages - 1) {
                previousItem = 0;
            };
			console.log('ref', this.divRef.current.offsetWidth);
            this.setState({
                containerWidth: this.divRef.current.offsetWidth,
                numMessages:    numMessages,
                currentItem:    0,
                nextItem:       1,
                previousItem:   previousItem
            });
			console.log('state', this.state.containerWidth);
        }
        if (!this.props.message_list || (this.props.message_list && this.props.message_list.length === 1)) {
            return;
        }
        // if not inside the AnimatedScroller component, or AnimatedScroller component has time cycle of 0
        if ((this.state.numMessages > 0 && !this.props.animatedScroller) || (this.props.animatedScroller && this.props.animatedScrollerTimer === 0)) {
            setTimeout(() => {
                this.startMessageTimer(duration);
            }, 500);
        }
    }

    startMessageTimer = (duration) => {
        clearInterval(this.state.runningInterval)
        this.messageTimer = setInterval(() => {
            this.cycleMessages();
        }, duration);
        this.setState({ runningInterval: this.messageTimer})
    }

    stopMessageTimer = () => {
        clearInterval(this.messageTimer);
    }

    animatedScrollerTimer1 = (duration) => {
        this.scrollerTimer1 = setTimeout(() => {
            this.cycleMessages();
        }, duration);
    }

    animatedScrollerTimer2 = (duration) => {
        this.scrollerTimer2 = setTimeout(() => {
            this.cycleMessages();
        }, duration);
    }

    stopAnimatedScrollerTimer1 = () => {
        clearTimeout(this.scrollerTimer1);
    }
    
    stopAnimatedScrollerTimer2 = () => {
        clearTimeout(this.scrollerTimer2);
    }

    cycleAnimatedScroller = () => {
        if (this.state.refresh === true) {
            if (this.animatedScrollerTimer1) {
                this.stopAnimatedScrollerTimer1();
            }
            if (this.animatedScrollerTimer2) {
                this.stopAnimatedScrollerTimer2();
            }
            this.setState({
                refresh: false
            });
            this.initialize();
        }
    
        if (!this.props.message_list || (this.props.message_list && this.props.message_list.length === 1)) {
            return;
        }
    
        let animatedScrollerTransitionTime = 400;
        let animatedScrollerTimer = this.props.animatedScrollerTimer * 1000;
        // scroll immediately after AnimatedScroller is done cycling
        this.animatedScrollerTimer1(animatedScrollerTransitionTime);
        // scroll halfway between the next AnimatedScroller cycle
        this.animatedScrollerTimer2((animatedScrollerTimer - (animatedScrollerTransitionTime / 2)) / 2);
    }

    cycleMessages = () => {
        let currentItem = this.state.currentItem;
        let nextItem = this.state.nextItem;
        let previousItem = this.state.previousItem;
        let numMessages = this.state.numMessages;
    
        if (this.state.currentItem + 1 > numMessages - 1) {
            currentItem = 0;
        } else {
            currentItem = this.state.currentItem + 1;
        }
    
        if (this.state.nextItem + 1 > numMessages - 1) {
            nextItem = 0;
        } else {
            nextItem = this.state.nextItem + 1;
        }
    
        if (this.state.previousItem + 1 > numMessages - 1) {
            previousItem = 0;
        } else {
            previousItem = this.state.previousItem + 1;
        }
    
        this.setState({
            currentItem:  currentItem,
            nextItem:     nextItem,
            previousItem: previousItem,
            wipe:     true
        });
    
        setTimeout(() => {
            this.setState({
                wipe: false
            });
        }, 1000);
    
        if (this.state.wipe === false) {
            setTimeout(() => {
                this.setState({
                    wipe: true
                });
            }, 1000);
        }
    }


    render() {
        let containerWidth = {
            width: `${this.state.containerWidth}px`
        };
    
        // Custom classes
        let customClasses = '';
        if (this.props.custom_classes && this.props.custom_classes.length > 0) {
            this.props.custom_classes.forEach((element) => {
                if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
                    customClasses += ` ${element}`;
                }
            });
        }
    
        // Modifier classes
        let modifierClasses = '';
        if (this.props.modifier_classes && this.props.modifier_classes.length > 0) {
            this.props.modifier_classes.forEach((element) => {
                if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
                    modifierClasses += ` ${element}`;
                }
            });
        }
    
        let animation = '';
        if (this.props.message_list && Array.isArray(this.props.message_list) && this.props.message_list.length > 1) {
            switch (this.props.animation_type) {
            case 0:
                animation = 'Pop';
                break;
            case 1:
                animation = 'Fade';
                break;
            case 2:
                animation = 'Roll';
                break;
            case 3:
                animation = 'Slide';
                break;
            case 4:
                animation = 'Wipe';
                break;
            default:
                animation = 'Fade';
            }
        }
    
        let messages = [];
        let msgClass = '';
    
        if (this.props.animation_type === 4) {
            msgClass = this.state.css.wipeMsg;
        } else {
            msgClass = this.state.css.msg;
        }
    
        if (this.props.message_list && Array.isArray(this.props.message_list)) {
            let message_list = this.props.message_list

            if (message_list.length === 1) {
                messages = this.props.message_list.map((item, i) => {
                    return (
                        <div className={`${msgClass}`} key={`dynamicMessage_${i}_${this.props.component_name}`}>{item}</div>
                    );
                });
            }

            if(message_list.length === 2) {
                message_list.push(...message_list)
            }

            messages = this.props.message_list.map((item, i) => {
                let animationClass = 'has';
                if (i === this.state.currentItem) {
                    animationClass = 'is';
                } else if (i === this.state.nextItem) {
                    animationClass = 'will';
                }
                return (
                    <div className={`${this.state.css[`${animationClass}${animation}`]} ${msgClass}`} key={`dynamicMessage_${i}_${this.props.component_name}`}>{item}</div>
                );
            });
        }
        
        let dynamicMessage = '';
        if (this.props.animation_type === 4) {
            dynamicMessage =
                <div ref={this.divRef} className={`dynamicMessage_container ${this.state.css.cont} ${modifierClasses} ${customClasses}`}>
                    <div className={this.state.wipe ? `${this.state.css.wipeOff}` : `${this.state.css.wipeOn}`}>
                        <div className={this.state.css.wipe} style={containerWidth}>
                            {messages}
                        </div>
                    </div>
                </div>
            ;
        } else {
            dynamicMessage =
                <div ref={this.divRef} className={`dynamicMessage_container ${this.state.css.cont} ${modifierClasses} ${customClasses}`} >
                    {messages}
                </div>
            ;
        }
    
        return (
            <>
                {dynamicMessage}
            </>
        );
    }
}

export default DynamicMessage;