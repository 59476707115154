import React, { Component } from 'react';
// import axios from 'axios';
import EventCurrent from './EventCurrent';
import EventList from './EventList';
import EventTimebar from './EventTimebar';
import TimeFmt from '../util/TimeFmt.js';

import { v4 as uuidv4 } from 'uuid';
import {
	broncoURLENV,
	herciniaURLENV,
} from '../ENV.js';

const broncoURL = broncoURLENV;
const herciniaURL = herciniaURLENV;
class Raven extends Component {
	constructor(props) {
		super(props)

		this.state = {
			css: props.CSSModules[props.condor_render_name],
			events: [],
			eventsID: false,
			booked: false,
			screensaverOn: false,
			inBusinessHours: true
		}

		this.fetching = false;
	}

	componentDidMount() {
		this.fetchEvents();

		let eventsID = setInterval(this.fetchEvents, 60 * 1000 * 2); // Ticks every 2 mins.

		this.setState({ eventsID: eventsID });

		if (this.props.raven_hour_start && this.props.raven_hour_end) {
			let shouldDisableScreensaver = this.checkHours();
			if (shouldDisableScreensaver){
				this.setState({inBusinessHours: true});
				this.screensaverRequest();
			} else {
				this.setState({inBusinessHours: false})
				this.screensaverRequest();
			}
		}
		/* this.orientation(); */
		/* this.timezone(); */
	}

	checkHours(){
		let startHour = this.props.raven_hour_start;
		let endHour = this.props.raven_hour_end;

		let startTime = new Date();
		let endTime = new Date();
		startTime.setHours(startHour);
		endTime.setHours(endHour);
		startTime.setMinutes(0);
		startTime.setSeconds(0);
		endTime.setMinutes(0);
		endTime.setSeconds(0);
		return (TimeFmt.isNowBetween(startTime, endTime));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.inBusinessHours !== this.checkHours()){
			this.screensaverRequest();
			this.setState({inBusinessHours: this.checkHours()}, this.screensaverRequest);
		}
}


	componentWillUnmount() {
		clearInterval(this.state.eventsID);
	}

	screensaverRequest = () => {
		if (this.state.inBusinessHours === true) {
			if (this.state.screensaverOn === true) {
				fetch(`${herciniaURL}/inhours`)
			.then(response => {
				this.setState({screensaverOn: false});
				if (response.ok) {
					return response.text();
				} else {
					throw new Error('Bad network response');
				}
			})
			.then(contents => {
				console.log(contents);
			})
			.catch(error => {
				console.error(error);
			});
		
			}
		}
		if (this.state.inBusinessHours === false) {
			if (this.state.screensaverOn === false) {
				fetch(`${herciniaURL}/outofhours`)
			.then(response => {
				this.setState({screensaverOn: true});
				if (response.ok) {
					return response.text();
				} else {
					throw new Error('Bad network response');
				}
			})
			.then(contents => {
				console.log(contents);
			})
			.catch(error => {
				console.error(error);
			});
		
			}
		}
	}

	fetchEvents = async () => {
		if (this.fetching) {
			return;
		}
		this.fetching = true;

		try {
			const {
				calendar_id,
				raven_provider,
				component_name
			} = this.props;

			const data = {
				component_uuid: component_name,
				provider:       raven_provider,
				calendar_id:    calendar_id
			};

			const response = await fetch(`${broncoURL}/raven/calendar/events`, {
				method:  'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const responseData = await response.json();
			this.setState({ events: responseData.events });
		} catch (error) {
			console.error("Error fetching events:", error);
			// Handle the error based on your application's needs
		} finally {
			this.fetching = false;
		}
	};


	// Logic for orientation switching
	orientation = () => {
		fetch(`${herciniaURL}/${this.props.raven_orientation}`).then(response => {
			if (response.ok) {
				console.log(response);
				return response.text();
			} else {
				throw new Error('Bad network response');
			}
		})
		.then(contents => {
			console.log(contents);
		})
		.catch(error => {
			console.error(error);
		});
	
	}

	// Logic for timezone settings
	timezone = () => {
		fetch(`${herciniaURL}/${this.props.timezone}`).then(response => {
			if (response.ok) {
				console.log(response);
				return response.text();
			} else {
				throw new Error('Bad network response');
			}
		})
		.then(contents => {
			console.log(contents);
		})
		.catch(error => {
			console.error(error);
		});
	}

	render() {
		const {
			show_calendar_name,
			show_event_list,
			show_event_timebar,
			show_event_current,
		} = this.props;
		let currentTime = new Date();
			const nextHour = new Date(currentTime.getTime() + 60 * 60 * 1000);

		let css = this.state.css;

		return (
			<div className={`ravenContainer ${css.container}`}>
				<div className={`calendarHeader ${css.header}`}>
					{show_calendar_name && (
						<div className={`calendarName ${css.calendarName}`}>{this.props.raven_calendar_name}</div>
					)}
				</div>
				<div className={`calendarEvents ${css.events}`}>
					{show_event_current && (
						<div className={`calendarEventItem ${css.eventsItem}`}>
							<EventCurrent
								{...this.props}
								css={css}
								component_name={uuidv4()}
								current_time={currentTime}
								event={this.state.events}
							/>
						</div>
					)}

					{show_event_list && (
						<div className={`calendarEventList ${css.eventList} ${css.eventsItem}`}>
							<EventList
								{...this.props}
								css={css}
								component_name={uuidv4()}
								// TODO: Get real events
								events={this.state.events}
							/>
						</div>
					)}

				</div>
				<div className={`calendarTimebar ${css.timebar}`}>
					{show_event_timebar && (
						<div className={`${css.eventTimebar} ravenEventTimebar`}>
							<EventTimebar
								{...this.props}
								css={css}
								component_name={uuidv4()}
								// TODO: Get real events
								events={this.state.events}
							/>
						</div>
					)}</div>

			</div>

		);
	}
}

export default Raven;