import React from 'react';
import TimeFmt from '../util/TimeFmt.js';

const validateCalendarEventProps = (props) => {
	// Does not check boolean values. Unless I am mistaken, no type errs can occur if only asking questions about truthiness.
	let isInvalid = (x) => {
		return typeof x === 'undefined';
	};

	switch (props) {
	case isInvalid(props.start_time):
	case isInvalid(props.end_time):
	case isInvalid(props.react_key):
	case isInvalid(props.subject):
	case (props.displayOrganizer && isInvalid(props.organizer)):
		return false;
	default:
		return true;
	}
};

const monthDayFormat = (dateString) => {
	let d = new Date(dateString);
	let month = (d.getMonth() + 1).toString().padStart(2, '0');
	let day = d.getDate().toString().padStart(2, '0');
	return `${month}/${day}`;
};

const EventItem = (props) => {
	let validated = validateCalendarEventProps(props);
	if (!validated) {
		console.error(`Could not render calendar events from these props:\n${JSON.stringify(props)}`);
		return '';
	}

	let {component_name, classes, css, displayOrganizer, displayRoomname, reactKey, start_time, end_time, subject, privacy, privateLabel, timeFormat, organizer, showDates, timePeriod, room_name, showDateMonth} = props;

	// Custom classes
	let customClasses = '';
	if (props.custom_classes && props.custom_classes.length > 0) {
		props.custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				classes += ` ${css[element]}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (props.modifier_classes && props.modifier_classes.length > 0) {
		props.modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

	let organizerElm = (<div className={`eventItem_organizer ${css.organizerHide}`}></div>);
	let organizerEntry = organizer;

	if (
		organizer &&
		typeof organizer === 'object'
	) {
		organizerEntry = organizer.name;
		if (typeof organizerEntry !== 'string') {
			organizerEntry = '';
		}
	}

	if (displayOrganizer) {
		organizerElm = (<div className={`eventItem_organizer ${css.organizer}`}>{organizerEntry}</div>);
	}

	let locationElm = (<div className={`eventItem_location ${css.locationHide}`}></div>);
	if (displayRoomname) {
		locationElm = (<div className={`eventItem_location ${css.location}`}>{room_name}</div>);
	}

	let timeElm = '';
	let dateElm = '';

	let startPeriod, endPeriod = '';
	if (timeFormat === '12_hour'){
		startPeriod = TimeFmt.makeDisplayAMPM(start_time, timeFormat, true);
		endPeriod = TimeFmt.makeDisplayAMPM(end_time, timeFormat, true);
	}
	let startTime = TimeFmt.makeDisplayStamp(start_time, timeFormat, timePeriod);
	let endTime = TimeFmt.makeDisplayStamp(end_time, timeFormat, timePeriod);
	let startAMPM = TimeFmt.makeDisplayAMPM(start_time, timeFormat, timePeriod);

	if (startTime) {
		timeElm = (
			<div className={css.time}>
				{startTime}
			</div>
		);

		if (endTime) {
			timeElm = (
				<div className={`eventItem_time ${css.time}`}>
					{startTime}{<span className={`eventItem_period ${css.period}`}>{startPeriod}</span>} - {endTime}{<span className={css.period}>{endPeriod}</span>}
				</div>
			);
		}
	
		let dateMonth = monthDayFormat(start_time)

		let dowAbbrv = true;
		let dayOfWeekString = TimeFmt.makeDayOfWeek(start_time, dowAbbrv);
		let parsedTime = TimeFmt.fmtRawStamp(start_time);
		
		if(showDateMonth){
		dateElm = (
				<>
					<div className={`eventItem_dayMonth ${css.dayMonth}`}>{dateMonth}</div>
					<div className={`eventItem_dayWeek ${css.dayWeek}`}>{dayOfWeekString}</div>
				</>
			);
		} else {
		dateElm = (
			<>
				<div className={`eventItem_day ${css.day}`}>{parsedTime.getDate()}</div>
				<div className={`eventItem_dayWeek ${css.dayWeek}`}>{dayOfWeekString}</div>
			</>
			);
		}
	}

	let eventClasses = css.dateHide;
	let cardClasses = css.cardHideDate;

	if (showDates === true) {
		eventClasses = css.dateShow;
		cardClasses = css.cardShowDate;
	}

	if (privacy === 'private') {
		subject = privateLabel;
	}
	let eventName = subject;

	return (
		<div className={`eventItem_container ${cardClasses} ${modifierClasses} ${customClasses}`} key={reactKey}>
			<div className={`eventItem_line ${css.line}`}></div>
			<div className={`eventItem_dateContainer ${eventClasses}`}>
				{dateElm}
			</div>
			<div className={`eventItem_details ${css.details}`}>
				<div className={`eventItem_eventName ${css.eventName}`}>{eventName}</div>
				{timeElm}
				{organizerElm}
				{locationElm}
			</div>
		</div>
	);
};

export default EventItem;
