import React from 'react';
import TimeFmt from '../util/TimeFmt.js';
import EventItem from './EventItem.js';

const EventList = (props) => {
	if (!Array.isArray(props.events) || props.events.length <= 0) {
		return '';
	}

	let maxEvents = props.raven_event_count;

	let calendarStartTime = props.raven_hour_start;
	let calendarEndTime = props.raven_hour_end;

	let eventsArray = [];

	if (props.events && props.events.length > 0) {

		props.events.forEach((calEvent, i) => {



			if (!calEvent || !calEvent.start_time || !calEvent.end_time) {
				return;
			}

			if (TimeFmt.isNowBetween(calEvent.start_time, calEvent.end_time)) {
				return;
			}

			if (TimeFmt.isBeforeNow(calEvent.end_time)) {
				return;
			}

			let {start_time, end_time} = calEvent;

			if (!start_time || !end_time) {
				return;
			}

			let startTime = TimeFmt.fmtRawStamp(start_time).getHours();
			
			let endTime = TimeFmt.fmtRawStamp(end_time).getHours();


			if (endTime < calendarStartTime) {
				console.log("calendarStartTime?")
				return;
			}

			if (startTime > calendarEndTime) {
				console.log("calendarEndTime?")
				return;
			}

			let calEventProps = {
				reactKey:         `${i}-raven-event`,
				name:             props.component_name,
				modifierClasses:  props.modifier_classes,
				customClasses:    props.custom_classes,
				css:			  props.css,
				displayOrganizer: props.raven_display_organizer,
				displayRoomname:  props.raven_show_room_name,
				showDateMonth:    props.show_date_month,
				showDates:        props.raven_show_event_date,
				timePeriod:       props.raven_time_period,
				timeFormat:       props.raven_time_format,
				hoursStart:       props.raven_hour_start,
				minutesStart:     props.raven_minute_start,
				hoursEnd:         props.raven_hour_end,
				minutesEnd:       props.raven_minute_end,
				privateLabel:     props.raven_private_event_label,
				theme:            props.condor_theme,
				themeVariation:   props.themeVariation,
				...calEvent
			};
			let nextEvent = EventItem(calEventProps);
			eventsArray.push(nextEvent);

		});
	}

	if (eventsArray.length > maxEvents) {
		eventsArray.length = maxEvents;
	}
	return (
		<>
			{eventsArray}
		</>
	);
};

export default EventList;
