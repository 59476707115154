import React, { useEffect, useState, } from 'react';

const ImageComp = (props) => {
	const css = props.CSSModules[props.condor_render_name];
	const [ source, setSource, ] = useState(props.source);
	const [ display, setDisplay, ] = useState("none");

	// Custom classes
	let customClasses = '';
	if (props.custom_classes && props.custom_classes.length > 0) {
		props.custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				customClasses += ` ${element}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (props.modifier_classes && props.modifier_classes.length > 0) {
		props.modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

	let keyValue = `img${props.keyNum}`;

	useEffect(() => {
		setSource(props.source);
		if (props.source && props.source !== '') {
			setDisplay("");
		} else {
			setDisplay("none");
		}
	}, [props.source]);

	return (
		<div id={`image_${props.component_name}`} className={`image_container ${css.cont} ${modifierClasses} ${customClasses}`} key={keyValue}>
			<img className={`${css.img} img`} src={source} alt="uploadedImage" style={{display: display}} draggable="false"/>
		</div>
	);

};

export default ImageComp;
