import React from 'react';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import LocationControl from '../util/LocationControl';
import {
	ternStaticTokenENV,
	ternUserNameENV,
} from '../ENV';

const userName = ternUserNameENV;

if (!mapboxgl.workerClass) {
	const accessToken = ternStaticTokenENV;
	// eslint-disable-next-line import/no-webpack-loader-syntax
	mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // Wire up loaded worker to be used instead of the default
	mapboxgl.accessToken = accessToken;
}

class TrafficResponsiveMap extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			css: props.CSSModules[props.condor_render_name],
			map: null,
			category: '',
			nextCategory: '',
			results: null,
			idleTimeoutSeconds: 60,
			traffic_line_color_low: 'green',
			traffic_line_color_moderate: 'yellow',
			traffic_line_color_heavy: 'orange',
			traffic_line_color_severe: 'red',
			traffic_line_width: 2.1,
			traffic_line_before_layer: 'road-label-simple',
		};

		this.containerId = `trafficMap_${props.component_name}`;

		if (props.property_latlon) {
			this.latitude = props.latitude;
			this.longitude = props.longitude;
		} else {
			this.latitude = props.system_latitude;
			this.longitude = props.system_longitude;
		}
	}

	componentDidMount() {
		this.createMap();
	}

	createMap() {
		const {
			map,
			traffic_line_color_low,
			traffic_line_color_moderate,
			traffic_line_color_heavy,
			traffic_line_color_severe,
			traffic_line_width,
			traffic_line_before_layer
		} = this.state;

		const {
            mapbox_map_id,
			zoom,
			hide_map_buttons,
		} = this.props;

		const {
            latitude,
            longitude,
		} = this;

		if (map) {
			map.remove();
		}

		// clearing the container before making a new map
		document.querySelector(`#${this.containerId}`).innerHTML = '';
	
		const boundOffset = 3;

		const newMap = new mapboxgl.Map({
			container: this.containerId, // container ID
			style: `mapbox://styles/${userName}/${mapbox_map_id}`, // style URL
			center: [longitude, latitude], // starting position [lng, lat]
			maxBounds: [[longitude - boundOffset, latitude - boundOffset], [longitude + boundOffset, latitude + boundOffset]],
			zoom, // starting zoom
			minZoom: zoom - 3,
			maxZoom: zoom + 3,
			preserveDrawingBuffer: true,
			attributionControl: false,
			logoPosition: 'top-left',
			performanceMetricsCollection: false,
		});
	
		newMap.on('load', () => {
			const marker = document.createElement('div');
			marker.style.backgroundImage = "url('https://ts-condor-assets.s3.amazonaws.com/images/icons/yah-icon.png')";
			marker.style.width = '25px';
			marker.style.height = '41px';
	
			new mapboxgl.Marker(marker).setLngLat([longitude, latitude]).addTo(newMap).setOffset([0, -20]);
	
			this.setState({
				map: newMap,
			});

			if (!hide_map_buttons) {
				const locationControl = new LocationControl({
					originalLat: latitude,
					originalLng: longitude,
					originalZoom: zoom,
					class: 'trafficMapSelector',
				});
				newMap.addControl(locationControl, 'bottom-left');
			}
	
			const attributionControl = new mapboxgl.AttributionControl();
			newMap.addControl(attributionControl, 'top-left');

			newMap.addLayer({
				id: "traffic",
				type: "line",
				source: {
					type: "vector",
					url:  "mapbox://mapbox.mapbox-traffic-v1"
				},
				"source-layer":"traffic",
				paint: {
					"line-color": [
						"match",
						["get", "congestion"],
						["low"],
						traffic_line_color_low,
						["moderate"],
						traffic_line_color_moderate,
						["heavy"],
						traffic_line_color_heavy,
						["severe"],
						traffic_line_color_severe,
						"black",
					],
					"line-width": traffic_line_width,
				},
			}, traffic_line_before_layer);
	
			document.querySelectorAll('.mapboxgl-control-container a').forEach((anchor) => {
				anchor.addEventListener("click", (event) => {
					event.preventDefault();
				});
			});

			newMap.resize();
		});
	
		newMap.on('error', (error) => {
			const mapTimeout = 15000;
			console.error(`Error loading map. Will retry in ${mapTimeout}ms.`, error);
			setTimeout(() => {
				this.createMap();
			}, mapTimeout);
		});
	
		newMap.on('move', () => {
			this.resetTimer();
		});
	}

	resetMap() {
		const {
			map,
		} = this.state;

        const {
			zoom,
        } = this.props;

		const {
            latitude,
            longitude,
		} = this;

		map.flyTo({
			center: [longitude, latitude],
			zoom,
			duration: 900,
		});
	}

	resetTimer() {
		const {
			idleTimeoutSeconds,
		} = this.state;

		clearTimeout(this.timer);

		this.timer = setTimeout(() => {
			this.resetMap();
		}, idleTimeoutSeconds * 1000);
	}

	render() {
		const {
			custom_classes,
			modifier_classes,
			keyNum,
		} = this.props;

		const {
			css,
		} = this.state;

		// Custom classes
		let customClasses = '';
		if (custom_classes && custom_classes.length > 0) {
			custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (modifier_classes && modifier_classes.length > 0) {
			modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		return (
			<div
				className={`${modifierClasses} ${customClasses} ${css.trafficMapContainer}`}
				key={`img${keyNum}`}
				style={{
					width: `100%`,
					height: `100%`,
				}}
			>
				<div
					id={this.containerId}
					style={{
						width: `100%`,
						height: `100%`,
					}}
				>
				</div>
			</div>
		);
	}
}

export default TrafficResponsiveMap;
