import React from 'react';

import {
	fetchEntertainmentContent,
} from '../util/entertainment.js';

import {
	deleteFromCache,
	preCache,
} from '../ServiceWorker.js';

const off = 0;
const ongoing = 1;
const finished = 2;

class EntertainmentProviderComponent extends React.Component {
	static providerName = 'entertainment';

	constructor(props) {
		super(props);

		this.state = {
			slides: [],
			cachedSlides: {
				operationID: null,
				progress: off,
				slides: [],
			},
		};

		this.requestSlideshowInterval = null;
		this.initUpdateThrottler = null;

		this.providerID = `PROVIDER_${props.component_name}`;
	}

	componentDidMount() {
		const {
			slideshow,
			polling_rate = 60,
		} = this.props;

		// don't initialize if we're not in a slideshow
		if (!slideshow) {
			return;
		}

		clearInterval(this.requestSlideshowInterval);
		this.initUpdate();

		this.requestSlideshowInterval = setInterval(() => {
			this.initUpdate();
		}, polling_rate * 60 * 1000);
	}

	componentDidUpdate(prevProps) {
		const {
			categories,
			polling_rate = 60,
			number_of_assets,
			order,
			resolution,
			slideshow,
		} = this.props;

		// don't initialize if we're not in a slideshow
		if (!slideshow) {
			return;
		}

		if (categories !== prevProps.categories
			|| order !== prevProps.order
			|| polling_rate !== prevProps.polling_rate
			|| number_of_assets !== prevProps.number_of_assets
			|| resolution !== prevProps.resolution) {
			clearInterval(this.requestSlideshowInterval);
			this.initUpdate();
	
			this.requestSlideshowInterval = setInterval(() => {
				this.initUpdate();
			}, polling_rate * 60 * 1000);
		}
	}

	componentWillUnmount() {
		this.deletePreviousPlaylist();
		clearInterval(this.requestSlideshowInterval);
		clearTimeout(this.initUpdateThrottler);
	}

	deletePreviousPlaylist() {
		const {
			slides,
		} = this.state;

		const slideURLs = slides.map((slide) => {
			return slide.data.src;
		});

		deleteFromCache(slideURLs);
	}

	async preCacheSlides(slides) {
		// don't pre-cache if there are no slides, reset the state
		if (slides.length === 0) {
			this.setState({
				cachedSlides: {
					operationID: null,
					progress: off,
					slides: [],
				}
			});

			return;
		}

		console.log('BROOD::ENTERTAINMENT::preCacheSlides:', slides);

		const assets = slides.map((slide) => {
			return {
				url: slide.data.src,
				type: slide.type,
			};
		});

		const operationID = preCache(assets, this.preCacheFinished.bind(this));

		this.setState({
			cachedSlides: {
				operationID,
				progress: ongoing,
				slides,
			}
		});
	}

	preCacheFinished(operationID) {
		const {
			cachedSlides,
		} = this.state;

		if (cachedSlides.operationID == operationID) {
			console.log('BROOD::ENTERTAINMENT::preCacheFinished:', cachedSlides);
			this.setState({
				cachedSlides: {
					...cachedSlides,
					progress: finished,
				}
			});
		}
	}

	initUpdate() {
		clearTimeout(this.initUpdateThrottler);
		this.initUpdateThrottler = setTimeout(async () => {
			const {
				slideshow,
			} = this.props;

			let slides = [];

			try {
				slides = await this.fetchSlides();
			} catch (err) {
				console.error('BROOD::ENTERTAINMENT::ERROR_initUpdate:', err);
			}

			const newProvider = this.makeProvider(slides);
			slideshow.updateProvider(newProvider);

			this.deletePreviousPlaylist();

			this.setState({
				slides,
			});
		}, 5000);
	}

	async fetchSlides() {
		const {
			component_name: componentUUID,
		} = this.props;

		const {
			cachedSlides,
		} = this.state;

		let slides = [];

		if (cachedSlides.progress === finished) {
			this.setState({
				cachedSlides: {
					processID: null,
					progress: off,
					slides: [],
				},
			});

			slides = cachedSlides.slides;
			console.log('BROOD::ENTERTAINMENT::CACHE_fetchSlides:', slides);
		} else {
			slides = await fetchEntertainmentContent(componentUUID);
			console.log('BROOD::ENTERTAINMENT::FETCH_fetchSlides:', slides);
		}

		if (slides.length === 0) {
			throw new Error('no slides found');
		}

		return slides || [];
	}

	makeProvider(slides = []) {
		const {
			providerID,
			number_of_assets: maxSlides,
			order: providerOrder,
			sort: sortType,
		} = this.props;

		return {
			providerID: providerID,
			options:    {
				maxSlides:     maxSlides,
				providerOrder: providerOrder,
				sortType:      sortType || 'sequential',
			},
			skipper:             this.skipper.bind(this),
			beforeLastSlide:     this.beforeLastSlide.bind(this),
			afterLastSlide:      this.afterLastSlide.bind(this),
			slides,
		};
	}

	skipper(currSlide) {
		return false;
	}

	beforeLastSlide(slides) {
	}

	afterLastSlide(slides) {
		console.log('BROOD::ENTERTAINMENT::afterLastSlide:', slides);
		const {
			component_name: componentUUID,
		} = this.props;

		const {
			cachedSlides,
		} = this.state;

		if (cachedSlides.progress === off) {
			this.setState({
				cachedSlides: {
					...cachedSlides,
					progress: ongoing,
				}
			});

			fetchEntertainmentContent(componentUUID)
				.then((slidesToPreCache) => {
					this.preCacheSlides(slidesToPreCache);
				});
		}
	}

	beforeNext(currSlide) {
	}

	afterNext(prevSlides, prevSlide, currSlide) {
	}

	render() {
		return null;
	}
}

export default EntertainmentProviderComponent;