
export const formatStringToLowerCamelCase = (str) => {
	let classValue = '';

	if (str && typeof str === 'string') {
		let text = str;
		if (str.includes(' ')) {
			text = str.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join('');
		}
		
		classValue = text.charAt(0).toLowerCase() + text.slice(1);
	}

	return classValue;
};


export const removeInvalideClassCharacters = (str) => {
	// but keep spaces, letters, numbers, underscores, and hyphens
	let classValue = '';

	if (str && typeof str === 'string') {
		classValue = str.replace(/[^\p{L}\p{N}_\-\s]/gu, '');
	}

	return classValue;
}


export const formatStringToClass = (str) => {
	let classValue = '';

	if (typeof str !== 'string') {
		console.error('Invalid input. Must be a string.');
		return classValue;
	}

	let text = str;
	if (str && typeof str === 'string' && str.startsWith('Location (apt/suite)')) {
		text = str.replace('Location (apt/suite)', 'Location');
	}

	const lowercaseStr = text.toLowerCase();
	const validName = removeInvalideClassCharacters(lowercaseStr);
	classValue = formatStringToLowerCamelCase(validName);

	return classValue;
};
