import React from 'react';

import {
	defaultSorter,
	makeNextSplitSlides
} from 'helper/Brood.js';

import {
	broncoURLENV,
	condorComponentENV
} from '../ENV';

import './Pelican.css';

class PelicanProviderComponent extends React.Component {
	static providerName = 'pelican';

	constructor(props) {
		super(props);

		this.state = {
			slides:           [],
			nonSkippedSlides: []
		};

		this.providerID = `PROVIDER_${props.component_name}`;
	}

	componentDidMount() {
		const {
			slideshow
		} = this.props;

		// don't initialize if we're not in a slideshow
		if (!slideshow) {
			return;
		}

		this.initUpdate();

		// this.updateInterval = setInterval(this.initUpdate.bind(this), 1000 * 60 * 15);
		this.refreshSlidesInterval = setInterval(() => {
			const {
				slides,
				nonSkippedSlides
			} = this.state;

			const nextSlides = this.refreshNonSkippedSlides(slides, nonSkippedSlides);

			const nextSlidesHash = [...nextSlides]
				.sort((a, b) => {
					return a.slideID < b.slideID ? -1 : 1;
				})
				.reduce((acc, slide) => {
					return acc + slide.slideID;
				}, '');

			const nonSkippedSlidesHash = [...nonSkippedSlides]
				.sort((a, b) => {
					return a.slideID < b.slideID ? -1 : 1;
				})
				.reduce((acc, slide) => {
					return acc + slide.slideID;
				}, '');

			if (nextSlidesHash !== nonSkippedSlidesHash) {
				const newProvider = this.makeProvider(nextSlides);
				slideshow.updateProvider(newProvider);

				this.setState({
					nonSkippedSlides: nextSlides
				});
			}
		}, 1000 * 5);
	}

	componentWillUnmount() {
		// clearInterval(this.updateInterval);
		clearInterval(this.refreshSlidesInterval);
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			count,
			update,
			last_update
		} = this.props;

		if (prevProps.count !== count || prevProps.last_update !== last_update) {
			this.initUpdate();
		}
	}

	async initUpdate() {
		const {
			slideshow,
			limit
		} = this.props;

		try {
			const slides = await this.fetchSlides();

			const newProvider = this.makeProvider(slides);
			slideshow.updateProvider(newProvider);
		} catch (err) {
			console.error('BROOD::PELICAN::ERROR_initUpdate:', err);
			debugger;
		}
	}

	async fetchSlides() {
		const {
			split
		} = this.state;

		const tlcUUID = condorComponentENV;
		const componentUUID = this.props.component_name;

		const body = {
			condor_component_uuid: tlcUUID,
			pelican_uuids:         [componentUUID]
		};

		let slides = [];
		try {
			const fetchURL = `${broncoURLENV}/pelican`;
			const response = await fetch(fetchURL, {
				body:    JSON.stringify(body),
				headers: {'Content-Type': 'application/json'},
				method:  'POST'
			});

			const jsonData = await response.json();
			// Object.values(jsonData).sort(() => {
			// 	return Math.random() - 0.5;
			// });

			for (const slide of Object.values(jsonData)) {
				// {
				// 	"component_type": "pelican_slide",
				// 	"component_uuids": [
				// 			"8fe79041-1aee-4b9c-9e3b-16ed6735158f"
				// 	],
				// 	"days": [],
				// 	"description": "pexels-irina-iriser-671618.jpg",
				// 	"duration": "7",
				// 	"end_date": "",
				// 	"end_time": "",
				// 	"file": "ciprod/fd8e38d3-d3a3-4d69-b323-d93db9e0519c/f8718a02aa31c14bc99c271f78da2f2c.jpeg",
				// 	"kind": "image/jpeg",
				// 	"order": 1,
				// 	"resolution": "5748px x 3663px",
				// 	"scheduling": false,
				// 	"start_date": "",
				// 	"start_time": "",
				// 	"tags": [],
				// 	"use_dates": false,
				// 	"use_days": false,
				// 	"use_times": false
				// }

				slide.data = {
					...slide,
					src: `https://s3.amazonaws.com/ts-pelican/${slide.file}`,
					alt: slide.title
				};

				if (slide.kind === 'html') {
					slide.data.html = <iframe
						src={slide.file}
						id={slide.slideID}
						allow='autoplay, geolocation'
						sandbox='allow-scripts allow-same-origin'
						className='pelican-iframe'
						loading='lazy'
					/>;
				}

				slide.allowAudio = !!this.props.allow_audio;

				slide.options = {
					duration: (Number(slide.duration) * 1000)
				};

				slide.slideID = slide.file;
				slide.type = slide.kind.split('/')[0];

				slide.provider = this;
				slides.push(slide);
			}
		} catch (err) {
			console.error('BROOD::PELICAN::ERROR_fetchSlides:', err);
			debugger;
		}
		slides.sort(defaultSorter);

		const nonSkippedSlides = this.makeNonSkippedSlides(slides);

		this.setState({
			slides:           slides,
			nonSkippedSlides: nonSkippedSlides
		});

		return nonSkippedSlides;
	}

	makeNonSkippedSlides(slides) {
		return slides.filter((slide) => {
			return !slide.skip && !slide.provider.skipper(slide);
		});
	}

	refreshNonSkippedSlides(allSlides, nonSkippedSlides) {
		let nextNonSkippedSlides = this.makeNonSkippedSlides(allSlides);

		const nonSkippedSlideIDs = nonSkippedSlides.map((slide) => {
			return slide.slideID;
		}).sort();
		const nextNonSkippedSlideIDs = nextNonSkippedSlides.map((slide) => {
			return slide.slideID;
		}).sort();

		if (nonSkippedSlideIDs.length === nextNonSkippedSlideIDs.length) {
			let allMatch = true;
			for (let i = 0; i < nonSkippedSlideIDs.length; i++) {
				if (nonSkippedSlideIDs[i] !== nextNonSkippedSlideIDs[i]) {
					allMatch = false;
					break;
				}
			}

			if (allMatch) {
				return nonSkippedSlides;
			}
		} else if (nonSkippedSlides.length > 0 && nextNonSkippedSlides.length > 0) {
			const startIndex = nextNonSkippedSlides.findIndex((slide) => {
				return slide.slideID === nonSkippedSlides[0].slideID;
			});

			if (startIndex === -1) {
				return nonSkippedSlides;
			}

			while (startIndex !== 0 && nextNonSkippedSlides[0].slideID !== nonSkippedSlides[0].slideID) {
				nextNonSkippedSlides.push(nextNonSkippedSlides.shift());
			}
		}

		return nextNonSkippedSlides;
	}


	makeProvider(slides = []) {
		const {
			providerID,
			limit: maxSlides,
			order: providerOrder,
			sort: sortType,
			transition_in: transitionIn,
			transition_out: transitionOut,
			isTouch
			// count, ???
		} = this.props;

		// Allow autoplay in chrome
		// let isMuted = ~window.location.href.indexOf('muted=1');

		return {
			providerID: providerID,
			options:    {
				maxSlides:     maxSlides,
				providerOrder: providerOrder,
				sortType:      sortType || 'sequential'
			},
			skipper:             this.skipper.bind(this),
			beforeLastSlide:     this.beforeLastSlide.bind(this),
			afterLastSlide:      this.afterLastSlide.bind(this),
			slides,
		};

		// {
		// 	"component_name": "8fe79041-1aee-4b9c-9e3b-16ed6735158f",
		// 	"uploaded_img_y_resolution": 1080,
		// 	"uploaded_img_x_resolution": 1920,
		// 	"condor_render_name": "Pelican",
		// 	"limit": 5,
		// 	"transition_in": "fadeIn",
		// 	"sort": "sequential",
		// 	"custom_tlc_builder_name": "",
		// 	"order": 1,
		// 	"slideshow_number": 1,
		// 	"uploaded_img_unit": "px",
		// 	"custom_classes": [],
		// 	"is_locked": false,
		// 	"modifier_classes": [],
		// 	"custom_content_sidebar_label": null,
		// 	"transition_out": "fadeOut",
		// 	"tlc_layout_order": 0,
		// 	"condor_component_group": "c",
		// 	"condor_configuration": "touch2",
		// 	"condor_theme": "dkWhiteOnBlack2",
		// 	"online_status": true,
		// 	"offline_minutes": 0,
		// 	"theme_variation": "variation1",
		// 	"navProps": {
		// 			"animationPhase": "idle",
		// 			"loadedCustomCSS": "",
		// 			"buttonActive": false,
		// 			"showMenu": false,
		// 			"pdfActive": false,
		// 			"iframeActive": false,
		// 			"adaActive": false,
		// 			"adaTopPad": "0em",
		// 			"timeoutStatus": false,
		// 			"hasNavigatedListings": false,
		// 			"resetListings": false,
		// 			"animationStartPhase": "idle",
		// 			"idleTimeoutSeconds": 45
		// 	},
		// 	"isTouch": true,
		// 	"last_update": null,
		// 	"count": 0,
		// 	"latitude": 39.748,
		// 	"longitude": -104.999,
		// 	"zip_code": "80202",
		// 	"monarch_location": "asdf",
		// 	"project_number": "234",
		// 	"CSSModules": {},
		// 	"condor_component_list": [],
		// 	"isMultiSlideFeed": false
		// }
	}

	formatTime(i) {
		if (i < 10) {
			i = `0${i}`;
		}

		return i;
	}

	getTime() {
		let now = new Date();
		let h = now.getHours();
		let m = now.getMinutes();
		let s = now.getSeconds();

		h = this.formatTime(h);
		m = this.formatTime(m);
		s = this.formatTime(s);

		let time = `${h}:${m}:${s}`;
		if (time.length === 7) {
			time = '0' + time;
		}

		return time;
	}

	scheduleSkipper(slide) {
		if (!slide.scheduling) {
			return false;
		}

		const now = new Date();

		if (slide.use_dates) {
			if (slide.start_date) {
				const startDate = new Date(slide.start_date);

				// skip if we are before the startDate
				if (now < startDate) {
					return true;
				}
			}

			if (slide.end_date) {
				const endDate = new Date(slide.end_date);

				// expire the slide the day after the end date
				endDate.setHours(23);
				endDate.setMinutes(59);
				endDate.setSeconds(59);

				if (now > endDate) {
					return true;
				}
			}
		}

		if (slide.use_times) {
			const now = new Date();
			const hours = String(now.getHours()).padStart(2, '0');
			const minutes = String(now.getMinutes()).padStart(2, '0');
			const seconds = String(now.getSeconds()).padStart(2, '0');
			const nowTime = `${hours}:${minutes}:${seconds}`;

			let startTime, endTime;

			if (slide.start_time) {
				startTime = slide.start_time;
				if (startTime.length === 7) {
					startTime = '0' + startTime;
				}
			}

			if (slide.end_time) {
				endTime = slide.end_time;
				if (endTime.length === 7) {
					endTime = '0' + endTime;
				}
			}

			// endTime is less than startTime
			if (startTime && endTime && endTime < startTime) {
				if (nowTime < startTime && nowTime >= endTime) {
					return true;
				}
			} else {
				if (startTime && nowTime < startTime) {
					return true;
				}

				if (endTime && nowTime > endTime) {
					return true;
				}
			}
		}

		if (slide.use_days) {
			const today = now.getDay();

			const days = slide.days;
			if (days && Array.isArray(days) && !~days.indexOf(today)) {
				return true;
			}
		}

		return false;
	}

	skipper(currSlide) {
		const {
			allow_urls,
		} = this.props;

		if (this.scheduleSkipper(currSlide)) {
			return true;
		}

		if (!allow_urls && currSlide.kind === 'html') {
			return true;
		}

		return false;
	}

	beforeLastSlide(slides) {

	}

	afterLastSlide(slides) {

	}

	beforeNext(currSlide) {

	}

	afterNext(prevSlides, prevSlide, currSlide) {
		console.log('BROOD::PELICAN::afterNext:', prevSlides, prevSlide, currSlide);

		const {
			providerID,
			limit,
			slideshow
		} = this.props;

		if (currSlide.providerID !== providerID) {
			if (limit !== 1) {
				return;
			}
		}

		const {
			// provider specific slides
			slides,
			nonSkippedSlides
		} = this.state;

		if (!limit || slides.length <= limit) {
			return;
		}

		// prevSlides comes from brood which can't tell when
		// a slide belongs to a different provider or not
		const prevProviderSlides = prevSlides.filter((slide) => {
			return slide.providerID === providerID;
		});

		const currSlideIndex = prevProviderSlides.findIndex((slide) => {
			return slide.slideID === currSlide.slideID;
		});

		if (currSlideIndex !== (limit - 1)) {
			if (limit !== 1) {
				return;
			}
		}

		let nextSlides = [...nonSkippedSlides];

		nextSlides = nextSlides.map((slide) => {
			return {...slide, skip: true};
		});

		let l = limit;
		while (l--) {
			nextSlides.push(nextSlides.shift());
		}

		for (let i = 0; i < limit; i++) {
			nextSlides[i].skip = false;
		}

		// TODO: potentially move this to a interval assuming business
		// TODO: logic allows for all slides to be skipped in a provider,
		// TODO: which would cause afterNext to never be called
		nextSlides = this.refreshNonSkippedSlides(slides, nextSlides);

		const newProvider = this.makeProvider(nextSlides);
		slideshow.updateProvider(newProvider);

		this.setState({
			nonSkippedSlides: nextSlides
		});
	}

	render() {
		return null;
	}
}

export default PelicanProviderComponent;