import React, {Component} from 'react';
import { tsLiveServerENV } from '../ENV';

const secondInMs = 1000;
const minuteInMs = 60 * secondInMs;
const expoBackoffMin = 1000;
const expoBackoffMax = minuteInMs * 15;

class News extends Component {
	constructor(props) {
		super(props);

		let reqRate = minuteInMs * 60;
		if (this.props.request_rate && typeof this.props.request_rate === "number") {
			reqRate = this.props.request_rate * secondInMs;
		}

		this.state = {
			animationState:    'fadeIn',
			containerWidth:    '',
			containerHeight:   '',
			currentItem:       0,
			css:               props.CSSModules[props.condor_render_name],
			data:              [],
			title:             'Headline News',
			nextItem:          1,
			network:           1,
			prevItem:          this.props.limit - 1,
			offlineMins:       0,
			refresh:           '',
			request_rate:      reqRate,
			request_timer:     false,
			request_backoff:   expoBackoffMin,
			stories:           [],
			stories2:          [],
			successfulRequest: false
		};

			this.startAnimationCycle = this.startAnimationCycle.bind(this);
			this.stopAnimationCycle = this.stopAnimationCycle.bind(this);
	}

	componentDidMount() {
		// delay added for CSS swap
		setTimeout(() => this.initializeAnimation(), 1500);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.request_rate !== this.props.request_rate && typeof this.props.request_rate === "number") {
			let request_rate = this.props.request_rate * secondInMs;

			this.setState({ request_rate });
		}
		if (prevProps.cycleCount !== this.props.cycleCount && this.props.animatedScroller === true && this.state.successfulRequest === true) {
			this.animateStories();
		}
	}

	componentWillUnmount() {
		console.log('News unmounted');
		this.stopAnimationCycle();
		clearTimeout(this.state.request_timer);
	}

	initializeAnimation = () => {
		this.setState({
			containerWidth:  document.getElementById(`news_${this.props.component_name}`).offsetWidth,
			containerHeight: document.getElementById(`news_${this.props.component_name}`).offsetHeight
		});
		this.getData();
	}

	getData = () => {
		const target = `${tsLiveServerENV}/news`;

		const getFileSuccess = (jsonData) => {
			if (jsonData.fail !== undefined) {
				getFileFail(new Error('Can\'t handle this format of data', jsonData));
				return;
			}

			clearInterval(this.timer);
			let timer = setTimeout(this.getData, this.state.request_rate);

			this.setState({
				data:              jsonData,
				request_backoff:   expoBackoffMin,
				request_timer:     timer,
				successfulRequest: true
			});
			this.createStories();
		}

		const getFileFail = (error) => {
			console.error(error);

			let currentBackoff = this.state.request_backoff;
			let nextBackoff = currentBackoff * 2;
			if (nextBackoff > expoBackoffMax) {
				nextBackoff = expoBackoffMax;
			}

			let timer = setTimeout(this.getData, currentBackoff);

			this.setState({
				request_backoff: nextBackoff,
				request_timer:   timer
			});
		}

		try {
			fetch(target)
				.then((response) => {
					return response.json();
				})
				.then((jsonData) => {
					getFileSuccess(jsonData);
				})
				.catch((err) => {
					getFileFail(err);
				});
		} catch(err) {
			getFileFail(err);
		}
	}

	createStories = () => {
		let css = this.state.css;
		let titleClass = css.titleOff;
		if (this.state.title !== '') {
			titleClass = css.titleOn;
		}

		const newsItemStyles = {
			width: `${this.state.containerWidth}px`,
		};

		let obj = this.state.data;
		let keys = Object.keys(obj);

		for (let i = 0, x = keys.length; i < x; i++) {
			let key = keys[i];
			let value = obj[key];
			let stories = value.map((item, i) => {
				let idValue = null;
				if (i === 0) {
					idValue = "firstStory";
				} else if (i === this.props.limit - 1) {
					idValue = "lastStory";
				}
				return (
					<div id={idValue} data-selector="news-item" key={`${this.props.id}_${this.props.component_name}` + i} className={`news_item ${css.newsItem} ${titleClass}`} style={newsItemStyles}>
						<div className={`${css.headline} newsHeadline`}>
							{item.title}
						</div>
						<div className={`${css.description} newsDescription`}>
							<p>{item.description}</p>
						</div>
					</div>
				);
			});
			this.setState({stories: stories});
		}
		this.startAnimationCycle();
	}

	setAnimationType = () => {
		let animate;
		switch (this.props.animation_type) {
			case 1:
				animate = this.cycleStories;
				break;
			case 2:
				animate = this.fadeStories;
				break;
			case 3:
				animate = this.rollStoriesUp;
				break;
			case 4:
				animate = this.slideStoriesLeft;
				break;
			default:
				animate = this.logError;
				break;
		}
		return animate;
	}

	startAnimationCycle = () => {
		// runs if not a child of animated scroller 
		if (this.props.animation_type && (!this.props.animatedScroller || this.props.animatedScrollerTimer === 0)) {
			let animate = this.setAnimationType();

			if (this.props.animation_type === 2) {
				this.setState({animationState: 'fadeIn'});
			}

			this.timer = setInterval(() => {
				animate();
			}, this.props.animation_interval * 1000);
		}
	}

	// clear scroll timer
	stopAnimationCycle = () => {
		clearInterval(this.timer);
	}

	fadeStories = () => {
		if (this.state.network === 1 || (this.state.network === 0 && this.state.offlineMins <= (1440))) {
			this.setState({animationState: 'fadeOut'});
			setTimeout(() => {
				this.cycleStories();
				this.setState({animationState: 'fadeIn'});
			}, 500);
		}
	}

	slideStoriesLeft = () => {
		// console.log('News, slide left');
		if (!document.getElementById(`container_${this.props.component_name}`)) {
			return;
		}
		if (this.state.network === 1 || (this.state.network === 0 && this.state.offlineMins <= (1440))) {
			document.getElementById(`container_${this.props.component_name}`).scrollBy({
				left: `${this.state.containerWidth}`,
				behavior: 'smooth'
			});
			this.swapStories();
		}
	}

	rollStoriesUp = () => {
		if (!document.getElementById(`container_${this.props.component_name}`)) {
			return;
		}
		if (this.state.network === 1 || (this.state.network === 0 && this.state.offlineMins <= (1440))) {
			document.getElementById(`container_${this.props.component_name}`).scrollBy({
				top: `${this.state.containerHeight}`,
				behavior: 'smooth'
			});
			this.swapStories();
		}
	}

	logError = () => {
		console.error("News animation_type has a bad value");
	}

	animateStories = () => {
		if (this.state.network === 1 || (this.state.network === 0 && this.state.offlineMins <= (1440))) {
			let animatedScrollingDuration = 400;
			let animatedScrollerTimer = this.props.animatedScrollerTimer * 1000;
			let animate = this.setAnimationType();

			// scroll immediately after animated scroller is done cycling
			setTimeout(() => {
				animate();
			}, animatedScrollingDuration);

			// scroll halfway between the next animated scroller cycle
			setTimeout(() => {
				setTimeout(() => {
					animate();
				}, animatedScrollingDuration);
			}, animatedScrollerTimer / 2);
		}
	}

	cycleStories = () => {
		if (this.state.network === 1 || (this.state.network === 0 && this.state.offlineMins <= (1440))) {
			this.setState({currentItem: this.state.currentItem + 1});
			if (this.state.currentItem > this.props.limit - 1) {
				this.setState({
					prevItem: this.props.limit - 1,
					currentItem: 0,
				});
			}
			this.setState({
				prevItem: this.state.currentItem - 1,
				nextItem: this.state.currentItem + 1,
			});
			if (this.state.nextItem > this.props.limit - 1) {
				this.setState({nextItem: 0});
			}
			if (this.state.currentItem === 0) {
				this.setState({
					prevItem: this.props.limit - 1,
					nextItem: 1,
				});
			}
		}
	}

	swapStories = () => {
		if (!document.getElementById("firstStory") || !document.getElementById("lastStory")) {
			console.error("News: firstStory not found");
			return;
		}
		if (!document.getElementById("lastStory") || !document.getElementById("lastStory")) {
			console.error("News: lastStory not found");
			return;
		}
		let firstStory = document.getElementById("firstStory");
		let lastStory = document.getElementById("lastStory");

		setTimeout(() => {		
			lastStory.after(firstStory);

			lastStory.removeAttribute('id');
			firstStory.setAttribute('id', 'lastStory');

			let item = document.querySelectorAll('div[data-selector="news-item"]');

			document.getElementById(`container_${this.props.component_name}`).scrollTo(0, 0);

			if (item.length > 0) {
				item[0].setAttribute('id', 'firstStory');
			}

		}, 500);
	}

	render() {
		const css = this.state.css;

		// Custom classes
		let customClasses = '';
		if (this.props.custom_classes) {
			this.props.custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (this.props.modifier_classes) {
			this.props.modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		let title = '';
		if (this.state.title !== '') {
			title = <div className={css.title}>{this.state.title}</div>
		}

		const news = this.state.stories.slice(0, this.props.limit);

		const newsItemStyles = {
			width: `${this.state.containerWidth}px`,
		};

		// SNAP ANIMATION
		if (this.props.animation_type === 1) {
			return (
				<div id={`news_${this.props.component_name}`} className={`news_wrapper ${css.news} ${modifierClasses} ${customClasses}`}>
					{title}
					<div id={`container_${this.props.component_name}`} className={`news_container ${css.container} ${css.snapContainer}`}>
						<div className={`news_item ${css.currentItem}`} style={newsItemStyles}>
							{news[this.state.currentItem]}
						</div>
					</div>
				</div>
			)
		}

		// FADE ANIMATION
		if (this.props.animation_type === 2) {
			return (
				<div id={`news_${this.props.component_name}`} className={`news_wrapper ${css.news} ${modifierClasses} ${customClasses}`}>
					{title}
					<div id={`container_${this.props.component_name}`} className={`news_container ${css.container} ${css.fadeContainer} ${css[this.state.animationState]}`}>
						<div className={`news_item ${css.currentItem}`} style={newsItemStyles}>
							{news[this.state.currentItem]}
						</div>
					</div>
				</div>
			)
		}

		// ROLL ANIMATION
		if (this.props.animation_type === 3) {
			return (
				<div id={`news_${this.props.component_name}`} className={`news_wrapper ${css.news} ${modifierClasses} ${customClasses}`}>
					{title}
					<div id={`container_${this.props.component_name}`} className={`news_wrapper ${css.container} ${css.rollContainer}`}>
						{news}
					</div>
				</div>
			);
		}

		// SLIDE ANIMATION
		if (this.props.animation_type === 4) {
			return (
				<div id={`news_${this.props.component_name}`} className={`news_wrapper ${css.news} ${modifierClasses} ${customClasses}`}>
					{title}
					<div id={`container_${this.props.component_name}`} className={`news_container ${css.container} ${css.slideContainer}`}>
						{news}
					</div>
				</div>
			);
		}

		return (
			<div id={`news_${this.props.component_name}`} className={`news_wrapper ${css.news} ${modifierClasses} ${customClasses}`}>
				<div className={css.container}>
					{title}
					<div className={`news_container ${css.currentItem}`} style={newsItemStyles}>
						{news[this.state.currentItem]}
					</div>
				</div>
			</div>
		);

	}
}

export default News;