import React, {Component} from 'react';
import TimeFmt from '../util/TimeFmt.js';



class EventTimebar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			css: {},
			currentMin:  new Date().getMinutes(),
			currentHour: new Date().getHours()
		};
	}

	componentDidMount() {
		let updateTime = this.updateTime;
		setInterval(function() {
			updateTime();
		}, 60000);
	}

	updateTime = () => {
		this.setState({
			currentMin:  new Date().getMinutes(),
			currentHour: new Date().getHours()
		});
	}

	validateFooterProps = (props) => {
		let isInvalid = (x) => {
			return isNaN(x) || x < 0;
		};

		switch (props) {
		case isInvalid(props.raven_hour_start):
		case isInvalid(props.raven_hour_end):
		case isInvalid(props.raven_minute_start):
		case isInvalid(props.raven_minute_end):
			return false;
		default:
			return true;
		}
	}

	eventTimebar = (props) => {
		let events = [];
		if (props.events) {
			events = props.events;
		}

		// Set theme
		let css = props.css;

		// Custom classes
		let customClasses = '';
		if (this.props.custom_classes) {
			this.props.custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${css[`${element}`]}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (this.props.modifier_classes) {
			this.props.modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		let timebar = (<div className={css.bar} id={`eventTimebar_${props.component_name}`}></div>);

		if (this.validateFooterProps(this.props)) {
			let hoursEnd     = this.props.raven_hour_end,
				hoursStart   = this.props.raven_hour_start,
				minutesEnd   = this.props.raven_minute_end,
				minutesStart = this.props.raven_minute_start,
				timeFormat   = this.props.raven_time_format,
				tsBranding   = this.props.raven_ts_branding;

			let start = new Date();
			let end = new Date();

			start.setHours(hoursStart);
			end.setHours(hoursEnd);
			start.setMinutes(minutesStart);
			end.setMinutes(minutesEnd);


			let msRange = (end - start);

			if (msRange > 0) {
				let timerEvents = [];
				let timeBarSeperators = [];
				events.forEach((calEvent, i) => {
					if (!calEvent) {
						return;
					}


					let {start_time, end_time} = calEvent;
					if (!start_time || !end_time) {
						return;
					}
					let startTime = TimeFmt.fmtRawStamp(start_time);
					let endTime = TimeFmt.fmtRawStamp(end_time);


					let today = new Date();
					let startDate = startTime.getDate();
					let startMonth = startTime.getMonth();
					let startFullYear = startTime.getFullYear();

					let hoursRange = hoursEnd - hoursStart;

					let startHourDiff = startTime.getHours() - hoursStart;
					let startMinuteDiff = startTime.getMinutes() / 60;
					let startPercent = ((startHourDiff + startMinuteDiff) / hoursRange) * 100;


					let endHourDiff = endTime.getHours() - hoursStart;
					let endMinuteDiff = endTime.getMinutes() / 60;
					let endPercent = ((endHourDiff + endMinuteDiff) / hoursRange) * 100;
					let reversePercent = 100 - endPercent;

					if (today.getDate() === startDate && today.getMonth() === startMonth && today.getFullYear() === startFullYear) {
						let styles = {
							left:  `${startPercent.toPrecision(6)}%`,
							right: `${reversePercent.toPrecision(6)}%`
						};

						if (endTime.getHours() >= hoursStart && startTime.getHours() <= hoursEnd) {
							
							if (startTime.getHours() <= hoursStart) {
								styles.left = '0%';
							}
							if (endTime.getHours() >= hoursEnd) {
								styles.right = '0%';
							}
							let reactKey = `${i}-time-bar`;

							// GTM
							let diff = Math.abs(endTime - startTime);
							let minutes = Math.floor((diff / 1000) / 60);

							let nextTimerEvent = (
								<div className={`${css.booked} ravenBooked`} style={styles} eventlength={minutes} key={reactKey}></div>
							);
							timerEvents.push(nextTimerEvent);
						}
					}
				});

				// GTM
				let eventLengths = timerEvents.map((length) => {

					return length.props.eventlength;
				});
				let meetingHours = eventLengths.map(Number).reduce((a, b) => {
					return a + b;
				}, 0) / 60;

				let businessHours = hoursEnd - hoursStart;
				let roomUtilization = Math.floor(meetingHours / businessHours * 100);
				roomUtilization = Number(roomUtilization);
				roomUtilization = roomUtilization.toFixed(1);

				for (let j = hoursStart; j < hoursEnd; j++) {
					let [hour, _displayFmt] = TimeFmt.makeDisplayHoursAndFmt((j + 1), timeFormat);
					let reactKey = `${j}-timebar-segment`;
					timeBarSeperators.push(
						<div className={`${css.hourSeg} ravenHourSeg`} key={reactKey}>
							<div className={`${css.quartSeg} ravenQuartSeg`}></div>
							<div className={`${css.quartMark} ravenQuartMark`}></div>
							<div className={`${css.quartSeg} ravenQuartSeg`}></div>
							<div className={`${css.quartMark} ravenQuartMark`}></div>
							<div className={`${css.quartSeg} ravenQuartSeg`}></div>
							<div className={`${css.quartMark} ravenQuartMark`}></div>
							<div className={`${css.quartSeg} ravenQuartSeg`}></div>
							<div className={`${css.hourMark} ravenHourMark`}>
								<div className={css.hour}>{hour}</div>
							</div>
						</div>
					);
				}

				let hourStartDisplay = TimeFmt.makeDisplayHoursAndFmt(hoursStart, timeFormat);

				let timeArrow = '';
				let hoursRange = hoursEnd - hoursStart;
				let currentHourDiff = this.state.currentHour - hoursStart;
				let currentMinDiff = this.state.currentMin / 60;

				let oneHourAsPercentage = 1 / hoursRange;
				let totalHoursAsPercentage = currentHourDiff * oneHourAsPercentage;
				let minutesAsTotalPercentage = currentMinDiff * oneHourAsPercentage;

				let timeTickPercentage = (totalHoursAsPercentage + minutesAsTotalPercentage) * 100;

				let timeStyles = {
					left: `${timeTickPercentage}%`
				};

				if (timeTickPercentage >= 0 && timeTickPercentage <= 100) {
					timeArrow = (<div id="bar-time" className={css.timeMarker} style={timeStyles}></div>);
				}


				let barClass = 'bar';
				if (tsBranding === false) {
					barClass = 'barNoBranding';
				}
				timebar = (
					<div id={`eventTimebar_${props.component_name}`} className={`eventTimebar_container ${css[barClass]} ${modifierClasses} ${customClasses}`}>
						{timeArrow}
						<div className={css.hourMark}>
							<div className={css.hour}>{hourStartDisplay[0]}</div>
						</div>
						{timerEvents}
						{timeBarSeperators}
					</div>
				);

			}
		}

		return (
			timebar
		);
	}

	render() {
		let timebar = this.eventTimebar(this.props);

		return (
			timebar
		);
	}
}

export default EventTimebar;
