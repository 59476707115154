class LocationControl {
    constructor(props) {
        this._panPX = props.panPX || 256;
        this._originalLat = props.originalLat;
        this._originalLng = props.originalLng;
        this._originalZoom = props.originalZoom;
        this._centerMapCallback = props.centerMapCallback ? props.centerMapCallback : () => {};
        this._controllerClass = props.class;
    }

    initializeController() {
        const controller = document.createElement('div', '');
        controller.setAttribute('class', this._controllerClass);
        controller.innerHTML = '';
        
        const controlsLeft = document.createElement('div', '');
        controlsLeft.classList.add('controlsLeft');
        controller.appendChild(controlsLeft);

        const controlsRight = document.createElement('div', '');
        controlsRight.classList.add('controlsRight');
        controller.appendChild(controlsRight);

        const directionWest = document.createElement('button', '');
        directionWest.innerHTML = `<svg id="locArrowLeft" x="0px" y="0px" viewBox="0 0 40 40"><polygon fill="#FFFFFF" points="29.6,9.1 10.3,20.4 29.6,32 22.4,20.4"></polygon></svg>`;
        directionWest.classList.add('mapBtn');
        directionWest.classList.add('mapWest');
        directionWest.onclick = () => {
            this.move('west');
        };
        controlsLeft.appendChild(directionWest);
        
        const verticalArrowControls = document.createElement('div', '');
        verticalArrowControls.classList.add('verticalArrowControls');
        controlsLeft.appendChild(verticalArrowControls);

        const directionNorth = document.createElement('button', '');
        directionNorth.innerHTML = `<svg id="locArrowUp" x="0px" y="0px" viewBox="0 0 40 40"><polygon fill="#FFFFFF" points="31.9,29.6 20.5,10.4 9.1,29.6 20.5,22.5"></polygon></svg>`;
        directionNorth.classList.add('mapBtn');
        directionNorth.classList.add('mapNorth');
        directionNorth.onclick = () => {
            this.move('north');
        };
        verticalArrowControls.appendChild(directionNorth);
        
        const directionSouth = document.createElement('button', '');
        directionSouth.innerHTML = `<svg id="locArrowDown" x="0px" y="0px" viewBox="0 0 40 40"><polygon fill="#FFFFFF" points="9.1,11.6 20.5,30.8 31.9,11.6 20.5,18.8"></polygon></svg>`;
        directionSouth.classList.add('mapBtn');
        directionSouth.classList.add('mapSouth');

        directionSouth.onclick = () => {
            this.move('south');
        };
        verticalArrowControls.appendChild(directionSouth);
        
        const directionEast = document.createElement('button', '');
        directionEast.innerHTML = `<svg id="locArrowRight" x="0px" y="0px" viewBox="0 0 40 40"><polygon fill="#FFFFFF" points="11.6,32 30.8,20.5 11.6,9.1 18.7,20.5"></polygon></svg>`;
        directionEast.classList.add('mapBtn');
        directionEast.classList.add('mapEast');
        directionEast.onclick = () => {
            this.move('east');
        };
        controlsLeft.appendChild(directionEast);
        
        const reCenter = document.createElement('button', '');
        reCenter.innerHTML = (`
            <svg id="locCenterIcon" x="0px" y="0px" viewBox="0 0 40 40">
                <path fill="#FFFFFF" d="M20,7.6c-5.2,0-9.6,4.8-8.9,9.9c0.5,4.1,5.9,11.7,8.9,15.5c3-3.7,8.4-11.3,8.9-15.5 C29.6,12.4,25.2,7.6,20,7.6z M20,22.1c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8C25.8,19.5,23.2,22.1,20,22.1z "></path>
            </svg>
        `);
        reCenter.classList.add('mapBtn');
        reCenter.classList.add('mapZoomOut');
        reCenter.onclick = () => {
            this.center();
        };
        controlsRight.appendChild(reCenter);

        const zoomIn = document.createElement('button', '');
        zoomIn.innerHTML = (`
            <svg id="locZoomInIcon" x="0px" y="0px" viewBox="0 0 40 40">
                <path fill="#FFFFFF" d="M32.7,30.8c0,0-5.1-5-5-5.1c1.6-1.9,2.6-4.4,2.6-7.1c0-6.1-5-11.1-11.1-11.1S8,12.5,8,18.7 s5,11.1,11.1,11.1c2.1,0,4-0.6,5.6-1.5c0,0,5.4,5.2,5.4,5.2c0.7,0.7,1.9,0.7,2.6,0C33.4,32.7,33.4,31.5,32.7,30.8z M11.2,18.7 c0-4.4,3.5-7.9,7.9-7.9c4.4,0,7.9,3.5,7.9,7.9c0,4.4-3.5,7.9-7.9,7.9C14.7,26.6,11.2,23,11.2,18.7z"></path>
                <rect x="14" y="17.3" fill="#FFFFFF" width="10.2" height="2.4"></rect>
                <rect x="17.9" y="13.4" fill="#FFFFFF" width="2.4" height="10.2"></rect>
            </svg>
        ` );
        zoomIn.classList.add('mapBtn');
        zoomIn.classList.add('mapZoomIn');
        zoomIn.onclick = () => {
            this.zoom('zoom-in');
        };
        controlsRight.appendChild(zoomIn);
        
        const zoomOut = document.createElement('button', '');
        zoomOut.innerHTML = (`
             <svg id="locZoomOutIcon" x="0px" y="0px" viewBox="0 0 40 40">
                <path fill="#FFFFFF" d="M32.7,30.8c0,0-5.1-5-5-5.1c1.6-1.9,2.6-4.4,2.6-7.1c0-6.1-5-11.1-11.1-11.1S8,12.5,8,18.7 s5,11.1,11.1,11.1c2.1,0,4-0.6,5.6-1.5c0,0,5.4,5.2,5.4,5.2c0.7,0.7,1.9,0.7,2.6,0C33.4,32.7,33.4,31.5,32.7,30.8z M11.2,18.7 c0-4.4,3.5-7.9,7.9-7.9c4.4,0,7.9,3.5,7.9,7.9c0,4.4-3.5,7.9-7.9,7.9C14.7,26.6,11.2,23,11.2,18.7z"></path>
                <rect x="14" y="17.3" fill="#FFFFFF" width="10.2" height="2.4"></rect>
            </svg>
        `);
        zoomOut.classList.add('mapBtn');
        zoomOut.classList.add('mapZoomOut');
        zoomOut.onclick = () => {
            this.zoom('zoom-out');
        };
        controlsRight.appendChild(zoomOut);

        this._controller = controller;
    }

    move(direction) {
        if (!this._map) {
            return;
        }

        switch(direction) {
        case 'south':
            this._map.panBy([0, +1 * this._panPX]);
            break;
        case 'north':
            this._map.panBy([0, -1 * this._panPX]);
            break;
        case 'west':
            this._map.panBy([-1 * this._panPX, 0]);
            break;
        case 'east':
            this._map.panBy([+1 * this._panPX, 0]);
            break;
        }
    }

    zoom(zoomDirection) {
        if (!this._map) {
            return;
        }

        const zoom = this._map.getZoom();
        if (zoomDirection === 'zoom-in') {
            this._map.zoomTo(zoom + 1, {
                duration: 700,
            });
        }

        if (zoomDirection === 'zoom-out') {
            this._map.zoomTo(zoom - 1, {
                duration: 700,
            });
        }
    }

    center() {
        if (!this._map) {
            return;
        }

        this._map.flyTo({
            center: [this._originalLng, this._originalLat],
            zoom: this._originalZoom,
            duration: 900,
        });

        this._centerMapCallback();
    }

    onAdd(map) {
        this.initializeController();
        this._map = map;
        
        this._container = this._controller;
        this._container.className += ' mapboxgl-ctrl';
        return this._container;
    }
     
    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

export default LocationControl;