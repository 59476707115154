import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import PdfComponent from './PdfComponent';
import VideoComponent from './VideoComponent';
import ListingRow from './ListingRow';
import ListingHeader from './ListingHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { dlClosePage, dlShowChildren } from '../DataLayer';
import Showdown from 'showdown';
import { withTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import Iframe from './Iframe';

import { getLowestSortValue, sortListingsByCols } from '../util/listings';
import { formatStringToClass } from '../util/format';
import { condorComponentENV, envENV } from '../ENV';

import {
	WayfindingComponent,
} from 'wayfinding_lib';

const converter = new Showdown.Converter();

const columnsNotToRender = ['row_key', 'row_hash', 'landmark_id', 'mall_store_url'];

class ListingsDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeButton: '',
			buttonClicked: false,
			css: props.CSSModules['ListingsDetails'],
			markerEnd: '',
			markerStyle: '',
			pathStyle: '',
			tieredDetails: [],
			tieredHeaders: [],
			touchAction: '',
			showModal: false,
			showIframe: false,
			showVideo: false,
			pdfFiles: {},
			currentPdf: '',
			currentIframe: '',
			currentVideo: '',
			listings: props.defaultList.listings,
		};
		this.scrollWindow = React.createRef();
		this.scrollUpBtn = React.createRef();
		this.scrollDownBtn = React.createRef();
		this.startScrolling = this.startScrolling.bind(this);
		this.stopScrolling = this.stopScrolling.bind(this);
		this.buttonClickTimer = null;
		this.buttonDelayTimer = null;
		this.wayfindingRef = React.createRef();
	}

	// Add shouldComponentUpdate
	shouldComponentUpdate(nextProps, nextState) {
		// Only update on specific prop/state changes
		return (
			this.props.detailsPage !== nextProps.detailsPage ||
			this.props.detailsUUID !== nextProps.detailsUUID ||
			this.props.details !== nextProps.details ||
			this.state.showModal !== nextState.showModal ||
			this.state.currentPdf !== nextState.currentPdf ||
			this.state.currentIframe !== nextState.currentIframe ||
			this.state.currentVideo !== nextState.currentVideo ||
			this.state.tieredDetails !== nextState.tieredDetails ||
			this.state.markerEnd !== nextState.markerEnd ||
			this.state.markerStyle !== nextState.markerStyle ||
			this.state.useNearbyLocation !== nextState.useNearbyLocation ||
			this.state.pathStyle !== nextState.pathStyle
		);
	}

	componentDidMount() {
		this.refilterListings();
		this.buildListingsNew(this.state.filteredListings || this.state.listings, []);

		this.resizeObserver = new ResizeObserver(() => {
			const { css } = this.state;

			if (!(this.scrollUpBtn.current && this.scrollDownBtn.current && css.disabledButton)) {
				return;
			}

			if (this.scrollWindow.current?.scrollHeight > this.scrollWindow.current?.clientHeight) {
				css.disabledButton.split(' ').forEach((className) => {
					this.scrollUpBtn.current.classList.remove(className);
					this.scrollDownBtn.current.classList.remove(className);
				});
			} else if (this.scrollWindow.current?.scrollHeight <= this.scrollWindow.current?.clientHeight) {
				css.disabledButton.split(' ').forEach((className) => {
					this.scrollUpBtn.current.classList.add(className);
					this.scrollDownBtn.current.classList.add(className);
				});
			}
		});

		this.resizeObserver.observe(this.scrollWindow.current);
	}

	componentWillUnmount = () => {
		clearTimeout(this.nextTimeout);
		clearTimeout(this.onMountTimeout);
		clearTimeout(this.animateTimeout);
		clearTimeout(this.buttonClickTimer);
		clearTimeout(this.buttonDelayTimer);
		this.props.handleDetailsUnmount();

		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
			this.resizeObserver = null;
		}
	}


	toggleModal = (value) => {
		if (this.state.buttonClicked === false) {
			this.props.navEvents('togglePdf');
			if (window.currentDetailAction === 12) {
				this.setState({
					currentPdf: value,
					showModal: !this.state.showModal,
				});
			} else {
				this.setState({
					currentPdf: value,
					showModal: !this.state.showModal
				});
			}
		}

		this.setState({
			buttonClicked: true,
		})

		clearTimeout(this.buttonClickTimer);
		this.buttonClickTimer = setTimeout(() => {
			this.setState({
				buttonClicked: false,
			})
		}, 700)
	}

	toggleVideo = (value) => {
		if (this.state.buttonClicked === false) {
			this.props.navEvents('togglePdf');
			this.setState({
				currentVideo: value,
				showVideo: !this.state.showVideo,
			});
		}

		this.setState({
			buttonClicked: true,
		})

		clearTimeout(this.buttonClickTimer);
		this.buttonClickTimer = setTimeout(() => {
			this.setState({
				buttonClicked: false,
			})
		}, 700)
	}

	closeModal = (modalType) => {
		if (this.state.buttonClicked === false) {
			if (this.props.detailsTouchAction === 12 || this.props.detailsTouchAction === 11 || this.props.detailsTouchAction === 13) {
				this.setState({
					showModal: false,
					showIframe: false,
					showVideo: false,
				});

				if (modalType === 'pdf') {
					this.props.navEvents('closePdf', 620);
				}

				if (modalType === 'iframe') {
					this.props.navEvents('toggleIframe')
				}

				if (modalType === 'video') {
					this.props.navEvents('closePdf', 620);
				}
				this.props.goBack();

			} else if (modalType === 'pdf') {
				this.props.navEvents('togglePdf');
				this.setState({
					currentPdf: '',
					showModal: !this.state.showModal
				});
			} else if (modalType === 'video') {
				this.props.navEvents('togglePdf');
				this.setState({
					currentVideo: '',
					showVideo: !this.state.showVideo
				});
			} else {
				this.props.navEvents('toggleIframe')
				this.setState({
					currentIframe: '',
					showIframe: false,
				});
			}
		}

		this.setState({
			buttonClicked: true,
		})

		clearTimeout(this.buttonClickTimer);
		this.buttonClickTimer = setTimeout(() => {
			this.setState({
				buttonClicked: false,
			})
		}, 700)
	}

	toggleIframe = (value) => {
		if (value !== undefined && value !== '') {
			this.setState({
				currentIframe: value,
				showIframe: !this.state.showIframe
			});
		} else if (value === 'closeIframe') {
			this.props.goBack();
		}

	}

	formatTime(i) {
		if (i < 10) {
			i = `0${i}`;
		}
		return i;
	}

	getTime() {
		let now = new Date();
		let h = now.getHours();
		let m = now.getMinutes();
		let s = now.getSeconds();

		h = this.formatTime(h);
		m = this.formatTime(m);
		s = this.formatTime(s);

		let time = `${h}:${m}:${s}`;
		return time;
	}

	startScrolling = (direction) => {
		let value = 0;
		if (this.props.scrollButtons === 1) {
			if (direction === 'down') {
				value = 2
			} else if (direction === 'up') {
				value = -2
			}
		} else if (this.props.scrollButtons === 2) {
			if (direction === 'down') {
				value = this.props.scrollDistance
			} else if (direction === 'up') {
				value = -(this.props.scrollDistance)
			}
		}

		if (this.props.scrollButtons === 1) {
			this.timer = setInterval(() => {
				this.scrollWindow.current.scrollTop += value;
			}, 5);
		} else if (this.props.scrollButtons === 2) {
			this.scrollWindow.current.scrollTop += value;
			this.timer = setInterval(() => {
				this.scrollWindow.current.scrollTop += value;
			}, 1000);
		}
	}

	stopScrolling = () => {
		clearInterval(this.timer);
	}

	schedulingFilter(objItem) {
		const m1Key = objItem.m1Key;

		const schedulingJSON = window.flatData?.[m1Key]?.listing_scheduling;

		if (schedulingJSON === undefined) {
			return true;
		}

		let parsedSchedulingJSON;

		try {
			parsedSchedulingJSON = JSON.parse(schedulingJSON);
		} catch (err) {
			console.error("Failed to parse schedulingJSON", err);
			return true;
		}

		if (!parsedSchedulingJSON.scheduling) {
			return true;
		}

		if (parsedSchedulingJSON.use_dates) {
			if (parsedSchedulingJSON.start_date) {
				const now = new Date().toISOString();
				const startDate = parsedSchedulingJSON.start_date;
				if (startDate && now < startDate) {
					return false;
				}
			}

			// NOTE: The end_date is supposed to represent the final day a slide will show. The date a slide expires is the next day.
			if (parsedSchedulingJSON.end_date) {
				const now = new Date().toISOString();
				const endDate = new Date(parsedSchedulingJSON.end_date);
				let dateToExpire = endDate.setDate(endDate.getDate() + 1);
				dateToExpire = new Date(dateToExpire).toISOString();
				if (dateToExpire && now > dateToExpire) {
					return false;
				}
			}
		}

		if (parsedSchedulingJSON.use_times) {
			if (parsedSchedulingJSON.start_time) {
				let now = this.getTime();
				if (now.length === 7) {
					now = '0' + now;
				}

				let startTime = parsedSchedulingJSON.start_time;
				if (startTime.length === 7) {
					startTime = '0' + startTime;
				}
				if (now < startTime) {
					return false;
				}
			}

			if (parsedSchedulingJSON.end_time) {
				let now = this.getTime();
				if (now.length === 7) {
					now = '0' + now;
				}

				let endTime = parsedSchedulingJSON.end_time;
				if (endTime.length === 7) {
					endTime = '0' + endTime;
				}

				if (now > endTime) {
					return false;
				}
			}
		}

		if (parsedSchedulingJSON.use_days) {
			const today = new Date().getDay();
			const days = parsedSchedulingJSON.days;
			if (days && Array.isArray(days) && !~days.indexOf(today)) {
				return false;
			}
		}

		return true;
	}

	scheduleRefilterListings() {
		const oneMinute = 1 * 60 * 1000;
		const thirtySeconds = 30 * 1000;
		const now = Date.now();
		const currentWindow = now - (now % oneMinute);
		const nextWindow = currentWindow + oneMinute + thirtySeconds;
		const timeUntilNextWindow = nextWindow - now;

		if (this.nextTimeout) {
			clearTimeout(this.nextTimeout);
		}

		this.nextTimeout = setTimeout(() => {
			this.refilterListings();
		}, timeUntilNextWindow);
	}

	refilterListings() {
		const {
			listings,
		} = this.state;

		const newFilteredListings = listings.map((listing) => {
			return {
				...listing,
				listings: listing.listings.filter(this.schedulingFilter.bind(this)),
			};
		});

		this.setState({ filteredListings: newFilteredListings });

		this.scheduleRefilterListings();
	}

	buildListingsNew = (listingsArray, rowsArray = []) => {
		let rowHeaders = [];
		let tieredHeaders = [];
		let css = this.state.css;
		let numberOfResults = 0
		let touchAction = ''
		// For translation
		const { t } = this.props;

		listingsArray.forEach((listing, j) => {
			let boxStyleDisplay;
			if (listing.m1Key === this.props.detailsUUID) {
				let headerWidths = [];
				let textAlign = [];

				if (listing.listings.length > 0) {
					listing.listings[0].colGroups.forEach(colGroup => {
						headerWidths.push(colGroup.width);
						textAlign.push(this.buildTextAlign(colGroup.textAlign))
					});
				}

				touchAction = listing.tierConfig.touchAction;

				if (listing.listings.length > 0) {
					let sortedCols = [...listing.listings[0].colGroups].filter((c) => {
						if (c.sort > 0) {
							return true;
						}

						return false;
					});

					sortedCols.sort((i, j) => {
						if (i.sort > j.sort) {
							return 1;
						}

						return -1;
					});

					listing.listings = listing.listings.map((listing) => {
						listing.sortedVals = [listing.rank || 0];

						for (let col of sortedCols) {
							let listingVals = listing.vals[col.order - 1];
							if (listingVals) {
								listing.sortedVals.push(listingVals);
							}
						}

						return listing;
					});

					listing.listings.sort(sortListingsByCols);
				}

				listing.listings.forEach((row, i) => {
					if (numberOfResults < 500) {
						numberOfResults++

						let currentHeaderWidths = row.colGroups.map(colGroup => {
							return colGroup.width
						});
						boxStyleDisplay = row.tierConfig.boxStyleDisplay
						let listingsWithType = []
						let headersArray = []
						row.colGroups.forEach((col) => {
							if (col.headerAsLabel) {
								headersArray.push(col.name)
							} else {
								headersArray.push('');
							}
						})
						row.vals.forEach((listingData, i) => {
								listingsWithType.push({
									value: listingData,
									type: listing.listings[0].types[i],
									displayName: listing.listings[0].displayNames[i],
									column_uuid: listing.listings[0].colGroups[i]?.m1Key
								});
						})
						// add tierConfig to listing detail
						listingsWithType.push(row.tierConfig);

						const [lowestSortValue, sortByIndex] = getLowestSortValue(row.colGroups);

						if (row.colGroups.length !== 0) {
							window.currentDetailAction = row.tierConfig.touchAction;

							const { defaultList, ...propsWithoutDefaultList } = this.props;

							rowsArray.push(
								<ListingRow
									{...propsWithoutDefaultList}
									key={`details_row_${i}`}
									boxColumnsPerRow={row.tierConfig.boxColumnsPerRow + 1}
									boxActiveLayout={row.tierConfig.boxActiveLayout + 1}
									boxStyleDisplay={row.tierConfig.boxStyleDisplay}
									headerData={headersArray}
									data={listingsWithType}
									detailsGrp={row.detailsGrp}
									css={currentHeaderWidths}
									currentDetailAction={row.tierConfig.touchAction}
									rowUUID={row.m1Key}
									addToHistory={this.addToHistory}
									detailsTitle={this.props.details[0]}
									setDetailsTitle={this.props.setDetailsTitle}
									setDetailsData={this.props.setDetailsData}
									sortByIndex={sortByIndex}
									textAlign={textAlign}
									displayChildTier={this.props.displayChildTier}
									messageLabels={this.props.messageLabels}
									num={i}
								/>
							)
						}
					}
				});

				if (listing.listings.length > 0) {
					listing.listings[0].colGroups.forEach(colGroup => {
						rowHeaders.push(colGroup.name)
					});
				}
				if (!boxStyleDisplay) {
					tieredHeaders.push(
						<div
							className={`${css.header} listingsDetailsHeader`}
							key={`tieredHeader_${j}`}
							data-ts-id={`listingsDetailsHeader.${this.props.detailsUuid}`}
						>
							<ListingHeader
								{...this.props}
								data-key={`tieredHeader`}
								data={rowHeaders}
								css={headerWidths}
								textAlign={textAlign}
							/>
						</div>
					)
				}

				if (numberOfResults >= 500) {
					tieredHeaders.unshift(
						<p
							className={`${css.overLimit} listingsDetailsTooManyResultsError`}
							data-ts-id={`listingsDetailsTooManyResultsError.${this.props.component_name}`}
						>
							{t("listingsTooManyResultsError")}
						</p>
					)
				}
			} else if (listing.listings && listing.listings.length > 0) {
				this.buildListingsNew(listing.listings, rowsArray);
			}

		});


		const uniqueRows = [...new Map(rowsArray.map((m) => [m.props.rowUUID, m])).values()];
		// console.log(uniqueRows, uniqueRows,rowsArray, rowsArray )
		if (touchAction === 10) {
			this.handleShowChildren(uniqueRows, tieredHeaders);
		}

		this.setState({
			tieredDetails: uniqueRows,
			tieredHeaders: tieredHeaders,
			touchAction: touchAction
		})
		this.animateTimeout = setTimeout(() => {
			this.setState({
				pathStyle: 'animatePath'
			})
		}, 1500);
		this.props.setDetailsHasRendered(true);
	}

	handleDown = (buttonId) => {
		this.setState({ activeButton: buttonId })
	}

	handleUp = () => {
		this.setState({ activeButton: '' })
	}

	buildTextAlign = (textAlign) => {
		switch (textAlign) {
			case 0:
				return 'left'
			case 1:
				return 'center'
			case 2:
				return 'right'
			default:
				return 'center'
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.navProps.resetListings !== this.props.navProps.resetListings && this.props.navProps.resetListings === true) {
			this.handleClosePage();
			this.props.navEvents('setHasNavigatedListings', false);
		}

		if (prevProps.detailsUUID !== this.props.detailsUUID) {
			this.setState({
				markerEnd: '',
				markerStyle: '',
				pathStyle: ''
			});
			this.buildListingsNew(this.state.filteredListings || this.state.listings);
		}

		if (prevProps.showDetailsPage !== this.props.showDetailsPage && this.props.showDetailsPage === false) {
			this.setState({
				markerEnd: '',
				markerStyle: '',
				pathStyle: ''
			});
		}

		if (JSON.stringify(prevProps.defaultList.listings) !== JSON.stringify(this.props.defaultList.listings)) {
			this.setState({
				listings: this.props.defaultList.listings,
			});
		}

		if (this.state.currentIframe !== prevState.currentIframe && this.state.currentIframe !== '') {
			this.props.navEvents('toggleIframe');
		}
	}

	addToHistory = () => {
		this.props.setHistory({
			detailsTitle: this.props.detailsTitle,
			tieredDetails: this.props.tieredDetails,
			tieredHeaders: this.props.tieredHeaders,
			type: 'listing',
		});
	}

	// Optimize close handler
	handleClosePage = () => {
		// Batch state updates
		const newState = {
			markerEnd: '',
			markerStyle: '',
			pathStyle: '',
			showModal: false,
			showIframe: false,
			showVideo: false,
			currentPdf: '',
			currentIframe: '',
			currentVideo: ''
		};

		this.setState(newState, () => {
			this.props.navEvents('idleTimer', '||| ListingsDetails.js > handleClosePage()');
			this.props.setHistory([]);
			this.props.setTieredHeadersAndDetails([], []);

			requestAnimationFrame(() => {
				this.props.setDetailsUUID('');
				this.props.setDetailsAction('');
				this.props.setDetailsHasRendered(false);
				this.props.setDetailsGrp([]);
				this.props.closeDetailsPage();
				this.props.setDetailsTitle('');
			});
		});

		dlClosePage();
	};

	triggerCloseButton = () => {
		console.log(`ListingDetails.js > triggerCloseButton()`);
		this.props.navEvents('idleTimer', '||| ListingsDetails.js > listingDetails()');
		this.props.goBack();
	}

	handleShowChildren = (tieredDetails, tieredHeaders) => {
		if (this.props.searchValue) {
			this.props.temporarilyDisableButton();
		} else {
			this.props.disableBackButton();
		}
		let sortByIndex = tieredDetails[0].props.sortByIndex
		let sortedTieredDetails = tieredDetails.sort(function (a, b) {
			const labelA = a.props.data[sortByIndex].value.toLowerCase();
			const labelB = b.props.data[sortByIndex].value.toLowerCase();
			if (labelA > labelB) {
				return 1;
			}
			if (labelA < labelB) {
				return -1;
			}
			return 0;
		});

		this.props.navEvents('idleTimer', '||| ListingsDetails.js > handleShowChildren()');
		this.props.setTieredHeadersAndDetails(tieredHeaders, sortedTieredDetails);
		this.props.setDetailsTitle(this.props.details[0].value);
		this.props.closeDetailsPage();
		this.props.setHistory({
			details: this.props.details,
			detailsUUID: this.props.detailsUUID,
			detailsGrp: this.props.detailsGrp,
			detailsTouchAction: this.props.detailsTouchAction,
			type: 'detail',
		});


		dlShowChildren({
			detailsTitle: this.props.details[0]?.value,
		});
	}

	getClass = (value) => {
		let classValue = '';

		switch (value) {
			case 'Location (apt/suite)':
				classValue = 'location';
				break;
			case 'Primary Phone':
				classValue = 'primaryPhone';
				break;
			case 'Text Small':
				classValue = 'textSmall';
				break;
			case 'Text Medium':
				classValue = 'textMedium';
				break;
			case 'Text Large':
				classValue = 'textLarge';
				break;
			default:
				classValue = value.toLowerCase();
		}
		return classValue;

	}

	buildDetailsSection = (array, section, dataType) => {
		let css = this.state.css;

		// details data replaced when nearby locations clicked
		let detailsData = this.state.nearbyDetailsGrp ? this.state.nearbyDetailsGrp : this.props.detailsGrp;

		let detailsArray = [];
		let hasDetailsNav = false;
		let errorStyle = {
			background: 'red',
			width: '50%'
		}

		array.forEach((label) => {
			let imageAlreadyExists = false; //to prevent more than one image in Details Page B

			detailsData.forEach((obj, i) => {
				// ignoring row_key, row_hash and landmark_id that are autogen columns for integrations
				if (columnsNotToRender.includes(obj.label)) {
					return;
				}

				if (label === obj.type) {
					let value = obj.value;
					let uniqueClass = formatStringToClass(`${obj.type} ${obj.label}`);

					let isJSON = false

					try {
						JSON.parse(value);  
						isJSON = true;        
					} catch (e) {
						isJSON = false;       
					}

					if (isJSON) {
						let jsonData = null;
						try {
						  jsonData = JSON.parse(value);
						} catch (err) {
						  console.error("Failed parsing JSON value", err);
						}
						
						if (jsonData && jsonData.cc) {
						  try {
							value = `${jsonData.area}-${jsonData.n}`;
						  } catch (err) {
							console.error("Error processing phone data", err);
						  }
						}
					  }

					if (obj.type === 'URL' && obj.value !== '') {
						hasDetailsNav = true;
						detailsArray.push(
							<div
								className={`${css.childDetailsBtn} childDetailsBtn childURLBtn ${uniqueClass} ${this.state.activeButton === 'urlButton' ? css.activeButton : ''}`}
								data-ts-id={`listingsDetailsUrlBtn.${this.props.detailsUUID}`}
								data-ts-index={i}
								onMouseDown={() => {
									this.handleDown('urlButton');
									this.props.navEvents(`btnDebouncer`, () => this.toggleIframe(value));
								}}
								onMouseUp={this.handleUp}
								onMouseLeave={this.handleUp}
								onTouchStart={() => {
									this.handleDown('urlButton');
									this.props.navEvents(`btnDebouncer`, () => this.toggleIframe(value));
								}}
								onTouchEnd={this.handleUp}
								onPointerDown={() => {
									this.handleDown('urlButton');
									this.props.navEvents(`btnDebouncer`, () => this.toggleIframe(value));
								}}
								onPointerUp={this.handleUp}
								onPointerLeave={this.handleUp}
							>
								{obj.label}
							</div>
						)
					} else if (obj.type === 'PDF' && obj.value !== '') {
						if (this.props.detailsTouchAction !== 12) {
							hasDetailsNav = true;
							detailsArray.push(
								<div
									className={`${css.childDetailsBtn} childDetailsBtn childPDFBtn ${uniqueClass} ${this.state.activeButton === 'pdfButton' ? css.activeButton : ''}`}
									data-ts-id={`listingsDetailsPdfBtn.${this.props.detailsUUID}`}
									data-ts-index={i}
									key={`${obj.label}_${i}`}
									onMouseDown={() => {
										this.handleDown('pdfButton');
										this.props.navEvents(`btnDebouncer`, () => this.toggleModal(obj.value));
									}}
									onMouseUp={this.handleUp}
									onMouseLeave={this.handleUp}
									onTouchStart={() => {
										this.handleDown('pdfButton');
										this.props.navEvents(`btnDebouncer`, () => this.toggleModal(obj.value));
									}}
									onTouchEnd={this.handleUp}
									onPointerDown={() => {
										this.handleDown('pdfButton');
										this.props.navEvents(`btnDebouncer`, () => this.toggleModal(obj.value));
									}}
									onPointerUp={this.handleUp}
									onPointerLeave={this.handleUp}
								>
									{obj.label}
								</div>
							)
						}
					} else if (obj.type === 'Video' && obj.value !== '') {
						if (this.props.detailsTouchAction !== 12) {
							hasDetailsNav = true;
							detailsArray.push(
								<div
									className={`${css.childDetailsBtn} childDetailsBtn childVideoBtn ${uniqueClass} ${this.state.activeButton === 'videoButton' ? css.activeButton : ''}`}
									data-ts-id={`listingsDetailsVideoBtn.${this.props.detailsUUID}`}
									data-ts-index={i}
									key={`${obj.label}_${i}`}
									onMouseDown={() => {
										this.handleDown('videoButton');
										this.props.navEvents(`btnDebouncer`, () => this.toggleVideo(obj.value));
									}}
									onMouseUp={this.handleUp}
									onMouseLeave={this.handleUp}
									onTouchStart={() => {
										this.handleDown('videoButton');
										this.props.navEvents(`btnDebouncer`, () => this.toggleVideo(obj.value));
									}}
									onTouchEnd={this.handleUp}
									onPointerDown={() => {
										this.handleDown('videoButton');
										this.props.navEvents(`btnDebouncer`, () => this.toggleVideo(obj.value));
									}}
									onPointerUp={this.handleUp}
									onPointerLeave={this.handleUp}
								>
									{obj.label}
								</div>
							)
						}
					} else if (obj.type === 'QR Code') {
						detailsArray.push(
							<div
								className={`${css.detailsQrCodeCont} detailsQrCodeCont ${uniqueClass}`}
								data-ts-id={`listingsDetailsQrCodeCont.${this.props.detailsUUID}`}
								data-ts-index={i}
							>
								<QRCode
									bgColor={"#fff"} // Color of the bright squares
									data-ts-id={`listingsDetailsQrCode.${this.props.detailsUUID}`}
									data-ts-index={i}
									fgColor={"#000"} // Color of the dark squares
									level="L" // QR Error correction level: L, M, Q, H
									style={{ width: '100%', height: '100%', border: `10px solid #fff` }} // Size in pixels
									value={value} // The url
								/>
								<div
									className={`${css.qrCodeLabel} qrCodeLabel`}
									data-ts-id={`listingsDetailsQrCodeLabel.${this.props.detailsUUID}`}
									data-ts-index={i}>
								</div>
							</div>
						)
					} else if (obj.type === 'Logo' || obj.type === 'Image' || obj.type === 'Photo') {
						if (!imageAlreadyExists) {
							detailsArray.push(
								<img
									src={value}
									className={`${css.detailsData} detailsData ${`imgDetailsData_${i}`} ${uniqueClass} ${css[this.getClass(obj.type)]} ${this.getClass(obj.type)}`}
									data-ts-id={`listingsDetails${obj.type}.${this.props.detailsUUID}`}
									data-ts-index={i}
									key={`${obj.label}_${i}`}
									onError={({ currentTarget }) => {
										currentTarget.style = { errorStyle }; // why doesn't this work?
									}}
								/>
							)
							if (this.props.detailsTouchAction === 5) {
								imageAlreadyExists = true;
							}
						}
					} else if (obj.type === 'Text Large') {
						const markdown = converter.makeHtml(value);
						detailsArray.push(
							<>
								<div
									className={`${css.editorOutput} editorOutput listingDetailsTextLarge ${uniqueClass}`}
									dangerouslySetInnerHTML={{ __html: markdown }}
									data-ts-id={`listingsDetailsTextLarge.${this.props.detailsUUID}`}
									data-ts-index={i}
									style={{
										pointerEvents: 'none',
									}}
								/>
							</>
						)
					} else if (obj.value) {
						if (obj.label === "FULLDATETIME" || obj.label === "FULLDATETIMEUNIX") {
							return;
						}
						detailsArray.push(
							<div
								className={`${css.detailsData} detailsData detailsData_${i} ${uniqueClass} ${css[this.getClass(obj.type)]} ${this.getClass(obj.type)}`}
								data-ts-id={`listingsDetailsData.${this.props.detailsUUID}`}
								data-ts-index={i}
								key={`${obj.label}_${i}`}
							>
								{value}
							</div>
						)
					}
				}
			})
		})

		if (hasDetailsNav) {
			return (
				<>
					{detailsArray}
				</>
			)
		}

		if (detailsArray.length > 0) {
			return (
				<div
					className={`${css.detailsSection} ${css[`detailsSection_${section}`]} detailsSection_${section} ${css[`detailsData${dataType}`]}`}
					data-ts-id={`listingsDetailsSection.${this.props.detailsUUID}`}
					data-ts-index={section}
				>
					{detailsArray}
				</div>
			)
		}
	}

	detailsPageType = () => {
		let css = this.state.css;
		let body = '';
		let detailActionToCheck = this.props.detailsTouchAction ? this.props.detailsTouchAction : window.currentDetailAction;

		if (detailActionToCheck === 4) { // Details Page A
			body = (
				<>
					<div className={`${css.detailsLeft} detailsLeft`} data-ts-id={`listingsDetailsLeftA.${this.props.detailsUUID}`}>
						<div className={`${css.detailsTopLeft} detailsTopLeft`} data-ts-id={`listingsDetailsTopLeftA.${this.props.detailsUUID}`}>
							{this.buildDetailsSection(["Name", "Title"], 0, "Name")}
						</div>
						<div className={`${css.detailsBottomLeft} detailsBottomLeft`} data-ts-id={`listingsDetailsBottomLeftA.${this.props.detailsUUID}`}>
							{this.buildDetailsSection(["Address"], 1)}
							{this.buildDetailsSection(["Building", "Floor", "Location (apt/suite)"], 1)}
							{this.buildDetailsSection(["City", "State", "Zip"], 3)}
							{this.buildDetailsSection(["Primary Phone", "Phone"], 4)}
							{this.buildDetailsSection(["Text Small", "Text Medium", "Text Large", "Building Picker"], 6)}
						</div>
					</div>
					<div className={`${css.detailsRight} detailsRight`} data-ts-id={`listingsDetailsRightA.${this.props.detailsUUID}`}>
						{this.buildDetailsSection(["Logo", "Image", "Photo", "QR Code"], 7)}
					</div>
				</>
			)
		}
		if (this.props.detailsTouchAction === 5) { // Details Page B
			body = (
				<div className={`${css.detailsPageB} ${css.detailsCustom} detailsPageB`} data-ts-id={`listingsDetailsPageB.${this.props.detailsUUID}`}>
					<div className={`${css.detailsTopLeft} detailsTopLeft`} data-ts-id={`listingsDetailsTopLeftB.${this.props.detailsUUID}`}>
						{this.buildDetailsSection(["Name", "Title"], 0)}
					</div>
					<div className={`${css.detailsBottomLeft} detailsBottomLeft`} data-ts-id={`listingsDetailsBottomLeftB.${this.props.detailsUUID}`}>
						<div className={`${css.detailsPageBText} detailsPageBText`} data-ts-id={`listingsDetailsPageTextB.${this.props.detailsUUID}`}>
							{this.buildDetailsSection(["Text Large"], 1)}
						</div>
						{this.buildDetailsSection(["Image"], 2)}
					</div>
				</div>
			)
		}
		if (this.props.detailsTouchAction === 6 || this.props.detailsTouchAction === 7) { // Details Page C and Wayfinding A
			const detailsType = this.props.detailsTouchAction === 6 ? 'C' : 'WayfindingA';
			body = (
				<>
					<div className={`${css.detailsWayfinding} detailsWayfinding`} data-ts-id={`listingsDetails${detailsType}.${this.props.detailsUUID}`}>
						{this.buildDetailsSection(["Name", "Title"], 0, "Name")}
						{this.buildDetailsSection(["Address"], 1, "Address")}
						{this.buildDetailsSection(["Building"], 2, "Building")}
						{this.buildDetailsSection(["Floor"], 3, "Floor")}
						{this.buildDetailsSection(["Location (apt/suite)"], 4, "Location")}
						{this.buildDetailsSection(["City", "State", "Zip"], 5, "Region")}
						{this.buildDetailsSection(["Primary Phone", "Phone"], 6, "Phone")}
						{this.buildDetailsSection(["Text Small", "Text Medium", "Text Large", "Building Picker"], 8, "Text")}
					</div>
					<div className={`${css.detailsWayfindingRight} detailsWayfindingRight`} data-ts-id={`listingsDetailsRight${detailsType}.${this.props.detailsUUID}`}>
						{this.buildDetailsSection(["Logo", "Image", "Photo", "QR Code"], 9, "Graphics")}
					</div>
				</>
			)
		}
		if (this.props.detailsTouchAction === 10) { // Details Tier+1
			body = (
				<></>
			)
		}
		if (this.props.detailsTouchAction === 11) { // iFrame URL
			let iframeActiveLabel;
			if (this.props.details && this.props.details.length > 0) {
				iframeActiveLabel = this.props.details[this.props.details.length - 1]?.iframeUrlLabel;
			}

			const urlItem = this.props.detailsGrp.find((obj) => {
				if (obj.label === "None") {
					return;
				}
				const matchedUrl = iframeActiveLabel === obj.label;
				return obj.type === "URL" && matchedUrl;
			});

			if (this.state.currentIframe === '' && urlItem && urlItem.value !== '') {
				this.toggleIframe(urlItem.value);
				body = (
					<></>
				)
			} else
				if (this.state.currentIframe === '') {
					this.toggleIframe('no source');
					body = (
						<></>
					)
				}
		}

		if (this.props.detailsTouchAction === 12) { // PDF Touch Action
			const fd = window.flatData
			let pdfValues = [];

			for (let [m1Key, m1Data] of Object.entries(fd)) {
				if (m1Key.startsWith('listing_group.')) {
					for (let [m2Key, m2Data] of Object.entries(m1Data)) {
						if (m2Key.startsWith('listing_config.')) {
							if (m2Data.pdf_url_label) {
								pdfValues.push(m2Data.pdf_url_label);
							}
						}
					}
				}
			}

			const pdfItem = this.props.detailsGrp.find((obj) => {
				if (obj.label === "None") {
					return;
				}
				const matchedPDF = pdfValues.find(pdfValue => pdfValue === obj.label);
				return obj.type === "PDF" && matchedPDF;
			});

			if (!this.state.currentPdf && pdfItem && !this.state.showModal) {
				this.setState({
					pdfItem: undefined,
				});
				this.toggleModal(pdfItem.value)
				body = (
					<></>
				)
			} else
				if (!pdfItem || pdfItem.value === '') {
					body = (
						<div className={`${css.noPdf} listingsDetailsNoPdf`} data-ts-id={`listingsDetailsNoPdf.${this.props.detailsUUID}`}>
							This Listing has no associated PDF.
						</div>
					)
				}
		}

		if (this.props.detailsTouchAction === 13) {
			const fd = window.flatData
			let videoValues = [];

			for (let [m1Key, m1Data] of Object.entries(fd)) {
				if (m1Key.startsWith('listing_group.')) {
					for (let [m2Key, m2Data] of Object.entries(m1Data)) {
						if (m2Key.startsWith('listing_config.')) {

							if (m2Data.video_url_label) {
								videoValues.push(m2Data.video_url_label);
							}
						}
					}
				}
			}

			const videoItem = this.props.detailsGrp.find((obj) => {
				if (obj.label === "None") {
					return;
				}
				const matchedvideo = videoValues.find(videoValue => videoValue === obj.label);
				return obj.type === "Video" && matchedvideo;
			});

			if (!this.state.currentVideo && videoItem?.value && !this.state.showModal) {
				this.setState({
					videoItem: undefined,
				});
				this.toggleVideo(videoItem.value)
				body = (
					<></>
				)
			} else
				if (!videoItem || videoItem.value === '') {
					body = (
						<div className={`${css.noVideo} listingsDetailsNoVideo`} data-ts-id={`listingsDetailsNoVideo.${this.props.detailsUUID}`}>
							This Listing has no associated Video.
						</div>
					)
				}
		}
		return body;
	}

	showMarker = () => {
		this.setState({
			markerEnd: 'url(#triangle)',
			markerStyle: 'showMarker'
		})
	}

	getNearbyLocationData = (key) => {
		const completeListings = window.completeListings;
		let listingDetails = [];

		completeListings.forEach((listingTier) => {
			listingTier.listings.forEach((listing) => {
				if (Array.isArray(listing) && listing[0] === key) {
					listingDetails = listing[listing.length - 1];
				}
			});
		});

		return listingDetails;
	}

	setNearbyLocationData = (key) => {
		const nearbyDetails = this.getNearbyLocationData(key);

		this.setState({
			nearbyDetailsGrp: nearbyDetails,
			useNearbyLocation: key
		});
	}

	getQRTextInstruction = (listingConfigUUID) => {
		const {
			tmp_data_etl,
		} = this.props
		
		let qrText = 'Use your camera or tablet to take this map with you';
		let listingGroupUUID = '';

		for (let [m1Key, m1Data] of Object.entries(window.flatData)) {
			if (m1Key.startsWith('listing_group.')) {
				for (let [m2Key] of Object.entries(m1Data)) {
					if (m2Key === listingConfigUUID) {
						listingGroupUUID = m1Key;
					}
				}
			}
		}

		for (let [m1Key, m1Data] of Object.entries(tmp_data_etl)) {
			if (m1Key.startsWith('listing_collection.')) {
				for (let [m2Key, m2Data] of Object.entries(m1Data.listing_groups)) {
					if (m2Key === listingGroupUUID) {
						if (m2Data.settings.qrcode_text?.value) {
							qrText = m2Data.settings.qrcode_text.value;
						}
					}
				}
			}
		}

		return qrText;
	}

	buildWayfinding = () => {
		// For translation
		const { t } = this.props;
		const fd = window.flatData
		const detailsUUID = this.state.useNearbyLocation ? this.state.useNearbyLocation : this.props.detailsUUID;
		
		if (this.props.detailsTouchAction === 7) {
			let primaryWayfindingColumn = '';
			let secondaryWayfindingColumn = '';
	
			for (let [m1Key, m1Data] of Object.entries(fd)) {
				if (m1Key.startsWith('listing_group.')) {
					for (let [m2Key, m2Data] of Object.entries(m1Data)) {
						if (m2Key.startsWith('listing_config.')) {
							if (m2Data.wf_column && fd[detailsUUID][`listing_column.${m2Data.wf_column}`]) {
								primaryWayfindingColumn = m2Data.wf_column;
							}
							if (m2Data.wf_fallback_column && fd[detailsUUID][`listing_column.${m2Data.wf_column}`]) {
								secondaryWayfindingColumn = m2Data.wf_fallback_column;
							}
						}
					}
				}
			}
	
			const landmarkIdentifier = fd[detailsUUID][`listing_column.${primaryWayfindingColumn}`]?.string || '';
			const landmarkIdentifierFallback = fd[detailsUUID][`listing_column.${secondaryWayfindingColumn}`]?.string || '';

			let legacyBuildingName = '';
			let legacyLandmarkId = '';
			let legacyLocationName = '';

			this.props.detailsGrp.forEach((columnData) => {
				if (columnData.type === 'Building') {
					legacyBuildingName = columnData.value;
				}
				if (columnData.type === 'Location (apt/suite)' && columnData.label === 'landmark_id') {
					legacyLandmarkId = columnData.value;
				}
				if (columnData.type === 'Location (apt/suite)' && columnData.label !== 'landmark_id') {
					legacyLocationName = columnData.value;
				}
			});

			let listingConfigUUID = '';
			for (let [m1Key, m1Data] of Object.entries(window.flatData)) {
				if (m1Key.startsWith(detailsUUID)) {
					for (let [m2Key] of Object.entries(m1Data)) {
						if (m2Key.startsWith('listing_config.')) {
							listingConfigUUID = m2Key;
						}
					}
				}
			}

			const qrCodeText = this.getQRTextInstruction(listingConfigUUID);

			return (
				<WayfindingComponent
					componentUUID={condorComponentENV}
					css={this.props.CSSModules['Wayfinding']}
					env={envENV}
					flatData={window.flatData}
					isMobile={false}
					landmarkIdentifier={landmarkIdentifier}
					landmarkIdentifierFallback={landmarkIdentifierFallback}
					legacyBuildingName={legacyBuildingName}
					legacyLandmarkId={legacyLandmarkId}
					legacyLocationName={legacyLocationName}
					listingConfigUUID={listingConfigUUID}
					listingNameCol={this.props.details?.[0]?.column_uuid}
					listingUUID={detailsUUID}
					locationMarkerUse={this.props.locationMarkerUse}
					locationMarkerColor={this.props.locationMarkerColor}
					locationMarkerSize={this.props.locationMarkerSize}
					mapDelayIn={this.props.mapDelayIn}
					mapDelayOut={this.props.mapDelayOut}
					pathColor={this.props.pathColor}
					pathEndmarker={this.props.pathEndmarker}
					pathEndmarkerSize={this.props.pathEndmarkerSize}
					pathWidth={this.props.pathWidth}
					primaryWayfindingColumn={primaryWayfindingColumn}
					qrCodeText={qrCodeText}
					ref={this.wayfindingRef}
					setNearbyLocationData={this.setNearbyLocationData}
					systemUUID={this.props.system_uuid}
					systemGroupUUID={this.props.systemGroup}
				/>
			);
		}

		// Temporary solution until full wayfinding solution is developed, must use "Details Page C"
		let wayfinding = '';
		let markerStyle = '';
		let pathStyle = '';

		let path = '0';
		let pathLength = '0';
		let contTop = '0';
		let contLeft = '0';
		let contTransform = 'none';
		let showSystemLocation = false;
		let textTop = '0';
		let textMarginRight = '0';
		let textTransform = 'none';


		let tlc = condorComponentENV;

		// 41b987c8-1ce1-4aa4-816f-cbcfd74d4a19 -> Craig's Hybrid 2 - Caremount Test system
		// 43df6579-b532-47ce-95aa-172f47689c3f -> Craig's Hybrid - Caremount system
		// baddbdcd-ebd1-4bb1-9bf3-4f4688e26d20 -> Optum temp system citest
		// 3c4d9254-2676-4f0f-9699-c221b128c672 -> Optum Test Hybrid local 
		// 6e13f311-b019-432c-80a1-43d68d477f8a -> 110 S Bedford Main-1 (PT-55H-TO) -DIR27 - TOUCH
		// 5a6e1266-2e1c-4271-91c8-883d3df36b8a -> 110 S Bedford-2nd Floor (PT-55H-TO)-DIR26 - TOUCH
		// 7e467328-6e01-4129-98d0-6a3a0abf3095 -> 90 S Bedford/Main Floor-1 (PT-55H-TO) - DIR25 - TOUCH
		// 76eb67b3-e9fc-461b-8a66-d63acb2102c3 -> 90 S Bedford/Main-2 (PT-55H-TO) - DIR24 - TOUCH
		if (Array.isArray(this.props.detailsGrp)) {
			this.props.detailsGrp.forEach((detailsData) => {
				if (detailsData.type === 'Array' && detailsData.label.toLowerCase() === 'building list') {
					markerStyle = this.state.markerStyle;
					pathStyle = this.state.pathStyle;

					if (tlc === '41b987c8-1ce1-4aa4-816f-cbcfd74d4a19' ||
						tlc === '43df6579-b532-47ce-95aa-172f47689c3f' ||
						tlc === 'baddbdcd-ebd1-4bb1-9bf3-4f4688e26d20' ||
						tlc === '3c4d9254-2676-4f0f-9699-c221b128c672' ||
						tlc === '7e467328-6e01-4129-98d0-6a3a0abf3095' ||
						tlc === '76eb67b3-e9fc-461b-8a66-d63acb2102c3') {

						// YAH for building 90 (west side)
						contTop = '389px';
						contLeft = '519px';
						contTransform = 'rotate(180deg)';
						showSystemLocation = true;
						textTop = '2.2em';
						textMarginRight = '3px';
						textTransform = 'rotate(180deg)';
						switch (detailsData.value) {
							case '100':
								path = 'M560 393 L532 393 L532 303 L491 264 L491 235';
								pathLength = '203.586';
								break;
							case '110':
								path = 'M560 391 L453 391 L453 451 L413 451';
								pathLength = '279';
								break;
							default:
								break;
						}

					} else if (tlc === 'building 90b') { // replace with TLC UUID
						// YAH for building 90 (east side)
						contTop = '389px';
						contLeft = '601px';
						contTransform = 'rotate(180deg)';
						showSystemLocation = true;
						textTop = '2.2em';
						textMarginRight = '3px';
						textTransform = 'rotate(180deg)';
						switch (detailsData.value) {
							case '90':

								break;
							case '100':
								path = 'M635 393 L532 393 L532 303 L491 264 L491 235';
								pathLength = '278.586';
								break;
							case '110':
								path = 'M632 391 L453 391 L453 451 L413 451';
								pathLength = '279';
								break;
							default:
								break;
						}

					} else if (tlc === 'building 100') { // replace with TLC UUID
						// YAH for building 100
						contTop = '183px';
						contLeft = '407px';
						contTransform = 'rotate(270deg)';
						textTop = '2.2em';
						textMarginRight = '3px';
						textTransform = 'rotate(90deg)';
						showSystemLocation = true;
						switch (detailsData.value) {
							case '90':
								path = 'M488 221 L488 259 L532 301 L532 392 L566 391';
								pathLength = '223.842';
								break;
							case '110':
								path = 'M489 223 L489 265 L452 297 L452 450 L413 449';
								pathLength = '282.931';
								break;
							default:
								break;
						}

					} else if (tlc === 'building 110a') {
						// YAH for building 110 (west side)
						contTop = '411px';
						contLeft = '269px';
						contTransform = 'rotate(270deg)';
						textTop = '2.2em';
						textMarginRight = '3px';
						textTransform = 'rotate(90deg)';
						showSystemLocation = true;
						switch (detailsData.value) {
							case '90':
								path = 'M347 451 L450 451 L450 390 L564 390';
								pathLength = '278';
								break;
							case '100':
								path = 'M351 451 L451 451 L451 289 L490 258 L490 227';
								pathLength = '342.82';
								break;
							default:
								break;
						}

					} else if (tlc === '6e13f311-b019-432c-80a1-43d68d477f8a' ||
						tlc === '5a6e1266-2e1c-4271-91c8-883d3df36b8a') {
						// YAH for building 110 (east side)
						contTop = '449px';
						contLeft = '383px';
						contTransform = 'rotate(180deg)';
						textTop = '2.2em';
						textMarginRight = '3px';
						textTransform = 'rotate(180deg)';
						showSystemLocation = true;
						switch (detailsData.value) {
							case '90':
								path = 'M421 452 L451 452 L451 392 L570 391';
								pathLength = '209.004';
								break;
							case '100':
								path = 'M423 451 L451 451 L451 289 L490 258 L490 227';
								pathLength = '270.82';
								break;
							default:
								break;
						}

					}
				}
			})
		}

		let strokeStyles = {
			strokeDasharray: `${pathLength} ${pathLength}`,
			strokeDashoffset: pathLength
		}
		let markerContStyles = {
			top: contTop,
			left: contLeft,
			transform: contTransform
		}
		let textContStyles = {
			top: textTop,
			marginRight: textMarginRight,
			transform: textTransform
		}

		if (this.props.detailsTouchAction === 6 && showSystemLocation === true) {
			wayfinding =
				<div id="svgWrapper" className={`svgWrapper`}>
					<div className={`hereCont`} style={markerContStyles}>
						<div className={`hereMarker`}></div>
						<div className={`hereMarkerCircle`}></div>
						<div className={`hereText`} style={textContStyles}>{t("listingsYouAreHereLabel")}</div>
					</div>

					<svg id="svgArea" className={`svgArea`}>
						<marker id="triangle" viewBox="0 0 8 8" refX="5" refY="4" markerUnits="strokeWidth" markerWidth="5" markerHeight="5" orient="auto" >
							<path id="endMarker" className={`${`endMarker`} ${markerStyle}`} fill="red" d="M 0 0 L 0 8 L 8 4 z"></path>
						</marker>
						<path markerEnd={this.state.markerEnd} style={strokeStyles} onTransitionEnd={this.showMarker} className={`line0 ${pathStyle}`} strokeWidth="3" stroke="red" id="mainPath" d={path}></path>
					</svg>
				</div>
				;
		}

		return wayfinding;
	}

	wayfindingReplayBtn = () => {
		const {
			locationMarkerUse,
		} = this.props;
		let css = this.state.css;

		if (this.props.detailsTouchAction === 7 && !locationMarkerUse) {
			return (
				<div
					className={`${css.childDetailsBtn} childDetailsBtn childReplayBtn ${this.state.activeButton === 'wayfindingReplayBtn' ? `${css.activeButton} activeButton` : ''}`}
					data-ts-id={`listingsDetailsReplayPathBtn.${this.props.detailsUUID}`}
					onMouseDown={(e) => {
						e.stopPropagation();
						this.handleDown('wayfindingReplayBtn');
						this.props.navEvents(`btnDebouncer`, () => this.wayfindingRef.current.reloadImage());
					}}
					onMouseUp={this.handleUp}
					onMouseLeave={this.handleUp}
					onTouchStart={(e) => {
						e.stopPropagation();
						this.handleDown('wayfindingReplayBtn');
						this.props.navEvents(`btnDebouncer`, () => this.wayfindingRef.current.reloadImage());
					}}
					onTouchEnd={this.handleUp}
					onPointerDown={(e) => {
						e.stopPropagation();
						this.handleDown('wayfindingReplayBtn');
						this.props.navEvents(`btnDebouncer`, () => this.wayfindingRef.current.reloadImage());
					}}
					onPointerUp={this.handleUp}
					onPointerLeave={this.handleUp}
				>
					<FontAwesomeIcon icon={faPlayCircle} className={`listingsSearchIcon ${css.searchIcon}`} /> Replay
				</div>
			);
		}
	}

	convertToCssClass = (str) => {
		const disallowedChars = /[^a-zA-Z0-9\-_]/g;

		if (str) {
			// Remove all spaces and disallowed CSS class characters
			const tierName = str.replace(/\s+/g, '').replace(disallowedChars, '');
			return `listingDetailsName${tierName}`
		} else {
			return '';
		}
	}

	render() {
		let css = this.state.css;
		let adaClass = '';
		const tier = `listingDetailsTier${parseInt(this.props.listingTier) + 1}`;
		const tierName = this.convertToCssClass(this.props.listingTierName);

		if (this.props.navProps.adaActive === true) {
			adaClass = css.adaActive;
		}

		let scrollType = '';
		if (this.props.scrollButtons === 2) {
			scrollType = css.smoothScroll;
		}

		let showManualScrolling = true;
		if (this.props.detailsTouchAction === 6) {
			showManualScrolling = false;
		}

		return (
			<>
				<CSSTransition
					in={this.props.detailsPage === true}
					appear={this.props.detailsPage === true}
					timeout={700}
					unmountOnExit
					classNames={`${css.contentFadeContainer} contentFade`}>
					{this.props.detailsTouchAction !== 10 && (
						<div
							className={`${css.listingsContainer} listingsContainer ${tier} ${tierName} ${adaClass}`}
							data-ts-id={`listingsDetails.${this.props.component_name}`}
						>
							<div
								className={`${css.detailsPage} detailsPage`}
								data-ts-id={`listingsDetailsScroller.${this.props.component_name}`}
								id='listingsScrollerDetails'
							>
								<div
									className={`${css.detailsPageInner} detailsPageInner ${scrollType} ${css.listingsScroller} listingsScroller`}
									data-ts-id={`listingsDetailsPageInner.${this.props.component_name}`}
									ref={this.scrollWindow}
								>
									{this.buildWayfinding()}
									{this.detailsPageType()}
								</div>

								<div
									className={`${css.btnContainer} btnContainer`}
									data-ts-id={`listingsDetailsBtnContainer.${this.props.component_name}`}
								>
									{this.state.tieredDetails.length > 0 &&
										<div
											className={`${css.childDetailsBtn} childDetailsBtn childListingsBtn ${this.state.activeButton === 'showListingsButton' ? `${css.activeButton} activeButton` : ''}`}
											data-ts-id={`listingsDetailsChildrenBtn.${this.props.component_name}`}
											onMouseDown={(e) => {
												e.stopPropagation();
												this.handleDown('showListingsButton')
												this.props.navEvents(`btnDebouncer`, () => this.handleShowChildren(this.state.tieredDetails, this.state.tieredHeaders));
											}}
											onMouseUp={this.handleUp}
											onMouseLeave={this.handleUp}
											onTouchStart={(e) => {
												e.stopPropagation();
												this.handleDown('showListingsButton')
												this.props.navEvents(`btnDebouncer`, () => this.handleShowChildren(this.state.tieredDetails, this.state.tieredHeaders));
											}}
											onTouchEnd={this.handleUp}
											onPointerDown={(e) => {
												e.stopPropagation();
												this.handleDown('showListingsButton')
												this.props.navEvents(`btnDebouncer`, () => this.handleShowChildren(this.state.tieredDetails, this.state.tieredHeaders));
											}}
											onPointerUp={this.handleUp}
											onPointerLeave={this.handleUp}
										>
											Show {this.props.details[0]?.value} listings
										</div>
									}

									{this.buildDetailsSection(["PDF", "URL", "Video"], 5)}

									<div
										className={`${css.childDetailsBtn} childDetailsBtn childCloseBtn ${this.state.activeButton === 'closeButton' ? `${css.activeButton} activeButton` : ''}`}
										data-ts-id={`listingsDetailsCloseBtn.${this.props.component_name}`}
										onMouseDown={(e) => {
											e.stopPropagation();
											this.handleDown('closeButton');
											this.props.navEvents(`btnDebouncer`, this.triggerCloseButton);
										}}
										onMouseUp={this.handleUp}
										onMouseLeave={this.handleUp}
										onTouchStart={(e) => {
											e.stopPropagation();
											this.handleDown('closeButton');
											this.props.navEvents(`btnDebouncer`, this.triggerCloseButton);
										}}
										onTouchEnd={this.handleUp}
										onPointerDown={(e) => {
											e.stopPropagation();
											this.handleDown('closeButton');
											this.props.navEvents(`btnDebouncer`, this.triggerCloseButton);
										}}
										onPointerUp={this.handleUp}
										onPointerLeave={this.handleUp}
									>
										Close
									</div>

									{this.wayfindingReplayBtn()}

									{showManualScrolling &&
										<div className={`${css.scrollBtnCont} scrollBtnCont`} data-ts-id={`listingsDetailsScrollBtnCont.${this.props.component_name}`}>
											<div
												className={`${css.scrollBtn} scrollBtn`}
												data-ts-id={`listingsDetailsScrollUpBtn.${this.props.component_name}`}
												onPointerDown={() => this.startScrolling('up')}
												onPointerUp={this.stopScrolling}
												onPointerLeave={this.stopScrolling}
												onPointerOut={this.stopScrolling}
												ref={this.scrollUpBtn}
											>
												<div
													className={`${css.scrollArrowUp} ${css.scrollArrow} scrollArrowUp scrollArrow`}
													data-ts-id={`listingsDetailsScrollUpArrow.${this.props.component_name}`}
												>
												</div>
												<div
													className={`${css.scrollBtnText} scrollBtnText`}
													data-ts-id={`listingsDetailsScrollUpText.${this.props.component_name}`}
												>
													Scroll Up
												</div>
											</div>
											<div
												className={`${css.scrollBtn} scrollBtn`}
												data-ts-id={`listingsDetailsScrollDownBtn.${this.props.component_name}`}
												onPointerDown={() => { this.startScrolling('down') }}
												onPointerUp={this.stopScrolling}
												onPointerLeave={this.stopScrolling}
												onPointerOut={this.stopScrolling}
												ref={this.scrollDownBtn}
											>
												<div
													className={`${css.scrollArrowDown} ${css.scrollArrow} scrollArrowDown scrollArrow`}
													data-ts-id={`listingsDetailsScrollDownArrow.${this.props.component_name}`}
												>
												</div>
												<div
													className={`${css.scrollBtnText} scrollBtnText`}
													data-ts-id={`listingsDetailsScrollDownText.${this.props.component_name}`}
												>
													Scroll Down
												</div>
											</div>
										</div>
									}
								</div>
							</div>
						</div>
					)}
				</CSSTransition>
				<CSSTransition
					in={this.state.showModal === true && this.state.pdfItem !== '' || this.state.currentPdf !== ''}
					appear={this.state.showModal === true}
					timeout={700}
					unmountOnExit
					classNames={`${css.contentFadeContainer} contentFade`}>
					<>
						<PdfComponent
							condor_component_group={this.props.condor_component_group}
							condor_configuration={this.props.condor_configuration}
							condor_theme={this.props.condor_theme}
							source={this.state.currentPdf || this.state.pdfItem} css={''}
							CSSModules={this.props.CSSModules}
							handleClose={this.closeModal}
							navEvents={this.props.navEvents}
						/>
					</>
				</CSSTransition>

				<CSSTransition
					in={this.state.showVideo === true || this.state.currentVideo !== ''}
					appear={this.state.showVideo === true}
					timeout={700}
					unmountOnExit
					classNames={`${css.contentFadeContainer} contentFade`}>
					<>
						<VideoComponent
							source={this.state.currentVideo || this.state.videoItem}
							condor_component_group={this.props.condor_component_group}
							condor_configuration={this.props.condor_configuration}
							condor_theme={this.props.condor_theme}
							CSSModules={this.props.CSSModules}
							handleClose={this.closeModal}
							navEvents={this.props.navEvents}
						/>
					</>
				</CSSTransition>

				<CSSTransition
					in={this.state.showIframe === true}
					appear={this.state.showIframe === true}
					timeout={700}
					unmountOnExit
					classNames={`${css.contentFadeContainer} contentFade`}>
					<>
						<Iframe
							{...this.props}
							source={this.state.currentIframe}
							isTouch={true}
							handleClose={this.closeModal} />
					</>
				</CSSTransition>
			</>
		)
	}
}

export default withTranslation()(ListingsDetails);
