// TimeFmt assumes the Normalized Format of Raven.

const TimeFmt = {
	daysOfWeek: {
		abbreviated: [
			'Sun',
			'Mon',
			'Tue',
			'Wed',
			'Thu',
			'Fri',
			'Sat'
		],

		full: [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday'
		]
	},

	monthsOfYear: {
		abbreviated: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec'
		],

		full: [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		]
	},

	makeDayOfWeek: (rawStamp, isAbbreviated) => {
		let stamp = TimeFmt.fmtRawStamp(rawStamp);
		if (!stamp) {
			return false;
		}

		let i = stamp.getDay();
		let x = TimeFmt.daysOfWeek.full[i];
		if (isAbbreviated) {
			x = TimeFmt.daysOfWeek.abbreviated[i];
		}

		if (typeof x === 'undefined') {
			return false;
		}

		return x;
	},

	makeMonthOfYear: (rawStamp, isAbbreviated) => {
		let stamp = TimeFmt.fmtRawStamp(rawStamp);
		if (!stamp) {
			return false;
		}

		let i = stamp.getMonth();
		let x = TimeFmt.monthsOfYear.full[i];
		if (isAbbreviated) {
			x = TimeFmt.monthsOfYear.abbreviated[i];
		}

		if (typeof x === 'undefined') {
			return false;
		}

		return x;
	},

	isNowBetween: (rawStart, rawEnd) => {
		let start = TimeFmt.fmtRawStamp(rawStart);
		let end = TimeFmt.fmtRawStamp(rawEnd);
		if (!start || !end) {
			return false;
		}

		let now = Date.parse(new Date());
		return ((start < now) && (now < end));
	},

	isBeforeNow: (testStamp) => {
		let stamp = TimeFmt.fmtRawStamp(testStamp);
		if (!stamp) {
			return true;
		}

		let now = Date.parse(new Date());
		return (stamp < now);
	},

	makeDisplayStamp: (rawStamp, timeFormat, timePeriod) => {
		let stamp = TimeFmt.fmtRawStamp(rawStamp);
		if (!stamp) {
			return false;
		}

		let displayMinutes = TimeFmt.makeDisplayMinutes(stamp.getMinutes());
		let [displayHours] = TimeFmt.makeDisplayHoursAndFmt(stamp.getHours(), timeFormat);

		let displayStamp = `${displayHours}:${displayMinutes}`;

		return displayStamp;
	},

	makeDisplayMinutes: (minutes) => {
		if (minutes >= 0 && minutes < 10) {
			return '0' + minutes;
		}
		return minutes;
	},

	makeDisplayPeriod: (rawStamp, timeFormat, timePeriod) => {
		let stamp = TimeFmt.fmtRawStamp(rawStamp);
		if (!stamp || timePeriod === false) {
			return false;
		}
		// let hours = TimeFmt.makeDisplayHoursAndFmt(stamp.getHours());
		let [displayFmt] = TimeFmt.makeDisplayHoursAndFmt(stamp.getHours(), timeFormat);

		return displayFmt;
	},

	makeDisplayAMPM: (rawStamp, timeFormat, timePeriod) => {
		let stamp = TimeFmt.fmtRawStamp(rawStamp);
		if (!stamp || timePeriod === false) {
			return false;
		}
		// let hours = TimeFmt.makeDisplayHoursAndFmt(stamp.getHours());
		let [displayFmt] = TimeFmt.makeDisplayHoursAndFmt(stamp.getHours(), '24_hour');
		if (displayFmt < 12){
			return 'AM';
		} else{
			return 'PM';
		}
	},

	makeDisplayHoursAndFmt: (rawHours, timeFormat) => {
		let displayFmt = '';
		if (timeFormat === '12_hour') {
			displayFmt = 'AM';
			if (rawHours >= 12) {
				displayFmt = 'PM';
			}
		}

		let hours = rawHours;
		if (timeFormat === '12_hour') {
			if (hours === 0) {
				hours = 12;
			}

			if (hours > 12) {
				hours = hours - 12;
			}
		}

		return [hours, displayFmt];
	},

	fmtRawStamp: (raw) => {
		let x = new Date(raw);
		let y = Date.parse(x);
		if (isNaN(y)) {
			return false;
		}

		return x;
	}
};

export default TimeFmt;
