import React, {Component} from 'react';
import TimeFmt from '../util/TimeFmt.js';
import { withTranslation } from 'react-i18next';
import { herciniaURLENV } from '../ENV.js';


const herciniaURL = herciniaURLENV;
class EventCurrent extends Component {
	constructor(props) {
		super(props);

		let timeFormat = '12_hour';
		if (props.timeFormat) {
			timeFormat = props.timeFormat;
		}

		this.state = {
			//changed "booked" from just false for testing in condor
			booked:         false,
			themeVariation: this.props.themeVariation
		};

	}

	componentDidMount(){
		this.lightServerRequest();
	}
	
	componentDidUpdate(prevProps, prevState) {
		let nextBooked = this.checkBooked();
		if (prevState.booked !== nextBooked) {
			this.setState({booked: nextBooked}, this.lightServerRequest);
		}
	}


	checkBooked = () => {
		if (
			this.validateEventByKey('start_time') &&
			this.validateEventByKey('end_time')
		) {
			let {start_time, end_time} = this.props.event[0];
			return TimeFmt.isNowBetween(start_time, end_time);
		}

		return false;
	}

	lightServerRequest = () => {
		let color = '';
		if (this.state.booked){
			color = 'red';
		} else {
			color = 'green';
		}
		fetch(`${herciniaURL}/${color}`)
			.then(response => {
				if (response.ok) {
					return response.text();
				} else {
					throw new Error('Bad network response');
				}
			})
			.then(contents => {
				console.log(contents);
			})
			.catch(error => {
				console.error(error);
			});
	}

	validateEventByKey = (key = false) => {
		if (Array.isArray(this.props.event) && this.props.event.length > 0) {
			return (typeof this.props.event[0][key] !== 'undefined');
		}

		return false;
	}

	isRoomInUse(currentDate, startDate, endDate){
		if (currentDate >= startDate && currentDate <= endDate) {
			return true;
		}
		return false;
	}

	createStatus = () => {
		let css = this.props.css
		const firstEvent = this.findFirstEvent(this.props.event);

		if (
			firstEvent &&
			this.validateEventByKey(['start_time']) &&
			this.validateEventByKey(['end_time']) &&
			this.validateEventByKey(['subject'])
		) {

			let {start_time, end_time, subject, organizer} = firstEvent;
			let startTime = TimeFmt.fmtRawStamp(start_time).getHours() * 60 + TimeFmt.fmtRawStamp(start_time).getMinutes();
			let endTime = TimeFmt.fmtRawStamp(end_time).getHours() * 60 + TimeFmt.fmtRawStamp(end_time).getMinutes();
			let meetingLength = endTime - startTime;
			let currentDate = new Date();
			let startDate = new Date(start_time);
			let endDate = new Date(end_time);
			let currentTime = new Date().getHours() * 60 + new Date().getMinutes() + new Date().getSeconds() / 60;
			let timeLeft = endTime - currentTime;
			let percentRemaining = timeLeft / meetingLength * 100;
			let startDisplay = TimeFmt.makeDisplayStamp(start_time, this.props.raven_time_format, this.props.raven_time_period);
			let endDisplay = TimeFmt.makeDisplayStamp(end_time, this.props.raven_time_format, this.props.raven_time_period);
			let startPeriod = TimeFmt.makeDisplayAMPM(start_time, this.props.timeFormat, true);
			let	endPeriod = TimeFmt.makeDisplayAMPM(end_time, this.props.timeFormat, true);
			// let percentComplete = 100 - percentRemaining;

			let progressBarInnerStyles = {
				width: `${percentRemaining}%`
			};

			let organizerElm = '';
			if (this.props.raven_display_organizer) {
				organizerElm = (<div className={css.organizer}>{this.props.event[0].organizer.name}</div>);
			}

			if (this.isRoomInUse(currentDate, startDate, endDate)) {
			/* if (this.state.booked) { */
				let isEventAllDay = this.isEventAllDay(start_time, end_time);
				return (
					<>
					<div className={`${css.statusInUse} statusInUse ravenStatus`}>
						In Use
						<div className={`${css.progressBar} progressBar`}>
							<div className={css.progressBarInner} style={progressBarInnerStyles}></div>
						</div>
					</div>
					{isEventAllDay ? <span className={`${css.innerSpan} ravenInnerSpan`}>ALL DAY</span> : null}
					<div className={`${css.time} ravenTime`}>
							<div className={`${css.subjectName} subjectName`}>{subject}</div>
							{startDisplay}<span className={`${css.period} ravenPeriod`}>{startPeriod}</span> - {endDisplay}<span className={`${css.period} ravenPeriod`}>{endPeriod}</span>
						</div>

						{organizerElm}
					</>
				);
			}
		}

		return (
			<div className={`${css.statusOpen} statusOpen ravenStatus`}>
				Open
				<div className={`${css.nextBar} nextBar`}></div>
				
			</div>);
	}

	//this ensures we only care about events in the present or future, and not any past events
	findFirstEvent(eventList) {
		if (!eventList) {
			return;
		}
		let currentTimestamp = new Date();
		for (let i = 0; i < eventList.length; i++) {
			let endTimestamp = new Date(eventList[i].end_time);
			if (endTimestamp > currentTimestamp) {
				return eventList[i];
			}
		}
	}

	createDetails = () => {
		let css = this.props.css
		const { t } = this.props;

		let result = (<div className={`${css.noEvents} ravenNoEvents`}>{t("ravenNoFutureEvents")}</div>);
		const firstEvent = this.findFirstEvent(this.props.event);

		if (
			firstEvent &&
			this.validateEventByKey(['start_time']) &&
			this.validateEventByKey(['end_time']) &&
			this.validateEventByKey(['subject'])
		) {
			let {start_time, end_time, subject} = firstEvent;
			let currentDate = new Date();
			let startDate = new Date(start_time);
			let endDate = new Date(end_time);
			if (this.isRoomInUse(currentDate, startDate, endDate)) {
				result = <></>;
				return result;
			}
			let startDisplay = TimeFmt.makeDisplayStamp(start_time, this.props.raven_time_format, this.props.raven_time_period);
			let endDisplay = TimeFmt.makeDisplayStamp(end_time, this.props.raven_time_format, this.props.raven_time_period);
			let startPeriod = TimeFmt.makeDisplayPeriod(start_time, this.props.raven_time_format, this.props.raven_time_period);
			let endPeriod = TimeFmt.makeDisplayPeriod(end_time, this.props.raven_time_format, this.props.raven_time_period);

			let organizerElm = '';

			if (this.props.raven_display_organizer) {
				organizerElm = (<div className={css.organizer}>{this.props.event[0].organizer.name}</div>);
			}

			if (!startDisplay) {
				console.error(`Could not parse start time out of: ${start_time}`);
				return result;
			}

			if (this.state.booked) {
				if (!endDisplay) {
					console.error(`Could not parse end time out of: ${end_time}`);
					result = (
						<>
							<div className={`${css.subjectName} subjectName`}>{subject}</div>
							<div className={css.time}>
								{startDisplay}<span className={css.period}>{startPeriod}</span>
							</div>
							{organizerElm}
						</>
					);
					return result;
				}

				result = (
					<>
						<div className={css.time}>
							<div className={`${css.subjectName} subjectName`}>{subject}</div>
							{startDisplay}<span className={css.period}>{startPeriod}</span> - {endDisplay}<span className={css.period}>{endPeriod}</span>
						</div>

						{organizerElm}
					</>
				);
				return result;
			}
			if (startDisplay){
				result = (
					<div className={`${css.nextEvent} nextEvent`}>
						Next meeting is <span>{this.getNextMeetingDate(start_time)}</span> at {startDisplay} {/* <span className={css.period}>{startPeriod}</span> */}
					</div>
				);
				
				return result;
			}
			result = (
				<div className={`${css.nextEvent} nextEvent`}>
					Next meeting at {startDisplay}{/* <span className={css.period}>{startPeriod}</span> */}
				</div>
			);
			return result;
		}

		return result;
	}

	formatLocalDate(date) {
		let year = date.getFullYear();
		let month = (date.getMonth() + 1).toString().padStart(2, '0');
		let day = date.getDate().toString().padStart(2, '0');

		return `${year}-${month}-${day}`;
	}

	getNextMeetingDate(start_time) {
		const startDate = new Date(start_time);
		const currentDate = new Date(this.props.current_time);
	
		const startDateString = this.formatLocalDate(startDate);
		const currentDateString = this.formatLocalDate(currentDate);
	
		let nextMeeting = ""; 
	
		if (startDateString === currentDateString) {
			nextMeeting = "today";
		} else {
			const tomorrow = new Date();
			tomorrow.setDate(tomorrow.getDate() + 1);
			const tomorrowString = this.formatLocalDate(tomorrow);
	
			if (startDateString === tomorrowString) {
				nextMeeting = "tomorrow";
			} else {
				const formattedDate = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`;
				nextMeeting = formattedDate;
			}
		}
	
		return nextMeeting;
	}

	isEventAllDay(start_time, end_time) {
		const startTime = new Date(start_time);
		const endTime = new Date(end_time);

		return Math.abs(startTime.getTime() - endTime.getTime()) === 24 * 60 * 60 * 1000
	}


	render() {
		let css = this.props.css

		let statusElm = this.createStatus();
		let detailsElm = this.createDetails();
		// For translation
		const { t } = this.props;

		if (this.props.raven_private_event_label !== '') {
			this.subject = this.props.raven_private_event_label;
		}

		// Custom classes
		let customClasses = '';
		if (this.props.custom_classes) {
			this.props.custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${this.props.css[`${element}`]}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (this.props.modifier_classes) {
			this.props.modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		return (
			<>
				<div id={`eventCurrent_${this.props.component_name}`} className={`eventCurrent_container ${modifierClasses} ${customClasses}`}>
					<div className={`${css.statusBar} statusBar`}>
						{statusElm}
					</div>
					<div className={`${css.details} ravenDetails`}>
						{detailsElm}
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(EventCurrent);
