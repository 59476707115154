import React, { PureComponent } from 'react';

import ListingsDetails from './ListingsDetails';
import Listings from './Listings';
import ListingRow from './ListingRow';
import ListingHeader from './ListingHeader';
import { applyFilters, buildFilterList } from '../helper/Filter';
import { sortListingsByCols } from '../util/listings';
import {
	broncoURLENV,
} from '../ENV.js';

class ListingsScroller extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeButton: '',
			activeRow: '',
			account_uuid: this.getAccountUUID(),
			css: props.CSSModules[props.condor_render_name],
			defaultList: this.getDefaultListing(this.props.default_list_shown),
			defaultListName: this.getDefaultListingName(this.props.default_list_shown),
			defaultKeyboard: this.props.default_keyboard,
			details: [],
			detailsCSS: props.CSSModules['ListingsDetails'],
			detailsHasRendered: false,
			detailsPage: false,
			detailsTouchAction: this.getDetailsTouchAction(this.props.default_list_shown),
			detailsTitle: '',
			detailsUUID: '',
			disabledButton: false,
			etlData: props.tmp_data_etl || {},
			history: [],
			rowCss: [],
			tieredDetails: [],
			tieredHeaders: [],
			scrollButtons: this.props.scroll_buttons,
			scrollDistance: this.props.scroll_distance,
			messageLabels: {},
			filteredListings: [], // Filtered results
			lastSearchValue: '', // Track last search value
		};
	}

	componentDidMount() {
		this.fetchSystemMessagingData();
		this.setSystemGroupAndAccount();
	}

	componentDidUpdate(prevProps, prevState) {
		if ((this.props.navProps.timeoutStatus !== prevProps.navProps.timeoutStatus && !this.props.navProps.timeoutStatus) && this.props.navProps.animationPhase === this.props.navProps.animationStartPhase) {
			this.setState({
				detailsPage: false,
				detailsTitle: '',
				detailsUUID: '',
				details: [],
				history: [],
				detailsHasRendered: false,
				tieredDetails: [],
				tieredHeaders: []
			});
		}
		if ((prevProps.tmp_data_etl !== this.props.tmp_data_etl) || (prevProps.last_update !== this.props.last_update)) {
			this.setState({
				defaultList: this.getDefaultListing(this.props.default_list_shown)
			});
		}
	}

	componentWillUnmount() {
		clearTimeout(this.closeDetailsTimeout1);
		clearTimeout(this.closeDetailsTimeout2);
	}

	setSystemGroupAndAccount() {
		const keys = Object.keys(window.flatData);
		let account, systemGroup;

		keys.find((key) => {
			if (key.startsWith('account.')) {
				account =  key;
			}
			if (key.startsWith('system_group.')) {
				systemGroup =  key;
			}
		});

		this.setState({
			account,
			systemGroup,
		});
	}

	getSystemOrPropertyOrAccountSetting = (mapSetting, checkbox = false) => {
		let settingValue = '';

		const account = window.flatData[this.state.account] || {};
		const systemGroup = window.flatData[this.state.systemGroup] || {};
		const activeSystem = window.flatData[`system.${this.props.system_uuid}`] || {};

		let accountSetting;
		if (mapSetting in account) {
			accountSetting = {
				value: account[mapSetting] ?? null
			};
		}

		let propertySetting;
		if (mapSetting in systemGroup) {
			propertySetting = {
				value: systemGroup[mapSetting] ?? null,
			};
		}

		let systemSetting;
		if (mapSetting in activeSystem) {
			systemSetting = {
				value: activeSystem[mapSetting] ?? null,
			};
		}

		if ((systemSetting?.value && !checkbox) || (checkbox && systemSetting?.value && systemSetting?.value !== false)) {
			settingValue = systemSetting.value;
		} else if ((propertySetting?.value && !checkbox) || (checkbox && propertySetting?.value && propertySetting?.value !== false)) {
			settingValue = propertySetting.value;
		} else if ((accountSetting?.value && !checkbox) || (checkbox && accountSetting?.value && accountSetting?.value !== false)) {
			settingValue = accountSetting.value;
		} else if (checkbox) {
			settingValue = false;
		} else {
			settingValue = '';
		}

		return settingValue;
	}

	fetchSystemMessagingData = () => {
		const url = `${broncoURLENV}/messaging/${this.state.account_uuid}/system/${this.props.system_uuid}`;

		try {
			fetch(url, {
				headers: { 'Content-Type': 'application/json' },
				method: "GET"
			})
				.then((response) => {
					return response.json();
				})
				.then((jsonData) => {
					console.log("jsonData", jsonData)
					this.setState({
						messageLabels: jsonData,
					});
				})
				.catch((err) => {
					console.error('Error on messaging response', err);
				});
		} catch (err) {
			console.error(err);
		}
	}

	getAccountUUID() {
		const flatData = window.flatData;

		const accountEntry = Object.keys(flatData).find(key => key.startsWith('account.'));
		if (accountEntry) {
			return accountEntry.split('.')[1];
		}

		return '';
	}

	getDefaultListingName = (listingConfigUUID) => {
		return window?.flatData?.[listingConfigUUID]?.display_name || '';
	}

	getDetailsTouchAction = (listingConfigUUID) => {
		return window?.flatData?.[listingConfigUUID]?.touch_action || '';
	}

	getDefaultListing = (listingConfigUUID) => {
		if (listingConfigUUID === '') {
			return {};
		}

		let fd = window.flatData;
		let etlData = this.props.tmp_data_etl;
		let configToCollectionMap = this.props.configToCollectionMap;

		let collectionList = [];
		this.props.condor_prop_list.forEach((listingCollection) => {
			collectionList.push(listingCollection.component_name); // ListingsScroller owns these ListingCollections
		});

		let collectionName = '';
		let groupName = '';

		collectionList.forEach((collection) => {
			for (let [key1, value1] of Object.entries(fd[`component.${collection}`])) {
				if (key1.startsWith('listing_collection.')) {
					if (configToCollectionMap[listingConfigUUID].includes(key1)) {
						collectionName = key1;
					}
				}
			}
		});

		for (let [key1, value1] of Object.entries(etlData[collectionName].listing_groups)) {
			if (key1.startsWith('listing_group.')) {
				groupName = key1;
			}
		}

		let unfilteredEtlData = etlData[collectionName].listing_groups[groupName];
		let etlDataWithFilters = { ...unfilteredEtlData, listings: buildFilterList(unfilteredEtlData.listings) };
		let filteredEtlData = { ...etlDataWithFilters, listings: applyFilters(etlDataWithFilters.listings) };

		return filteredEtlData;
	}

	setDetailsPage = (detailsPage) => {
		this.setState({ detailsPage: detailsPage });
	}

	setDetailsUUID = (detailsUUID) => {
		this.setState({ detailsUUID: detailsUUID });
	}

	setDetailsAction = (detailsAction) => {
		this.setState({ detailsTouchAction: detailsAction });
	}

	setDetailsHasRendered = (detailsHasRendered) => {
		this.setState({ detailsHasRendered: detailsHasRendered });
	}

	setDetailsGrp = (detailsGrp) => {
		this.setState({ detailsGrp: detailsGrp });
	}

	setRowCSS = (rowCSS) => {
		this.setState({ rowCSS: rowCSS });
	}

	setHistory = (history) => {
		if (history.length === 0) {
			this.setState({ history: [] });
			return;
		}
		let newHistory = this.state.history;
		newHistory.push(history);
		this.setState({ history: newHistory });
	}

	setTieredHeadersAndDetails = (tieredHeaders, tieredDetails) => {
		this.setState({
			tieredHeaders,
			tieredDetails,
		});
	}

	setSearchValue = (setSearchValue) => {
		this.setState({ searchValue: setSearchValue });
	}

	setDetailsData = (listData, detailsUUID, detailsGrp, detailsTouchAction) => {
		const listingDetails = Array.isArray(listData) ? listData[listData.length - 1] : null;

		if (detailsTouchAction) {
			this.setState({
				details: listData,
				detailsGrp: detailsGrp,
				detailsPage: true,
				detailsUUID: detailsUUID,
				listingTier: listingDetails?.listingTier || 0,
				listingTierName: listingDetails?.listingTierName || null,
				detailsTouchAction: detailsTouchAction
			});
		} else {
			this.setState({
				details: listData,
				detailsGrp: detailsGrp,
				detailsPage: true,
				detailsUUID: detailsUUID,
				listingTier: listingDetails?.listingTier || 0,
				listingTierName: listingDetails?.listingTierName || null
			});
		}
	}

	addToHistory = (parentIsTierPlusOne = false) => {
		this.setHistory({
			detailsTitle: this.state.detailsTitle,
			tieredDetails: this.state.tieredDetails,
			tieredHeaders: this.state.tieredHeaders,
			type: 'listing',
			returnMultiple: parentIsTierPlusOne
		});
	}

	setDetailsTitle = (detailsTitle) => {
		this.setState({ detailsTitle: detailsTitle });
	}

	closeDetailsPage = () => {
		this.setState({ detailsPage: false });
		if (this.state.history.length === 0) {
			this.setState({
				detailsHasRendered: false,
				detailsUUID: ''
			});
		}
	}

	handleDown = (buttonId) => {
		this.setState({ activeButton: buttonId });
	}

	handleUp = () => {
		this.setState({ activeButton: '' });
	}

	displayChildTier = (listingUUID, rowsArray = [], newTierListings, rowData, parentIsTierPlusOne = false) => {
		let listingsArray = newTierListings || this.state.defaultList?.listings;
		let rowHeaders = [];
		let tieredHeaders = [];
		let detailsCSS = this.state.detailsCSS;
		let numberOfResults = 0;
		let touchAction = '';
		let listInfo = listingsArray[0];

		if (!listInfo.colGroups || listInfo.colGroups.length === 0) {
			return null;
		}

		// For translation
		const { t } = this.props;

		listingsArray.forEach((listing, j) => {

			if (listing.m1Key === listingUUID) {
				let headerWidths = [];

				if (listing.listings.length === 0) {
					return;
				}
				listing.listings[0].colGroups.forEach((colGroup) => {
					headerWidths.push(colGroup.width);
				});

				let sortedCols = [...listing.listings[0].colGroups].filter((c) => {
					if (c.sort > 0) {
						return true;
					}

					return false;
				});

				sortedCols.sort((i, j) => {
					if (i.sort > j.sort) {
						return 1;
					}

					return -1;
				});

				listing.listings = listing.listings.map((listing) => {
					listing.sortedVals = [listing.rank || 0];

					for (let col of sortedCols) {
						listing.sortedVals.push(listing.vals[col.order - 1]);
					}

					return listing;
				});

				listing.listings.sort(sortListingsByCols);

				listing.listings.forEach((row, i) => {
					let textAlign = [];

					if (numberOfResults < 500) {
						numberOfResults++;

						let currentHeaderWidths = row.colGroups.map((colGroup) => {
							return colGroup.width;
						});

						let listingsWithType = [];
						row.vals.forEach((listingData, i) => {
							listingsWithType.push({
								value: listingData,
								type: listing.listings[0].types[i],
								displayName: listing.listings[0].displayNames[i],
								column_uuid: listing.listings[0].colGroups[i].m1Key
							});
						});
						// add tierConfig to listing detail
						listingsWithType.push(row.tierConfig);

						let headersArray = [];
						let headerAsLabelArray = [];
						row.colGroups.forEach((col) => {
							if (col.headerAsLabel) {
								headersArray.push(col.name);
								headerAsLabelArray.push(true);
							} else {
								headersArray.push('');
								headerAsLabelArray.push(false);
							}
						});

						row.colGroups.forEach((column) => {
							switch (column.textAlign) {
								case 0:
									textAlign.push('left');
									break;
								case 1:
									textAlign.push('center');
									break;
								case 2:
									textAlign.push('right');
									break;
								default:
									textAlign.push('center');
									break;
							}
						});

						if (row.colGroups.length !== 0) {
							rowsArray.push(
								<ListingRow
									{...this.props}
									activeRow={this.state.activeRow}
									addToHistory={this.addToHistory}
									boxActiveLayout={row.tierConfig.boxActiveLayout + 1}
									boxColumnsPerRow={row.tierConfig.boxColumnsPerRow + 1}
									boxStyleDisplay={row.tierConfig.boxStyleDisplay}
									css={currentHeaderWidths}
									currentDetailAction={row.tierConfig.touchAction}
									data={listingsWithType}
									detailsGrp={row.detailsGrp}
									detailsTitle={this.state.details[0]}
									displayChildTier={this.displayChildTier}
									headerAsLabel={headerAsLabelArray}
									headerData={headersArray}
									key={`details_row_${i}`}
									messageLabels={this.state.messageLabels}
									num={i}
									parent='ListingsScroller'
									rowUUID={row.m1Key}
									setDetailsData={this.setDetailsData}
									setDetailsTitle={this.setDetailsTitle}
									textAlign={textAlign}
								/>
							);
						}
					}

					if (tieredHeaders.length < 1) {
						if (listing.listings.length > 0) {
							listing.listings[0].colGroups.forEach((colGroup) => {
								rowHeaders.push(colGroup.name);
							});
						}

						if (!row.tierConfig.boxStyleDisplay) {
							tieredHeaders.push(
								<div className={detailsCSS.header} key={`tieredHeader_${j}`}>
									<ListingHeader
										{...this.props}
										data-key={`tieredHeader`}
										data={rowHeaders}
										css={headerWidths}
										textAlign={textAlign}
									/>
								</div>
							);
						}
					}


					if (numberOfResults >= 500) {
						tieredHeaders.unshift(<p className={detailsCSS.overLimit}>{t('listingsTooManyResultsError')}</p>);
					}
				});

				const uniqueRows = [...new Map(rowsArray.map((m) => {
					return [m.props.rowUUID, m];
				})).values()];

				this.addToHistory(parentIsTierPlusOne);

				this.setState({
					tieredDetails: uniqueRows,
					tieredHeaders: tieredHeaders,
					touchAction: touchAction
				});

				// the || operator is here to avoid crashing Condor when closing a details page
				this.setDetailsTitle(rowData || '');
				setTimeout(() => {
					this.setState({
						pathStyle: 'animatePath'
					});
				}, 1500);
			} else if (listing.listings && listing.listings.length > 0) {
				this.displayChildTier(listingUUID, rowsArray, listing.listings, rowData?.value);
			}
		});
	}

	backButton = () => {
		this.goBack();
		this.props.navEvents('idleTimer', '||| ListingsScroller.js > buildBackBtn');
	}

	buildBackBtn = () => {
		let { css, detailsPage, history, disabledButton, activeButton } = this.state;
		let adaClass = this.props.navProps.adaActive ? css.adaActive : '';
		let disabledButtonClass = disabledButton ? css.disabledButton : '';
		let activeButtonClass = activeButton === 'backButton' ? `${css.activeButton} activeButton` : '';
		let disabledButtonStyle = disabledButton ? { pointerEvents: 'none', opacity: '0.5' } : {};

		if (detailsPage || history?.length > 0) {
			return (
				<div
					className={`${css.backBtn} listingsScrollerBackBtn ${adaClass} ${disabledButtonClass} ${activeButtonClass} ${disabledButton ? 'disabledBackButton' : ''}`}
					style={disabledButtonStyle}
					onMouseDown={(e) => {
						e.stopPropagation();
						this.handleDown('backButton');
						this.props.navEvents('btnDebouncer', this.backButton);
					}}
					onMouseUp={this.handleUp}
					onMouseLeave={this.handleUp}
					onTouchStart={(e) => {
						e.stopPropagation();
						this.handleDown('backButton');
						this.props.navEvents('btnDebouncer', this.backButton);
					}}
					onTouchEnd={this.handleUp}
					onPointerDown={(e) => {
						e.stopPropagation();
						this.handleDown('backButton');
						this.props.navEvents('btnDebouncer', this.backButton);
					}}
					onPointerUp={this.handleUp}
					onPointerLeave={this.handleUp}
				></div>
			);
		}
	}

	temporarilyDisableButton = () => {
		this.setState({ disabledButton: true });
		setTimeout(() => {
			this.setState({ disabledButton: false });
		}, 800);
	}

	disableBackButton = () => {
		this.setState({ disabledButton: true });
	}

	handleBoxData = (boxStyleDisplay, boxColumnsPerRow, boxActiveLayout) => {
		this.setState({
			boxStyleDisplay: boxStyleDisplay,
			boxColumnsPerRow: boxColumnsPerRow,
			boxActiveLayout: boxActiveLayout
		});
	}

	handleHeaderAsLabel = (headerAsLabel) => {
		this.setState({
			headerAsLabel: headerAsLabel
		});
	}

	// Optimize goBack function
	goBack = () => {
		window.currentDetailAction = this.state.detailsTouchAction;

		// Fast path for empty history
		if (this.state.history.length === 0) {
			this.setState({
				tieredDetails: [],
				tieredHeaders: [],
				detailsGrp: [],
				detailsHasRendered: false,
				detailsTitle: '',
				history: [],
				detailsPage: false
			});
			return;
		}

		const lastHistoryElement = this.state.history[this.state.history.length - 1];
		const historyWithoutLastElement = this.state.history.slice(0, -1);

		// Batch state updates
		const newState = {
			history: historyWithoutLastElement
		};

		if (lastHistoryElement.detailsTouchAction === 10) {
			// Optimize tier handling
			const newHistory = this.state.history.filter((item, i, self) =>
				self.findIndex(j => j.detailsUUID === item.detailsUUID) === i
			);

			if (newHistory.length === 1) {
				Object.assign(newState, {
					tieredDetails: [],
					tieredHeaders: [],
					detailsTitle: '',
					history: []
				});
			} else {
				Object.assign(newState, {
					tieredDetails: [],
					tieredHeaders: [],
					detailsTitle: '',
					details: lastHistoryElement.details,
					detailsPage: true,
					detailsUUID: lastHistoryElement.detailsUUID,
					detailsGrp: lastHistoryElement.detailsGrp,
					detailsTouchAction: lastHistoryElement.detailsTouchAction
				});
			}
			newState.detailsPage = false;
		} else if (lastHistoryElement.type === 'detail') {
			Object.assign(newState, {
				details: lastHistoryElement.details,
				detailsPage: true,
				detailsUUID: lastHistoryElement.detailsUUID,
				detailsGrp: lastHistoryElement.detailsGrp,
				detailsTouchAction: lastHistoryElement.detailsTouchAction
			});
		} else if (lastHistoryElement.type === 'listing') {
			Object.assign(newState, {
				tieredDetails: lastHistoryElement.tieredDetails,
				tieredHeaders: lastHistoryElement.tieredHeaders,
				detailsTitle: lastHistoryElement.detailsTitle,
				details: [],
				detailsPage: false
			});
		}

		// Batch all state updates
		this.setState(newState, () => {
			if (lastHistoryElement.type === 'listing') {
				// Delay detailsHasRendered update
				requestAnimationFrame(() => {
					this.setState({ detailsHasRendered: false });
				});
			}
		});
	};

	// Optimize details mounting/unmounting
	handleDetailsUnmount = () => {
		requestAnimationFrame(() => {
			this.setState({
				disabledButton: false,
				detailsHasRendered: false
			});
		});
	};

	render() {
		let css = this.state.css;
		let scrollBtnStyle = '';
		if (this.state.scrollButtons === 1 || this.state.scrollButtons === 2) {
			scrollBtnStyle = 'useScrollButtons';
		}

		if (this.props.default_list_shown === '') {
			return <div />;
		}

		const commonProps = {
			...this.props,
			account:			          this.state.account,
			activeRow:   	              this.state.activeRow,
			closeDetailsPage:             this.closeDetailsPage,
			defaultList:                  this.state.defaultList,
			details:                      this.state.details,
			detailsPage:                  this.state.detailsPage,
			detailsTouchAction:           this.state.detailsTouchAction,
			displayChildTier:             this.displayChildTier,
			etlData:                      this.props.tmp_data_etl,
			handleRowDown: 		          this.handleRowDown,
			handleRowUp: 		          this.handleRowUp,
			scrollButtons:                this.state.scrollButtons,
			scrollDistance:               this.state.scrollDistance,
			setHistory:                   this.setHistory,
			setDetailsData:               this.setDetailsData,
			setDetailsTitle:              this.setDetailsTitle,
			setTieredHeadersAndDetails:   this.setTieredHeadersAndDetails,
			showOnPhase:                  this.props.showOnPhase,
			systemGroup:                  this.state.systemGroup,
			tieredDetails:                this.state.tieredDetails,
			tieredHeaders:                this.state.tieredHeaders,

			// wayfindingData
			locationMarkerUse:   this.getSystemOrPropertyOrAccountSetting('wf_location_marker_use', true),
			locationMarkerColor: this.getSystemOrPropertyOrAccountSetting('wf_location_marker_color'),
			locationMarkerSize:  this.getSystemOrPropertyOrAccountSetting('wf_location_marker_size'),
			mapDelayIn:          this.getSystemOrPropertyOrAccountSetting('wf_map_delay_in'),
			mapDelayOut:         this.getSystemOrPropertyOrAccountSetting('wf_map_delay_out'),
			pathColor:           this.getSystemOrPropertyOrAccountSetting('wf_path_color'),
			pathEndmarker:       this.getSystemOrPropertyOrAccountSetting('wf_path_endmarker'),
			pathEndmarkerSize:   this.getSystemOrPropertyOrAccountSetting('wf_path_endmarker_size'),
			pathWidth:           this.getSystemOrPropertyOrAccountSetting('wf_path_width'),
		}

		return (
			<div className={`${css.contentContainer} listingsScrollerContentContainer ${scrollBtnStyle}`}>
				{this.buildBackBtn()}

				<Listings
					{...commonProps}
					addToHistory={this.addToHistory}
					defaultListName={this.state.defaultListName}
					detailsTitle={this.state.detailsTitle?.value ? this.state.detailsTitle.value : this.state.detailsTitle}
					defaultKeyboard={this.state.defaultKeyboard}
					handleBoxData={this.handleBoxData}
					handleHeaderAsLabel={this.handleHeaderAsLabel}
					messageLabels={this.state.messageLabels}
					setDetailsPage={this.setDetailsPage}
					setRowCSS={this.setRowCSS}
					setSearchValue={this.setSearchValue}
				/>

				{
					(this.state.detailsPage || this.state.detailsHasRendered) &&
					<ListingsDetails
						{...commonProps}
						boxActiveLayout={this.state.boxActiveLayout}
						boxColumnsPerRow={this.state.boxColumnsPerRow}
						boxStyleDisplay={this.state.boxStyleDisplay}
						detailsGrp={this.state.detailsGrp}
						detailsTitle={this.state.detailsTitle}
						detailsUUID={this.state.detailsUUID}
						disableBackButton={this.disableBackButton}
						goBack={this.goBack}
						handleDetailsUnmount={this.handleDetailsUnmount}
						headerAsLabel={this.state.headerAsLabel}
						listingTier={this.state.listingTier}
						listingTierName={this.state.listingTierName}
						messageLabels={this.state.messageLabels}
						searchValue={this.state.searchValue}
						setDetailsAction={this.setDetailsAction}
						setDetailsGrp={this.setDetailsGrp}
						setDetailsHasRendered={this.setDetailsHasRendered}
						setDetailsUUID={this.setDetailsUUID}
						showDetailsPage={this.state.detailsPage}
						temporarilyDisableButton={this.temporarilyDisableButton}
					/>
				}
			</div>
		);
	}
}

export default ListingsScroller;
