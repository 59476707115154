import React, {Component} from 'react';
import QRCode from 'qrcode.react';
import {
	condorComponentENV,
	envENV,
} from '../ENV';

class QRComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			css: props.CSSModules[props.condor_render_name]
		};
	}

	componentDidMount() { }

	render() {
		let css = this.state.css;

		// Custom classes
		let customClasses = '';
		if (this.props.custom_classes) {
			this.props.custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (this.props.modifier_classes) {
			this.props.modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		let keyValue = `qr${this.props.key_num}`;
		let qrURL = this.props.url;

		if (this.props.mobile_display === true) {
			// eventually this should be a straight pull of window._getEnv("MOBILE_URL") env var
			// but that's not set yet, so derive a sensible url from ENV
			let url = 'http://web.touchsource.com/';
			let env = envENV;
			if (env !== 'ciprod') {
				url = `http://web-${env}.touchsource.com/`;
			}
			if (this.props.mobile_qrcode_url && this.props.mobile_qrcode_url !== null && this.props.mobile_qrcode_url.length > 5) {
				url = this.props.mobile_qrcode_url;
			}

			qrURL = `${url}?id=${condorComponentENV}`;
			// console.log('mobile url: ' + qrURL);
		}

		return (
			<div id={`qrCode_${this.props.component_name}`} className={`qr_cont ${css.cont} ${modifierClasses} ${customClasses} ${css[`${this.props.format}`]}`} key={keyValue}>
				<div className={`${css[`${this.props.format}Box`]} qrCode`}>
					<QRCode
						bgColor={this.props.bg_color} // Color of the bright squares
						fgColor={this.props.fg_color} // Color of the dark squares
						level="L" // QR Error correction level: L, M, Q, H
						style={{width: '100%', height: '100%', border: `${this.props.border_width}px solid ${this.props.border_color}`}} // Size in pixels
						value={qrURL} // The url
					/>
				</div>
				<div className={`${css[`${this.props.format}Text`]} qrCodeText`}>{this.props.text}</div>
			</div>
		);
	}
}

export default QRComponent;
