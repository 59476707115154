import React, {PureComponent} from 'react';
import {CSSTransition} from 'react-transition-group';
import {withTranslation} from 'react-i18next';


import Keyboard from './Keyboard';
import ListingRow from './ListingRow';
import ListingHeader from './ListingHeader';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {dlListingSelect, dlSearch} from '../DataLayer';
import {sortListingsByCols, makeSortValues} from '../util/listings';

class Listings extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeButton:         '',
			adaTopPad:            0,
			columnWidths:         ['0'],
			css:                  props.CSSModules['Listings'],
			currentListings:      [ [''] ],
			currentHeader:        [''],
			currentDetailAction:  0,
			defaultList:          this.props.defaultListName,
			details:              [],
			detailsPage:          false,
			detailsUUID:          '',
			filteredListings:     [],
			isScrolling:          false,
			listingsNavButtons:   [],
			searchInputValue:     '',
			searchUpdater:        0,
			textAlign:            [],
			boxStyleDisplay:      false,
			boxColumnsPerRow:     3,
			boxActiveLayout:      0,
			transitions:          true
		};
		this.scrollTimeout = null;
		this.scrollWindow = React.createRef();
		this.scrollUpBtn = React.createRef();
		this.scrollDownBtn = React.createRef();
		this.activeScrollTimers = new Set();
		this.startScrolling = this.startScrolling.bind(this);
		this.stopScrolling = this.stopScrolling.bind(this);
	}

	handleAdaListingsPadding = () => {
		let listingsContainerHeight = this.scrollWindow.current.getBoundingClientRect().height;
		let adaTopPad = listingsContainerHeight - (window.innerHeight >= 2160 ? 600 : 300);
		this.setState({
			adaTopPad: adaTopPad
		});
		document.getElementById('listingsScroller').scrollTo({
			top: 0
		});
	}

	componentDidMount() {
		this.buildListingsNav();
		this.scheduleRefilterListings();
		this.setCurrentListings(this.state.defaultList);

		let etlData = this.props.defaultList.listings;
		window.completeListings = [];

		// temporary hack fix via timeout
		setTimeout(() => {
			window.completeListings.length = 0; // Clear the array completely
			this.state.listingsNavButtons.forEach((navButtonObj, i) => {
				window.completeListings.push({category: navButtonObj.dataName, listings: []});
				this.getCompleteListings(etlData, navButtonObj.dataName);
				window.completeListings[i].listings = Array.from(new Set(window.completeListings[i].listings.map(JSON.stringify)), JSON.parse);
			});

		}, 1000);

		if (this.props.navProps.adaActive) {
			setTimeout(() => {
				this.handleAdaListingsPadding();
			}, 500);
		}

		this.resizeObserver = new ResizeObserver(() => {
			const { css } = this.state;

			if (!(this.scrollUpBtn.current && this.scrollDownBtn.current && css.disabledButton)) {
				return;
			}

			if (this.scrollWindow.current?.scrollHeight > this.scrollWindow.current?.clientHeight) {
				css.disabledButton.split(' ').forEach((className) => {
					this.scrollUpBtn.current.classList.remove(className);
					this.scrollDownBtn.current.classList.remove(className);
				});
			} else if (this.scrollWindow.current?.scrollHeight <= this.scrollWindow.current?.clientHeight) {
				css.disabledButton.split(' ').forEach((className) => {
					this.scrollUpBtn.current.classList.add(className);
					this.scrollDownBtn.current.classList.add(className);
				});
			}
		});

		this.resizeObserver.observe(this.scrollWindow.current);
	}

	componentWillUnmount() {
		if (this.nextTimeout) {
			clearTimeout(this.nextTimeout);
		}
		if (this.scrollTimeout) {
			clearTimeout(this.scrollTimeout);
		}

		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
			this.resizeObserver = null;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.navProps.resetListings !== this.props.navProps.resetListings && this.props.navProps.resetListings === true) {
			this.setCurrentListings(this.state.defaultList);
			this.props.closeDetailsPage();
			this.props.setDetailsTitle('');
			this.props.setTieredHeadersAndDetails([], []);
			this.props.setHistory([]);
			this.props.navEvents('setHasNavigatedListings', false);
		}
		if (prevProps.navProps.adaActive !== this.props.navProps.adaActive) {
			if (this.props.navProps.adaActive === true) {
				setTimeout(() => {
					this.handleAdaListingsPadding();
				}, 500);
			} else {
				setTimeout(() => {
					this.setState({
						adaTopPad: 0
					});
					document.getElementById('listingsScroller').scrollTo({
						top: 0
					});
				}, 500);
			}
		}

		if (prevProps.defaultList !== this.props.defaultList) {
			let etlData = this.props.defaultList.listings;
			window.completeListings = [];

			// temporary hack fix via timeout
			setTimeout(() => {
				window.completeListings.length = 0; // Clear the array completely
				this.state.listingsNavButtons.forEach((navButtonObj, i) => {
					window.completeListings.push({category: navButtonObj.dataName, listings: []});
					this.getCompleteListings(etlData, navButtonObj.dataName);
					window.completeListings[i].listings = Array.from(new Set(window.completeListings[i].listings.map(JSON.stringify)), JSON.parse);
				});

			}, 1000);
		}

		if ((this.props.navProps.timeoutStatus !== prevProps.navProps.timeoutStatus && !this.props.navProps.timeoutStatus) && this.props.navProps.animationPhase === this.props.navProps.animationStartPhase) {
			this.setCurrentListings(this.state.defaultList);
		}

		if ((prevProps.defaultList !== this.props.defaultList)) {
			this.setCurrentListings(this.state.defaultList);
		}

		if (prevState.filteredListings !== this.state.filteredListings) {
			if (this.state.searchInputValue !== '') {
				dlSearch({
					searchTerm:      this.state.searchInputValue,
					numberOfResults: this.state.filteredListings.reduce((accum, curr) => {
						return accum + curr.listings.length;
					}, 0)
				});
			}
		}
	}

	componentWillUnmount = () => {
		clearTimeout(this.scrollTimeout);
		clearTimeout(this.nextTimeout);
	}

	findChildLabels = (listArray) => {
		let labels = window.listingsNav;

		if (Array.isArray(listArray) && listArray.length > 0) {
			listArray.forEach((item) => {
				if (item.colGroups.length > 0) {
					let name = item.colGroups[0].dataName;
					let btnObj = {
						dataName: name,
						label:    name
					};

					let labelOverride = item.tierConfig.mobileButtonLabel;
					if (labelOverride !== undefined && labelOverride !== null && labelOverride !== '') {
						btnObj.label = labelOverride;
					}

					if (labels.length === 0) {
						labels.push(btnObj);
					} else {
						let addToArray = true;
						// let newObj = {};
						labels.forEach((obj) => {
							// newObj = obj;
							if (obj.dataName === name || name === undefined || !item.tierConfig.showSubNavButton) {
								addToArray = false;
							}
						});
						if (addToArray === true) {
							labels.push(btnObj);
						}
					}
					this.findChildLabels(item.listings);
				}
			});
		}
		return;
	}

	buildListingsNav = () => {
		// hard coded for faster testing
		window.listingsNav = [];
		let listingsData = this.props.defaultList.listings;

		this.findChildLabels(listingsData);

		this.setState({
			listingsNavButtons: window.listingsNav
		});

	}

	handleDown = (buttonId) => {
		this.setState({activeButton: buttonId});
	}

	handleUp = () => {
		if (this.state.activeButton !== '') {
			this.setState({activeButton: ''});
		}
	}

	buttonEvents = (obj) => {
		this.props.navEvents('idleTimer', '||| Listings.js > createButtons()');
		this.props.navEvents('setHasNavigatedListings', true);
		this.props.closeDetailsPage();
		this.props.setDetailsTitle('');
		this.props.setTieredHeadersAndDetails([], []);
		this.props.setHistory([]);
		this.setCurrentListings(obj.dataName);
		this.setState({searchUpdater: this.state.searchUpdater + 1});
		dlListingSelect({
			listName: obj.dataName
		});
	}

	createButtons = () => {
		let {css, activeButton} = this.state;
		let buttons = [];
		let currentSelectedNav = this.state.currentHeader[0].replace(/[\s/&:!]+/g, '');

		this.state.listingsNavButtons.forEach((obj, i) => {
			const activeButtonClass = activeButton === obj.dataName ? `${css.activeButton} activeButton` : '';

			buttons.push(
				<div
					className={`listingsSecNavBtn ${currentSelectedNav + i} ${css.secNavBtn} ${activeButtonClass}`}
					// ------ Reverted this: Craig's touch fixes + Pedro's stopPropagation ---------------
					// onMouseDown={(e) => {
					// 	e.stopPropagation();
					// 	this.handleDown(obj.dataName);
					// 	this.props.navEvents(`btnDebouncer`, () => this.buttonEvents(obj));
					// }}
					// onMouseUp={this.handleUp}
					// onMouseLeave={this.handleUp}
					// onTouchStart={(e) => {
					// 	e.stopPropagation();
					// 	this.handleDown(obj.dataName);
					// 	this.props.navEvents(`btnDebouncer`, () => this.buttonEvents(obj));
					// }}
					// onTouchEnd={this.handleUp}
					// onPointerDown={(e) => {
					// 	e.stopPropagation();
					// 	this.handleDown(obj.dataName);
					// 	this.props.navEvents(`btnDebouncer`, () => this.buttonEvents(obj));
					// }}
					// onPointerUp={this.handleUp}
					// onPointerLeave={this.handleUp}

					// ------ Tier ButtonHotfix - reverted the code above ---------------
					onClick={(e) => {
						e.stopPropagation();
						this.props.navEvents('idleTimer', '||| Listings.js > createButtons()');
						this.props.navEvents('setHasNavigatedListings', true);
						this.props.closeDetailsPage();
						this.props.setDetailsTitle('');
						this.props.setTieredHeadersAndDetails([], []);
						this.props.setHistory([]);
						this.setCurrentListings(obj.dataName);
						this.setState({searchUpdater: this.state.searchUpdater + 1});
						dlListingSelect({
							listName: obj.dataName
						});
					}}
					onMouseDown={(e) => {
						e.stopPropagation();
						this.handleDown(obj.dataName);
					}}
					onMouseUp={this.handleUp}
					onMouseLeave={this.handleUp}
					onTouchStart={(e) => {
						e.stopPropagation();
						this.handleDown(obj.dataName);
					}}
					onTouchEnd={this.handleUp}
					key={`listBtn_${i}`}
				>{obj.label}</div>
			);
		});
		return buttons;
	}

	startScrolling = (direction) => {
		// Clear any existing timers first
		this.stopScrolling();
		
		const value = this.props.scrollButtons === 1 ? 2 : this.props.scrollDistance;
		const scrollInterval = this.props.scrollButtons === 1 ? 5 : 1000;
	
		// Do initial scroll for mode 2
		if (this.props.scrollButtons === 2) {
			this.scrollWindow.current.scrollTop += direction === 'down' ? value : -value;
		}
	
		// Create new timer
		const timerId = setInterval(() => {
			if (this.scrollWindow.current) {
			this.scrollWindow.current.scrollTop += direction === 'down' ? value : -value;
			}
		}, scrollInterval);
	
		// Store timer ID in Set
		this.activeScrollTimers.add(timerId);
	}

	stopScrolling = () => {
		// Clear all active timers
		this.activeScrollTimers.forEach(timerId => {
			clearInterval(timerId);
		});
		this.activeScrollTimers.clear();
	}
	
	handlePointerDown = (e, direction) => {
		e.preventDefault();
		// Check if it's already scrolling
		if (this.activeScrollTimers.size === 0) {
			this.startScrolling(direction);
		}
	}

	arrayDiffer = (row, arr) => {
		let itsTheSame = true;
		if (row[0] !== arr[0]) {
			itsTheSame = false;
		}
		return itsTheSame;
	}

	arrayContainsArray(arrayOfArrays, arr) {
		let equal = false;
		arrayOfArrays.forEach((row) => {
			if (Array.isArray(row) && Array.isArray(arr) && row.length === arr.length && this.arrayDiffer(row, arr)) {
				equal = true;
			}
		});
		return equal;
	}

	findListingByDataName(listings, targetDataName) {
		if (!Array.isArray(listings) || listings.length === 0) {
			return null;
		}

		for (const listing of listings) {
			if (listing.colGroups[0] && listing.colGroups[0].dataName === targetDataName) {
				return listing;
			}

			// Recursively search within nested listings
			const foundInChildren = this.findListingByDataName(listing.listings, targetDataName);
			if (foundInChildren) {
				return foundInChildren;
			}
		}

		// Return null if no matching listing is found
		return null;
	}

	getTierData = (listings, listName, addTier = false) => {
		let tier = 0;
		if (addTier) {
			tier++;
		}

		let listInfo = this.findListingByDataName(listings, listName) || listings[0];
		if (!listInfo || !listInfo.colGroups || listInfo.colGroups.length === 0) {
			return null;
		}

		let sortedCols = [...listInfo.colGroups].filter((c) => {
			if (c.sort > 0) {
				return true;
			}

			return false;
		});

		sortedCols.sort((i, j) => {
			if (i.sort > j.sort) {
				return 1;
			}

			return -1;
		});

		if (Array.isArray(listings) && listings.length > 0) {
			listings.forEach((item) => {
				if (item.colGroups[0] && item.colGroups[0].dataName === listName) {
					let row = [];
					let textAlign = [];
					let headerAsLabel = [];
					let touchAction = item.tierConfig.touchAction;

					item.sortedVals = [item.rank || 0];
					for (let col of sortedCols) {
						let itemVals = item.vals[col.order - 1];
						if (itemVals) {
							item.sortedVals.push(itemVals);
						}
					}

					// listings
					item.vals.forEach((listingData, i) => {
						row.push({
							value:       listingData,
							type:        item.types[i],
							displayName: item.displayNames[i],
							column_uuid: item.colGroups[i].m1Key,
							tier
						});
					});
					if (!this.arrayContainsArray(window.listingsShown, [item.m1Key, touchAction, ...row, item.tierConfig, item.detailsGrp, item.sortedVals])) {
						window.listingsShown.push([item.m1Key, touchAction, ...row, item.tierConfig, item.detailsGrp, item.sortedVals]);
					}

					// header
					let headerWidthsArr = [];
					let headerShownArr = [];

					item.colGroups.forEach((headerData) => {
						headerWidthsArr.push(headerData.width);
						headerShownArr.push(headerData.name);
					});

					this.setState({
						columnWidths:  headerWidthsArr,
						currentHeader: headerShownArr
					});
					window.headerWidths = headerWidthsArr;
					window.headerShown = headerShownArr;
					window.boxStyleDisplay = item.tierConfig.boxStyleDisplay;
					window.boxColumnsPerRow = item.tierConfig.boxColumnsPerRow;
					window.boxActiveLayout = item.tierConfig.boxActiveLayout;


					// text alignment

					item.colGroups.forEach((colGroup) => {
						headerAsLabel.push(colGroup.headerAsLabel);
						switch (colGroup.textAlign) {
						case 0:
							textAlign.push('left');
							break;
						case 1:
							textAlign.push('center');
							break;
						case 2:
							textAlign.push('right');
							break;
						default:
							textAlign.push('center');
							break;
						}
					});

					this.setState({
						textAlign:     textAlign,
						headerAsLabel: headerAsLabel
					});
					this.props.handleHeaderAsLabel(headerAsLabel);
					window.currentDetailAction = item.tierConfig.touchAction;
				}

				if (item.listings.length > 0) {
					this.getTierData(item.listings, listName, true);
				}
			});
		}
	}

	formatTime(i) {
		if (i < 10) {
			i = `0${i}`;
		}
		return i;
	}

	getTime() {
		let now = new Date();
		let h = now.getHours();
		let m = now.getMinutes();
		let s = now.getSeconds();

		h = this.formatTime(h);
		m = this.formatTime(m);
		s = this.formatTime(s);

		let time = `${h}:${m}:${s}`;
		if (time.length === 7) {
			time = '0' + time;
		}
		return time;
	}

	schedulingFilter(objItem) {
		const m1Key = objItem[0];

		const schedulingJSON = window.flatData?.[m1Key]?.listing_scheduling;

		if (schedulingJSON === undefined) {
			return true;
		}

		let parsedSchedulingJSON;

		try {
			parsedSchedulingJSON = JSON.parse(schedulingJSON);
		} catch (err) {
			console.error('Failed to parse schedulingJSON', err);
			return true;
		}

		if (!parsedSchedulingJSON.scheduling) {
			return true;
		}

		if (parsedSchedulingJSON.use_dates) {
			if (parsedSchedulingJSON.start_date) {
				const now = new Date().toISOString();
				const startDate = parsedSchedulingJSON.start_date;
				if (startDate && now < startDate) {
					// console.debug('SKIP(start_date expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.start_date);
					return false;
				}
			}

			// NOTE: The end_date is supposed to represent the final day a slide will show. The date a slide expires is the next day.
			if (parsedSchedulingJSON.end_date) {
				const now = new Date().toISOString();
				const endDate = new Date(parsedSchedulingJSON.end_date);
				let dateToExpire = endDate.setDate(endDate.getDate() + 1);
				dateToExpire = new Date(dateToExpire).toISOString();
				if (dateToExpire && now > dateToExpire) {
					// console.debug('SKIP(end_date expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.end_date);
					return false;
				}
			}
		}

		if (parsedSchedulingJSON.use_times) {
			const now = this.getTime();

			if (parsedSchedulingJSON.start_time) {
				let startTime = parsedSchedulingJSON.start_time;
				if (startTime.length === 7) {
					startTime = '0' + startTime;
				}
				if (now < startTime) {
					// console.debug('SKIP(start_time expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.start_time);
					return false;
				}
			}

			if (parsedSchedulingJSON.end_time) {
				let endTime = parsedSchedulingJSON.end_time;
				if (endTime.length === 7) {
					endTime = '0' + endTime;
				}

				if (now > endTime) {
					// console.debug('SKIP(end_time expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.end_time);
					return false;
				}
			}
		}

		if (parsedSchedulingJSON.use_days) {
			const today = new Date().getDay();
			const days = parsedSchedulingJSON.days;
			if (days && Array.isArray(days) && !~days.indexOf(today)) {
				// console.debug('SKIP(use_days expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.days);
				return false;
			}
		}

		return true;
	}

	scheduleRefilterListings() {
		// const fifteenMinutes = 15 * 60 * 1000;
		const oneMinute = 1 * 60 * 1000;
		const thirtySeconds = 30 * 1000;
		const now = Date.now();
		const currentWindow = now - (now % oneMinute);
		const nextWindow = currentWindow + oneMinute + thirtySeconds;
		const timeUntilNextWindow = nextWindow - now;

		if (this.nextTimeout) {
			clearTimeout(this.nextTimeout);
		}

		this.nextTimeout = setTimeout(() => {
			this.refilterListings();
		}, timeUntilNextWindow);
	}

	refilterListings() {
		const currentListings = this.state.currentListings.filter(this.schedulingFilter.bind(this));

		this.setState({
			currentListings:  currentListings,
			boxStyleDisplay:  window.boxStyleDisplay,
			boxColumnsPerRow: window.boxColumnsPerRow,
			boxActiveLayout:  window.boxActiveLayout
		});
		this.props.handleBoxData(window.boxStyleDisplay, window.boxColumnsPerRow, window.boxActiveLayout);

		this.scheduleRefilterListings();
	}

	setCurrentListings = (listName) => {
		let etlData = this.props.defaultList.listings;
		window.listingsShown = [];
		window.currentDetailAction = 0;

		// add sortedVals
		this.getTierData(etlData, listName);

		let currentListings = window.listingsShown.filter(this.schedulingFilter.bind(this));

		// sort using the sortedVals index
		currentListings.sort(sortListingsByCols);

		// remove sortedVals so details will render properly
		currentListings = currentListings.map((listing) => {
			listing.pop();

			return listing;
		});

		this.setState({
			currentListings:     currentListings,
			currentDetailAction: window.currentDetailAction,
			boxStyleDisplay:     window.boxStyleDisplay,
			boxColumnsPerRow:    window.boxColumnsPerRow,
			boxActiveLayout:     window.boxActiveLayout
		});

		this.props.handleBoxData(window.boxStyleDisplay, window.boxColumnsPerRow, window.boxActiveLayout);
	}

	initializeListings = () => {
		this.setAdaTransitions();

		this.setState({
			details: false
		});
	}

	setAdaTransitions = () => {
		// allows React Transition Group to run first, then set transition
		if (this.props.weakProcessor === false) {
			setTimeout(() => {
				this.setState({
					transitions: true
				});
			}, 1000);
		}
	}

	getSearchAtScaleSetting = () => {
		let searchAtScaleSetting = '';
		let searchAtScale = false;

		for (const [m1Key, m1Data] of Object.entries(window.flatData)) {
			if (m1Key.startsWith('setting.')) {
				if (m1Data.name === 'search_at_scale') {
					searchAtScaleSetting = m1Key;
					break;
				}
			}
		};

		searchAtScale = window.flatData[`component.${this.props.component_name}`][searchAtScaleSetting]?.bool;

		return searchAtScale;
	}

	createRows = () => {
		let rows = [];
		let css = this.state.css;
		let numberOfResults = 0;

		let searchAtScale = this.getSearchAtScaleSetting();
		let searchAtScaleInstructions = '';

		for (const [m1Key, m1Data] of Object.entries(window.flatData)) {
			if (m1Key.startsWith('setting.')) {
				if (m1Data.name === 'search_at_scale_instructions') {
					searchAtScaleInstructions = m1Key;
					break;
				}
			}
		};

		if (searchAtScale && this.state.currentListings.length > 100) {
			let containerStyles = {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				fontStyle: 'italic'
			};

			return (
				<div style={containerStyles} className='searchAtScaleContainer'>
					{window.flatData[`component.${this.props.component_name}`][searchAtScaleInstructions]?.string || 'Type to search.'}
				</div>
			)
		}

		// For translation
		const {t} = this.props;

		if (!this.state.boxStyleDisplay) {
			rows.push(
				<div className={`listingsHeader ${css.header}`} key='listingsHeaderLine'>
					<ListingHeader
						{...this.props}
						key={'test'}
						data={this.state.currentHeader}
						css={this.state.columnWidths}
						textAlign={this.state.textAlign}
					/>
				</div>
			);
		}
		let boxStyleDetails;
		this.state.currentListings.forEach((row, i) => {
			let rowUUID = row[0];
			let touchAction = row[1];
			let listingTier = row[row.length - 2]?.listingTier || 0;
			boxStyleDetails = row[row.length - 2]

			let listingsWithoutUUID = row.slice(2);
			let detailsGrp = listingsWithoutUUID.pop();

			if (numberOfResults < 500) {
				numberOfResults++;
				//special handling for Tier+1
				let addToHistory = this.props.addToHistory;
				if (touchAction !== 10) {
					addToHistory = null;
				}

				const { defaultList, ...propsWithoutDefaultList } = this.props;

				rows.push(
					<ListingRow
						{...propsWithoutDefaultList}
						key={`row_${i}`}
						data={listingsWithoutUUID}
						headerData={this.state.currentHeader}
						detailsGrp={detailsGrp}
						css={this.state.columnWidths}
						currentDetailAction={touchAction}
						rowUUID={rowUUID}
						boxStyleDisplay={boxStyleDetails?.boxStyleDisplay}
						boxColumnsPerRow={boxStyleDetails?.boxColumnsPerRow + 1}
						boxActiveLayout={boxStyleDetails?.boxActiveLayout + 1}
						setDetailsData={this.props.setDetailsData}
						detailsTitle={this.props.details[0]}
						setDetailsTitle={this.props.setDetailsTitle}
						textAlign={this.state.textAlign}
						headerAsLabel={this.state.headerAsLabel}
						addToHistory={addToHistory}
						displayChildTier={this.props.displayChildTier}
						messageLabels={this.props.messageLabels}
						num={i}
						listingTier={listingTier}
					/>
				);
			}
		});

		if (numberOfResults >= 500) {
			rows.unshift(<p className={`listingsOverlimit ${css.overLimit}`}>{t('listingsTooManyResultsError')}</p>);
		}

		if (boxStyleDetails?.boxStyleDisplay) {
			rows = (
				<div className={`${css.boxContainer} listingsBoxContainer`}>
					{rows}
				</div>
			);
		}

		return (
			rows
		);
	}

	createFilteredRows = () => {
		let css = this.state.css;
		let categoryRowsMap = {};
		let filteredListings = this.state.filteredListings;
		let searchAtScale = this.getSearchAtScaleSetting();

		let searchAtScaleTooManyResults = '';

		for (const [m1Key, m1Data] of Object.entries(window.flatData)) {
			if (m1Key.startsWith('setting.')) {
				if (m1Data.name === 'search_at_scale_too_many_results') {
					searchAtScaleTooManyResults = m1Key;
					break;
				}
			}
		};


		if (searchAtScale) {
			let containerStyles = {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				fontStyle: 'italic'
			};

			let totalNumberOfListings = 0;

			filteredListings.forEach((category, i) => {
				totalNumberOfListings += category.listings.length	
			})

			if (totalNumberOfListings > 100 * filteredListings.length) {
				return (
					<div style={containerStyles} className='searchAtScaleContainer'>
						{totalNumberOfListings} {window.flatData[`component.${this.props.component_name}`][searchAtScaleTooManyResults]?.string || 'results found. Keep typing to narrow down your search.'}
					</div>
				)
			}
		}

		// For translation
		const {t} = this.props;
	
		filteredListings.forEach((category, i) => {
			if (!filteredListings[i].listings) {
				filteredListings[i].listings = [];
				return;
			}
	
			filteredListings[i].listings = [...filteredListings[i].listings]
				.filter(this.schedulingFilter.bind(this));
		});
	
		let numberOfResults = 0;
	
		filteredListings.forEach((category, i) => {
			let categoryRows = [];
			let categoryBoxStyleDetails = {};
			if (category.listings.length > 0) {
				if (numberOfResults < 500) {
					let textAlign = [];
					categoryBoxStyleDetails.boxStyleDisplay = category.listings[0][category.listings[0].length - 2]?.boxStyleDisplay
					categoryBoxStyleDetails.boxColumnsPerRow = category.listings[0][category.listings[0].length - 2]?.boxColumnsPerRow

					category.category.textAlign.forEach((alignment) => {
						switch (alignment) {
						case 0:
							textAlign.push('left');
							break;
						case 1:
							textAlign.push('center');
							break;
						case 2:
							textAlign.push('right');
							break;
						default:
							textAlign.push('center');
							break;
						}
					});
	
					if (!categoryBoxStyleDetails.boxStyleDisplay) {
						categoryRows.push(
							<div className={`listingsHeader ${css.header}`} key={`header_${i}`}>
								<ListingHeader
									{...this.props}
									data={category.category.headersShown}
									css={category.category.headersWidths}
									textAlign={this.state.textAlign}
								/>
							</div>
						);
					} else {
						let boxHeaderArr = [category.category.category];
						categoryRows.push(
							<div className={`listingsHeader ${css.header}`} key={`box_header_${i}`}>
								<ListingHeader
									{...this.props}
									data={boxHeaderArr}
									css={this.state.columnWidths}
									textAlign={this.state.textAlign}
								/>
							</div>
						);
					}
				}
			}
	
			category.listings.forEach((row, j) => {
				if (numberOfResults < 500) {
					let rowUUID = row[0];
					let listingsWithoutUUID = row.slice(1);
					let detailsGrp = listingsWithoutUUID.pop();
					let boxActiveLayout = row[row.length - 2]?.boxActiveLayout
					let textAlign = [];
					category.category.textAlign.forEach((alignment) => {
						switch (alignment) {
						case 0:
							textAlign.push('left');
							break;
						case 1:
							textAlign.push('center');
							break;
						case 2:
							textAlign.push('right');
							break;
						default:
							textAlign.push('center');
							break;
						}
					});
	
					numberOfResults++;

					const { defaultList, ...propsWithoutDefaultList } = this.props;
					categoryRows.push(
						<ListingRow
							{...propsWithoutDefaultList}
							key={`filtered_row_${i}_${j}`}
							data={listingsWithoutUUID}
							detailsGrp={detailsGrp}
							css={category.category.headersWidths}
							currentDetailAction={category.category.detailAction}
							headerData={category.category.headersShown}
							headerAsLabel={category.category.headerAsLabel}
							rowUUID={rowUUID}
							boxStyleDisplay={categoryBoxStyleDetails.boxStyleDisplay}
							boxColumnsPerRow={categoryBoxStyleDetails.boxColumnsPerRow + 1}
							boxActiveLayout={boxActiveLayout + 1}
							setDetailsData={this.props.setDetailsData}
							detailsTitle={this.props.details[0]}
							setDetailsTitle={this.props.setDetailsTitle}
							textAlign={textAlign}
							displayChildTier={this.props.displayChildTier}
							messageLabels={this.props.messageLabels}
							num={j}
							searchInputValue={this.state.searchInputValue}
							tier={i + 1}
						/>
					);
				}
			});

			if (categoryBoxStyleDetails.boxStyleDisplay) {
				if (categoryRows.length > 0) {
					categoryRows = (
						<div className={`${css.boxContainer} listingsBoxContainer`} key={`box_container_${i}`}>
							{categoryRows}
						</div>
					);
				}
			}

			if (categoryRows.length > 0 ||   React.isValidElement(categoryRows)) {
				categoryRowsMap[i] = categoryRows;
			}
		});
	
		let mergedRows = [];	
		Object.keys(categoryRowsMap).forEach((category) => {
			mergedRows = mergedRows.concat(categoryRowsMap[category]);
		});
	
		if (mergedRows.length === 0) {
			mergedRows.push(
				<div className={`listingsNoMatchingResults ${css.noMatchingResults}`} key={`key_no_results`}>
					No matching results found
				</div>
			);
		}
	
		if (numberOfResults >= 500) {
			mergedRows.unshift(
				<p className={`listingsOverLimit ${css.overLimit}`} key={`key_over_limit`}>
					{t('listingsTooManyResultsError')}
				</p>
			);
		}

		return (
			mergedRows
		);
	}

	buildContent = () => {
		return (
			<CSSTransition
				in={this.state.detailsPage === false}
				appear={this.state.detailsPage === false}
				timeout={1000}
				unmountOnExit
				classNames='contentFade'>
				<>
					{this.state.searchInputValue === '' ? this.createRows() : this.createFilteredRows()}
				</>
			</CSSTransition>
		);
	}

	handleListingsScroll = () => {
		 // Clear the previous timer, if it exists
		 if (this.scrollTimeout) {
			clearTimeout(this.scrollTimeout);
		}

		// Set scrolling state to true
		this.setState({isScrolling: true});

		// Set a timer to detect when scrolling stops
		this.scrollTimeout = setTimeout(() => {
			this.setState({isScrolling: false});
			// This is where you can trigger your desired action
			console.log('Scrolling has stopped!');
			this.props.navEvents('idleTimer', '||| Listings.js > handleListingsScroll()');
		}, 150); // 150ms delay, you can adjust this value

	}

	closeDetailsPage = () => {
		this.setState({
			detailsPage: false
		});
	}

	setSearchInputValue = (value) => {
		this.setState({searchInputValue: value});
		this.props.setSearchValue(value);
		if (value === '') {
			this.setState({filteredListings: []});
			return;
		}
		this.searchListings(value);
	}

	searchListings = (value) => {
		let filteredListings = [];
		window.completeListings.forEach((category, i) => {
			filteredListings.push({category: category, listings: []});

			const sortValues = makeSortValues(category.sortValues);

			// add sortedVals
			category.listings = category.listings.map((listing) => {
				const sortedVals = [listing.rank || 0];

				for (let sortIndex of sortValues) {
					sortedVals.push(listing[sortIndex].value);
				}
				listing.push(sortedVals);

				return listing;
			});

			// sort by sortedVals
			category.listings.sort(sortListingsByCols);

			// remove sortedVals
			category.listings = category.listings.map((listing) => {
				listing.pop();

				return listing;
			});

			category.listings.forEach((listingRow) => {
				listingRow.forEach((listingElement, index) => {
					if (index > 0 && index < listingRow.length - 2) {
						if (listingElement.value.toLowerCase().includes(value.toLowerCase()) && filteredListings[i].listings[filteredListings[i].listings.length - 1] !== listingRow) {
							filteredListings[i].listings.push(listingRow);
						}
					}
				});
			});
		});
		this.setState({filteredListings: filteredListings});
	}

	getCompleteListings = (listings, listName) => {
		if (listings.length > 0) {
			listings.forEach((item) => {
				if (item.colGroups[0] && item.colGroups[0].dataName === listName) {
					let row = [];
					let headerWidths = [];
					let headerShown = [];
					let textAlign = [];
					let headerAsLabel = [];
					let sortValues = [];
					let detailAction = item.tierConfig.touchAction;

					// listings
					item.vals.forEach((listingData, i) => {
						row.push({
							value:       listingData,
							type:        item.types[i],
							displayName: item.displayNames[i],
							column_uuid: item.colGroups[i].m1Key,
						});
					});

					window.completeListings.forEach((category, i) => {
						if (category.category === listName) {
							window.completeListings[i].listings.push([item.m1Key, ...row, item.tierConfig, item.detailsGrp]);
						}
					});

					// header & text
					item.colGroups.forEach((headerData) => {
						headerWidths.push(headerData.width);
						headerShown.push(headerData.name);
						textAlign.push(headerData.textAlign);
						headerAsLabel.push(headerData.headerAsLabel);
						sortValues.push(headerData.sort);
					});

					window.completeListings[window.completeListings.length - 1].headersWidths = headerWidths;
					window.completeListings[window.completeListings.length - 1].headersShown = headerShown;
					window.completeListings[window.completeListings.length - 1].textAlign = textAlign;
					window.completeListings[window.completeListings.length - 1].headerAsLabel = headerAsLabel;
					window.completeListings[window.completeListings.length - 1].detailAction = detailAction;
					window.completeListings[window.completeListings.length - 1].sortValues = sortValues;
				}

				if (item.listings.length > 0) {
					this.getCompleteListings(item.listings, listName);
				}
			});
		}
	}


	render() {
		let css = this.state.css;
		let adaClass = '';
		let cssTransition = '';
		// t function for translation.
		const {t} = this.props;
		if (this.props.navProps.adaActive === true) {
			adaClass = css.adaActive;
		}

		if (this.state.transitions === true) {
			cssTransition = css.adaTransition;
		}

		let adaTopPad = {
			paddingTop: this.state.adaTopPad
		};

		let message = '';
		let listingsScrollerMessage = '';
		if (window.currentDetailAction !== 0) {
			listingsScrollerMessage = css.listingsScrollerMessage;

			let messageText = t('selectListingLabel');
			if (window.currentDetailAction === 7) {
				messageText = t('selectMapListingLabel');
			}
			
			if(this.props.list_scroller_instructions && this.props.list_scroller_instructions !== ''){
				messageText = this.props.list_scroller_instructions;
			}
			
			message = <div onScroll={() => {
				return this.handleListingsScroll();
			}} className={`listingsDirectionsMessage ${css.directionsMessage}`}><div className={`${css.directionsIcon} directionsIcon`}></div>{messageText}</div>;
		}

		let scrollType = '';
		if (this.props.scrollButtons === 2) {
			scrollType = css.smoothScroll;
		}

		return (
			<CSSTransition
				in={true}
				appear={true}
				timeout={1000}
				unmountOnExit
				onEntering={this.initializeListings}
				onExited={this.closeDetailsPage}
				classNames='contentFade'>
				<>
					<div className={`${adaClass}`}>
						<div id="listingsMenuBarLeft" className={`listingsContentMenuBarLeft ${css.contentMenuBarLeft} ${cssTransition}`}></div>
						<div id="listingsMenuBarRight" className={`listingsContentMenuBarRight ${css.contentMenuBarRight} ${cssTransition}`}>
							<div className={`listingsKeyboardContainer ${css.keyboardContainer}`}>
								<div className={`listingsSearchInputContainer ${css.searchInputContainer}`}>
									<FontAwesomeIcon icon={faSearch} className={`listingsSearchIcon ${css.searchIcon}`} />
									<input
										type="text"
										defaultValue={this.state.searchInputValue}
										className={`listingsSearchInput ${css.searchInput}`}
										placeholder={t('searchPlaceholder')}
										onClick={(e) => {
											this.props.navEvents('idleTimer', '||| Listings.js > render()');
											e.preventDefault();
											this.setState({isKeyboardHidden: false});
										}}
									/>
								</div>
								<Keyboard
									navEvents={this.props.navEvents}
									navProps={this.props.navProps}
									closeDetailsPage={this.props.closeDetailsPage}
									defaultKeyboard={this.props.defaultKeyboard}
									setDetailsTitle={this.props.setDetailsTitle}
									setTieredHeadersAndDetails={this.props.setTieredHeadersAndDetails}
									setHistory={this.props.setHistory}
									setInputValue={this.setSearchInputValue}
									searchUpdater={this.state.searchUpdater}
									condor_component_group={this.props.condor_component_group}
									condor_configuration={this.props.condor_configuration}
									condor_theme={this.props.condor_theme}
									CSSModules={this.props.CSSModules}
								/>
							</div>
						</div>

						<div id="secondaryNav" className={`listingsSecondaryNav ${css.secondaryNav} ${cssTransition}`}>
							{this.createButtons()}
						</div>

						<div className={`listingsListingsContainer ${css.listingsContainer} ${cssTransition} ${this.props.defaultListName.toLowerCase()}`}>
							<CSSTransition
								in={this.props.detailsPage === false}
								appear={this.props.detailsPage === false}
								timeout={1000}
								classNames={`contentFade`}>
								<div style={{width: '100%', height: '100%'}}>
									<div id='listingsScroller' ref={this.scrollWindow} className={`listingsListingsScroller ${css.listingsScroller} ${scrollType} ${listingsScrollerMessage}`} style={adaTopPad} onScroll={this.handleListingsScroll} >
										{this.props.tieredDetails.length > 0 && this.props.detailsTitle !== '' && <p className={`listingsDetailsTitle ${css.detailsTitle}`}>{this.props.detailsTitle}</p>}
										{this.props.tieredDetails.length === 0 && this.buildContent()}
										{this.props.tieredHeaders}
										{/* this is done cause state and props based boxStyleDisplay is unreliable */}
										{this.props.tieredDetails.length > 0 && this.props.tieredDetails[this.props.tieredDetails.length - 1]?.props?.boxStyleDisplay ? (
          									<div className={`${css.boxContainer} listingsBoxContainer`}>
           										{this.props.tieredDetails}
         									</div>
       									 ) : (
         									this.props.tieredDetails
       	 								)}
										{message}
										<div className={`${css.scrollBtnCont} scrollBtnCont`}>
											<div className={`${css.scrollBtn} scrollBtn`}
												onContextMenu={(e) => {
													e.preventDefault();
												}}
												onPointerDown={(e) => {
													e.preventDefault();
													this.handlePointerDown(e, 'up')
												}}
												onPointerUp={this.stopScrolling}
												onPointerLeave={this.stopScrolling}
												onPointerOut={this.stopScrolling}
												ref={this.scrollUpBtn}
											>
												<div className={`${css.scrollArrowUp} ${css.scrollArrow} scrollArrowUp scrollArrow`}></div>
												<div className={`${css.scrollBtnText} scrollBtnText`}>Scroll Up</div>
											</div>
											<div className={`${css.scrollBtn} scrollBtn`}
												onContextMenu={(e) => {
													e.preventDefault();
												}}
												onPointerDown={(e) => {
													e.preventDefault();
													this.handlePointerDown(e, 'down')
												}}
												onPointerUp={this.stopScrolling}
												onPointerLeave={this.stopScrolling}
												onPointerOut={this.stopScrolling}
												ref={this.scrollDownBtn}
											>
												<div className={`${css.scrollArrowDown} ${css.scrollArrow} scrollArrowDown scrollArrow`}></div>
												<div className={`${css.scrollBtnText} scrollBtnText`}>Scroll Down</div>
											</div>
										</div>
									</div>
								</div>
							</CSSTransition>
						</div>

					</div>

					<div>
						{
							this.props.isDetailsPage &&
							(
								this.props.tieredDetails ?
									(<div>
										{this.props.tieredHeaders}
										{this.props.tieredDetails}
									</div>) :
									this.buildContent()
							)
						}
					</div>
				</>
			</CSSTransition>

		);
	}
}

export default withTranslation()(Listings);
