// '' is before '*' in the sort order
const sortEmptyStringsFirst = true;

// a/b.sortedVals = [rank, col1, col2, col3, ...] in the proper order
// if this is provided, it will multi-sort by the columns in order
export const sortListingsByCols = function(a, b) {
	// when listings are filtered(searched) they come through as arrays
	// the last element in the array is the sortedVals array, and we just
	// create the object here to make it easier to work with
	if (Array.isArray(a) && Array.isArray(b)) {
		a = {sortedVals: a[a.length - 1]};
		b = {sortedVals: b[b.length - 1]};
	}

	for (let i = 0; i < a.sortedVals.length; i++) {
		let aVal = a.sortedVals[i];
		let bVal = b.sortedVals[i];

		if (sortEmptyStringsFirst) {
			if (aVal === '' && bVal !== '') {
				return -1;
			}

			if (aVal !== '' && bVal === '') {
				return 1;
			}
		}

		const isNumber = !isNaN(parseFloat(aVal)) && isFinite(aVal) && !isNaN(parseFloat(bVal)) && isFinite(bVal);

		aVal = isNumber ? parseFloat(aVal) : aVal.toLowerCase();
		bVal = isNumber ? parseFloat(bVal) : bVal.toLowerCase();

		if (aVal < bVal) {
			return -1;
		}

		if (aVal > bVal) {
			return 1;
		}
	}

	return 0;
};

/**
 * Sorts an array of numerical values and returns an array of indices indicating
 * the original positions of each value in sorted order.
 *
 * For example, given the input array `[2, 3, 0]`, the function will return `[3, 1, 2]`,
 * meaning that `0` (smallest value) was originally at index 3, `2` (second smallest) was
 * at index 1, and `3` (largest) was at index 2.
 *
 * This function is useful when you want to identify the original indices of the sorted elements
 * instead of sorting the array directly.
 *
 * @param {number[]} arr - An array of numbers to be sorted and analyzed.
 * @returns {number[]} - An array of indices representing the original positions of each sorted value.
 */
export const makeSortValues = function(arr) {
	const indexedValues = arr.map((value, index) => {
		return {index: index, value: value};
	});

	indexedValues.sort((a, b) => {
		return a.value - b.value;
	});

	const sortedIndices = indexedValues.map((pair) => {
		return pair.index + 1;
	});

	return sortedIndices;
};

export const getLowestSortValue = (colGroups) => {
	if (!Array.isArray(colGroups) || colGroups.length === 0) {
		return [0, 0]; // or other appropriate default values
	}

	let index = 0;

	const lowestSortValue = colGroups.reduce((lowestValueYet, currentValue, i) => {
		if (currentValue?.sort === 0) {
			return lowestValueYet;
		}

		if (currentValue?.sort < lowestValueYet) {
			index = i;
			return currentValue.sort;
		}

		return lowestValueYet;
	}, Infinity);

	if (lowestSortValue === Infinity) {
		return [0, 0];
	}

	return [lowestSortValue, index];
};