import React, { PureComponent } from 'react'
// import style from './ListingRow.module.css';

class ListingHeader extends PureComponent {

	constructor(props) {
		super(props)

		this.state = {
			configuration: 0,
			css: props.CSSModules["ListingRow"],
		}
	}

	componentDidMount() {
		// this.setMenuBarHeights();
	}

	createData = () => {
		let css = this.state.css;
		let row = [];
		let rowCss = this.props.css;
		
		this.props.data.forEach((data, i) => {
			let lineCss = {
				width: '0%'
			}
			if (typeof this.props.css !== 'undefined') {
				lineCss = {
					width: rowCss[i] + '%',
				}
				
				if (this.props.textAlign) {
					lineCss = {
						...lineCss, 
						textAlign: this.props.textAlign[i],
					}
				}
			}
			if (lineCss.width !== '0%') {
				row.push(
					<div className={`listingHeaderRowData_${i} ${css.rowData} ${css[`rowData_${i}`]}`} data-key={`listingHeader${i}`} key={`listingHeader${i}`} style={lineCss}>{data}</div>
				)
			}
		})
		return row;
	}

	render() {
		let css = this.state.css;
		return (
			<div className={`listingHeaderHeader ${css.listingHeader}`}>
				{this.createData()}
			</div>
		)
	}
}

export default ListingHeader