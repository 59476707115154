import React, {Component} from 'react';
import ListingGroupCustom1 from './ListingGroupCustom1';

import {sortListingsByCols} from '../util/listings.js';
import { getIntegerIconLabel } from '../helper/IconOptions';

const getColumnsAndListings = function(tmpObj, isCombined = false, isFirst = false, tierColGroups = []) {
	let listings = [];

	// Don't mutate props/state
	let obj = JSON.parse(JSON.stringify(tmpObj));

	if (obj.listings) {
		obj.listings.forEach((listing) => {
			// Only override tierColGroups if there is a length of listing.colGroups
			if (listing.colGroups.length > 0) {
				tierColGroups = listing.colGroups;
				listings.push(listing);

				if (isCombined) {
					(listing.listings || []).forEach((childListing) => {
						childListing.colGroups = tierColGroups;
						listings.push(childListing);
					});
					listing.listings = [];
				}
			} else {
				let [childColGroups, childListings] = getColumnsAndListings(listing, false, false, tierColGroups);
				tierColGroups = childColGroups;
				listings.push(...childListings);
			}
		});
	}

	let sortedCols = [...tierColGroups].filter((c) => {
		if (c.sort > 0) {
			return true;
		}

		return false;
	});

	sortedCols.sort((i, j) => {
		if (i.sort > j.sort) {
			return 1;
		}

		return -1;
	});

	listings = listings.map((listing) => {
		listing.sortedVals = [listing.rank || 0];

		for (let col of sortedCols) {
			listing.sortedVals.push(listing.vals[col.order - 1]);
		}

		return listing;
	});

	listings.sort(sortListingsByCols);

	return [tierColGroups, listings];
};

const getFilteringDefinitions = (fd, listingGroupUUID) => {
	let listingSchedulingToggleSetting;
	let listingColumnGroup;
	let listingConfig;
	let isFilteringOn = false;

	for (const m1Key in fd) {

		if (fd[m1Key]?.name === 'listing_scheduling_toggle') {
			listingSchedulingToggleSetting = m1Key;
		}

		if (listingSchedulingToggleSetting) {
			break;
		}
	}

	for (const m1Key in fd[listingGroupUUID]) {
		if (m1Key.startsWith('listing_column_group.')) {
			listingColumnGroup = m1Key;

			for (const m2Key in fd[listingColumnGroup]) {
				if (m2Key.startsWith('listing_config.')) {
					listingConfig = m2Key;
					break;
				}
			}

			if (listingConfig) {
				break;
			}
		}
	}

	if (listingConfig) {
		for (const m1Key in fd[listingConfig]) {
			if (m1Key === listingSchedulingToggleSetting) {
				isFilteringOn = fd[listingConfig][m1Key]?.bool;
				break;
			}
		}
	}

	return isFilteringOn;
};

class ListingGroup extends Component {
	constructor(props) {
		super(props);

		this.listingCustom1Ref = React.createRef();

		let [columns] = getColumnsAndListings(props);

		const isFilteringOn = getFilteringDefinitions(window.flatData, props.listingGroupUUID);
		this.isFilteringOn = isFilteringOn;

		this.state = {
			columnWidths:       '100%',
			css:                props.CSSModules[props.condor_render_name],
			listings:           [],
			data:               props.condor_component_list,
			logs:               [],
			settings:           props,
			numColumnsNew:      1,
			configuration:      0,
			listingsColumnData: columns.map((col) => {
				return {
					header:    col.name,
					width:     `${col.width}%` || '0%',
					textAlign: col.textAlign
				};
			}),
			sideGapWidthLeft:    props.side_gap_width_left || `5%`,
			sideGapWidthRight:   props.side_gap_width_right || `5%`,
			parentIconIndent:    props.parent_icon_indent || `0%`,
			childIconIndent:     props.child_icon_indent || `4%`,
			gridTemplateColumns: ``
		};
	}


	componentDidMount() {
		setTimeout(() => {
			this.buildContent();
		}, 800);

		// vert_line_color placeholder
		if (this.props.vert_line_color && this.props.vert_line_color.length > 0) {
			document.documentElement.style.setProperty('--vertLineColor', this.props.vert_line_color);
		}
		if (this.props.show_listing_border && this.props.show_listing_border_color) {
			document.documentElement.style.setProperty('--listingBorderColor', this.props.show_listing_border_color);
		}

		// console.log('88+++++ Component Mounted +++++++');
		let modifiedData = this.props.listings.map(function(listing) {
			return listing;
		});

		if (this.props.combined === true) {
			modifiedData.forEach((listing) => {
				if (listing.props && listing.props.condor_component_list) {
					listing.props.condor_component_list.forEach((child, ii) => {
						modifiedData.push(child);
						// console.log('index', ii, 'sorted child', child.props.component_name, 'name', child.props.listing_name);
					});
				}
			});
		}
		// console.log(`DATA`, modifiedData);
		this.setState({
			numColumnsNew: this.props.num_columns
		});

		this.setColumnWidths();
	}

	componentWillUnmount() {
		clearTimeout(this.buildDomTimeout);
		clearTimeout(this.fontLoadedTimeout);
		clearTimeout(this.nextTimeout);
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.listings) !== JSON.stringify(this.props.listings) ||
			JSON.stringify(prevProps.settings) !== JSON.stringify(this.props.settings) ||
			prevProps.navProps.loadedCustomCSS !== this.props.navProps.loadedCustomCSS) {

			// console.log('=============== ListingGroup componentDidUpdate ===============');
			document.getElementById(this.props.listingGroupID).classList.add('dontLook');

			let [columns] = getColumnsAndListings(this.props);
			let listingsColumnData = columns.map((col) => {
				return {
					// dataName: col.name.toLowerCase(),
					header:    col.name,
					width:     `${col.width}%` || '0%',
					textAlign: col.textAlign
				};
			});

			this.setState({
				listings:           [],
				numColumnsNew:      this.props.num_columns,
				listingsColumnData: listingsColumnData
			});

			this.setColumnWidths(listingsColumnData);

			// Clear styles off cont when transitioning from font_size_fixed value, to a value of zero
			// there is potenitial to optimize this
			let cont = document.getElementById(`listingContainer_${this.props.component_name}`);
			let fontSize = 16;

			if (this.props.font_size) {
				fontSize = this.props.font_size;
			}

			if (this.props.fixed_font_size && this.props.fixed_font_size !== 0) {
				fontSize = this.props.fixed_font_size;
			}

			if (cont) {
				cont.style.fontSize = `${fontSize}px`;
				cont.dataset.retry = 0;

				// reload Port Authority
				if (this.listingCustom1Ref.current) {
					const resizeColumns = this.listingCustom1Ref.current.resizeColumns;
					if (typeof resizeColumns === 'function') {
						this.listingCustom1Ref.current.resizeColumns();
					}
				} else {
					let buildDom = this.buildContent;

					clearTimeout(this.buildDomTimeout);
					this.buildDomTimeout = setTimeout(() => {
						buildDom();
					}, 2000);
				}
			}
		}

		if (JSON.stringify(prevState.content) !== JSON.stringify(this.state.content)) {
			this.refilterListings();
		}

		if (prevProps.navProps.fontLoaded !== this.props.navProps.fontLoaded && this.props.navProps.fontLoaded === true) {
			clearTimeout(this.fontLoadedTimeout);
			this.fontLoadedTimeout = setTimeout(() => {
				this.checkContainerSizes();
			}, 30000);
		}
	}

	checkContainerSizes = () => {
		let cont = document.getElementById(`listingContainer_${this.props.component_name}`);
		let contScrollWidth = cont.scrollWidth;
		let contWidth = Math.ceil(this.getCSS(cont, 'width'));

		if (contScrollWidth > contWidth) {
			console.log(`Static List: Font loaded late, rebuild content, contScrollWidth `, contScrollWidth, ' > contWidth', contWidth);
			this.buildContent();
		} else {
			console.log(`Static List: container sizes look good, contScrollWidth `, contScrollWidth, ' > contWidth', contWidth);
		}
	}

	formatTime(i) {
		if (i < 10) {
			i = `0${i}`;
		}
		return i;
	}

	getTime() {
		let now = new Date();
		let h = now.getHours();
		let m = now.getMinutes();
		let s = now.getSeconds();

		h = this.formatTime(h);
		m = this.formatTime(m);
		s = this.formatTime(s);

		let time = `${h}:${m}:${s}`;
		if (time.length === 7) {
			time = '0' + time;
		}
		return time;
	}

	schedulingFilter(objItem) {
		const m1Key = objItem.props.m1key;

		const schedulingJSON = window.flatData?.[m1Key]?.listing_scheduling;

		if (schedulingJSON === undefined) {
			return true;
		}

		let parsedSchedulingJSON;

		try {
			parsedSchedulingJSON = JSON.parse(schedulingJSON);
		} catch (err) {
			console.error('Failed to parse schedulingJSON', err);
			return true;
		}

		if (!parsedSchedulingJSON.scheduling) {
			return true;
		}

		if (parsedSchedulingJSON.use_dates) {
			if (parsedSchedulingJSON.start_date) {
				const now = new Date().toISOString();
				const startDate = parsedSchedulingJSON.start_date;
				if (startDate && now < startDate) {
					console.debug('SKIP(start_date expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.start_date);
					return false;
				}
			}

			// NOTE: The end_date is supposed to represent the final day a slide will show. The date a slide expires is the next day.
			if (parsedSchedulingJSON.end_date) {
				const now = new Date().toISOString();
				const endDate = new Date(parsedSchedulingJSON.end_date);
				let dateToExpire = endDate.setDate(endDate.getDate() + 1);
				dateToExpire = new Date(dateToExpire).toISOString();
				if (dateToExpire && now > dateToExpire) {
					console.debug('SKIP(end_date expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.end_date);
					return false;
				}
			}
		}

		if (parsedSchedulingJSON.use_times) {
			const now = this.getTime();
			if (parsedSchedulingJSON.start_time) {
				let startTime = parsedSchedulingJSON.start_time;
				if (startTime.length === 7) {
					startTime = '0' + startTime;
				}
				if (now < startTime) {
					console.debug('SKIP(start_time expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.start_time);
					return false;
				}
			}

			if (parsedSchedulingJSON.end_time) {
				let endTime = parsedSchedulingJSON.end_time;
				if (endTime.length === 7) {
					endTime = '0' + endTime;
				}

				if (now > endTime) {
					console.debug('SKIP(end_time expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.end_time);
					return false;
				}
			}
		}

		if (parsedSchedulingJSON.use_days) {
			const today = new Date().getDay();
			const days = parsedSchedulingJSON.days;
			if (days && Array.isArray(days) && !~days.indexOf(today)) {
				console.debug('SKIP(use_days expired):', '\n', parsedSchedulingJSON.src, '\n', parsedSchedulingJSON.days);
				return false;
			}
		}

		return true;
	}

	scheduleRefilterListings() {
		const fifteenMinutes = 15 * 60 * 1000;
		// const oneMinute = 1 * 60 * 1000;
		// const thirtySeconds = 30 * 1000;
		const tenSeconds = 10 * 1000;
		const now = Date.now();
		const currentWindow = now - (now % fifteenMinutes);
		const nextWindow = currentWindow + fifteenMinutes + tenSeconds;
		const timeUntilNextWindow = nextWindow - now;

		clearTimeout(this.nextTimeout);

		this.nextTimeout = setTimeout(() => {
			this.refilterListings();
		}, timeUntilNextWindow);
	}

	refilterListings() {
		const {
			content
		} = this.state;

		if (!this.isFilteringOn) {
			this.setState({filteredContent: content});
			return;
		}

		const newFilteredContent = content.filter(this.schedulingFilter.bind(this));

		this.setState({filteredContent: newFilteredContent});

		this.scheduleRefilterListings();
	}

	setColumnWidths = (listingsColumnData = this.state.listingsColumnData) => {
		if (this.props.row_concatenation === true) {
			return;
		}
		// console.log('888 setColumnWidth');
		let listData = [];
		let sideGapWidthLeft = this.state.sideGapWidthLeft;
		let sideGapWidthRight = this.state.sideGapWidthRight;
		let parentIconIndent = this.state.parentIconIndent;
		let childIconIndent = this.state.childIconIndent;
		let col2Width = 0;
		let col3Width = 0;
		let col4Width = 0;
		let col5Width = 0;

		if (parseInt(this.state.parentIconIndent) === 0 && this.props.icon_parent > 0) {
			parentIconIndent = `4%`;
		}
		if (parseInt(this.state.parentIconIndent) === 0 && this.props.icon_child > 0) {
			childIconIndent = `4%`;
		}

		listingsColumnData.forEach((column, i) => {
			listData.push(column.dataName);
			if (i === 1) {
				col2Width = column.width;
			}
			if (i === 2) {
				col3Width = column.width;
			}
			if (i === 3) {
				col4Width = column.width;
			}
			if (i === 4) {
				col5Width = column.width;
			}
		});

		this.setState({
			gridTemplateColumns: `${sideGapWidthLeft} ${parentIconIndent} ${childIconIndent} 1fr ${col2Width} ${col3Width} ${col4Width}  ${col5Width} ${sideGapWidthRight}`
		});
	}

	getCSS = (obj, css) => {
		return parseFloat(window.getComputedStyle(obj).getPropertyValue(css));
	};

	fontAwesome = (type) => {
		let css = this.state.css;
		let icon = '';

		switch (this.props[type]) {
		case 0:
			icon = '';
			break;
		case 1:
			icon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-circle'}`}></i>;
			break;
		case 2:
			icon = <i className={`${css.fas} ${css.far} ${'far'} ${'fa-circle'}`}></i>;
			break;
		case 3:
			icon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-square'}`}></i>;
			break;
		case 4:
			icon = <i className={`${css.fas} ${css.far} ${'far'} ${'fa-square'}`}></i>;
			break;
		case 5:
			icon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-users'}`}></i>;
			break;
		case 6:
			icon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-user-friends'}`}></i>;
			break;
		case 7:
			icon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-user'}`}></i>;
			break;
		case 8:
			icon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-user-circle'}`}></i>;
			break;
		case 9:
			icon = <i className={`${css.fas} ${css.far} ${'far'} ${'fa-user-circle'}`}></i>;
			break;
		case 10:
			icon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-building'}`}></i>;
			break;
		case 11:
			icon = <i className={`${css.fas} ${css.far} ${'far'} ${'fa-building'}`}></i>;
			break;
		default:
			icon = '';
		}

		return icon;
	}

	fontAwesome2 = (type) => {
		let css = this.state.css;
		let childIcon = '';

		switch (this.props[type]) {
		case 0:
			childIcon = '';
			break;
		case 1:
			childIcon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-circle'}`}></i>;
			break;
		case 2:
			childIcon = <i className={`${css.fas} ${css.far} ${'far'} ${'fa-circle'}`}></i>;
			break;
		case 3:
			childIcon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-square'}`}></i>;
			break;
		case 4:
			childIcon = <i className={`${css.fas} ${css.far} ${'far'} ${'fa-square'}`}></i>;
			break;
		case 5:
			childIcon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-users'}`}></i>;
			break;
		case 6:
			childIcon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-user-friends'}`}></i>;
			break;
		case 7:
			childIcon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-user'}`}></i>;
			break;
		case 8:
			childIcon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-user-circle'}`}></i>;
			break;
		case 9:
			childIcon = <i className={`${css.fas} ${css.far} ${'far'} ${'fa-user-circle'}`}></i>;
			break;
		case 10:
			childIcon = <i className={`${css.fas} ${css.far} ${'fas'} ${'fa-building'}`}></i>;
			break;
		case 11:
			childIcon = <i className={`${css.fas} ${css.far} ${'far'} ${'fa-building'}`}></i>;
			break;
		default:
			childIcon = '';
		}

		return childIcon;
	}

	columnWidth = (value) => {
		let root = document.documentElement;

		switch (value) {
		case 1:
			root.style.setProperty(`--width${this.props.component_name}`, `100%`);
			return;
		case 2:
			root.style.setProperty(`--width${this.props.component_name}`, `calc(50% - ${(this.props.vert_line_width / this.props.num_columns)}px)`);
			return;
		case 3:
			root.style.setProperty(`--width${this.props.component_name}`, `calc(33% - ${(this.props.vert_line_width / this.props.num_columns)}px)`);
			return;
		case 4:
			root.style.setProperty(`--width${this.props.component_name}`, `calc(24% - ${(this.props.vert_line_width / this.props.num_columns)}px)`);
			return;
		case 5:
			root.style.setProperty(`--width${this.props.component_name}`, `calc(19% - ${(this.props.vert_line_width / this.props.num_columns)}px)`);
			return;
		default:
			root.style.setProperty(`--width${this.props.component_name}`, `100%`);
			return;
		}

	}

	columnHeader = () => {
		// console.log(`888 columnHeaader()`);
		let headerTextAlignment = this.getHeaderTextAlignment;
		if (this.props.show_column_headers === false || this.props.row_concatenation === true) {
			return;
		}

		let css = this.state.css;

		// let i = 1;
		let style = {
			fontSize:   this.props.font_size,
			background: this.props.style_parent_header_color
		};

		let labelStyle = {};
		// let height = this.props.headerHeight;
		if (this.props.header_height !== 0) {
			labelStyle = {
				height: this.props.header_height + 'px'
			};
		}
		if (this.props.header_font_size !== 0) {
			labelStyle = {
				...labelStyle,
				fontSize: this.props.header_font_size + 'px'
			};
		}
		if (this.props.header_padding !== 0) {
			labelStyle = {
				...labelStyle,
				paddingTop:    this.props.header_padding + 'px',
				paddingBottom: this.props.header_padding + 'px'
			};
		}

		let spaceClass = css.vertColLineSpacedTop;
		if (this.props.vert_line_style === 0) {
			spaceClass = css.vertColLineGradientTop;
		}
		if (this.props.vert_line_style === 1) {
			spaceClass = css.vertColLineSolidTop;
		}
		let headerFirstOnly = '';
		if (this.props.header_first_only === true) {
			headerFirstOnly = css[`colHeaderFirstOnly`];
			spaceClass = css.headerFirstOnly;
			style = {
				...style,
				background: 'transparent'
			};
		}

		let columnLineWidth = {
			width: `${this.props.vert_line_width}px`
		};

		// let labels = this.props.labels;
		let labelGrp = [];
		let configNum = this.props.configuration;
		let numColumns = this.state.numColumnsNew;
		let columns = [];
		let count = 1;

		for (let i = 0; i < this.state.listingsColumnData.length; i++) {

			let label = this.state.listingsColumnData[i].header;
			if (i === 0) {
				// add spacer to represent icon area
				labelGrp.push(
					<div className={`col${count}Head ${css[`colHead`]} ${headerFirstOnly} pCol pCol${count} ${css[`pCol${count}`]}`} style={labelStyle} key={`label_spacer_${label}`} ></div>
				);
				count++;
			} else if (this.state.listingsColumnData[i].width === '0%') {
				continue;
			}

			labelStyle = {
				...labelStyle,
				justifyContent: headerTextAlignment(this.state.listingsColumnData[i].textAlign)
			};

			labelGrp.push(
				<div className={`col${count}Head ${css[`colHead`]} ${headerFirstOnly} pCol pCol${count} ${css[`pCol${count}`]}`} style={labelStyle} key={`label_${label}`} >{label}</div>
			);
			count++;
		}

		let headerStyles = {
			// width: this.columnWidth(this.state.numColumnsNew),
			width:               `var(--width${this.props.component_name})`,
			gridTemplateColumns: this.state.gridTemplateColumns
		};
		for (let i = 0; i < numColumns; i++) {
			if (this.props.header_first_only === false || (i === 0 && this.props.header_first_only === true)) {
				columns.push(
					<div className={`${css.colHeader} ${`colHeaderNum${i + 1}`}`} style={headerStyles} key={`label_${i}`}>{labelGrp}</div>
				);
			} else {
				columns.push(
					<div className={`${css.colHeader} ${`colHeaderNum${i + 1}`}`} style={headerStyles} key={`label${i}`}></div>
				);
			}
			if (i !== numColumns - 1 && this.props.vert_line_width > 0) {
				let spacer = <div className={spaceClass} key={`spacer_${i}`} style={columnLineWidth}></div>;
				columns.push(spacer);
			}
		}

		return <div id={`listColumnHeader_${this.props.component_name}`} className={`${css[`listHeader`]} listHeader list${this.props.num}Header`} style={style} key={`listColumnHeader_${this.props.component_name}`}>{columns}</div>;
	}

	columnLines = (firstListings, lastListings, multiColArrayY, content) => {
		// console.log(`STATIC LIST ${this.props.num} - Add Column Lines`);
		let css = this.state.css;
		let listingLines = document.getElementsByClassName(`listingLine_${this.props.component_name}`) || [];
		let lineMarginTop = listingLines[0]?.style?.marginTop;
		let lineMarginbottom = listingLines[0]?.style?.marginBottom;
		let fontSize = document.getElementById(`listingContainer_${this.props.component_name}`).style.fontSize;
		let numColumns = multiColArrayY.length;

		let isCombined = this.props.combined;
		let putLineAfter = [];

		if (this.props.vert_line_width > 0) {
			let data = JSON.parse(JSON.stringify(this.state.listings));
			if (data.length === 0) {
				let [, listingsData] = getColumnsAndListings(this.props, isCombined, true); // sorts T1
				data = listingsData;
			}

			// convert element IDs to Bronco IDs
			let lastListingsArray = [];
			lastListings.forEach(function(listingID) {
				let listingElement = document.getElementById(listingID);
				if (listingElement.dataset.col !== numColumns) {
					let data_bronco = listingElement.dataset.bronco;
					lastListingsArray.push(data_bronco);
				}
			});
			// console.log('lastListingsArray', lastListingsArray);
			// console.log(lastListingsArray);
			let newData = data.map(function(item) {
				return item;
			});

			newData = Array.from(this.removeColumnLines(newData)); // brian fix
			// console.log('newData columnLines', newData);

			console.log('newData columnLines', newData);

			if (!this.props.show_dev_styles) {
				this.clearDevClasses();
			}

			// console.log(`======================== Number of Listings: ${this.props.condor_component_list.length} =======================`);
			console.log(`%cList ${this.props.num} has ${this.props.listings.length} T1 listings`, 'background: #1b41af; color: white; padding: 5px 100px;');
			let ifFixed = this.props.fixed_font_size > 0 ? ' (FIXED)' : '';
			console.log(`%cFinal Font Size: ${fontSize}${ifFixed}`, 'background: #1b41af; color: white; padding: 5px 103px;');
			// console.log(`======================= Final Font Size: ${fontSize} =======================`);

			content.forEach((listing, i) => {
				lastListings.forEach((listingId, index) => {
					if (listing.props && listing.props.id && listing.props.id === listingId && index !== lastListings.length - 1) {
						let setKey = listing.props.id;
						content.splice(i + 1, 0,
							<VerticalLine {...this.props} dataKey={`vertLine_${setKey}`} key={`vertLine_${setKey}`} css={this.state.css} />
						);
					}
				});
			});

			console.log(`content`, content);

			document.getElementById(this.props.listingGroupID).classList.remove('dontLook');

			this.setState({
				content:                  content,
				numColumnsNew:            numColumns,
				num_columns:              numColumns,
				font_size:                parseFloat(fontSize),
				listing_margin_bottom_em: parseFloat(lineMarginbottom),
				listing_margin_top_em:    parseFloat(lineMarginTop)
			});
		} else {
			if (!this.props.show_dev_styles) {
				this.clearDevClasses();
			}
			document.getElementById(this.props.listingGroupID).classList.remove('dontLook');
			this.setState({
				numColumnsNew:            numColumns,
				num_columns:              numColumns,
				font_size:                parseFloat(fontSize),
				listing_margin_bottom_em: parseFloat(lineMarginbottom),
				listing_margin_top_em:    parseFloat(lineMarginTop)
			});
		}

		let cont = document.getElementById(`listingContainer_${this.props.component_name}`);
		console.log(`Static List: Final Sizes: contScrollWidth`, cont.scrollWidth, 'this.getCSS(cont, "width")', this.getCSS(cont, 'width'));

		// THE END - GENERATION OF LISTING COMPLETE
	}


	clearDevClasses = () => {
		let css = this.state.css;
		let listings = document.getElementsByClassName(`row_${this.props.component_name}`);

		for (let i = 0; i < listings.length; i++) {
			let item = document.getElementById(listings[i].id);
			if (item !== null && item !== undefined) {
				item.removeAttribute('data-place');
				if (item.classList.contains(css[`${`list${this.props.num}`}LastLine`])) {
					item.classList.remove(css[`${`list${this.props.num}`}LastLine`]);
				}
				if (item.classList.contains(css[`${`list${this.props.num}`}FirstLine`])) {
					item.classList.remove(css[`${`list${this.props.num}`}FirstLine`]);
				}
			}
		}
	}

	resetHiddenLastListingLines = () => {
		let css = this.state.css;
		let listings = document.getElementsByClassName(`row_${this.props.component_name}`);

		for (let i = 0; i < listings.length; i++) {
			let item = document.getElementById(listings[i].id);
			if (item !== null && item !== undefined) {
				let lastLine = item.lastChild;
				if (lastLine.classList.contains(css.hideLastListingLine)) {
					// console.log(`${i} item.lastChild`);
					// console.log(item.lastChild);
					lastLine.classList.remove(css.hideLastListingLine);
				}
			}
		}
	}

	calculateColumns = (content) => {
		// console.log(`STATIC LIST ${this.props.num} - Calculate Columns`);
		let css = this.state.css;
		let listings = document.getElementsByClassName(`row_${this.props.component_name}`);
		this.resetHiddenLastListingLines();
		this.clearDevClasses();

		// tags listings with x/y positions
		for (let i = 0; i < listings.length; i++) {
			let bound = listings[i].getBoundingClientRect();
			let currentListing = document.getElementById(listings[i].id);
			if (currentListing !== null && currentListing !== undefined) {
				currentListing.dataset.posX = bound.x;
				currentListing.dataset.posY = bound.y;
			}
		}

		// ====  X / Y positions data added to elements ===
		let posArrayX = [];
		let posArrayY = [];
		for (let i = 0; i < listings.length; i++) {
			let item = document.getElementById(listings[i].id);
			if (item !== null && item !== undefined) {
				posArrayX.push(item.dataset.posX);
				posArrayY.push(item.dataset.posY);
			}
		}

		// ==== store position X values ===
		let colArray = [];
		posArrayX.forEach(function(i) {
			if (colArray.length === 0) {
				// console.log(`parseFloat(i)`);
				// console.log(parseFloat(i));
				colArray.push(i);
			} else {
				let add = false;
				for (let j = 0; j < colArray.length; j++) {
					if (i === colArray[j]) {
						add = false;
						return;
					}
					add = true;
				}
				if (add !== false) {
					colArray.push(i); // can this be cleaned up and added into for loop <=====================
				}
			}
			// sorting of the array might need to be implemented
		});


		// ==== Sort to fix issue when first item is ranked last ====
		let numberArray = colArray.map(function(i) {
			return parseFloat(i);
		});
		numberArray.sort(function(a, b) {
			return a - b;
		});

		let stringArray = numberArray.map(function(i) {
			return i.toString();
		});
		colArray = stringArray;


		// ==== Column dataset added to elements ===
		for (let i = 0; i < listings.length; i++) {
			let currentListing = document.getElementById(listings[i].id);
			if (currentListing !== null && currentListing !== undefined) {
				colArray.forEach(function(arrayItem, k) {
					if (currentListing.dataset.posX === arrayItem) {
						currentListing.dataset.col = k + 1;
					}
				});
			}
		}
		// console.log('listings');
		// console.log(listings);

		// ==== Fill multidimensional array with Y values, and then sort ===
		let multiColArrayY = [];
		for (let i = 0; i < colArray.length; i++) {
			multiColArrayY.push([]);
		}

		for (let i = 0; i < listings.length; i++) {
			let item = document.getElementById(listings[i].id);
			if (item !== null && item !== undefined) {
				multiColArrayY[(item.dataset.col - 1)].push(item.dataset.posY);
			}
		}


		// Consider moving this code block into columnLines(), code is already modified to pass multiColArrayY
		let firstListings = [];
		let lastListings = [];
		for (let i = 0; i < multiColArrayY.length; i++) {
			multiColArrayY[i].sort((a, b) => {
				return a - b;
			});
			let lastArrayIndex = multiColArrayY[i].length - 1;

			let firstItem = document.querySelector(`.row_${this.props.component_name}[data-pos-y="${multiColArrayY[i][0]}"][data-col="${i + 1}"]`);
			let lastItem = document.querySelector(`.row_${this.props.component_name}[data-pos-y="${multiColArrayY[i][lastArrayIndex]}"][data-col="${i + 1}"]`);
			let lastItemLine = document.querySelector(`.row_${this.props.component_name}[data-pos-y="${multiColArrayY[i][lastArrayIndex]}"][data-col="${i + 1}"] .listingLine`);

			if (!firstItem || !lastItem || !lastItemLine) {
				continue;
			}

			if (this.props.show_dev_styles) {
				firstItem.classList.add(css[`${`list${this.props.num}`}FirstLine`]);
				lastItem.classList.add(css[`${`list${this.props.num}`}LastLine`]);
			}

			firstItem.dataset.place = `firstCol${i + 1}`;
			firstListings.push(firstItem.id);

			lastItem.dataset.place = `lastCol${i + 1}`;
			lastItemLine.classList.add(css[`hideLastListingLine`]);
			lastListings.push(lastItem.id);
		}

		this.columnLines(firstListings, lastListings, multiColArrayY, content);

	}

	calculateFontSizeMin = () => {
		// console.log(`888 calculateFontSizeMin()`);
		let fontSizeMin = this.props.font_size_min;
		let numberOfListing = this.state.data.length;
		if (fontSizeMin === 0) {
			// various equations to predict the best minimum font value to start with based on the number of listings being displayed
			// fontSizeMin = ((8 * numberOfListing) + 200) / (numberOfListing - 1); // 17  (23n)
			fontSizeMin = ((10 * numberOfListing) + 200) / (numberOfListing - 1); // 19.1  (23n)
			// fontSizeMin = ((8 * numberOfListing) + 200) / (numberOfListing - 8); // 24 (23n)
			// fontSizeMin = ((6 * numberOfListing) + 1000) / (numberOfListing + 16); // 28.6 (23n)
		}
		// console.log('fontSizeMin', fontSizeMin);
		return fontSizeMin.toFixed(1);
	}

	resizeColumns = (content) => {
		// console.log(`888 resizeColumns()`);
		let cont = document.getElementById(`listingContainer_${this.props.component_name}`);
		// let cont = document.getElementById(`${`list${this.props.num}`}Container`);
		let colHeader = document.getElementById(`listColumnHeader_${this.props.component_name}`);

		this.columnWidth(this.state.numColumnsNew);

		let columns = parseInt(cont.dataset.cols);
		let currentRetryValue = parseInt(cont.dataset.retry);
		cont.dataset.retry = currentRetryValue + 1;

		let listingLine = document.getElementsByClassName(`listingLine_${this.props.component_name}`);

		let fontSize = this.getCSS(cont, 'font-size');
		let fixedFontSize = 0;
		if (this.props.fixed_font_size) {
			fixedFontSize = this.props.fixed_font_size;
		}

		// console.log('font size', fontSize);
		let lineHeight = this.getCSS(cont, 'line-height'); // value is pixels
		let letterSpacing = window.getComputedStyle(cont).getPropertyValue('letter-spacing');

		// Something went wrong! Hard coded value to stop cycling over code
		if (currentRetryValue > 500) {
			console.log(`%cSTATIC LIST ${this.props.num} IS BAILING - Something broke - too many retries!!!`, 'background: #c70000; color: white; padding: 20px 70px;font-size: 2em');
			return;
		}

		// TEST CONDITION
		if (currentRetryValue >= 20) {
			// return;
			// debugger
		}
		// console.log(`%cSTATIC LIST ${this.props.num} / Font Size: ${fontSize} / Retry ${currentRetryValue}`, 'background: #111111; color: #bada55; padding: 5px 50px');

		let rebuild_log = this.state.logs;
		rebuild_log.push(`List ${this.props.num}, Font Size: ${fontSize}, Retry: ${currentRetryValue}`);
		this.setState({
			logs: rebuild_log
		});

		// ============== Experimental equation and clamp on min value if font_size_min === 0 ==============
		let fontSizeMin = this.calculateFontSizeMin();
		if (fontSizeMin > this.props.font_size) {
			fontSizeMin = this.props.font_size - 2;
			console.log('fontSizeMin = this.props.fontSize - 2', fontSizeMin);
		}


		// ================= Container is too wide - scale down values, or fixed values need to be set =================
		let contScrollWidth = cont.scrollWidth;
		let fixedValues = false;
		if (currentRetryValue === 0 && (fixedFontSize !== 0 || this.props.fixed_margin_top !== 0 || this.props.fixed_margin_bottom !== 0)) {
			fixedValues = true;
		}
		if (contScrollWidth > Math.ceil(this.getCSS(cont, 'width')) || fixedValues === true) {
			// console.log(`Static List, Retry: ${currentRetryValue} contScrollWidth`, contScrollWidth, 'this.getCSS(cont, "width")', this.getCSS(cont, 'width'));
			let rebuild_msg = '';
			// ======================== Reduce or Fix Font Size ========================
			let shrinkFontValue = this.props.bias_font_scale_vs_margin;
			if (fontSize > fontSizeMin && fixedFontSize === 0) {
				let newSize = fontSize - shrinkFontValue + 'px';
				rebuild_msg = `REBUILD LIST ${this.props.num} / Font Size ${fontSize} > Font Min ${fontSizeMin} / New font size: ${newSize}`;
				// console.log(`REBUILD LIST ${this.props.num} / Font Size ${fontSize} > Font Min ${fontSizeMin} / New font size: ${newSize}`);
				cont.style.fontSize = newSize;
				if (this.props.show_column_headers) {
					colHeader.style.fontSize = newSize;
				}
			} else if (this.props.num_columns_auto && fixedFontSize === 0) {
				rebuild_msg = `STATIC LIST ${this.props.num} - min font size reached, auto calculate columns flagged on`;
				// console.debug(`STATIC LIST ${this.props.num} - min font size reached, auto calculate columns flagged on`);
				cont.dataset.minfontsize = 'true';
			} else if (fixedFontSize > 0) {
				let fixedSize = `${fixedFontSize}px`;
				rebuild_msg = `STATIC LIST ${this.props.num} - Fixing font size to ${fixedSize}`;
				// console.debug(`STATIC LIST ${this.props.num} - Fixing font size to ${fixedSize}`);
				cont.style.fontSize = fixedSize;
				if (this.props.show_column_headers) {
					colHeader.style.fontSize = fixedSize;
				}
			} else if (fontSize >= 1) {
				let newSize = fontSize - shrinkFontValue + 'px';
				rebuild_msg = `STATIC LIST ${this.props.num} - Ignoring min font size, shrinking to ${newSize}`;
				// console.debug(`STATIC LIST ${this.props.num} - Ignoring min font size, shrinking to ${newSize}`);
				cont.style.fontSize = newSize;
				if (this.props.show_column_headers) {
					colHeader.style.fontSize = newSize;
				}
			}

			let rebuild_log2 = this.state.logs;
			rebuild_log2.push(rebuild_msg);
			this.setState({
				logs: rebuild_log2
			});

			// ============= Reduce letter-spacing and line-height =============
			if (fontSize === 12 || (fontSize === 8 && fixedFontSize !== 0)) {
				if (letterSpacing === 'normal') {
					letterSpacing = 0;
				}
				cont.style.letterSpacing = letterSpacing - 0.1 + 'px';
				let lineHeightValue = lineHeight / fontSize; // convert pixel value to number
				if (lineHeightValue > 1.1) {
					cont.style.lineHeight = lineHeightValue - 0.1;
				}
			}


			// ====================== Reduce or Fix Margins ======================
			let shrinkMarginValueEm = 0.1;

			if (this.props.fixed_margin_top > 0 || this.props.fixed_margin_bottom > 0) {
				for (let i = 0; i < listingLine.length; i++) {
					if (this.props.fixed_margin_top > 0) {
						listingLine[i].style.marginTop = this.props.fixed_margin_top + 'em';
					}
					if (this.props.fixed_margin_bottom > 0) {
						listingLine[i].style.marginBottom = this.props.fixed_margin_bottom + 'em';
					}
				}
			} else if ((this.props.listing_margin_top_em > 0 || this.props.listing_margin_bottom_em > 0) && (fontSize <= fontSizeMin || fixedFontSize !== 0)) {
				for (let i = 0; i < listingLine.length; i++) {
					let lineMarginTop = listingLine[i].dataset.margintop;
					let lineMarginBottom = listingLine[i].dataset.marginbottom;

					if (lineMarginTop - shrinkMarginValueEm >= 0.2) {
						listingLine[i].dataset.margintop = lineMarginTop - shrinkMarginValueEm;
						listingLine[i].style.marginTop = (lineMarginTop - shrinkMarginValueEm) + 'em';
						if (i === 0) {
							console.log(`STATIC LIST ${this.props.num} - Shrink Bottom Margin to ${lineMarginBottom - shrinkMarginValueEm}em`);
						}
					} else if (this.props.num_columns_auto) {
						cont.dataset.minmargintop = 'true';
					}

					if (lineMarginBottom - shrinkMarginValueEm >= 0.2) {
						listingLine[i].dataset.marginbottom = lineMarginBottom - shrinkMarginValueEm;
						listingLine[i].style.marginBottom = (lineMarginBottom - shrinkMarginValueEm) + 'em';
						if (i === 0) {
							console.log(`STATIC LIST ${this.props.num} - Shrink Bottom Margin to ${lineMarginBottom - shrinkMarginValueEm}em`);
						}
					} else if (this.props.num_columns_auto) {
						cont.dataset.minmarginbottom = 'true';
					}
				}
			}


			// ========== NUM_COLUMN_AUTO ONLY: determine when there are enough columns and only reduce font and margin size ==========
			let maxColumns = this.props.num_columns_max;
			if ((cont.dataset.minfontsize === 'true' && cont.dataset.minmargintop === 'true' && cont.dataset.minmarginbottom === 'true' && cont.dataset.cols < maxColumns) || (currentRetryValue > 100 && this.props.num_columns_auto && cont.dataset.cols < maxColumns)) {
				if (cont.dataset.minfontsize === 'true' && cont.dataset.minmargintop === 'true' && cont.dataset.minmarginbottom === 'true' && cont.dataset.cols < maxColumns) {
					console.log('condition 1 is true');
				}
				if (currentRetryValue > 100 && this.props.num_columns_auto && cont.dataset.cols < maxColumns) {
					console.log('condition 2 is true');
				}
				console.log(`%cSTATIC LIST ${this.props.num} - Resizing maxed out, Add New ${`list_${this.props.num}_${this.props.component_name}`} Column`, 'background: purple; color: white; padding: 10px 70px;font-size: 1.5em');
				cont.dataset.minfontsize = 'false';
				cont.dataset.minmargintop = 'false';
				cont.dataset.minmarginbottom = 'false';
				let newColumns = columns + 1;
				cont.dataset.cols = newColumns;

				// reset values
				cont.style.fontSize = this.props.font_size + 'px';

				let newTopValue = 0;
				let newBottomValue = 0;

				if (this.props.fixed_margin_top > 0 || this.props.fixed_margin_bottom > 0) {
					newTopValue = this.props.fixed_margin_top;
					newBottomValue = this.props.fixed_margin_bottom;
				} else if (this.props.listing_margin_top_em > 0 || this.props.listing_margin_bottom_em > 0) {
					newTopValue = this.props.listing_margin_top_em;
					newBottomValue = this.props.listing_margin_bottom_em;
				}

				for (let i = 0; i < listingLine.length; i++) {
					listingLine[i].dataset.margintop = newTopValue;
					listingLine[i].style.marginTop = `${newTopValue}em`;
					listingLine[i].dataset.marginbottom = newBottomValue;
					listingLine[i].style.marginBottom = `${newBottomValue}em`;
				}

				this.setState({
					numColumnsNew: newColumns
				});

				this.resizeColumns(content);
			} else {
				this.resizeColumns(content);
			}

		} else {
			let smiley = String.fromCodePoint(0x1F600);
			console.log(`${smiley} ${smiley} STATIC LIST ${this.props.num} Resizing Complete, Retry: ${currentRetryValue}, contScrollWidth: ${contScrollWidth}, contWidth: ${this.getCSS(cont, 'width')} ${smiley} ${smiley}`);
			this.calculateColumns(content);
			return;
		}
	};

	getTextAlignment = (value) => {
		switch (value) {
		case 0:
			return `left`;
		case 1:
			return `center`;
		case 2:
			return `right`;
		default:
			return 'left';
		}
	}

	getHeaderTextAlignment = (value) => {
		switch (value) {
		case 0:
			return `flex-start`;
		case 1:
			return `center`;
		case 2:
			return `flex-end`;
		default:
			return 'flex-start';
		}
	}

	convertMilitaryToCivilianTime(militaryTime) {
		const timeArray = militaryTime.split(':');
		let hour = parseInt(timeArray[0], 10);
		const minute = timeArray[1];
		let period = 'AM';

		if (hour === 0) {
			hour = 12;
		} else if (hour === 12) {
			period = 'PM';
		} else if (hour > 12) {
			hour -= 12;
			period = 'PM';
		}


		// Pad minute with leading zeros if needed
		const paddedMinute = minute.padStart(2, '0');

		return `${hour}:${paddedMinute} ${period}`;
	}

	buildContent = () => {
		if (this.props.row_concatenation === true) {
			return;
		}

		let css = this.state.css;
		let textAlignment = this.getTextAlignment;
		this.columnWidth(this.state.numColumnsNew);

		let columnWidth = `var(--width${this.props.component_name})`;
		let content = [];

		// Pass props to getColumnsAndListings until this.state.listings has been updated.
		let listingsParentObj = this.props;
		let isFirst = true;
		if (this.state.listings.length > 0) {
			listingsParentObj = this.state;
			isFirst = false;
		}

		let [, listingsData] = getColumnsAndListings(listingsParentObj, this.props.combined, isFirst);

		let newMarginBottomEm = this.state.listing_margin_bottom_em || this.props.listing_margin_bottom_em; // Use props if state hasn't been set yet
		let newMarginTopEm = this.state.listing_margin_top_em || this.props.listing_margin_top_em; // Use props if state hasn't been set yet
		let showBackground = this.props.show_listing_bg_color;
		let showListingLines = this.props.show_listing_lines;
		let showListingBorder = this.props.show_listing_border;
		let linesPerNum = this.props.show_listing_lines_num;
		let combined = this.props.combined;
		let icon = this.fontAwesome('icon_parent');

		let propName = this.props.component_name;

		let columnLineWidth = this.props.vert_line_width;
		let useIndented = this.props.indented_scaling;
		let indentedSize = this.props.indented_scaling_size;

		let lineStyle = {
			marginTop:    newMarginTopEm + 'em',
			marginBottom: newMarginBottomEm + 'em'
		};
		let styleData = {
			marginTop:    '0.0em',
			marginBottom: '0.0em'
		};

		// Parent Style Settings
		let lineBg = '';
		let indent = '';
		let entryBg = '';
		let entryBorderTopBottom = '';
		let entryBorderRight = '';
		let entryBorderLeft = '';

		if (showBackground) {
			entryBg = css['entryBg'];
			styleData = {
				...styleData,
				background: showBackground
			};
		}
		if (showListingBorder === true) {
			entryBorderTopBottom = css['borderTopBottom'];
			entryBorderRight = css['borderRight'];
			entryBorderLeft = css['borderLeft'];
		}
		if (showListingLines === false) {
			lineStyle = {
				...lineStyle,
				background: 'transparent'
			};
		}
		if (showBackground || showListingBorder === true) {
			indent = css['indent'];
			lineBg = css['lineHide'];
		}


		function returnClasses(arr = []) {
			let newClassList = '';
			if (arr && Array.isArray(arr)) {
				arr.forEach((item) => {
					newClassList += ` ${css[item]}`;
				});
			}
			return newClassList;
		}

		function returnModifierClasses(arr = []) {
			let newClassList = '';
			if (arr && Array.isArray(arr)) {
				arr.forEach((item) => {
					newClassList += ` ${item}`;
				});
			}
			return newClassList;
		}

		let lineCounter = 1;
		let fullLine = this.props.vert_line_style;

		// Identify the data types being used and if an icon should be used

		let listData = [];
		let useParentIcons = false;
		let useChildIcons = false;
		let col2Width = 0; // 5%
		let col3Width = 0; // 14%
		let col4Width = 0;
		let col5Width = 0;

		if (this.props.icon_parent > 0) {
			useParentIcons = true;
			indent = '';
		}
		if (this.props.icon_child > 0) {
			useChildIcons = true;
		}

		this.state.listingsColumnData.forEach((column, i) => {
			listData.push(column.dataName);
			if (i === 1) {
				col2Width = column.width;
			}
			if (i === 2) {
				col3Width = column.width;
			}
			if (i === 3) {
				col4Width = column.width;
			}
			if (i === 4) {
				col5Width = column.width;
			}
		});

		let gridTemplateColumnsState = this.state.gridTemplateColumns;
		// console.log(`buildContent() data`, listData);

		let existingm1Keys = [];

		// =========================== Parents ===========================
		listingsData.forEach((listing, index) => {
			if (listing.m1Key) {
				if (~existingm1Keys.indexOf(listing.m1Key)) {
					return;
				}

				existingm1Keys.push(listing.m1Key);

				let style = {
					width:               columnWidth,
					gridTemplateColumns: gridTemplateColumnsState
				};

				let customClasses = returnClasses(listing.settings.custom_classes?.value);
				let modifierClasses = returnModifierClasses(listing.settings.modifier_classes?.value);

				let incrementLines = {};
				if ((lineCounter) % linesPerNum !== 0 && combined === true) {
					incrementLines = {
						...lineStyle,
						background: 'transparent'
					};
				} else {
					incrementLines = {
						...lineStyle
					};
				}
				// scale parent line margins to match spacing of scaled child listings
				if (useIndented === true) {
					incrementLines = {
						...incrementLines,
						fontSize: `${indentedSize}em`
					};
				}
				// let listGroup = '';
				let listGroupArray = [];
				let lineItem = '';

				let listingsWithType = [];
				listing.vals.forEach((listingData, i) => {
					listingsWithType.push({
						value: listingData,
						type:  listing.types[i],
						displayName: listing.displayNames[i]
					});
				});

				let formattedVals = listingsWithType.map((val) => {
					let formattedData = val.value;
					switch (val.type) {
					case 'time':
						if (val.value) {
							formattedData = this.convertMilitaryToCivilianTime(val.value);
						}
						break;
					case 'datetime':
						if (val.value) {
							let unixDate = Date.parse(val.value);
							let date = new Date(unixDate);
							formattedData = date.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'});
						}
						break;
					case 'int':
						if (val.displayName === 'Directional Arrows') {
							let iconData = getIntegerIconLabel(val.displayName, val.value)
							formattedData = iconData
						}
						break;
					default:
						break;
					}

					return formattedData;
				});

				listing.vals = formattedVals;

				listing.colGroups
					.filter((dataCol, i) => {
						if (i === 0) return true;
						if (dataCol.width === 0) return false;
						return true;
					})
					.forEach((dataCol, i) => {
						let addNum = 2;

						let columnStyleData = {
							...styleData,
							textAlign: textAlignment(dataCol.textAlign)
						};

						if (i === 0 && useParentIcons === true) { // if icon
							listGroupArray.push(<div className={`${css[`pCol1`]} pCol pCol1 ${entryBorderTopBottom} ${entryBorderLeft} ${entryBg} ${indent}`} style={columnStyleData} key={`A${listing.m1Key}${index}${i}`}>{icon}</div>);
						}

						let leftBorder = useParentIcons === true ? '' : entryBorderLeft;
						let content = listing.vals[i];

						if (listing.displayNames[i] === 'Photo' || listing.displayNames[i] === 'Logo') {
							content = '';
						}

						if (listing.displayNames[i] === 'Directional Arrows') {
							columnStyleData = {
								...styleData,
								display: 'flex',
								justifyContent: textAlignment(dataCol.textAlign)
							};
						}

						if (i === 0) { // first
							listGroupArray.push(<div className={`${css[`pCol${i + addNum}`]} pCol pCol${i + addNum} ${entryBorderTopBottom} ${leftBorder} ${entryBg} ${indent}`} style={columnStyleData} key={`B${listing.m1Key}${index}${i}`}>{content}</div>);
						} else if (i === listData.length - 1) { // last
							listGroupArray.push(<div className={`${css[`pCol${i + addNum}`]} pCol pCol${i + addNum} ${entryBorderTopBottom} ${entryBorderRight} ${entryBg}`} style={columnStyleData} key={`C${listing.m1Key}${index}${i}`}>{content}</div>);
						} else {
							listGroupArray.push(<div className={`${css[`pCol${i + addNum}`]} pCol pCol${i + addNum} ${entryBorderTopBottom} ${entryBg}`} style={columnStyleData} key={`D${listing.m1Key}${index}${i}`}>{content}</div>);
						}
					});

				lineItem = <div className={`listingLine_${propName} listingLine ${css[`listingLine`]} ${lineBg}`} data-margintop={newMarginTopEm} data-marginbottom={newMarginBottomEm} data-type="lineParent" style={incrementLines}></div>;
				if (useParentIcons === true) {
					lineItem = <div className={`listingLine_${propName} listingLine ${css[`listingLine`]} ${lineBg} ${css.listingLineParent}`} data-margintop={newMarginTopEm} data-marginbottom={newMarginBottomEm} data-type="lineParent" style={incrementLines}></div>;
				}


				lineCounter++;

				content.push(
					<div
						id={`t1-i${index}-${propName}`}
						className={`listingsItem t1 row_${propName} ${css[`listRow`]} ${modifierClasses} ${customClasses}`}
						data-bronco={listing.m1Key}
						key={`listing.${listing.m1Key}_${index}`}
						data-tier1-key={`key-${listing.m1Key}`}
						style={style}
						m1key={listing.m1Key}
					>
						{listGroupArray}
						{lineItem}
					</div>
				);

				// =========================== Children ===========================
				if (listing.listings && listing.listings.length > 0 && !combined) {
					content = this.makeChildListings(listing, content, index);
				}

			} else {
				lineCounter = 1;
				let randomNum = Math.random();
				let verticalClasses = css.vertColLineSpacedBot;
				if (fullLine === 0) {
					verticalClasses = css.vertColLineGradientBot;
				}
				if (fullLine === 1) {
					verticalClasses = css.vertColLineSolidBot;
				}
				let columnLineStyles = {
					width: `${columnLineWidth}px`
				};
				content.push(
					<div
						className={verticalClasses}
						style={columnLineStyles}
						key={`verticalLine${index}_${randomNum}`}
					></div>
				);
			}
		});

		// return content;
		this.setState({
			content: content
		});
		console.log('============ SET CONTENT ===============');

		this.resizeColumns(content);
	}

	makeChildListings(listing, content, parentIndex) {
		let css = this.state.css;
		let textAlignment = this.getTextAlignment;
		// let columnWidth = this.columnWidth(this.state.numColumnsNew);
		let columnWidth = `var(--width${this.props.component_name})`;

		let isFirst = true;
		if (this.state.listings.length > 0) {
			isFirst = false;
		}

		let [, listingsData] = getColumnsAndListings(listing, this.props.combined, isFirst);

		let newMarginBottomEm = this.state.listing_margin_bottom_em || this.props.listing_margin_bottom_em; // Use props if state hasn't been set yet
		let newMarginTopEm = this.state.listing_margin_top_em || this.props.listing_margin_top_em; // Use props if state hasn't been set yet
		let showBackground = this.props.show_listing_bg_color;
		let showListingLines = this.props.show_listing_lines;
		let showListingBorder = this.props.show_listing_border;
		let linesPerNum = this.props.show_listing_lines_num;
		let childIcon = this.fontAwesome2('icon_child');

		let propName = this.props.component_name;

		let columnLineWidth = this.props.vert_line_width;
		let useIndented = this.props.indented_scaling;
		let indentedSize = this.props.indented_scaling_size;

		let lineStyle = {
			marginTop:    newMarginTopEm + 'em',
			marginBottom: newMarginBottomEm + 'em'
		};
		let styleData = {
			marginTop:    '0.0em',
			marginBottom: '0.0em'
		};
		let styleDataChild = {
			marginTop:    '0.0em',
			marginBottom: '0.0em'
		};

		if (showBackground) {
			// entryBg = css['entryBg'];
			styleData = {
				...styleData,
				background: showBackground
			};
		}

		if (showListingLines === false) {
			lineStyle = {
				...lineStyle,
				background: 'transparent'
			};
		}

		function returnClasses(arr = []) {
			let newClassList = '';
			if (arr && Array.isArray(arr)) {
				arr.forEach((item) => {
					newClassList += ` ${css[item]}`;
				});
			}
			return newClassList;
		}

		function returnModifierClasses(arr = []) {
			let newClassList = '';
			if (arr && Array.isArray(arr)) {
				arr.forEach((item) => {
					newClassList += ` ${item}`;
				});
			}
			return newClassList;
		}

		let lineCounter = 1;
		let fullLine = this.props.vert_line_style;

		// Identify the data types being used and if an icon should be used
		let useChildIcons = false;
		if (this.props.icon_child > 0) {
			useChildIcons = true;
		}

		let gridTemplateColumnsState = this.state.gridTemplateColumns;

		listingsData.forEach(function(childListing, index) {
			let childClass = '';
			let childLine = ''; // showListingBackground
			if (index === listingsData.length - 1 && (showBackground || showListingBorder === true)) {
				childClass = css['childLast'];
				childLine = css['lineHide'];
			} else if (index === 0) {
				childClass = css['childFirst'];
				childLine = css['lineFirst'];
			} else if (index === listingsData.length - 1) {
				childClass = css['childLast'];
				childLine = css['lineLast'];
			}
			let incrementChildLines = {};
			if ((lineCounter) % linesPerNum !== 0) {
				incrementChildLines = {
					...lineStyle,
					background: 'transparent'
				};
			} else {
				incrementChildLines = {
					...lineStyle
				};
			}

			// let listGroupChildren = [];
			let listGroupChildArray = [];
			let childLineItem = '';

			let valsWithFormattedDate = childListing.vals.map((val, i) => {
				if (childListing.types[i] === 'datetime') {
					let unixDate = Date.parse(val);
					let date = new Date(unixDate);
					return date.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'});
				}
				return val;
			});

			childListing.vals = valsWithFormattedDate;

			let valsWithFormattedTime = childListing.vals.map((val, i) => {
				if (childListing.types[i] === 'time') {
					const timeArray = val.split(':');
					let hour = parseInt(timeArray[0], 10);
					const minute = timeArray[1];
					let period = 'AM';

					if (hour === 0) {
						hour = 12;
					} else if (hour >= 12) {
						hour -= 12;
						period = 'PM';
					}

					// Pad minute with leading zeros if needed
					const paddedMinute = minute.padStart(2, '0');
					val = `${hour}:${paddedMinute} ${period}`;
				}
				return val;
			});

			childListing.vals = valsWithFormattedTime;

			listing.colGroups.forEach((dataCol, i) => {
				// check for a columnLine
				if (!childListing.vals) {
					return;
				}
				if (childListing.colGroups && childListing.colGroups[i] && childListing.colGroups[i].width === 0 && i !== 0) {
					return;
				}
				styleDataChild = {
					...styleDataChild,
					textAlign: textAlignment(dataCol.textAlign)
				};
				let addNum = 2;

				if (i === 0 && useChildIcons === true) { // if icon
					listGroupChildArray.push(<div className={`${css[`cCol1`]} cCol cCol1`} style={styleDataChild} key={`Q${childListing.m1Key}${index}${i}`}>{childIcon}</div>);
				}

				listGroupChildArray.push(<div className={`${css[`cCol${i + addNum}`]} cCol cCol${i + addNum}`} style={styleDataChild} key={`R${childListing.m1Key}${index}${i}`}>{childListing.vals[i]}</div>);
			});

			childLineItem = <div className={`listingLine_${propName} listingLine ${css[`listingLine`]} ${css[`listingLineChild`]} ${childLine}`} data-margintop={newMarginTopEm} data-marginbottom={newMarginBottomEm} data-type="lineChild" style={incrementChildLines}></div>;


			lineCounter++;
			if (childListing.m1Key) {
				let childStyle = {
					width:               columnWidth,
					gridTemplateColumns: gridTemplateColumnsState
				};
				let customClassesChild = returnClasses(childListing.settings.custom_classes?.value);
				let modifierClassesChild = returnModifierClasses(childListing.settings.modifier_classes?.value);

				if (useIndented === true) {
					childStyle = {
						...childStyle,
						fontSize: `${indentedSize}em`
					};
				}
				let iconStyle = useChildIcons === true ? '' : '';
				content.push(
					<div
						id={`t2-i${parentIndex}-c${index}-${childListing.m1Key}`}
						className={`listingsItem t2 row_${propName} ${css[`listRow`]} ${childClass} ${modifierClassesChild} ${customClassesChild}`}
						data-bronco={childListing.m1Key}
						key={`t2-i${parentIndex}-c${index}-${childListing.m1Key}`}
						data-tier2-key={`t2-i${parentIndex}-c${index}-${childListing.m1Key}`}
						style={childStyle}
						m1key={childListing.m1Key}
					>
						{listGroupChildArray}
						{childLineItem}
					</div>
				);

				// TODO: FE needs to support infinite tiers, data will support infinite tiers with recursive call below.
				// content = this.makeChildListings(childListing, content);
			} else {
				lineCounter = 1;
				let randomNum = Math.random();
				let verticalClasses = css.vertColLineSpacedBot;
				if (fullLine === 0) {
					verticalClasses = css.vertColLineGradientBot;
				}
				if (fullLine === 1) {
					verticalClasses = css.vertColLineSolidBot;
				}
				let columnLineStyles = {
					// order: 2
					width: `${columnLineWidth}px`
				};
				content.push(
					<div
						className={verticalClasses}
						style={columnLineStyles}
						key={`verticalLine${randomNum}_${lineCounter}`}
					></div>
				);
			}
		});

		return content;
	}

	// sortByRank = (a, b) => {

	// 	if (a.props && b.props && a.props.rank && b.props.rank && (a.props.rank === b.props.rank)) {
	// 		if (a.props.listing_name.toLowerCase() > b.props.listing_name.toLowerCase()) {
	// 			return 1;
	// 		}
	// 		return -1;
	// 	}
	// 	if (a.props.rank > b.props.rank) {
	// 		return 1;
	// 	}
	// 	if (a.name || b.name) {
	// 		return 0;
	// 	}
	// 	return -1;
	// };

	removeColumnLines = (array) => {
		// console.log(`888 removeColumnLines()`);
		let currentData = array.filter(function(data, i) {
			return data.name !== 'columnLine';
		});

		let cleanData = Array.from(currentData);
		cleanData.forEach(function(listing, k) {
			// console.log(listing);
			if (Array.isArray(listing.listings) && listing.listings.length > 0) {
				for (let p = 0; p < listing.listings.length; p++) {
					// console.log(listing.children[p]);
					if (listing.listings[p].name === 'columnLine') {
						console.warn('Child Line Found');
						console.log(listing.listings);
						listing.listings.splice(p, 1);
						p--;
					}
				}
			}
		});
		// console.warn('--cleanData--');
		// console.log(cleanData);

		return cleanData;
	}

	standardOrCustom = () => {
		if (this.props.row_concatenation === true) {
			// Custom build for Port Authority
			return <ListingGroupCustom1
				{...this.props}
				ref={this.listingCustom1Ref}
				getColumnsAndListings={getColumnsAndListings}
			/>;
		}
		// return this.buildContent();
		return this.state.filteredContent;
	}

	render() {
		// Getting number of listings
		let listingsItems = document.querySelectorAll('.listingsItem').length;
		// console.log('GA Total Num Listings: ' + listingsItems);

		let css = this.state.css;

		// Custom classes
		let customClasses = '';
		let customClassesArray = [];
		if (this.props.custom_classes) {
			customClassesArray = this.props.custom_classes;
		}
		if (customClassesArray.length > 0) {
			customClassesArray.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		let modifierClassesArray = [];
		if (this.props.modifier_classes) {
			modifierClassesArray = this.props.modifier_classes;
		}
		if (modifierClassesArray.length > 0) {
			modifierClassesArray.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}
		let headerPadding = '';
		if (this.props.show_column_headers === true) {
			headerPadding = 'headerPadding';
		}

		let style = {
			fontSize:      this.props.font_size + 'px',
			letterSpacing: this.props.letter_spacing + 'px',
			lineHeight:    this.props.line_height,
			width:         `100%`
		};

		return (
			<>
				<div id={`list_${this.props.num}_${this.props.component_name}`} style={this.props.style} className={`listing_wrapper staticList${this.props.num} ${css[`cont`]}`}>
					{this.columnHeader()}
					<div className={`listing_container${this.props.num} ${css[`listingContainer`]} ${css[headerPadding]} ${headerPadding} ${modifierClasses} ${customClasses}`} id={`listingContainer_${this.props.component_name}`} style={style} data-cols={this.props.num_columns} data-retry="0">
						{this.standardOrCustom()}
					</div>
				</div>
			</>
		);
	}
}


class VerticalLine extends React.Component {
	render() {
		let css = this.props.css;
		let randomNum = Math.random();
		let verticalClasses = css.vertColLineSpacedBot;

		if (this.props.vert_line_style === 0) {
			verticalClasses = css.vertColLineGradientBot;
		}
		if (this.props.vert_line_style === 1) {
			verticalClasses = css.vertColLineSolidBot;
		}
		let columnLineStyles = {
			width: `${this.props.vert_line_width}px`
		};

		return (
			<div className={verticalClasses} style={columnLineStyles} data-key={`verticalLine${this.props.dataKey}_${randomNum}`} key={`verticalLine${this.props.dataKey}_${randomNum}`}></div>
		);
	}
}


export default ListingGroup;
