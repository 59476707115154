import React, { useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import QRCode from 'qrcode.react';
import LocationControl from '../util/LocationControl';
import { fetchPlaces } from '../util/places';
import {
	ternStaticTokenENV,
	ternUserNameENV,
} from '../ENV';

const userName = ternUserNameENV;

const YAHPinUrl = 'https://ts-condor-assets.s3.amazonaws.com/images/icons/yah-icon.png';
const placePinUrl = 'https://ts-condor-assets.s3.amazonaws.com/images/icons/bluePin.png';

if (!mapboxgl.workerClass) {
	const accessToken = ternStaticTokenENV;
	// eslint-disable-next-line import/no-webpack-loader-syntax
	mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // Wire up loaded worker to be used instead of the default
	mapboxgl.accessToken = accessToken;
}

class AmenitiesResponsiveMap extends React.Component{
	constructor(props) {
		super(props);

		this.state = {
			css: props.CSSModules[props.condor_render_name],
			map: null,
			category: '',
			nextCategory: '',
			results: null,
			idleTimeoutSeconds: 60,
			listingRefs: null,
			scrollPaddingTop: 0,
		}

		this.horizScrollRef = React.createRef();
		this.resultSet = {};
		this.markerSet = {};
		this.timer = null;
		this.mapRef = React.createRef();

		this.containerId = `amenitiesMap_${props.component_name}`;

		if (props.property_latlon) {
			this.latitude = props.latitude;
			this.longitude = props.longitude;
		} else {
			this.latitude = props.system_latitude;
			this.longitude = props.system_longitude;
		}
	}

	componentDidMount() {
		this.createMap();
	}

	resetMap() {
		const {
			map,
		} = this.state;

		if (!map) {
			return;
		}

		this.reCenterMap();

		this.setState({
			results: null,
			category: '',
			nextCategory: '',
		}, () => {
			this.clearMarkers();
		});
	}

	clearMarkers() {
		Object.values(this.markerSet).forEach((markerArray) => {
			markerArray.forEach((marker) => {
				marker.remove();
			});
		});
	}

	clearPopups() {
		Object.values(this.markerSet).forEach((markerArray) => {
			markerArray.forEach((marker) => {
				if (marker.getPopup().isOpen()) {
					marker.togglePopup();
				}
			});
		});
	}

	createMap() {
		const {
			map,
		} = this.state;

		const {
			zoom,
			mapbox_map_id,
		} = this.props;

		const {
			latitude,
			longitude,
		} = this;

		if (map) {
			map.remove();
		}

		// clearing the container before making a new map
		document.querySelector(`#${this.containerId}`).innerHTML = '';

		const newMap = new mapboxgl.Map({
			container: this.containerId, // container ID
			style: `mapbox://styles/${userName}/${mapbox_map_id}`, // style URL
			center: [longitude, latitude], // starting position [lng, lat]
			maxBounds: [[longitude - 4, latitude - 4], [longitude + 4, latitude + 4]],
			zoom, // starting zoom
			minZoom: zoom - 3,
			maxZoom: zoom + 3,
			preserveDrawingBuffer: false,
			attributionControl: false,
			logoPosition: 'top-left',
			performanceMetricsCollection: false,
		});
	
		newMap.on('load', () => {
			const YAHMarkerDiv = document.createElement('div');
			YAHMarkerDiv.style.backgroundImage = `url(${YAHPinUrl})`;
			YAHMarkerDiv.style.backgroundRepeat = 'no-repeat';
			YAHMarkerDiv.style.backgroundSize = 'cover';
			YAHMarkerDiv.style.width = '28px';
			YAHMarkerDiv.style.height = '45px';
	
			const YAHMarker = new mapboxgl.Marker(YAHMarkerDiv);
	
			YAHMarker.setLngLat([longitude, latitude]).addTo(newMap).setOffset([0, -20]);
	
			this.setState({
				map: newMap,
			});
	
			const locationControl = new LocationControl({
				originalLat: latitude,
				originalLng: longitude,
				originalZoom: zoom,
				class: 'amenitiesMapSelector',
				centerMapCallback: this.resetMap.bind(this),
			});

			newMap.addControl(locationControl, 'bottom-left');
	
			const attributionControl = new mapboxgl.AttributionControl();
			newMap.addControl(attributionControl, 'top-left');
	
			document.querySelectorAll('.mapboxgl-control-container a').forEach((anchor) => {
				anchor.addEventListener("click", (event) => {
					event.preventDefault();
				});
			});

			newMap.resize();
		});
	
		newMap.on('error', (error) => {
			const mapTimeout = 15000;
			console.error(`Error loading map. Will retry in ${mapTimeout}ms.`, error);
			setTimeout(() => {
				this.createMap();
			}, mapTimeout);
		});
	
		newMap.on('move', () => {
			this.resetTimer();
		});
	}

	async selectCategory(nextCategory) {
		const {
			category,
		} = this.state;

		const {
			places_search_radius,
		} = this.props;

		const {
			latitude,
			longitude,
		} = this;

		let nextResults = null;

		if (!this.resultSet[nextCategory]) {
			this.resultSet[nextCategory] = [];
			nextResults = await fetchPlaces(nextCategory, places_search_radius, latitude, longitude);
			this.resultSet[nextCategory] = nextResults;
	
			if (nextResults) {
				this.makeMarkers(nextResults, nextCategory);
			}
		} else {
			nextResults = this.resultSet[nextCategory];
		}

		if (category !== nextCategory) {
			this.clearMarkers();
		} else {
			this.clearPopups();
		}

		this.reCenterMap();

		this.setState({
			category: nextCategory,
			results: nextResults,
		}, () => {
			this.showMarkers(nextCategory);
		});
	}

	setScrollPaddingTop = (value, contHeight) => {
		let newValue = this.state.scrollPaddingTop + value;
		if (newValue < 0) {
			newValue = 0;
		}
		if (newValue > (contHeight - 50)) {
			return;
		}
		this.setState({
			scrollPaddingTop: newValue
		});
	}

	resetScrollPaddingTop = () => {
		this.setState({
			scrollPaddingTop: 0
		})
	}

	reCenterMap() {
		const {
			map,
		} = this.state;
		
		const {
			zoom,
		} = this.props;

		const {
			latitude,
			longitude,
		} = this;

		if (!map) {
			return;
		}

		map.flyTo({
			center: [longitude, latitude],
			zoom,
			duration: 900,
		});
	}

	makeMarkers(results, category) {
		const {
			css,
		} = this.state;

		this.markerSet[category] = this.markerSet[category] || [];
	
		this.markerSet[category] = results.map((result) => {
			const {
				latitude: markerLat,
				longitude: markerLng,
			} = result.geocodes.main;
	
			const placeNameToSearch = `${result.name}, ${result.location.formatted_address}`;
	
			const qrCode = <QRCode
				bgColor={'white'} // Color of the bright squares
				fgColor={'black'} // Color of the dark squares
				level="M" // QR Error correction level: L, M, Q, H
				style={{border: `5px solid white`}} // Size in pixels
				value={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(placeNameToSearch)}`}
				renderAs={'svg'}
			/>;
	
			const popup = `
				<div class="amenitiesMapPopup" style="color:black;">
					<div class="amenitiesMapPlaceName ${css.amenitiesMapPlaceName}"><b>${result.name}</b></div>
					<div class="amenitiesMapPlaceAddress ${css.amenitiesMapPlaceAddress}">${result.location.formatted_address}</div>
					<div class="amenitiesMapQRCode ${css.amenitiesMapQRCode}">${renderToStaticMarkup(qrCode)}</div>
				</div>
			`;
	
			const amenityPopup = new mapboxgl.Popup().setHTML(popup);
	
			const amenityMarker = new mapboxgl.Marker({
                color: 'green',    
            }).setLngLat([markerLng, markerLat]).setPopup(amenityPopup);
	
			return amenityMarker;
		});
	}

	showMarkers(category) {
		const {
			map,
		} = this.state;

		if (!map || !this.markerSet[category]) {
			return;
		}

		this.markerSet[category].forEach((marker) => {
			marker.addTo(map).setOffset([0, -20]);
		});
	}

	resetTimer() {
		const {
			idleTimeoutSeconds,
		} = this.state;

		clearTimeout(this.timer);

		this.timer = setTimeout(() => {
			this.resetMap();
		}, idleTimeoutSeconds * 1000);
	}
	
	render() {
		const {
			custom_classes,
			modifier_classes,
			keyNum,
			zoom,
			amenity_categories,
		} = this.props;

		const {
			css,
			map,
			results,
			category,
		} = this.state;

		// Custom classes
		let customClasses = '';
		if (custom_classes && custom_classes.length > 0) {
			custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (modifier_classes && modifier_classes.length > 0) {
			modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		let scrollHorizontally = (direction) => {
			if (this.horizScrollRef?.current) {
				let cont = this.horizScrollRef.current;
				let amount = 378;
				if (direction === 'right') {
					amount = -378;
				}
				cont.scrollLeft += amount;
			}
		}

		return (
			<div
				className={`${css.amenitiesMapContainer} ${modifierClasses} ${customClasses}`}
				style={{
					width: `100%`,
					height: `100%`,
				}}
				key={`img${keyNum}`}
			>
				<ResultsBar
					map={map}
					zoom={zoom}
					results={results}
					category={category}
					css={css}
					markerSet={this.markerSet}
					clearPopups={this.clearPopups.bind(this)}
					scrollPaddingTop={this.state.scrollPaddingTop}
					setScrollPaddingTop={this.setScrollPaddingTop}
				/>
				<div
					id={this.containerId}
				>
				</div>
				<div className='categorySelectMenu'>
					<div className='scrollLeft' onClick={() => scrollHorizontally('right')}>
						<svg className="horizScrollLeft" x="0px" y="0px" viewBox="0 0 50 50">
							<path d="M25.9,10L14.1,25l11.8,15H18L6.2,25L18,10H25.9z "></path>
							<path d="M40.7,10L28.9,25l11.8,15h-7.9L21.1,25l11.8-15 H40.7z"></path>
						</svg>
					</div>
					<div className='scrollContainer' ref={this.horizScrollRef}>
						<CategoryBar
							map={map}
							categories={amenity_categories}
							category={category}
							selectCategory={this.selectCategory.bind(this)}
							resetScrollPaddingTop={this.resetScrollPaddingTop}
						/>
					</div>
					<div className='scrollRight' onClick={() => scrollHorizontally('left')}>
						<svg className="horizScrollRight" x="0px" y="0px" viewBox="0 0 50 50">
							<path d="M22.4,40l11.8-15L22.4,10h7.9 l11.8,15L30.3,40H22.4z"></path>
							<path d="M7.5,40l11.8-15L7.5,10h7.9 l11.8,15L15.4,40H7.5z"></path>
						</svg>
					</div>
				</div>
			</div>
		);
	}
}


const generateIcon = (category) => {
	let src = '';

	switch (category) {
	case '13065':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_restaurant.png';
		break;
	case '13032':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_cafe.png';
		break;
	case '19009':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_lodging.png';
		break;
	case '11045':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_bank.png';
		break;
	case '19007':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_gas_station.png';
		break;
	case '17029':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_convenience_store.png';
		break;
	case '11044':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_atm.png';
		break;
	case '17069':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_grocery_or_supermarket.png';
		break;
	case '17035':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_pharmacy.png';
		break;
	case '17114':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_shopping_mall.png';
		break;
	case '18021':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_gym.png';
		break;
	case '11061':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_beauty_salon.png';
		break;
	case '11073':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_spa.png';
		break;
	case '17056':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_florist.png';
		break;
	case '17089':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_gift_store.png';
		break;
	case '15014':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_hospital.png';
		break;
	case '12075':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_post_office.png';
		break;
	case '19047':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_train_station.png';
		break;
	case '11011':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_car_wash.png';
		break;
	case '12098':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_place_of_worship.png';
		break;
	case '12080':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_library.png';
		break;
	case '16032':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_park.png';
		break;
	case '10027':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_museum.png';
		break;
	case '10024':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_movie_theater.png';
		break;
	case '13003':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_bar.png';
		break;
	case '10032':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_night_club.png';
		break;
	case '17076':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_liquor_store.png';
		break;
	case '13002':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_bakery.png';
		break;
	case '17018':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_book_store.png';
		break;
	case '11066':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_dry_cleaner.png';
		break;
	case '11069':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_laundry.png';
		break;
	case '17043':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_clothing_store.png';
		break;
	case '17023':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_electronics_store.png';
		break;
	case '17090':
		src = 'https://ts-condor-assets.s3.amazonaws.com/images/legacy-icons/ic_hardware_store.png';
		break;
	default:
		src = '';
		break;
	}

	return src;
};

const generateLabel = (category) => {
	let label = '';
	
	switch (category) {
	case '13065':
		label = 'Restaurant';
		break;
	case '13032':
		label = 'Café';
		break;
	case '19009':
		label = 'Lodging';
		break;
	case '11045':
		label = 'Bank';
		break;
	case '19007':
		label = 'Gas Station';
		break;
	case '17029':
		label = 'Convenience Store';
		break;
	case '11044':
		label = 'ATM';
		break;
	case '17069':
		label = 'Grocery';
		break;
	case '17035':
		label = 'Pharmacy';
		break;
	case '17114':
		label = 'Shopping Mall';
		break;
	case '18021':
		label = 'Gym';
		break;
	case '11061':
		label = 'Health & Beauty';
		break;
	case '11073':
		label = 'Spa';
		break;
	case '17056':
		label = 'Florist';
		break;
	case '17089':
		label = 'Gifts';
		break;
	case '15014':
		label = 'Hospital';
		break;
	case '12075':
		label = 'Post Office';
		break;
	case '19047':
		label = 'Rail Station';
		break;
	case '11011':
		label = 'Car Wash';
		break;
	case '12098':
		label = 'Spiritual Center';
		break;
	case '12080':
		label = 'Library';
		break;
	case '16032':
		label = 'Park';
		break;
	case '10027':
		label = 'Museum';
		break;
	case '10024':
		label = 'Movie Theater';
		break;
	case '13003':
		label = 'Bar';
		break;
	case '10032':
		label = 'Night Club';
		break;
	case '17076':
		label = 'Liquor Store';
		break;
	case '13002':
		label = 'Bakery';
		break;
	case '17018':
		label = 'Bookstore';
		break;
	case '11066':
		label = 'Dry Cleaner';
		break;
	case '11069':
		label = 'Laundry';
		break;
	case '17043':
		label = 'Clothing Store';
		break;
	case '17023':
		label = 'Electronics Store';
		break;
	case '17090':
		label = 'Hardware Store';
		break;
	default:
		label = category;
		break;
	}

	return label;
};

const ResultsBar = (props) => {
	const {
		map,
		zoom,
		results,
		category,
		css,
		markerSet,
		clearPopups,
		scrollPaddingTop,
		setScrollPaddingTop
	} = props;

	const vertScrollRef = useRef(null);
	const firstItemRef = useRef(null);

	const returnListingResults = () => {
		const resultsArr = results.map((result, index) => {
			return (
				<div
					className="resultsRow"
					key={`categoryListing_${index}`}
					onClick={() => {
						clearPopups(category);

						setTimeout(() => {
							clearPopups(category);
							markerSet[category][index].togglePopup();
						}, 800);

						const lnglat = markerSet[category][index].getLngLat();
						map.flyTo({
							center: [lnglat.lng, lnglat.lat],
							zoom,
							duration: 900,
						});
					}}
				>
					<div className="listingId">
						<div className="listingNumber">
							<div className="idBox">{index + 1}</div>
						</div>
					</div>
					<div className="listingName">{result.name}</div>
				</div>
			)
		});

		return (
			<div 
				className={css.listResults} 
				style={{paddingTop: scrollPaddingTop}} 
				ref={vertScrollRef}
			>
				{resultsArr}
			</div>
		);
	}

	let scrollVertically = (direction) => {
		if (vertScrollRef?.current) {
			let cont = vertScrollRef.current;
			let startScrollPos = cont.scrollTop;
			let contHeight = cont.clientHeight;

			let amount = 300;
			if ((direction === 'up' && startScrollPos === 0) || (direction === 'up' && parseInt(cont.style.paddingTop) > 0)) {
				setScrollPaddingTop(100, contHeight); // add ADA padding
			} else if (direction === 'down' && parseInt(cont.style.paddingTop) > 0) {
				setScrollPaddingTop(-100, contHeight); // reduce ADA padding
			} else if (direction === 'up') {
				amount = -300;
				cont.scrollTop += amount;
			} else if (direction === 'down') {
				cont.scrollTop += amount;
			}
		}
	}

	return (
		<div className="amenResults">
			<div className="amenResultsList">
				<div className="listingHeader">{generateLabel(category)}</div>

				{(!results) &&
					<div className="listingInfo">Please Choose a Category From the Buttons Below</div>
				}
				{(results?.length === 0) &&
					<div className="listingInfo noResults">No Results Found</div>
				}
				{(results?.length > 0) &&
					returnListingResults(results, category, map)
				}
				<div className={css.vertScrollBtns}>
					<div className={css.scrollDown} onClick={() => scrollVertically('down')}>
						<div className={css.scrollText}>SCROLL DOWN</div>
						<svg className={css.scrollSvg} x="0px" y="0px" viewBox="0 0 47 31">
							<path d="M3,2l20.5,13.5L44,2v11.5L23.5,27L3,13.5V2z"></path>
						</svg>
					</div>
					<div className={css.scrollUp}  onClick={() => scrollVertically('up')}>
						<div className={css.scrollText}>SCROLL UP</div>
						<svg className={css.scrollSvg} x="0px" y="0px" viewBox="0 0 47 31">
							<path d="M44,27L23.5,13.5L3,27V15.5L23.5,2 L44,15.5V27z"></path>
						</svg>
					</div>
				</div>

			</div>
		</div>
	);
};

const CategoryBar = (props) => {
    const {
        categories,
        selectCategory,
		resetScrollPaddingTop
    } = props;

    return (
        <div className='amenitiesMapSelector'>
            {categories.split(',').map((category) => {
                return (
                    <div className='radioOption'
						key={`categoryBar_${generateLabel(category)}`}
					>
                        <input
                            type='radio'
                            name='amenitiesCategorySelector'
                            className='radio'
                            onMouseDown={(e) => {
                                e.target.willUncheck = e.target.checked;
                            }}
                            onClick={(e) => {
								resetScrollPaddingTop();
                                if (e.target.willUncheck) {
                                    e.target.checked = false;
                                }
                                selectCategory(category);
                            }}
                        />
                        <label
                            className='radioText'
                        >
                            <img src={generateIcon(category)} />
                            <span>{generateLabel(category)}</span>
                        </label>
                    </div>
                )
            })}
        </div>
    );
};

export default AmenitiesResponsiveMap;
