/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-console */
/* eslint-disable no-invalid-this */
import React, {Component} from 'react';



class ListingGroupCustom1 extends Component {
	constructor(props) {
		super(props);

		this.state = {
			css:      {},
			content:  [],
			retry:    0,
			listings: [],
			data:     props.condor_component_list,
			logs:     [],
			settings: props
		};
	}

	componentDidMount() {
		this.setCSS(this.buildContent);
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.listings) !== JSON.stringify(this.props.listings) || JSON.stringify(prevProps.settings) !== JSON.stringify(this.props.settings)) {
			console.log('=============== ListingGroup UPDATE =============== ')

			document.getElementById(this.props.listingGroupID).classList.add('dontLook');

			this.setState({
				listings: [],
				retry: 0
			});

			// Clear styles off cont when transitioning from font_size_fixed value, to a value of zero
			// there is potenitial to optimize this
			let cont = document.getElementById(`listingContainer_${this.props.component_name}`);
			let fontSize = 16;
			if (this.props.font_size) {
				fontSize = this.props.font_size;
			}
			if (this.props.fixed_font_size && this.props.fixed_font_size !== 0) {
				fontSize = this.props.fixed_font_size;
			}
			if (cont) {
				cont.style.fontSize = `${fontSize}px`;
			}

			let buildDom = this.buildContent;
			setTimeout(function() {
				buildDom();
			}, 2000);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	setCSS = (callback) => {
		// Set theme
		const componentGroup = this.props.condor_component_group || 'default';
		const configuration = this.props.condor_configuration || 'layout1';
		const theme = this.props.condor_theme || 'theme1';

		import(`../styles/layout/${componentGroup}/${configuration}/themes/${theme}/listingsStaticCustom1.module.css`)
		.then((module) => {
			this.setState({ css: module, });
			setTimeout(() => {
				callback();
			}, 200);
		})
		.catch((err) => {
			console.error(err);
			import(`../styles/layout/default/layout1/themes/theme1/listingsStaticCustom1.module.css`)
			.then((module) => {
				this.setState({ css: module, });
				setTimeout(() => {
					callback();
				}, 200);
			})
			.catch((err) => {
				console.error(err);
			});
		});
	

	}

	getCSS = (obj, css) => {
		return parseFloat(window.getComputedStyle(obj).getPropertyValue(css));
	};

	resizeColumns = () => {
		console.log(`999 resizeColumns()`);
		let cont = document.getElementById(`listingContainer_${this.props.component_name}`);
		
		// let currentRetryValue = parseInt(cont.dataset.retry);
		let currentRetryValue = this.state.retry;
		// cont.dataset.retry = currentRetryValue + 1;

		let fontSize = this.getCSS(cont, 'font-size');
		let fixedFontSize = 0;
		if (this.props.fixed_font_size) {
			fixedFontSize = this.props.fixed_font_size;
		}

		// Something went wrong! Hard coded value to stop cycling over code
		if (currentRetryValue > 500) {
			console.log(`%cSTATIC LIST ${this.props.num} IS BAILING - Something broke - too many retries!!!`, 'background: #c70000; color: white; padding: 20px 70px;font-size: 2em');
			return;
		}

		// TEST CONDITION
		if (currentRetryValue >= 20) {
			// console.log(`%cSTATIC LIST ${this.props.num} / Font Size: ${fontSize} / Retry ${currentRetryValue}`, 'background: #111111; color: #bada55; padding: 5px 50px');
		}
		
		let rebuild_log = this.state.logs;
		rebuild_log.push(`List ${this.props.num}, Font Size: ${fontSize}, Retry: ${currentRetryValue}`);
		this.setState({
			logs: rebuild_log
		});

		let fontSizeMin = this.props.font_size_min.toFixed(1);
		if (fontSizeMin > this.props.font_size) {
			fontSizeMin = this.props.font_size - 2;
			console.log('fontSizeMin = this.props.fontSize - 2', fontSizeMin);
		}


		// ================= Container is too wide - scale down values, or fixed values need to be set =================
		let contScrollWidth = cont.scrollWidth;
		let fixedValues = false;
		// if (currentRetryValue === 0 && (fixedFontSize !== 0 || this.props.fixed_margin_top !== 0 || this.props.fixed_margin_bottom !== 0)) {
		if (currentRetryValue === 0 && fixedFontSize !== 0) {
			fixedValues = true;
		}
		if (contScrollWidth > Math.ceil(this.getCSS(cont, 'width')) || fixedValues === true ) {
			let rebuild_msg = '';
			// ======================== Reduce or Fix Font Size ========================
			let shrinkFontValue = this.props.bias_font_scale_vs_margin;
			if (fontSize > fontSizeMin && fixedFontSize === 0) {
				let newSize = fontSize - shrinkFontValue + 'px';
				rebuild_msg = `REBUILD LIST ${this.props.num} / Font Size ${fontSize} > Font Min ${fontSizeMin} / New font size: ${newSize}`;
				cont.style.fontSize = newSize;
			} else if (fixedFontSize > 0) {
				let fixedSize = `${fixedFontSize}px`;
				rebuild_msg = `STATIC LIST ${this.props.num} - Fixing font size to ${fixedSize}`;
				cont.style.fontSize = fixedSize;
			} else if (fontSize >= 1) {
				let newSize = fontSize - shrinkFontValue + 'px';
				rebuild_msg = `STATIC LIST ${this.props.num} - Ignoring min font size, shrinking to ${newSize}`;
				cont.style.fontSize = newSize;
			}

			let rebuild_log2 = this.state.logs;
			rebuild_log2.push(rebuild_msg);
			this.setState({
				logs: rebuild_log2,
				retry: this.state.retry + 1
			});

			this.resizeColumns();

		} else {
			let smiley = String.fromCodePoint(0x1F600);
			console.log(`${smiley} ${smiley} STATIC LIST CUSTOM ${this.props.num} Resizing Complete ${smiley} ${smiley}`);
			document.getElementById(this.props.listingGroupID).classList.remove('dontLook');
			return;
		}

	};


	returnClasses = (arr = []) => {
		let newClassList = '';
		if (arr && Array.isArray(arr)) {
			arr.forEach((item) => {
				newClassList += ` ${item}`;
			});
		}
		return newClassList;
	}

	returnModifierClasses = (arr = []) => {
		let newClassList = '';
		if (arr && Array.isArray(arr)) {
			arr.forEach((item) => {
				newClassList += ` ${item}`;
			});
		}
		return newClassList;
	}

	buildContent = () => {
		let css = this.state.css;
		let content = [];

		// Pass props to getColumnsAndListings until this.state.listings has been updated.
		let listingsParentObj = this.props;
		let isFirst = true;
		if (this.state.listings.length > 0) {
			listingsParentObj = this.state;
			isFirst = false;
		}

		let [,listingsData] = this.props.getColumnsAndListings(listingsParentObj, this.props.combined, isFirst);
		let propName = this.props.component_name;
		let existingm1Keys = [];

		listingsData.forEach((listing, index) => {
			if (listing.m1Key) {
				if (~existingm1Keys.indexOf(listing.m1Key)) {
					return;
				}

				existingm1Keys.push(listing.m1Key);

				let customClasses = this.returnClasses(listing.settings.custom_classes?.value);
				let modifierClasses = this.returnModifierClasses(listing.settings.modifier_classes?.value);
				let listGroupArray = [];
				let lineItem = '';


				// convert this to look for [T1 Image], [T1 Name], and then all [T2 listings (1st Column only)]
				listing.colGroups.filter((dataCol, i) => {
						if (i === 0) return true;
						if (dataCol.width === 0) return false;
						return true;
					})
					.forEach((dataCol, i) => {
						let value = listing.vals[i];
						if (listing.vals[i].includes('http')) {
							value = <img src={listing.vals[i]} />
						}
						listGroupArray.push(<div className={`${css[`xCol${i}`]} xCol xCol${i} `} key={`item${index}_col${i}_${listing.m1Key}`}>{value}</div>);
					});

				
				// T2 data
				let childListings = [];
				if (listing.listings && listing.listings.length > 0) {
					
					let [,t2Listings] = this.props.getColumnsAndListings(listing, this.props.combined, isFirst);
					
					// to use default sorting comment this secstion out, and use t2Listings instead
					let numSortedT2 = [...t2Listings].sort(function(a, b){
						return a.vals[0].localeCompare(b.vals[0], undefined, {numeric: true});
					});

					numSortedT2.forEach((child) => {
						childListings.push(
							<div className={`${css.xT2Item} xT2Item`} key={child.m1Key}>{child.vals}</div>
						);
					});

					listGroupArray.push(<div className={`${css[`xCol2`]} xCol xCol2 `} key={`zCol_${listing.m1Key}`}>
						<div className={`${css.xT2Cont} xT2Cont`}>
							{childListings}
						</div>
					</div>);
				}
				
				lineItem = <div className={`xListingLine_${propName} xListingLine ${css[`xListingLine`]} `} data-type="lineParent" ></div>;

				content.push(
					<div id={`t1-i${index}-${propName}`} className={`listingsItem t1 row_${propName} ${css[`xListRow`]} ${modifierClasses} ${customClasses}`} data-bronco={listing.m1Key} key={`listing.${listing.m1Key}_${index}`} data-tier1-key={`key-${listing.m1Key}`}>
						{listGroupArray}
						{lineItem}
					</div>
				);

			}
		});

		this.setState({
			content: content,
			// retry: 0
		})
		console.log('============ SET CONTENT ===============');

		this.resizeColumns();
	}



	render() {
		return (
			this.state.content
		);
	}
}

export default ListingGroupCustom1;
