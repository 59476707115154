import React from 'react';

import {
	defaultSorter
} from 'helper/Brood.js';

import '../styles/custom/brood.css';
// import 'animate.css/animate.css';

import './Brood.new.css';

const broodPOPSessionKey = 'brood_pop_queue';

window._broodProofOfPlay = window._broodProofOfPlay || {};

window._addMediaToBroodQueue = (slide) => {
	if (!window._broodMediaSlides) {
		window._broodMediaSlides = [];
	}

	for (const existingSlide of window._broodMediaSlides) {
		if (existingSlide.slideID === slide.slideID) {
			return;
		}
	}

	window._broodMediaSlides.push(slide);
};

window._broodMediaQueue = () => {
	if (!window._broodMediaSlides && !window._broodMediaErrors) {
		window._broodMediaSlides = [];
		window._broodMediaErrors = {};
	}

	const images = [];
	const videos = [];
	const media = {};

	const next = function (elmIndex = 0) {
		console.log('BROOD::MEDIA::NEXT:', window._broodMediaSlides.length);

		if (window._broodMediaSlides.length === 0) {
			images.forEach((image) => {
				image.removeAttribute('src');
			});
			videos.forEach((video) => {
				video.removeAttribute('src');
			});

			return;
		}

		const allImageElmsInUse = images.every((image) => {
			return image.hasAttribute('src');
		});
		const allVideoElmsInUse = videos.every((video) => {
			return video.hasAttribute('src');
		});

		const slide = window._broodMediaSlides.shift();
		if (slide.type === 'image' && allImageElmsInUse) {
			console.log('BROOD::MEDIA::ALL_IMAGE_ELMS_IN_USE:', window._broodMediaSlides.length);

			window._broodMediaSlides.unshift(slide);
			return;
		} else if (slide.type === 'video' && allVideoElmsInUse) {
			console.log('BROOD::MEDIA::ALL_VIDEO_ELMS_IN_USE:', window._broodMediaSlides.length);

			window._broodMediaSlides.unshift(slide);
			return;
		}

		let elm = null;
		if (slide.type === 'image') {
			elm = images[elmIndex];
		} else if (slide.type === 'video') {
			elm = videos[elmIndex];
		}

		elm.src = slide.data.src;
		elm.slide = slide;

		if (slide.type === 'video') {
			elm.play()
				.then(() => {
					// debugger;
				})
				.catch((err) => {
					console.error('BROOD::VIDEO::PLAY::ERROR:', err);
				});
		}
	};

	const retry = function (slide) {
		if (!slide) {
			return;
		}

		if (window._broodMediaErrors[slide.slideID] > 3) {
			console.error('BROOD::MEDIA::MAX_RETRIES_REACHED:', slide);

			return;
		}

		if (!window._broodMediaErrors[slide.slideID]) {
			window._broodMediaErrors[slide.slideID] = 0;
		}
		window._broodMediaErrors[slide.slideID]++;

		window._broodMediaSlides.push(slide);
	};

	for (let i = 0; i < 5; i++) {
		images[i] = document.createElement('img');
		images[i].style.display = 'none';

		images[i].addEventListener('load', () => {
			console.log('BROOD::IMAGE::SAVE:', images[i].src);

			images[i].removeAttribute('src');
			next();
		});

		images[i].addEventListener('error', (err) => {
			if (!images[i].src) {
				return;
			}
			console.error('BROOD::IMAGE::ERROR:', err);

			retry(images[i].slide);
		});

		document.body.appendChild(images[i]);
	}

	for (let i = 0; i < 3; i++) {
		videos[i] = document.createElement('video');
		videos[i].style.display = 'none';
		videos[i].muted = true;
		videos[i].preload = 'auto';
		videos[i].autoplay = true;

		videos[i].addEventListener('ended', (e) => {
			console.log('BROOD::VIDEO::SAVE:', videos[i].src);

			videos[i].removeAttribute('src');
			next(i);
		});

		videos[i].addEventListener('error', (err) => {
			if (!videos[i].src) {
				return;
			}
			debugger;
			console.error('BROOD::VIDEO::ERROR:', err);

			retry(videos[i].slide);
		});
		document.body.appendChild(videos[i]);

		videos[i].play()
			.then(() => {
				// debugger;
			})
			.catch((err) => {
				console.error('BROOD::VIDEO::PLAY::ERROR:', err);
			});
	}

	return next;
};
class BroodComponent extends React.Component {
	constructor(props) {
		super(props);

		this.debug = true;

		this.playing = false;
		this.updated = false;

		let transitionEnabled = props.brood_enable_transition;
		if (transitionEnabled !== false) {
			transitionEnabled = true;
		}

		let transitionDuration = 1;
		if (transitionEnabled) {
			transitionDuration = 1000;
		}

		this.broodID = `BROOD_${this.props.component_name}`;

		this.state = {
			transitionEnabled:  transitionEnabled,
			transitionDuration: transitionDuration,
			status:             {
				playing:        false,
				slideLoopCount: 0
			},

			pendingMediaReadySlideMap: {},
			mediaReadySlideMap:        {},

			providers:           [],
			slides:              [],
			currSlideID:         false,
			nextSlideID:         false,
			prevSlide:           false,
			isLastProviderSlide: false
		};

		this.runMediaQueue = window._broodMediaQueue();

		if (!window._brood) {
			window._brood = {};
		}
		window._brood[this.broodID] = this;
		this.currentPoPQueue = JSON.parse(sessionStorage.getItem(broodPOPSessionKey) || '{}');
	}

	componentDidMount() {
		// this.initDB(() => {
		// 	this.checkIfMediaIsReady();
		// });

		// start the slideshow
		this.play();

		document.removeEventListener('keydown', this.controls);
		document.addEventListener('keydown', this.controls);
	}

	componentWillUnmount() {
		// stop the slideshow
		this.stop();

		if (this.db) {
			this.db.close();
		}

		document.removeEventListener('keydown', this.controls);
		clearTimeout(this.transitionTimer);
	}

	componentDidUpdate(prevProps) {
		const {
			count,
			last_update,
			condor_component_list: providerComponents,
			showPhase,
			brood_enable_transition: transitionEnabled
		} = this.props;

		if (prevProps.showPhase !== showPhase) {
			if (showPhase) {
				this.play();
			} else {
				this.stop();
			}
		}

		// TODO: is this required from the old Pelican.js?
		if (prevProps.count !== count || prevProps.last_update !== last_update) {
			// debugger;
		}

		if (prevProps.brood_enable_transition !== transitionEnabled) {
			this.setState({
				transitionEnabled:  transitionEnabled,
				transitionDuration: (transitionEnabled) ? 1000 : 1
			});
		}

		const sortedProviderComponents = providerComponents.sort(defaultSorter);
		const providerComponentUUIDs = sortedProviderComponents.map((providerComponent) => {
			return providerComponent.props.component_name;
		});

		const {
			condor_component_list: prevProviderComponents
		} = prevProps;

		const sortedPrevProviderComponents = prevProviderComponents.sort(defaultSorter);
		const prevProviderComponentUUIDs = sortedPrevProviderComponents.map((providerComponent) => {
			return providerComponent.props.component_name;
		});

		let providerComponentsUpdated = false;
		for (let i = 0; i < providerComponentUUIDs.length; i++) {
			if (providerComponentUUIDs[i] !== prevProviderComponentUUIDs[i]) {
				providerComponentsUpdated = true;
				break;
			}
		}

		// check if the order or number of providers is
		// different, this does not check provider settings
		if (providerComponentsUpdated) {
			debugger;

			// TODO: trigger render, but don't update providers
			// this.setState({
			// 	providers: this.makeProviders()
			// });
		}
	}

	initDB(cb = () => { }) {
		if (!this.db) {
			this.dbRequest = indexedDB.open('arara', 1);
			this.dbRequest.onsuccess = (e) => {
				this.db = e.target.result;

				if (typeof cb === 'function') {
					return cb();
				}
			};

			this.dbRequest.onerror = (e) => {
				console.error('BROOD::ERROR_initDB:', e);
				debugger;
			};
		}
	}

	_isMediaIsReady(url) {
		return new Promise((resolve, reject) => {
			if (!this.db) {
				reject('Database not initialized');
			}

			const transaction = this.db.transaction('metadata', 'readonly');
			const store = transaction.objectStore('metadata');
			const request = store.get(url);

			request.onsuccess = (e) => {
				const result = e.target.result;
				resolve(Boolean(result));
			};

			request.onerror = (e) => {
				console.error('BROOD::ERROR_isMediaIsReady:', e);
				reject(e);
			};
		});
	}

	isMediaReady(slide) {
		this._isMediaIsReady(slide.data.src)
			.then((isReady) => {
				console.log('BROOD::isMediaReady:', slide.slideID, isReady);

				if (isReady) {
					const {
						pendingMediaReadySlideMap,
						mediaReadySlideMap
					} = this.state;

					pendingMediaReadySlideMap[slide.slideID] = false;
					mediaReadySlideMap[slide.slideID] = true;

					this.setState({
						pendingMediaReadySlideMap: pendingMediaReadySlideMap,
						mediaReadySlideMap:        mediaReadySlideMap
					});
				}
			})
			.catch((err) => {
				console.error('BROOD::ERROR_isMediaReady:', err);
				debugger;
			});
	}

	checkIfMediaIsReady() {
		const {
			pendingMediaReadySlideMap
		} = this.state;

		for (const slide of Object.values(pendingMediaReadySlideMap)) {
			this.isMediaReady(slide);
		}

		setTimeout(this.checkIfMediaIsReady.bind(this), 5000);
	}

	updateProvider(...newProvider) {
		const {
			providers,
			slides,
			pendingMediaReadySlideMap
		} = this.state;

		let existingProviders = {};
		for (let i = 0; i < providers.length; i++) {
			existingProviders[providers[i].providerID] = i;
		}

		let newProviders = [...providers];
		for (let i = 0; i < newProvider.length; i++) {
			const provider = newProvider[i];

			const existingProviderIndex = existingProviders[provider.providerID];
			if (existingProviderIndex !== undefined) {
				newProviders[existingProviderIndex] = provider;
			} else {
				newProviders.push(provider);
			}
		}
		newProviders.sort(defaultSorter);

		let [newSlides, newPendingMediaReadySlides] = this.makeSlides(newProviders);

		if (newSlides.length === 0) {
			this.setState({
				providers:                 newProviders,
				pendingMediaReadySlideMap: newPendingMediaReadySlides,
				slides:                    newSlides,
				currSlideID:               false,
				nextSlideID:               false
			});

			return;
		}

		let currSlide = this._currSlide(slides);
		if (!currSlide) {
			// if there is no current slide, then we're starting a new slideshow
			// or we could be dropping down to no slides at all (empty slideshow)
			// so we need to start with the first slide in the new array of slides
			if (newSlides.length > 0) {
				currSlide = newSlides[0];
			} else {
				this.setState({
					providers:                 newProviders,
					pendingMediaReadySlideMap: newPendingMediaReadySlides,
					slides:                    newSlides,
					currSlideID:               false,
					nextSlideID:               false
				});

				return;
			}
		}

		const {
			providerID,
			slideID
		} = currSlide;

		let hasCurrSlideInNewSlides = newSlides.find((slide) => {
			return (slide.providerID === providerID && slide.slideID === slideID);
		});

		// make sure the current slide finishes before moving onto
		// the next slide as part of the new array or slides in state
		if (!hasCurrSlideInNewSlides) {
			let providerIndex = 0;
			for (let i = 0; i < newSlides.length; i++) {
				if (newSlides[i].providerID === providerID) {
					providerIndex = i;
					break;
				}
			}

			currSlide = newSlides[providerIndex];
		}

		let hasProviderUpdates = false;
		if (providers.length !== newProviders.length) {
			hasProviderUpdates = true;
		}

		if (!hasProviderUpdates) {
			for (let i = 0; i < providers.length; i++) {
				if (providers[i].providerID !== newProviders[i].providerID) {
					hasProviderUpdates = true;
					break;
				}
			}
		}

		let hasSlideUpdates = false;
		if (slides.length !== newSlides.length) {
			hasSlideUpdates = true;
		}

		if (!hasSlideUpdates) {
			for (let i = 0; i < slides.length; i++) {
				if (slides[i].slideID !== newSlides[i].slideID) {
					hasSlideUpdates = true;
					break;
				}
			}
		}

		let hasPendingMediaReadySlideUpdates = Object.values(pendingMediaReadySlideMap).filter((slide) => {
			return slide;
		}).length;

		if (!hasProviderUpdates) {
			console.log('no provider updates');
		}

		if (!hasSlideUpdates) {
			console.log('no slide updates');
		}

		if (!hasPendingMediaReadySlideUpdates) {
			console.log('no pending media ready slide updates');
		}

		if (!hasProviderUpdates && !hasSlideUpdates && !hasPendingMediaReadySlideUpdates) {
			// return;
		}

		let currSlideID = currSlide.slideID;
		let nextSlideID = false;

		const nextSlide = this._nextSlide(newSlides, currSlide);
		if (nextSlide) {
			nextSlideID = nextSlide.slideID;
		}

		this.updated = true;
		this.setState({
			providers:                 newProviders,
			pendingMediaReadySlideMap: pendingMediaReadySlideMap,
			slides:                    newSlides,
			currSlideID:               currSlideID,
			nextSlideID:               nextSlideID,
			prevSlide:                 currSlide
		});

		console.log('BROOD::updateProvider:', newProvider, newSlides, currSlideID, nextSlideID);
	}

	removeProvider(providerID) {
		// debugger;
	}

	makeSlides(providers = []) {
		const {
			sort: broodSortType
		} = this.props;

		const {
			pendingMediaReadySlideMap,
			mediaReadySlideMap
		} = this.state;

		const slides = [];

		for (const provider of providers) {
			const {
				options: providerOptions
			} = provider;

			const {
				// eslint-disable-next-line no-shadow
				providerSortType
			} = providerOptions;

			if (!provider.skipper || typeof provider.skipper !== 'function') {
				// eslint-disable-next-line no-unused-vars
				provider.skipper = (slide) => {
					return false;
				};
			}

			if (providerSortType === 'random') {
				provider.slides.sort(() => {
					return Math.random() - 0.5;
				});
			}

			for (let i = 0; i < provider.slides.length; i++) {
				const slide = provider.slides[i];

				slide.providerID = provider.providerID;

				if (!slide.skipper || typeof slide.skipper !== 'function') {
					// eslint-disable-next-line no-shadow
					slide.skipper = (slide) => {
						// should brood or provider skip?
						return this.skipper(slide) || provider.skipper(slide);
					};
				}

				// if (slide.type === 'video') {
				// 	let isMediaReady = false;
				// 	for (const [mediaReadySlideID, mediaReadySlide] of Object.entries(mediaReadySlideMap)) {
				// 		if (mediaReadySlideID === slide.slideID && mediaReadySlide) {
				// 			isMediaReady = true;
				// 			break;
				// 		}
				// 	}

				// 	if (!isMediaReady) {
				// 		pendingMediaReadySlideMap[slide.slideID] = slide;
				// 		continue;
				// 	}

				// 	// window._addMediaToBroodQueue(slide);
				// }

				slides.push(slide);
			}
		}

		// handle any background media pre-fetching
		// this.runMediaQueue();

		const providerCount = Object.keys(providers).length;
		if (providerCount > 1 && broodSortType === 'random') {
			slides.sort(() => {
				return Math.random() - 0.5;
			});
		}

		return [slides, pendingMediaReadySlideMap];
	}

	controls = (e) => {
		switch (e.key) {
		case 'p':
			if (this.playing) {
				this.stop();
			} else {
				this.play();
			}

			break;

		case 'n':
			this.next();

			break;

		case 't':
			this.setState({
				transitionEnabled:  !this.state.transitionEnabled,
				transitionDuration: (!this.state.transitionEnabled) ? 1000 : 1
			});

			break;

		default:
			break;
		}
	}

	play = () => {
		if (this.playing) {
			return;
		}
		// console.log('play');

		this.playing = true;
		this.setState({
			status: {
				...this.state.status,
				playing: true
			}
		});
	}

	stop = () => {
		if (!this.playing) {
			return;
		}

		this.playing = false;
		this.setState({
			status: {
				...this.state.status,
				playing: false
			}
		});
	}

	_index = (slides = [], currSlideIndex = 0, diff = 0) => {
		if (slides.length <= 1) {
			return 0;
		}

		let nextIndex = currSlideIndex + diff;
		if (nextIndex > (slides.length - 1)) {
			nextIndex = (diff - (slides.length - currSlideIndex));
			// nextIndex = 0;
		}

		return nextIndex;
	}

	_currSlideProvider = (slides = []) => {
		const currSlide = this._currSlide(slides);
		if (!currSlide) {
			return false;
		}

		const {
			providers
		} = this.state;

		return providers.find((provider) => {
			return provider.providerID === currSlide.providerID;
		});
	}

	_currSlide = (slides = [], slideID = false) => {
		const {
			currSlideID
		} = this.state;

		if (slides.length === 0 || !currSlideID) {
			return null;
		}

		return slides.find((slide) => {
			if (slideID) {
				return slide.slideID === slideID;
			}

			return slide.slideID === currSlideID;
		});
	}

	_nextSlide = (slides = [], currSlide) => {
		if (!currSlide || !currSlide.slideID) {
			// try to find it at a new index in the current slides
			currSlide = this._currSlide(slides);

			// if we still can't find it, then slides have been
			// updated to no longer include our current slide
			// we'll need to get it from state.prevSlide instead
			if (!currSlide) {
				const {
					prevSlide
				} = this.state;

				currSlide = prevSlide;

				// if we still can't find it, then we're in a bad state somehow
				// and we should just return the first slide in the new slides
				// this could cause a slide to get skipped, but it's better than
				// crashing the slideshow
				if (!currSlide) {
					if (slides.length > 0) {
						currSlide = slides[0];
					}
				}
			}
		}

		if (slides.length === 0) {
			return false;
		}

		if (slides.length === 1) {
			return slides[0];
		}

		let currSlideIndex = 0;
		for (let i = 0; i < slides.length; i++) {
			if (slides[i].slideID === currSlide.slideID) {
				currSlideIndex = i;
				break;
			}
		}

		let nextSlideIndex = this._index(slides, currSlideIndex, 1);
		let nextSlide = slides[nextSlideIndex];

		let nextCount = 0;
		let slideCount = slides.length;

		while (currSlide.slideID === nextSlide.slideID || nextSlide.skipper(nextSlide) || nextSlide.skip) {
			nextSlide = slides[this._index(slides, currSlideIndex, nextCount)];
			nextCount++;

			if (slideCount === 0) {
				return currSlide;
			}
			slideCount--;
		}

		return nextSlide;
	}

	// beforeNext, beforeLastSlide,
	_beforeNext(currSlide, isLastSlide = false) {
		const {
			providers,
			slides
		} = this.state;

		if (currSlide && currSlide.provider && typeof currSlide.provider.beforeNext === 'function') {
			if (this.debug) {
				console.log('BROOD::DEBUG::beforeNext: curr:', currSlide.providerID, currSlide.slideID);
			}

			currSlide.provider.beforeNext(currSlide);
		}

		if (isLastSlide) {
			const eventIDs = [];
			for (const provider of providers) {
				if (provider.eventID) {
					if (eventIDs.includes(provider.eventID)) {
						continue;
					}

					eventIDs.push(provider.eventID);
				}

				if (provider.beforeLastSlide && typeof provider.beforeLastSlide === 'function') {
					provider.beforeLastSlide(slides);
				}
			}
		}
	}

	// afterLastSlide, afterNext, beforeLastSlide
	_afterNext(currSlide, isLastSlide = false) {
		if (this.debug) {
			console.log('BROOD::DEBUG::afterNext');
		}

		const {
			providers,
			slides
		} = this.state;

		// prevSlide just finished, currSlide is now playing
		if (isLastSlide) {
			this.sendPoPQueue();
			const eventIDs = [];
			for (const provider of providers) {
				if (provider.eventID) {
					if (eventIDs.includes(provider.eventID)) {
						continue;
					}

					eventIDs.push(provider.eventID);
				}

				if (provider.afterLastSlide && typeof provider.afterLastSlide === 'function') {
					provider.afterLastSlide(slides);
				}
			}
		}

		if (currSlide && currSlide.provider && typeof currSlide.provider.afterNext === 'function') {
			const {
				// eslint-disable-next-line no-shadow
				slides
			} = this.state;

			const prevSlide = currSlide;
			const nextCurrSlide = this._currSlide(slides);

			if (this.debug) {
				console.log('BROOD::DEBUG::afterNext: prev:', prevSlide.providerID, prevSlide.slideID);
				console.log('BROOD::DEBUG::afterNext: curr:', nextCurrSlide.providerID, nextCurrSlide.slideID);
			}

			// afterNext(prevSlides=slides, prevSlide, currSlide)
			currSlide.provider.afterNext(slides, prevSlide, nextCurrSlide);
		}
	}

	// hooks: beforeNext, beforeLastSlide, afterLastSlide, afterNext
	next = (slidePlayInfo = {}) => {
		if (!this.playing) {
			return;
		}
		// console.log('next');

		// handle any background media pre-fetching
		// this.runMediaQueue();

		const {
			providers,
			slides,
			status: {
				slideLoopCount
			},
			transitionDuration
		} = this.state;

		const currSlide = this._currSlide(slides);
		// const provider = this._currSlideProvider(slides);

		const isOnlySlide = (!this.hasNextSlide());
		if (isOnlySlide) {
			this._beforeNext(currSlide, true);

			this.setState({
				status: {
					...this.state.status,
					slideLoopCount: (slideLoopCount + 1)
				}
			}, () => {
				this._afterNext(currSlide, true);
			});

			return;
		}

		const currSlideIndex = slides.findIndex((slide) => {
			return slide.slideID === currSlide.slideID;
		});

		const nextSlide = this._nextSlide(slides, currSlide);
		const nextSlideIndex = slides.findIndex((slide) => {
			return slide.slideID === nextSlide.slideID;
		});

		const isLastSlide = (nextSlideIndex < currSlideIndex);
		this._beforeNext(currSlide, isLastSlide);

		let nextCurrSlideID = false;
		let nextNextSlideID = false;

		if (nextSlide) {
			nextCurrSlideID = nextSlide.slideID;

			const nextNextSlide = this._nextSlide(slides, nextSlide);
			if (nextNextSlide) {
				nextNextSlideID = nextNextSlide.slideID;
			}
		}

		const nextState = {
			currSlideID: nextCurrSlideID,
			nextSlideID: nextNextSlideID,
			status:      {
				...this.state.status,
				slideLoopCount: 0
			}
		};

		this.setState(nextState, () => {
			clearTimeout(this.transitionTimer);

			this.transitionTimer = setTimeout(() => {
				this._afterNext(currSlide, isLastSlide);
			}, transitionDuration); // matches CSS transition duration

			const [, playTime] = this.broodProofOfPlayEnd(currSlide);
			if (slidePlayInfo.error || playTime < 1000) {
				// this._afterNext(currSlide, isLastSlide);

				// if the slide didn't play properly or it
				// was skipped quickly then we shouldn't pop
				return;
			}

			this.sendProofOfPlay(currSlide);
		});

		return;
	}

	hasNextSlide = () => {
		const {
			slides
		} = this.state;

		if (slides.length <= 1) {
			return false;
		}

		const currSlide = this._currSlide(slides);
		const nextSlide = this._nextSlide(slides, currSlide);

		return currSlide.slideID !== nextSlide.slideID;
	}

	skipper = (slide) => {
		return false;
	}

	broodProofOfPlayStart(slide) {
		if (!slide) {
			return;
		}

		// TODO: move from window to service worker for proper retries
		if (!window._broodProofOfPlay) {
			window._broodProofOfPlay = {};
		}

		console.log('BROOD::POP::START:', slide.slideID, slide.description);
		window._broodProofOfPlay[slide.slideID] = new Date();
	}

	broodProofOfPlayEnd(slide) {
		const now = new Date();

		console.log('BROOD::POP::END:', slide.slideID, slide.description);
		return [now, now - window._broodProofOfPlay[slide.slideID]];
	}

	async sendBroodOfPlay(slide) {
		const [endOfPlay, playTime] = this.broodProofOfPlayEnd(slide);
	
		if (!window._broodProofOfPlay[slide.slideID]) {
			// an error prevented the slide from playing
			// returning true to remove it from the queue
			return true;
		}

		const body = {
			system_uuid:   this.props.system_uuid,
			asset_url:     slide.data.src,
			description:   slide.description,
			provider:      slide.provider.constructor.providerName,
			provider_name: slide.provider.props.custom_content_sidebar_label || '',
			duration:      (playTime),
			media_type:    slide.type,
			start_of_play: window._broodProofOfPlay[slide.slideID].toISOString(),
			end_of_play:   (new Date(endOfPlay)).toISOString()
		};

		let host = window._getEnv('BROOD_POP_URL');
		if (!host) {
			host = window._getEnv('BRONCO_URL');
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'Slideshow POP',
			...body,
		});

		return fetch(`${host}/brood/pop`, {
			'method':  'POST',
			'headers': {
				'Content-Type': 'application/json'
			},
			'body': JSON.stringify(body)
		})
			.then((response) => {
				if (response.ok) {
					console.log('BROOD::POP::SUCCESS:', body);
				}

				return response.status === 200;
			})
			.catch((error) => {
				console.error('BROOD::POP::ERROR:', error);
			});
	}

	addToPoPQueue(slide, shouldAddToProviderProofOfPlayQueue, shouldAddToBroodProofOfPlayQueue) {
		const {
			provider,
		} = slide;
	
		this.currentPoPQueue[slide.provider.providerID] = this.currentPoPQueue[slide.provider.providerID] || {};
		this.currentPoPQueue[this.broodID] = this.currentPoPQueue[this.broodID] || {};

		const trimmedSlide = {
			slideID:           slide.slideID,
			proof_of_play_url: slide.proof_of_play_url,
			queueTime:         Date.now(),
			type:              slide.type,
			description:       slide.description,
			data: {
				src: slide.data.src,
			},
			provider: {
				providerID: provider.providerID,
				constructor: {
					providerName: provider.constructor.providerName,
				},
			},
		};

		if (shouldAddToProviderProofOfPlayQueue) {
			this.currentPoPQueue[provider.providerID][slide.proof_of_play_url] = trimmedSlide;
		}

		if (shouldAddToBroodProofOfPlayQueue) {
			this.currentPoPQueue[this.broodID][slide.slideID] = trimmedSlide;
		}

		sessionStorage.setItem(broodPOPSessionKey, JSON.stringify(this.currentPoPQueue));
	}

	removeFromPoPQueue(slide, removeFromProviderQueue, removeFromBroodQueue) {
		if (removeFromProviderQueue) {
			const {
				provider,
			} = slide;

			this.currentPoPQueue[provider.providerID] = this.currentPoPQueue[provider.providerID] || {};
			delete this.currentPoPQueue[provider.providerID][slide.proof_of_play_url];
		}

		if (removeFromBroodQueue) {
			this.currentPoPQueue[this.broodID] = this.currentPoPQueue[this.broodID] || {};
			delete this.currentPoPQueue[this.broodID][slide.slideID];
		}

		sessionStorage.setItem(broodPOPSessionKey, JSON.stringify(this.currentPoPQueue));
	}

	async sendProofOfPlay(slide, shouldSendToProvider = true, shouldSendToBrood = true) {
		const {
			enable_proof_of_play
		} = this.props;

		const {
			providers,
		} = this.state;

		let providerProofOfPlaySuccess = false;
		let broodProofOfPlaySuccess = false;

		let shouldAddToProviderProofOfPlayQueue = false;		
		let shouldAddToBroodProofOfPlayQueue = false;

		const provider = providers.find((p) => {
			return p.providerID === slide.provider?.providerID;
		});

		if (shouldSendToProvider && provider?.providerProofOfPlay) {
			const res = await provider.providerProofOfPlay(slide);
			providerProofOfPlaySuccess = !!res;
		}

		shouldAddToProviderProofOfPlayQueue = shouldSendToProvider && !providerProofOfPlaySuccess && !!provider?.providerProofOfPlay;

		if (shouldSendToBrood && enable_proof_of_play) {
			const res = await this.sendBroodOfPlay(slide);
			broodProofOfPlaySuccess = !!res;
		}

		shouldAddToBroodProofOfPlayQueue = shouldSendToBrood && !broodProofOfPlaySuccess && enable_proof_of_play;

		if (shouldAddToProviderProofOfPlayQueue || shouldAddToBroodProofOfPlayQueue) {
			this.addToPoPQueue(slide, shouldAddToProviderProofOfPlayQueue, shouldAddToBroodProofOfPlayQueue);
		}

		if (providerProofOfPlaySuccess || broodProofOfPlaySuccess) {
			this.removeFromPoPQueue(slide, providerProofOfPlaySuccess, broodProofOfPlaySuccess);
		}
	}

	async sendPoPQueue() {
		console.log('BROOD::POP_QUEUE::START');

		for (const [providerKey, providerData] of Object.entries(this.currentPoPQueue)) {
			for (const [slideKey, slideData] of Object.entries(providerData)) {
				if (slideData.queueTime + 1000 * 60 * 60 < Date.now()) {
					this.removeFromPoPQueue(slideData, true, true);
				} else {
					await this.sendProofOfPlay(slideData, providerKey !== this.broodID, providerKey === this.broodID);
				}
			}
		}

		console.log('BROOD::POP_QUEUE::END');
	}

	renderProviders() {
		const {
			condor_component_list: providerComponents
		} = this.props;

		if (providerComponents.length === 0) {
			return null;
		}

		const sortedProviderComponents = providerComponents.sort(defaultSorter);

		return (
			React.Children.map(sortedProviderComponents, (providerComponent, i) => {
				if (React.isValidElement(providerComponent) === false) {
					return null;
				}

				return (
					<React.Fragment
						key={i}
					>
						{React.cloneElement(providerComponent, {
							broodID:    this.broodID,
							providerID: `PROVIDER_${providerComponent.props.component_name}`,
							slideshow:  this
						})}
					</React.Fragment>
				);
			})
		);
	}

	renderSlides() {
		const {
			status,
			transitionEnabled,
			transitionDuration,
			slides,
			currSlideID,
			nextSlideID
		} = this.state;

		if (slides.length === 0) {
			return null;
		}

		let lastSlideID;
		return slides.map((slide) => {
			if (lastSlideID === slide.slideID) {
				return null;
			}
			lastSlideID = slide.slideID;

			let className = 'brood-slide new-brood-slide';
			if (slide.slideID === currSlideID) {
				className += ' new-brood-slide-curr';
			}
			if (slide.slideID === nextSlideID) {
				className += ' new-brood-slide-next';
			}

			if (transitionEnabled) {
				className += ' new-brood-slide-transition';
			}

			let curr = false;
			if (slide.slideID === currSlideID) {
				curr = true;
			}

			let next = false;
			if (slide.slideID === nextSlideID) {
				next = true;
			}

			if (curr && next) {
				curr = false;
				next = true;
			}

			if (slides.length === 1) {
				curr = true;
				next = false;
			}

			return (
				<BroodSlide
					key={slide.slideID}
					className={className}
					curr={curr}
					next={next}
					done={!curr && !next}
					slide={slide}
					slideshow={this}
					status={status}
					transitionEnabled={transitionEnabled}
					transitionDuration={transitionDuration}
				/>
			);
		});
	}

	render() {
		const {
			slides
		} = this.state;

		if (slides.length === 0) {
			return this.renderProviders();
		}

		let {
			CSSModules,
			condor_render_name,
			custom_classes,
			modifier_classes,
			theme_variation
		} = this.props;

		let css = CSSModules[condor_render_name] || {};

		// Custom classes
		let customClasses = '';
		if (custom_classes && custom_classes.length > 0) {
			custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (modifier_classes) {
			modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		return (
			<>
				{this.renderProviders()}

				<div className={`${css.slideshowCont} slideshowCont ${customClasses} ${css[theme_variation]}`}>
					<div className={`${css.cont} ${modifierClasses} $`}>
						<div className="brood-container">
							{this.renderSlides()}
						</div>
					</div>
				</div>
			</>
		);
	}
}

function BroodSlide(props) {
	const {
		slide
	} = props;

	// if (slide.component) {
	// 	let SlideComponent = slide.component;
	// 	return (
	// 		<SlideComponent {...props} />
	// 	);
	// }

	switch (slide.type) {
	case 'image':
		return (
			<BroodImageSlide {...props} />
		);

	case 'video':
		return (
			<BroodVideoSlide {...props} />
		);

	case 'html':
		return (
			<BroodHTMLSlide {...props} />
		);

	default:
		console.error('BROOD::ERROR::UNKNOWN_SLIDE_TYPE:', slide);
		return null;
	}
}

class BroodHTMLSlide extends React.Component {
	constructor(props) {
		super(props);

		const {
			slide
		} = props;

		this.state = {
			// playing: props.status.playing,
			secondsRemaining: (slide.options.duration / 1000)
		};
	}

	componentDidMount() {
		this.startTimers();
	}

	componentWillUnmount() {
		this.stopTimers();
	}

	componentDidUpdate(prevProps) {
		const {
			status
		} = this.props;

		if (prevProps.status.playing !== status.playing) {
			if (status.playing) {
				let resume = true;
				this.startTimers(resume);
			} else {
				this.stopTimers();
			}
		}

		if (prevProps.status.slideLoopCount !== status.slideLoopCount) {
			// slideshow.next() has no next slide currently
			// so we need to check if the slideLoopCount has changed
			// and if so, reset the timer to loop this slide again
			if (status.slideLoopCount > 0) {
				this.stopTimers();

				this.setState({
					secondsRemaining: (this.props.slide.options.duration / 1000)
				}, () => {
					let resume = false;
					this.startTimers(resume);
				});
			}
		}

		if (prevProps.curr !== this.props.curr && this.props.curr) {
			this.stopTimers();

			this.setState({
				secondsRemaining: (this.props.slide.options.duration / 1000)
			}, () => {
				let resume = false;
				this.startTimers(resume);
			});
		}

		// console.log('UPDATE', prevProps, this.props);
	}

	startTimers(resume = false) {
		const {
			slideshow,
			slide: {
				options
			}
		} = this.props;

		let {
			duration
		} = options;

		const {
			secondsRemaining
		} = this.state;

		if (resume) {
			duration = (secondsRemaining * 1000);
		}

		this.nextSlideTimer = setTimeout(() => {
			slideshow.next();
		}, duration + 500); // add 500ms to account for transition

		this.secondsRemainingCountdownInterval = setInterval(() => {
			this.setState({
				secondsRemaining: (this.state.secondsRemaining - 1)
			});
		}, 1000);
	}

	stopTimers() {
		clearTimeout(this.nextSlideTimer);
		clearInterval(this.secondsRemainingCountdownInterval);
	}

	renderControls() {
		const {
			status,
			slideshow
		} = this.props;

		return (
			<>
				<button
					disabled={status.playing}
					onClick={() => {
						slideshow.play();
					}}
				>PLAY</button>
				&nbsp;
				<button
					onClick={() => {
						slideshow.stop();
					}}
				>STOP</button>
				&nbsp;
				<button
					onClick={() => {
						slideshow.next();
					}}
				>NEXT</button>
			</>
		);
	}

	render() {
		const {
			slide
		} = this.props;

		let {
			secondsRemaining
		} = this.state;

		if (!this.props.curr) {
			return null;
		}

		return (slide.data.html);

		// left pad with 0
		const slideNumber = String((slide.providerIndex + 1)).padStart(3, '0');
		secondsRemaining = String(secondsRemaining).padStart(3, '0');

		return (
			<div className={'html-slide slide-' + slideNumber}>
				<div className="middle">
					<h1>Slide #{slideNumber} - {secondsRemaining}<small>s</small></h1>
					{this.renderControls()}
				</div>
			</div>
		);
	}
}

class BroodImageSlide extends React.Component {
	constructor(props) {
		super(props);

		// this.state = {};

		this.imageRef = React.createRef();
	}

	componentDidMount() {
		const {
			curr
		} = this.props;

		if (curr) {
			this.prepareImage();
		}
	}

	componentWillUnmount() {
		this.stopTimer();
	}

	componentDidUpdate(prevProps) {
		const {
			status,
			curr,
			next,
			done,
			slideshow
		} = this.props;

		if (prevProps.status.playing !== status.playing) {
			if (curr && status.playing) {
				this.onImageLoad();
			} else {
				this.stopTimer();
			}
		}

		// // no longer just a single slide
		// if (slideshow.state.slides.length > 1) {
		// 	if (curr && status.slideLoopCount > 0 && !this.slideTimer) {
		// 		return this.startTimer();
		// 	}
		// }

		if (prevProps.status.slideLoopCount !== status.slideLoopCount) {
			if (prevProps.status.slideLoopCount < status.slideLoopCount) {
				return;
			}
		}

		if (prevProps.curr !== curr) {
			if (curr) {
				this.prepareImage();
			}
		}

		if (prevProps.next !== next) {
			if (next) {
				if (!curr && next) {
					this.stopTimer();
				}
			}
		}

		if (prevProps.done !== done) {
			if (done) {
				this.stopTimer();
			}
		}
	}

	startTimer() {
		const {
			curr,
			slide,
			slideshow
		} = this.props;

		if (!curr) {
			return;
		}

		const {
			options
		} = slide;

		const {
			duration
		} = options;

		if (this.slideTimer) {
			this.stopTimer();
		}

		slideshow.broodProofOfPlayStart(slide);
		console.log('BROOD::IMAGE::START_TIMER:', slide.slideID);

		this.slideTimer = setInterval(() => {
			console.log('BROOD::IMAGE::NEXT:', slide.slideID);
			slideshow.next();
		}, duration);
	}

	stopTimer() {
		const {
			slide
		} = this.props;

		console.log('BROOD::IMAGE::STOP_TIMER:', slide.slideID);

		clearInterval(this.slideTimer);
		this.slideTimer = null;
	}

	prepareImage() {
		const {
			slide
		} = this.props;

		const {
			data
		} = slide;

		const img = new Image();
		img.src = data.src;

		img.onload = this.onImageLoad.bind(this);
		img.onerror = this.onImageError.bind(this);
	}

	onImageLoad(e) {
		this.startTimer();
	}

	onImageError(err) {
		const {
			slideshow
		} = this.props;

		this.stopTimer();

		console.error('BROOD::IMAGE::ERROR:', err);

		slideshow.next({
			error: err
		});
	}

	render() {
		const {
			slide,
			className: slideClassName
		} = this.props;

		const {
			data
		} = slide;

		return (
			<img
				className={slideClassName}
				style={this.props.style || {}}
				ref={this.imageRef}
				data-limit-skip={!!slide.skip}
				data-desc={slide.description}
				src={data.src}
				alt={data.alt}
				// onLoad={this.onImageLoad.bind(this)}
				// onError={this.onImageError.bind(this)}
			/>
		);
	}
}

class BroodVideoSlide extends React.Component {
	constructor(props) {
		super(props);

		// this.state = {};

		this.videoPlayedFully = false;
		this.videoRef = React.createRef();
	}

	componentDidMount() {
		const {
			curr,
			slide,
			slideshow
		} = this.props;

		// block duplicate error/slideshow.next() calls
		// due to video play promise errors catching and
		// video onerror event firing at the same time
		this.errHandled = false;

		if (curr) {
			this.play();
		}
	}

	// Brood unmounted, so clean up everything!
	componentWillUnmount() {
		// const {
		// 	slideshow
		// } = this.props;

		// if (slideshow.state.slides.length < 3) {
		// 	// don't release video from memory
		// 	// if there are only 2 slides
		// 	return;
		// }

		this.unload();
	}

	componentDidUpdate(prevProps) {
		const {
			status,
			transitionDuration,
			slide,
			curr,
			next,
			done
		} = this.props;

		if (prevProps.status.playing !== status.playing) {
			if (status.playing && curr) {
				this.play();
			} else {
				this.pause();
			}
		}

		if (prevProps.curr !== curr) {
			if (curr) {
				// console.log('VIDEO::CURR::PLAY:', slide.slideID, this.props);

				this.play();
			}

			if (!curr) {
				// console.log('VIDEO::CURR::PAUSE:, slide.slideID, this.props);

				this.pause();
				this.videoRef.current.currentTime = 0;
			}
		}

		if (prevProps.next !== next) {
			if (next) {
				// preload video when it is the next slide
				// if we have only 2x slides the slides will
				// swap between curr and next, so we need to
				// make sure if props.done is never triggered
				// and the video is never unloaded from memory
				// that we don't preload the video again
				if (!this.loaded()) {
					console.log('BROOD::VIDEO::LOAD:', slide.slideID, this.props);

					this.load();
				}
			}
		}

		if (prevProps.done !== done) {
			if (done) {
				console.log('BROOD::VIDEO::DONE:', slide.slideID, this.props);

				clearTimeout(this.unloadTimer);

				// don't release video from memory until the transition is complete
				this.unloadTimer = setTimeout(() => {
					this.unload();
				}, transitionDuration); // matches CSS transition duration
			}
		}
	}

	load(cb = () => { }) {
		const {
			slide
		} = this.props;

		const {
			data
		} = slide;

		if (this.videoRef.current) {
			this.videoRef.current.src = data.src;
		}

		if (cb && typeof cb === 'function') {
			return cb();
		}
	}

	unload() {
		this.errHandled = true;

		if (!this.videoPlayedFully) {
			return;
		}

		this.pause();

		if (this.videoRef.current) {
			this.videoRef.current.src = '';
		}
	}

	loaded() {
		if (this.videoRef.current) {
			let l = window.location.href;
			if (this.videoRef.current.src === '' || this.videoRef.current.src === l) {
				return false;
			}
		}

		return true;
	}

	_play() {
		const {
			slideshow,
			slide
		} = this.props;

		if (this.videoRef.current) {
			this.videoRef.current.play()
				.then(() => {
					slideshow.broodProofOfPlayStart(slide);
					console.log('BROOD::VIDEO::PLAY:', this.props);
				})
				.catch((err) => {
					console.error('BROOD::VIDEO::ERROR_play:', err);

					console.log('BROOD::VIDEO::NEXT::ERROR:', err);

					if (!this.errHandled) {
						this.errHandled = true;

						slideshow.next({
							error: err
						});
					}
				});
		}
	}

	play() {
		this.errHandled = false;

		if (this.loaded()) {
			this._play();
		} else {
			this.load(() => {
				this._play();
			});
		}
	}

	pause() {
		if (this.videoRef.current) {
			this.videoRef.current.pause();
			// this.videoRef.current.currentTime = 0;
		}
	}

	onTimeUpdate() {
		const {
			slideshow
		} = this.props;

		const video = this.videoRef.current;

		if (video.loop) { // && video.currentTime >= video.duration - 0.5) {
			if (slideshow.hasNextSlide()) {
				video.loop = false;
			}
		}
	}

	onVideoError(err) {
		const {
			slideshow
		} = this.props;

		// don't error out due to this.unload()
		if (!this.loaded()) {
			return;
		}

		console.log('BROOD::VIDEO::NEXT::ERROR:', err);

		if (!this.errHandled) {
			this.errHandled = true;

			slideshow.next({
				error: err
			});
		}
	}

	onVideoEnded() {
		const {
			slideshow
		} = this.props;

		this.videoPlayedFully = true;

		if (slideshow.hasNextSlide()) {
			console.log('BROOD::VIDEO::NEXT');
			slideshow.next();
		}
	}

	render() {
		const {
			slideshow,
			slide,
			curr,
			next,
			className: slideClassName
		} = this.props;

		let preload = 'none';
		if (curr || next) {
			preload = 'auto';
		}

		if (!this.videoPlayedFully) {
			preload = 'auto';
		}

		let loop = false;
		if (!slideshow.hasNextSlide()) {
			loop = true;
		}

		return (
			<video
				className={slideClassName}
				style={this.props.style || {}}
				ref={this.videoRef}
				data-limit-skip={!!slide.skip}
				data-desc={slide.description}
				// crossOrigin='anonymous'
				preload={preload}
				// src={''} handled by the this.videoRef;
				onError={this.onVideoError.bind(this)}
				onTimeUpdate={this.onTimeUpdate.bind(this)}
				onEnded={this.onVideoEnded.bind(this)}
				loop={loop}
				muted={!slide.allowAudio}
			// controls={true}
			/>
		);
	}
}

export default BroodComponent;
