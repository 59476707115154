import React from 'react';

export const integerIconOptions = {
	'Directional Arrows': {
		0: (
			<div>
			</div>
		),
		1: (
			<div
				className='iconSvgWrapper'
				style={{
					display:    'flex',
					alignItems: 'center',
				}}
			>
				<svg
					className='arrowIconSvg'
					viewBox="0 0 500 309.9"
				>
					<path d="M227.2,294.4l-91.6-91.6h356.8v-62.5H135.6l91.6-91.6L178.5,0,7,171.5l171.5,171.5,48.7-48.7h0Z" />
					<path d="M9 18l6-6-6-6" />
				</svg>
			</div>
		),
		2: (
			<div
				className='iconSvgWrapper'
				style={{
					display:    'flex',
					alignItems: 'center',
				}}
			>
				<svg
					className='arrowIconSvg'
					viewBox="0 0 500 309.9"
				>
					<path d="M10.1,162.8l188.2,141.7c3,2.3,7,2.7,10.4,1c3.4-1.7,5.5-5.1,5.5-8.9v-66.6h269.5c5.5,0,9.9-4.5,9.9-9.9V89.7c0-5.5-4.4-9.9-9.9-9.9H214.1V13.2c0-3.8-2.2-7.2-5.5-8.9c-3.4-1.6-7.4-1.2-10.4,1L10.1,147c-2.5,1.9-4,4.8-4,7.9s1.5,6,4,7.9h0Z" />
					<path d="M9 18l6-6-6-6" />
				</svg>
			</div>
		),
		3: (
			<div
				className='iconSvgWrapper'
				style={{
					display:    'flex',
					alignItems: 'center',
				}}
			>
				<svg
					className='arrowIconSvg'
					viewBox="0 0 500 309.9"
				>
					<path d="M295.1,1.1L78.7,171.4l215.8,171.6v-79c3.8-.2,123.7-.9,126.8-1.1V81.6h-126.3V1.1h0Z"/>
				</svg>
			</div>
		),
		4: (
			<div
				className='iconSvgWrapper'
				style={{
					display:    'flex',
					alignItems: 'center',
				}}
			>
				<svg
					className='arrowIconSvg'
					viewBox="0 0 500 309.9"
				>
					<path d="M272.2,48.6l91.6,91.6H7v62.5h356.8l-91.6,91.6l48.7,48.7l171.5-171.5L320.9,0l-48.7,48.7h0Z" />
					<path d="M9 18l6-6-6-6" />
				</svg>
			</div>
		),
		5: (
			<div
				className='iconSvgWrapper'
				style={{
					display:    'flex',
					alignItems: 'center',
				}}
			>
				<svg
					className='arrowIconSvg'
					viewBox="0 0 500 309.9"
				>
					<path
						d="M489.6,147.1L301.4,5.4c-3-2.3-7-2.7-10.4-1c-3.4,1.7-5.5,5.1-5.5,8.9v66.6H16c-5.5,0-9.9,4.5-9.9,9.9v130.4
               c0,5.5,4.4,9.9,9.9,9.9h269.6v66.6c0,3.8,2.2,7.2,5.5,8.9c3.4,1.6,7.4,1.2,10.4-1l188.1-141.7c2.5-1.9,4-4.8,4-7.9
               s-1.5-6-4-7.9h0Z"
					/>
					<path d="M9 18l6-6-6-6" />
				</svg>
			</div>
		),
		6: (
			<div
				className='iconSvgWrapper'
				style={{
					display:    'flex',
					alignItems: 'center',
				}}
			>
				<svg
					className='arrowIconSvg'
					viewBox="0 0 500 309.9"
				>
					<path d="M126.2,341.9l216.4-170.3L126.8,0v79c-3.8.2-123.7.9-126.8,1.1v181.3h126.3v80.5h-.1Z"/>
				</svg>
			</div>
		),
	},
};

/**
 * Helper function for retrieving the label (JSX) for a given category + integer value.
 */
export function getIntegerIconLabel(category, value) {
	return integerIconOptions[category]?.[value] || null;
}
