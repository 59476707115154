import React, { PureComponent } from 'react'
import { dlRowClick } from '../DataLayer';
import { getIntegerIconLabel } from '../helper/IconOptions';
import Showdown from 'showdown';

const converter = new Showdown.Converter();

class ListingRow extends PureComponent {

	constructor(props) {
		super(props)

		this.state = {
			activeButton: '',
			configuration: 0,
			css: props.CSSModules["ListingRow"],
			data: ['John Smith', 'Floor A', '201'],
			messageText: "Sending...",
			modalTimeout: null,
			showMessageText: false,

			trayDetails: '',
			trayOpen: false,
		}
	}

	trayDetails = () => {
		let details = '';
		if (this.props.currentDetailAction === 1 && this.state.trayOpen === true) {
			details = this.state.trayDetails;
		}
		return details;
	}

	isTime(time) {
		if (time.match(/^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/g)) {
			return true
		}
		return false
	}

	isValidDate(date) {
		// Checks if the value string is a date in ISO 8601 format"
		// YYYY-MM-DDTHH:MM:SSZ
		const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

		return iso8601Regex.test(date);
	}

	convertMilitaryToCivilianTime(militaryTime) {
		const timeArray = militaryTime.split(":");
		let hour = parseInt(timeArray[0], 10);
		const minute = timeArray[1];
		let period = "AM";

		if (hour === 0) {
			hour = 12;
		} else if (hour === 12) {
			period = "PM"
		} else if (hour > 12) {
			hour -= 12;
			period = "PM";
		}

		// Pad with leading zeros if needed
		const paddedMinute = minute.padStart(2, '0');

		return `${hour}:${paddedMinute} ${period}`;
	}

	handleDown = (buttonId, msg) => {
		// console.log(`Event ${msg} handleDown() activeButton:${buttonId}`);
		this.setState({activeButton: buttonId})
	}

	handleUp = (msg) => {
		// console.log(`Event ${msg} handleUp() activeButton:''`);
		if (this.state.activeButton !== '') {
			this.setState({activeButton: ''});
		}
	}

	sendMessage = (formattedData, type) => {		
		const url = `${window._getEnv('BRONCO_URL')}/vc`;

		const body = {
			'messageID': formattedData.messageID,
			'contact': formattedData.contact,
			'type':	   type,
		}

		fetch(url, {
			body: JSON.stringify(body),
			headers: { 'Content-Type': 'application/json' },
			method: 'POST'
		})
		.then((response) => {
			if (response.ok || response.status === 200 || response.status === 201) { // Check if status is 200 or 201			
				this.setState({ messageText: "Your message has been sent." });
        	} else {
            	throw new Error('Non-success response status'); // Throw an error for non-200/201 statuses
        	}
        	return response.json();
		})
		.catch((err) => {
			this.setState({ messageText: "Message failed to send."});
			console.error(err);
			
		});
		this.handleMessageClick();
	}

	getDataFromMessagingOptions = (data) => {
		const { detailsGrp } = this.props;

		let label;
		let contact = '';
		for (let detail of detailsGrp) {
			if (detail.column_uuid === data.column_uuid && detail.value) {
				label = detail.label;
		
				try {
					if (detail.type === "SMS Message") {
						let jsonPhoneData = JSON.parse(detail.value);
						contact = `+1${jsonPhoneData.area}-${jsonPhoneData.n}`.replace(/-/g, '');;
					} else if (detail.type === "Email Message") {
						debugger
						let jsonEmailData = JSON.parse(detail.value);
						contact = `${jsonEmailData.email}@${jsonEmailData.domain}`;
					} else {
						contact = detail.value;
					}
				} catch (err) {
					console.log("Failed parsing detail.value", err);
				}
			}
		}
		
		return[contact, label] ;
	}
	

	createData = () => {
		let css = this.state.css;
		let rowCss = this.props.css;
		let rowUUID = this.props.rowUUID;
		let currentDetailAction = this.props.currentDetailAction;
		let messageLabels = this.props.messageLabels

		let rowIconClasses = `${css.rowIcon} rowIcon`;
		if (currentDetailAction === 7) {
			rowIconClasses += ` ${css.wayfindingIcon}`
		}
		if (currentDetailAction === 13) { 
			rowIconClasses += ` ${css.videoIcon}`
		}

		let row = [];
		let imageTypeData = []; // To store image-type data
		let stringTypeData = []; // To store string-type data

		
		if (this.props.currentDetailAction !== 0 && !this.props.boxStyleDisplay) {
			row.push(<div className={rowIconClasses} key={`icon_${rowUUID}`}></div>);
		}
		this.props.data.forEach((data, i) => {
			// console.log("data", data)
			
			let formattedData = data.value
			switch (data.type) {
				case 'time':
					if (data.value) {
						formattedData = this.convertMilitaryToCivilianTime(data.value);
					}
					break;
				case 'datetime':
					if (data.value) {
						let unixDate = Date.parse(data.value);
						let date = new Date(unixDate);
						formattedData = date.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });
					}
					break;
				case 'int':
					if (data.displayName === 'Directional Arrows') {
						formattedData = getIntegerIconLabel(data.displayName, data.value)
					}
					break;
				default:
					break;
			}

			if (data.displayName === 'SMS Message' || data.displayName === 'Email Message' || data.displayName === 'Teams' || data.displayName === 'Slack') {				
				let [contact, label] = this.getDataFromMessagingOptions(data);
				formattedData = { messageID: messageLabels[label]?.messageID, contact: contact, label: messageLabels[label]?.messageButtonLabel };
			}

			if (!this.props.boxStyleDisplay) {
				let lineCss = {
					width: '0%'
				}
				if (typeof this.props.css !== 'undefined') {
					lineCss = {
						width: rowCss[i] + '%',
					}
				}

				if (this.props.textAlign) {
					lineCss = {
						...lineCss, 
						textAlign: this.props.textAlign[i],
					}
				}

				if (data.displayName === 'Directional Arrows') {
					lineCss = {
						...lineCss, 
						display: 'flex',
    					justifyContent: this.props.textAlign[i],
					}
				}
					
					if (lineCss.width !== '0%') {
						switch (data.displayName) {
							case 'SMS Message':
								if (formattedData.label) {
									stringTypeData.push(
										<div
											className={`${css.rowData} ${css[`rowData_${i}`]} rowData rowData_${i} ${css.msgBtnCont} msgBtnCont`}
											style={lineCss}
											data-key={`button_${i}_${rowUUID}`}
											key={`button_${i}_${rowUUID}`}
										>
										<button 
											className={`${css.rowData} rowData_${i} ${css.msgBtn} msgBtn`}
											onClick={(event) => {
												event.stopPropagation();
												this.sendMessage(formattedData, 'sms')
											}}>
											{formattedData.label}
										</button>
										</div>
									)
								} else {
									// If empty label, create empty div for formatting purposes
									stringTypeData.push(
										<div
												className={`${css.rowData} ${css[`rowData_${i}`]} rowData rowData_${i}`}
												style={lineCss}
												data-key={`button_${i}_${rowUUID}`}
												key={`button_${i}_${rowUUID}`}
											>
										</div>
									)
								}
								break;
							case 'Email Message':
								if (formattedData.label) {
									stringTypeData.push(
										<div
											className={`${css.rowData} ${css[`rowData_${i}`]} rowData rowData_${i} ${css.msgBtnCont} msgBtnCont`}
											style={lineCss}
											data-key={`button_${i}_${rowUUID}`}
											key={`button_${i}_${rowUUID}`}
										>
										<button 
											className={`${css.rowData} rowData_${i} ${css.msgBtn} msgBtn`}
											onClick={(event) => {
												event.stopPropagation();
												this.sendMessage(formattedData, 'email')
											}}>
											{formattedData.label}
										</button>
										</div>
									)
								} else { 
									// If empty label, create empty div for formatting purposes
									stringTypeData.push(
										<div
												className={`${css.rowData} ${css[`rowData_${i}`]} rowData rowData_${i}`}
												style={lineCss}
												data-key={`button_${i}_${rowUUID}`}
												key={`button_${i}_${rowUUID}`}
											>
										</div>
									)
								}
							break;
							case 'Teams':
								if (formattedData.label) {
									stringTypeData.push(
										<div
											className={`${css.rowData} ${css[`rowData_${i}`]} rowData rowData_${i} ${css.msgBtnCont} msgBtnCont`}
											style={lineCss}
											data-key={`button_${i}_${rowUUID}`}
											key={`button_${i}_${rowUUID}`}
										>
										<button 
											className={`${css.rowData} rowData_${i} ${css.msgBtn} msgBtn`}
											onClick={(event) => {
												event.stopPropagation();
												this.sendMessage(formattedData, 'teams')
											}}>
											{formattedData.label}
										</button>
										</div>
									)
								} else { 
									// If empty label, create empty div for formatting purposes
									stringTypeData.push(
										<div
												className={`${css.rowData} ${css[`rowData_${i}`]} rowData rowData_${i}`}
												style={lineCss}
												data-key={`button_${i}_${rowUUID}`}
												key={`button_${i}_${rowUUID}`}
											>
										</div>
									)
								}
							break;
							case 'Slack':
								if (formattedData.label) {
									stringTypeData.push(
										<div
											className={`${css.rowData} ${css[`rowData_${i}`]} rowData rowData_${i} ${css.msgBtnCont} msgBtnCont`}
											style={lineCss}
											data-key={`button_${i}_${rowUUID}`}
											key={`button_${i}_${rowUUID}`}
										>
										<button 
											className={`${css.rowData} rowData_${i} ${css.msgBtn} msgBtn`}
											onClick={(event) => {
												event.stopPropagation();
												this.sendMessage(formattedData, 'slack')
											}}>
											{formattedData.label}
										</button>
										</div>
									)
								} else { 
									// If empty label, create empty div for formatting purposes
									stringTypeData.push(
										<div
												className={`${css.rowData} ${css[`rowData_${i}`]} rowData rowData_${i}`}
												style={lineCss}
												data-key={`button_${i}_${rowUUID}`}
												key={`button_${i}_${rowUUID}`}
											>
										</div>
									)
								}
							break;
							default:
								stringTypeData.push(
									<div
										className={`${css.rowData} ${css[`rowData_${i}`]} rowData rowData_${i}`}
										style={lineCss}
										data-key={`row_${i}_${rowUUID}`}
										key={`row_${i}_${rowUUID}`}
									>
										{formattedData}
										{this.trayDetails()}
									</div>
								);
								break;
						}
					}
				
			} else {
				let lineCss = {
					width: 'auto'
				}
				let headerLabel = '';
				if (this.props.headerAsLabel[i] && this.props.headerData[i].length > 0) {
					headerLabel = this.props.headerData[i] + ': ';
				}

				if (data.displayName === 'Image') {
					imageTypeData.push(
						<div
							className={`${css.rowData} ${css[`rowData_${i}`]} rowData_${i} ${css.boxImage} boxImage`}
							style={lineCss}
							data-key={`row_${i}_${rowUUID}`}
							key={`row_${i}_${rowUUID}`}
						>
							<img
								src={formattedData}
								className={`${css.detailsData} detailsData ${`imgDetailsData_${i}`} imgDetailsData_${i}`}
								key={`${data.label}_${i}`}
							/>
						</div>
					);
				} else if (data.displayName === 'SMS Message'|| data.displayName === 'Email Message') {
					// Todo add Virtual concierge to box listings, skips data type if present
					return;
				} else if (data.displayName === 'Text Large') {
					const markdown = converter.makeHtml(formattedData);
					stringTypeData.push(
						<div
							className={`${css.rowData} ${css[`rowData_${i}`]} rowData_${i} ${css.boxStringData} listingRowTextLarge`}
							dangerouslySetInnerHTML={{ __html: markdown }}
							style={lineCss}
							data-key={`row_${i}_${rowUUID}`}
							key={`row_${i}_${rowUUID}`}
						/>
					)
				} else {
					stringTypeData.push(
						<div
							className={`${css.rowData} ${css[`rowData_${i}`]} ${css.boxStringData} ${css[`boxStringData_${i}`]}  boxStringData rowData_${i} boxStringData_${i}`}
							style={lineCss}
							data-key={`row_${i}_${rowUUID}`}
							key={`row_${i}_${rowUUID}`}
						>
							{headerLabel}
							{formattedData}
							{this.trayDetails()}
						</div>
					);
				}
			}
		});

		return (
			<>
				{this.props.boxStyleDisplay ? (
					<>
						<div className={`${css.boxColumn} ${css.imageColumn} boxColumn imageColumn`}>{imageTypeData}</div>
						<div className={`${css.boxColumn} ${css.textColumn} boxColumn textColumn`}>{stringTypeData}</div>
						{this.props.boxActiveLayout !== 2 ? (
							<div className={`${css.iconColumn} iconColumn`} key={`icon_${rowUUID}`}>
								<div className={rowIconClasses} />
							</div>
						) : <></>}
					</>
				) : (
					<>
						{stringTypeData}
						{this.props.currentDetailAction !== 0 && (
							<div className={rowIconClasses} key={`icon_${rowUUID}`} />
						)}
					</>
				)}
			</>
		);
	};

	handleRowClick = (event, msg) => {
		// event.persist(); // console error: test a fix with passing the event to App.js props.navEvents('btnDebouncer', event)
		event.stopPropagation();
		console.log(`Event ${msg} handleRowClick`);
		
		this.props.navEvents('idleTimer', '||| ListingsRow.js > handleRowClick()');
		this.props.navEvents('setHasNavigatedListings', true);

		if (this.props.currentDetailAction === 4 || this.props.currentDetailAction === 6 || this.props.currentDetailAction === 7) {
			this.props.setDetailsData(this.props.data, this.props.rowUUID, this.props.detailsGrp, this.props.currentDetailAction);

			if (typeof (this.props.addToHistory) === 'function') {
				this.props.addToHistory();
			}

			if (this.props.detailsTitle) {
				this.props.setDetailsTitle(this.props.detailsTitle.value);
			}
		}

		if (this.props.currentDetailAction === 5) {
			let dataArray = this.props.data;
			//loop through this.props.detailsGrp
			let imageItem = this.props.detailsGrp.find(item => item.type === 'Image');
			if (imageItem) {
				dataArray.push(imageItem.value);
			}
			this.props.setDetailsData(dataArray, this.props.rowUUID, this.props.detailsGrp, this.props.currentDetailAction);

			if (this.props.detailsTitle) {
				this.props.setDetailsTitle(this.props.detailsTitle);
			}
			if (typeof (this.props.addToHistory) === 'function') {
				this.props.addToHistory();
			}
		}

		if (this.props.currentDetailAction === 10) { // tier+1
			if (typeof (this.props.displayChildTier) === 'function') {
				this.props.displayChildTier(this.props.rowUUID, [], undefined, this.props.data[0], true);
			}
		}

		if (this.props.currentDetailAction === 11 ) { //iframe URL
			this.props.setDetailsData(this.props.data, this.props.rowUUID, this.props.detailsGrp, this.props.currentDetailAction);
			
			if(this.props.detailsTitle){
				this.props.setDetailsTitle(this.props.detailsTitle);
			}

			if(typeof(this.props.addToHistory) === 'function') {
				this.props.addToHistory();
			}
		} 
		

		if (this.props.currentDetailAction === 12 ) { //PDF Touch action
			this.props.setDetailsData(this.props.data, this.props.rowUUID, this.props.detailsGrp, this.props.currentDetailAction);
			
			if(this.props.detailsTitle){
				this.props.setDetailsTitle(this.props.detailsTitle);
			}
		
			if(typeof(this.props.addToHistory) === 'function') {
				this.props.addToHistory();
			}
		}

		if (this.props.currentDetailAction === 13 ) { //Video Touch action
			this.props.setDetailsData(this.props.data, this.props.rowUUID, this.props.detailsGrp, this.props.currentDetailAction);
			
			if(this.props.detailsTitle){
				this.props.setDetailsTitle(this.props.detailsTitle);
			}
		
			if(typeof(this.props.addToHistory) === 'function') {
				this.props.addToHistory();
			}
		}  

		dlRowClick({
			rowData: (this.props.data.map((entry) => { return entry.value; }) || []).join(' | '),
			headerData: (this.props.headerData || []).join(' | '),
			listName: this.props.defaultListName,
			listing: this.props.rowUUID,
			searchTerm: this.props.searchInputValue || '',
			tier: this.props.tier,
		});
	}

	messageActive = ({ message, onClose }) => {
        let css = this.state.css
        return (
          <div className={`${css.messageRow}`} onClick={onClose}>
              <p>{message}</p>
          </div>
        );
      };

    handleMessageClick = () => {
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
    
        this.setState({ showMessageText: true });
    
        const timeoutId = setTimeout(() => {
            this.setState({ showMessageText: false, timeoutId: null, messageText: "Sending..." });
        }, 5000);
    
        this.setState({ timeoutId });
    };

    handleMessageClose = () => {
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ showMessageText: false, messageText: "Sending..." })
    };

	render() {
		let { css, activeButton } = this.state;
		let { currentDetailAction, rowUUID } = this.props;

		let activeButtonClass = activeButton === rowUUID ? `${css.activeButton} activeButton` : '';
		let currentDetailActionClass = '';

		if (currentDetailAction === 0) {
			activeButtonClass =  "";
			currentDetailActionClass = css.noDetailsRow
		}

		
		let rowClass = `${css.listingLine} listingLine ${currentDetailActionClass} ${activeButtonClass}`;
		if (this.props.boxStyleDisplay) {
			rowClass = `${css.listingBox} listingBox ${css[`boxLayout${this.props.boxActiveLayout}`]} boxLayout${this.props.boxActiveLayout} ${css[`cols${this.props.boxColumnsPerRow}`]} ${currentDetailActionClass} ${activeButtonClass}`
		}

		return (
			<div
				className={rowClass}
				data-name={rowUUID}
				key={rowUUID}
				onClick={(event) => {
					this.props.navEvents('btnDebouncer', () => this.handleRowClick(event, '[onClick]'));
				}}
				onMouseDown={() => { 
					this.handleDown(rowUUID, '[onMouseDown]');
				}} 
				onMouseUp={() => this.handleUp('[onMouseUp]')}
				onMouseLeave={() => this.handleUp('[onMouseLeave]')}
				onTouchStart={() => { 
					this.handleDown(rowUUID, '[onTouchStart]');
				}}
				onTouchEnd={() => this.handleUp('[onTouchEnd]')}
				onPointerDown={() => { 
					this.handleDown(rowUUID, '[onPointerDown]');
				}}
				onPointerUp={() => this.handleUp('[onPointerUp]')}
				onPointerLeave={() => this.handleUp('[onPointerLeave]')}
			>
				{this.state.showMessageText ? this.messageActive({ message: this.state.messageText, onClose: this.handleMessageClose }) : this.createData()}
			</div>
		);

	}
}

export default ListingRow
