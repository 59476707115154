import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import { withTranslation } from 'react-i18next';
import { dlToggleADA, dlToggleMenu } from '../DataLayer';

class Navigation extends Component {
	constructor(props) {
		super(props)

		this.state = {
			activeButton: '',
			currentButton: '',
			css: props.CSSModules[props.condor_render_name],
		}
	}

	componentDidMount() { }

	componentDidUpdate(prevProps) {
		if (prevProps.navProps.animationPhase !== this.props.navProps.animationPhase &&
			this.props.navProps.animationPhase === this.props.navProps.animationStartPhase) {
			this.setState({
				currentButton: this.props.navProps.animationStartPhase
			});
		}
	}

	toggleMenu = () => {
		this.props.navEvents('toggleMenu');
	}

	displayCondition = () => {
		if (
			(Array.isArray(this.props.show_on_phase) && this.props.show_on_phase.length === 0) ||
			this.props.show_on_phase === undefined ||
			(this.props.show_on_phase && this.props.show_on_phase.includes(this.props.navProps.animationPhase))
		) {
			return true;
		}
		return false;
	}

	adaAction(adaActive) {
		let css = this.state.css;
		let classes = '';
		if (adaActive === true && this.displayCondition() === true) {
			classes = css['adaActive']
		}
		return classes;
	}

	handleDown = (buttonId) => {
		this.setState({activeButton: buttonId})
	}

	handleUp = () => {
		if (this.state.activeButton !== '') {
			this.setState({activeButton: ''});
		}
	}

	setCurrentButton = (buttonId) => {
		this.setState({
			currentButton: buttonId
		});
	}

	render() {
		let { css, activeButton } = this.state;
		const { t } = this.props;
		// console.log(" t(menuLabel)" ,t("menuLabel"))
		let transitionInner = this.props.transition_inner;
		let transitionMiddle = this.props.transition_middle;
		let transitionOuter = this.props.transition_outer;
		let transitionMenuBtn = this.props.transition_menu_btn;
		

		let mainMenuMask = this.props.navProps.showMenu === true && this.displayCondition() && this.props.nav_type === 'navMain'  ?
			<CSSTransition
				in={true}
				appear={true}
				timeout={500}
				classNames='fade'>
					<div className={`${css.mainMenuMask}`}></div>
			</CSSTransition> : '';
		
		const activeButtonClass = activeButton === 'adaButton' ? `${css.activeButton} activeButton` : '';

		let adaBtn = this.displayCondition() && this.props.nav_type === 'ADA' ? 
			<CSSTransition
				in={true}
				appear={true}
				timeout={500}
				classNames='fade'>
				<div
					onClick={() => {
						dlToggleADA();
						this.props.navEvents('toggleAda');
					}}
					className={`${css.adaBtn} navAdaBtn ${this.adaAction(this.props.navProps.adaActive)} ${activeButtonClass}`}
					onMouseDown={() => {this.handleDown('adaButton')}} 
					onMouseUp={this.handleUp}
					onMouseLeave={this.handleUp}
					onTouchStart={() => {this.handleDown('adaButton')}}
					onTouchEnd={this.handleUp}	
				>
					<svg className={`${css.adaIcon} navigationAdaIcon`} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
						<g>
							<path d="M1043.7,544.2c-14.2-14.6-34.1-22.3-54.4-21.2l-245.9,13.7l135.3-154.1c19.3-22,24.8-51.2,17.3-77.4
								c-3.9-17.9-14.8-34.2-31.5-44.6C864,260.2,541,72.6,541,72.6c-26.4-15.3-59.6-11.9-82.4,8.4L300.9,221.6
								c-29.1,25.9-31.6,70.5-5.7,99.6s70.5,31.6,99.6,5.7l119.6-106.7l98.9,57.4L438.8,476.4c-72.4,11.8-137.5,45.2-188.6,93.2l91.2,91.2
								c41.2-37.5,95.9-60.3,155.9-60.3c127.9,0,231.9,104,231.9,231.9c0,60-22.9,114.7-60.3,155.9l91.1,91.2c60.7-64.5,98-151.4,98-247
								c0-57-13.2-110.8-36.7-158.7l95.2-5.3l-23.2,284c-3.2,38.8,25.7,72.9,64.6,76c1.9,0.2,3.9,0.2,5.8,0.2c36.4,0,67.2-27.9,70.2-64.8
								l29.7-364.7C1065.2,578.8,1057.9,558.8,1043.7,544.2z"/>
							<path d="M913.1,242c65.2,0,118.1-52.9,118.1-118.1S978.3,5.8,913.1,5.8C847.9,5.9,795,58.8,795,124S847.9,242,913.1,242z"/>
							<path d="M497.2,1064.3c-127.9,0-231.9-104-231.9-231.9c0-48.2,14.8-93,40-130.1l-92.1-92.1c-48,61.3-76.7,138.4-76.7,222.2
								c0,199.2,161.5,360.7,360.7,360.7c83.9,0,161-28.7,222.2-76.7l-92.1-92.1C590.2,1049.5,545.4,1064.3,497.2,1064.3z"/>
						</g>
					</svg>
				</div>
			</CSSTransition> : '';

		let openMenuButtonComp = this.props.nav_type === 'navMain' ? 
			<OpenMainMenuBtn
				{...this.props} 
				id={`openMainMenuBtn_${this.props.component_name}`}
				adaOn={this.props.navProps.adaActive}
				toggleMenu={this.toggleMenu}
				transition={transitionMenuBtn}
				unmountOnExit
				condition={this.displayCondition() && this.props.nav_type === 'navMain'}
				css={css}
			/> : '';



		return (
			<>
				{mainMenuMask}

				<CSSTransition
					id={`nav_${this.props.component_name}`}
					in={(this.props.navProps.adaActive === false && this.props.navProps.showMenu === true && this.displayCondition()) || (this.props.nav_type === 'navIdle' && this.displayCondition()) || (this.props.nav_type === 'navConstant' && this.displayCondition())}
					appear={(this.props.navProps.adaActive === false && this.props.navProps.showMenu === true && this.displayCondition()) || (this.props.nav_type === 'navIdle' && this.displayCondition()) || (this.props.nav_type === 'navConstant' && this.displayCondition())}
					adaOn={this.props.navProps.adaActive}
					timeout={500}
					unmountOnExit
					classNames={transitionOuter}>
						<MainMenu
							{...this.props}
							componentList={this.props.condor_component_list}
							condition={this.props.navProps.adaActive === false && this.props.navProps.showMenu === true} 
							css={css}
							currentButton={this.state.currentButton}
							nav_type={this.props.nav_type}
							transitionMiddle={transitionMiddle}
							transitionInner={transitionInner}
							setCurrentButton={this.setCurrentButton}
						/>
				</CSSTransition>

				{openMenuButtonComp}
				{adaBtn}

				<CSSTransition
					id='mainMenuAda'
					in={this.props.navProps.adaActive === true && this.props.navProps.showMenu === true && this.displayCondition()}
					appear={this.props.navProps.adaActive === true && this.props.navProps.showMenu === true && this.displayCondition()}
					adaOn={true}
					timeout={500}
					unmountOnExit
					classNames={transitionOuter}>
						<MainMenu
							{...this.props}
							componentList={this.props.condor_component_list}
							condition={this.props.navProps.adaActive === true && this.props.navProps.showMenu === true}
							css={css}
							currentButton={this.state.currentButton}
							transitionMiddle={transitionMiddle}
							transitionInner={transitionInner}
							setCurrentButton={this.setCurrentButton}
						/>
				</CSSTransition>
			</>
		)
	}
}

class OpenMainMenuBtn extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			activeButton: '',
		}
	}

	handleDown = (buttonId) => {
		this.setState({activeButton: buttonId})
	}

	handleUp = () => {
		if (this.state.activeButton !== '') {
			this.setState({activeButton: ''});
		}
	}

	render() {
		let adaClass = '';
		let { css, t } = this.props;
		let { activeButton } = this.state

		if (this.props.adaOn === true) {
			adaClass = css.adaActive;
		}

		const activeButtonClass = activeButton === 'menuButton' ? `${css.activeButton} activeButton` : '';
				
		return (
			<div className={`${adaClass} ${css.menuBtnCont}`}>
				<CSSTransition
					in={this.props.condition}
					appear={this.props.condition}
					timeout={500}
					unmountOnExit
					classNames={this.props.transition}>
					<div
						id={this.props.id}
						onClick={() => {
							dlToggleMenu();
							this.props.toggleMenu();
						}}
						className={`${css.openMenuButton} ${activeButtonClass}`}
						onMouseDown={() => {this.handleDown('menuButton')}} 
						onMouseUp={this.handleUp}
						onMouseLeave={this.handleUp}
						onTouchStart={() => {this.handleDown('menuButton')}}
						onTouchEnd={this.handleUp}	
					>
						<span className={css.openMenuButtonDetail}></span>
						<div className={css.openMenuButtonText}>{t("navMenuLabel")}</div>
					</div>
				</CSSTransition>
			</div>
		)
	}
}

class MainMenu extends React.Component {
	render() {
		let css = this.props.css;
		let adaClass = this.props.adaOn === true ? `${this.props.nav_type}Ada` : this.props.nav_type;

		const modifiedButtonList = this.props.componentList.map((ButtonComponent) => {
			return React.cloneElement(ButtonComponent, {
				currentButton: this.props.currentButton,
				setCurrentButton: this.props.setCurrentButton
			});
		});

		return (
			<div id={this.props.id} className={`${this.props.custom_classes}Outer ${css[`${this.props.nav_type}Outer`]} ${css[`${adaClass}Outer`]}`}>
				<CSSTransition
					in={this.props.condition}
					appear={this.props.condition}
					timeout={500}
					classNames={this.props.transitionMiddle}>
						<nav className={`${this.props.custom_classes}Middle ${css[`${this.props.nav_type}Middle`]} ${css[`${adaClass}Middle`]}`}>
							<CSSTransition
								in={this.props.condition}
								appear={this.props.condition}
								timeout={2000}
								classNames={this.props.transitionInner}
							>
								<div className={`${this.props.custom_classes}Inner ${css[`${this.props.nav_type}Inner`]} ${css[`${adaClass}Inner`]}`}>
									{modifiedButtonList}
								</div>
							</CSSTransition>
						</nav>
				</CSSTransition>
			</div> 
		)
	}
}

export default withTranslation()(Navigation);
