import React from 'react';
// import InfoBoxHeader from './InfoBoxHeader'

const InfoBoxGroup = (props) => {
	const css = props.CSSModules[props.condor_render_name];
	
	// Custom class list
	function returnClasses(arr) {
		if (arr && Array.isArray(arr)) {
			let newClassList = '';
			arr.forEach((item) => {
				newClassList += ` ${item}`;
			});
			return newClassList;
		}
	}

	function modifierClasses(arr) {
		if (arr && Array.isArray(arr)) {
			let newClassList = '';
			arr.forEach((item) => {
				newClassList += ` ${item}`;
			});
			return newClassList;
		}
	}

	function flexClasses(styleArray) {
		if (styleArray && Array.isArray(styleArray)) {
			let classes = ``;

			// align-items
			if (styleArray[0] === 1) {
				classes += `${css['fjl']}`;
			} else if (styleArray[0] === 2) {
				classes += `${css['fjc']}`;
			} else if (styleArray[0] === 3) {
				classes += `${css['fjr']}`;
			}

			// justify-content
			if (styleArray[1] === 1) {
				classes += ` ${css['fat']}`;
			} else if (styleArray[1] === 2) {
				classes += ` ${css['fac']}`;
			} else if (styleArray[1] === 3) {
				classes += ` ${css['fab']}`;
			}

			return classes;
		}
	}

	let logo = false;
	function imageContent(boxObj) {
		let image;

		if (boxObj.props && boxObj.props.condor_component_list) {
			boxObj.props.condor_component_list.forEach((component) => {
				if (component.props.condor_render_name && component.props.condor_render_name === 'InfoBoxImg' && (component.props.image_url !== null && component.props.image_url !== '' && component.props.image_url !== undefined)) {
					logo = true;
					if (component.props.image_url) {
						image = (
							<div className={`${css.logoCont} logoCont ${returnClasses(component.props.custom_classes)}`}>
								<img id={`infoBoxImage_${props.component_name}`} className={css.logo} alt={component.props.image_url} src={component.props.image_url} />
							</div>
						);
					}
				}
			});
		}

		return image;
	}

	function heading(boxObj) {
		let heading;

		if (boxObj.props && boxObj.props.condor_component_list) {
			boxObj.props.condor_component_list.forEach((component) => {
				if (component.props.condor_render_name && component.props.condor_render_name === 'InfoBoxHeader') {
					heading = <div key={`header${component.props.component_name}`} className={`${css.text} infoBoxGroupText ${css.header} ${returnClasses(component.props.modifier_classes)} ${returnClasses(component.props.custom_classes)}`}>{component.props.text}</div>;
				}
			});
		}

		return heading;
	}

	function blockHeading(boxObj) {
		let blockHeading;

		if (boxObj.props && boxObj.props.condor_component_list) {
			boxObj.props.condor_component_list.forEach((component) => {
				if (component.props.condor_render_name && component.props.condor_render_name === 'InfoBoxHeaderBlock') {
					blockHeading = <div key={`header${component.props.component_name}Block`} className={`${css.text} ${css.blockHeader} ${returnClasses(component.props.modifier_classes)} ${returnClasses(component.props.custom_classes)}`}>{component.props.text}</div>;
				}
			});
		}

		return blockHeading;
	}

	function textContent(boxObj) {
		let content = [];
		// let num = 0;
		content.push(heading(boxObj));

		if (boxObj.props && boxObj.props.condor_component_list) {
			boxObj.props.condor_component_list.forEach((component) => {
				if (component.props.condor_render_name && component.props.condor_render_name === 'InfoBoxText') {
					content.push(<div key={`text${component.props.component_name}`} className={`${css.text} ${returnClasses(component.props.modifier_classes)} ${returnClasses(component.props.custom_classes)}`}>{component.props.text}</div>);
					// num++;
				}
			});
		}

		return content;
	}

	function containerStyles(box) {
		let fontSize = `${box.props.font_size}em`;
		let style = {
			fontSize: fontSize
		};

		let value = props.condor_component_list.length === 1 ? 0 : props.multi_box_gap;
		let percentage = 100 / props.condor_component_list.length;
		let calc = `calc(${percentage}% - ${value}em)`;

		if (props.config === 0) {
			style = {
				...style,
				width:  calc,
				height: '100%'
			};
		} else {
			style = {
				...style,
				width:  '100%',
				height: calc
			};

		}

		return style;
	}

	function isCol() {
		let classes = ``;

		if (props.config === 1) {
			classes = `${css.fcol}`;
		}

		return classes;
	}

	function containerSettings(box) {
		let classes = ``;

		if (box.props.text_align === 1) {
			classes += ` ${css['txtl']}`;
		} else if (box.props.text_align === 2) {
			classes += ` ${css['txtc']}`;
		} else if (box.props.text_align === 3) {
			classes += ` ${css['txtr']}`;
		}
		return classes;
	}

	function contentSettings(box) {
		let classes = ``;

		if (props.config === 1 && box.props.reverse_order === 1) {
			classes = `${css.fcolRev}`;
		} else if (props.config === 0 && box.props.reverse_order === 1) {
			classes = `${css.frowRev}`;
		}

		return classes;
	}

	function dataSeperatorLine() {
		let lineDiv = props.line_display;
		let classes = '';
		let spacer = '';

		if (props.config === 1) {
			classes = css.lineHoriz;
			spacer = css.spacerHoriz;
		} else {
			classes = css.lineVert;
			spacer = css.spacerVert;
		}

		if (logo === false) {
		    return;
		}

		if (lineDiv === true && logo === true) {
			return <div className={`${classes} infoBoxLine ${returnClasses(props.line_modifier_classes)} ${returnClasses(props.line_custom_classes)}`}></div>;
		}
		return <div className={`${spacer} ${returnClasses(props.line_modifier_classes)} ${returnClasses(props.line_custom_classes)}`}></div>;
	}

	function boxSeperatorLine(numBoxes, i) {
		let classes = css.boxLineVert;
		if (props.config === 1) {
			classes = css.boxLineHoriz;
		}
		if (i < (numBoxes - 1) && props.box_seperator_lines === true) {
			return <div className={classes} key={`line${i}`}></div>;
		}
		return;
	}

	function bgBox(num) {
		if (num === 0 && props.multi_box_gap === 0) {
			return css.bgColor;
		} else if (num === 1 && props.multi_box_gap !== 0) {
			return css.bgColor;
		}
		return '';
	}

	function buildBoxes() {
		let boxArray = [];
		let children = props.condor_component_list;

		if (children) {
			children.forEach((box, i) => {

				boxArray.push(
					<div style={containerStyles(box)} key={`childBox_${i}`} className={`infoBox_Container ${css.container} ${bgBox(1)} ${flexClasses(box.props.container_layout)} ${containerSettings(box)} ${modifierClasses(box.props.container_classes)} ${returnClasses(box.props.container_custom_classes)}`}>
						{blockHeading(box)}
						<div className={`infoBox_Box ${css.box} ${flexClasses(box.props.box_layout)} ${isCol()} ${returnClasses(box.props.box_custom_classes)}`}>
							<div className={`infoBox_Content ${css.content} ${flexClasses(box.props.content_layout)} ${isCol()} ${contentSettings(box)} ${returnClasses(box.props.content_classes)} ${returnClasses(box.props.content_custom_classes)}`}>
								{imageContent(box)}
								{dataSeperatorLine()}
								<div className={`${css.bldgInfo} bldgInfo`}>
									{textContent(box)}
								</div>
							</div>
						</div>
					</div>
				);
				boxArray.push(
					boxSeperatorLine(children.length, i)
				);
			});
		}

		return (boxArray);
	}

	return (
		<div id={`infoBox_${props.component_name}`} className={`infoBoxGrp_container ${css.infoBoxGrp} ${css[props.theme_variation]} ${isCol()} ${bgBox(0)}`}>
			{buildBoxes()}
		</div>
	);

};

export default InfoBoxGroup;
