import React from 'react';

import {
	defaultSorter,
	makeNextSplitSlides
} from 'helper/Brood.js';

import {
	broncoURLENV,
	monarchServerENV
} from '../ENV';

const makeFeeds = (props, monarchConfig) => {
	const processedMonarchConfig = monarchConfig.map((feed) => {
		const processedFeed = {...feed};
		processedFeed.name = `monarchfeed${feed.name.replaceAll('_', '')}`;
		processedFeed.src = `${monarchServerENV}/monarch/data${feed.server.path}?tzo=-3`;
		return processedFeed;
	});

	const feeds = (props.condor_prop_list || []).map((child) => {
		const feed = processedMonarchConfig.find((entry) => {
			return entry.name === child.condor_render_name.toLowerCase();
		});

		feed.allow_audio = !!child.allow_audio;

		if (props.monarch_location) {
			feed.src += `&location=${props.monarch_location}`;
			feed.location = props.monarch_location;
		}

		if (child.monarch__category) {
			feed.src += `&category=${child.monarch__category}`;
			feed.category = child.monarch__category;
		}

		if (child.monarch_distance) {
			feed.src += `&distance=${child.monarch_distance}`;
			feed.distance = child.monarch_distance;
		}

		if (child.monarch_league) {
			feed.src += `&league=${child.monarch_league}`;
			feed.league = child.monarch_league;
		}

		if (child.monarch_order) {
			feed.src += `&order=${child.monarch_order}`;
			feed.order = child.monarch_order;
		}

		if (child.monarch_take) {
			feed.src += `&take=${child.monarch_take}`;
			feed.take = child.monarch_take;
		}

		if (child.monarch_start_date) {
			feed.src += `&start_date=${child.monarch_start_date}`;
			feed.start_date = child.monarch_start_date;
		}

		if (child.monarch_stop_date) {
			feed.src += `&stop_date=${child.monarch_stop_date}`;
			feed.stop_date = child.monarch_stop_date;
		}

		if (child.monarch_sort) {
			feed.src += `&sort=${child.monarch_sort}`;
			feed.sort = child.monarch_sort;
		}

		if (child.monarch_duration) {
			feed.src += `&duration=${child.monarch_duration}`;
			feed.duration = child.monarch_duration;
		}

		if (child.monarch_width) {
			feed.src += `&width=${child.monarch_width}`;
			feed.width = child.monarch_width;
		}

		if (child.monarch_height) {
			feed.src += `&height=${child.monarch_height}`;
			feed.height = child.monarch_height;
		}

		if (child.monarch_size) {
			feed.src += `&size=${child.monarch_size}`;
			feed.size = child.monarch_size;
		}

		if (child.monarch_limit) {
			feed.limit = child.monarch_limit;
		}

		if (child.monarch_theme) {
			feed.src += `&theme=${child.monarch_theme}`;
			feed.theme = child.monarch_theme;
		}

		return feed;
	});

	return feeds;
};

class MonarchProviderComponent extends React.Component {
	static providerName = 'monarch';

	constructor(props) {
		super(props);

		this.state = {
			config:    [],
			slidesMap: {},
			limits:    {}
		};

		this.fetchTimes = {};
		this.fetchTimer = null;

		this.providerID = `PROVIDER_${props.component_name}`;
	}

	componentDidMount() {
		const {
			slideshow
		} = this.props;

		// don't initialize if we're not in a slideshow
		if (!slideshow) {
			return;
		}

		this.fetchConfig();

		this.fetchTimer = setInterval(this.fetchFeeds.bind(this), 1000 * 60);
	}

	componentWillUnmount() {
		const {
			slideshow
		} = this.props;

		for (const providerID of Object.keys(this.state.slidesMap)) {
			slideshow.removeProvider(providerID);
		}

		clearInterval(this.fetchTimer);
	}

	componentDidUpdate(prevProps) {
		const {
			count,
			last_update
		} = this.props;

		// TODO: is this required from the old Pelican.js?
		if (prevProps.count !== count || prevProps.last_update !== last_update) {
			this.fetchConfig();
		}
	}

	async fetchConfig() {
		const {
			order
		} = this.props;

		try {
			const fetchURL = `${broncoURLENV}/monarch/config`;
			const response = await fetch(fetchURL);

			const monarchConfig = await response.json();

			this.setState({
				config: monarchConfig
			}, () => {
				this.fetchTimes = {};
				this.fetchFeeds();
			});
		} catch (err) {
			console.error('BROOD::MONARCH::ERROR_fetchConfig:', err);
			debugger;
		}
	}

	async fetchFeeds() {
		const {
			limit,
			slideshow
		} = this.props;

		const {
			config,
			slidesMap,
			limits
		} = this.state;

		try {
			const feeds = makeFeeds(this.props, config);

			// monarch feed ordering
			feeds.sort(defaultSorter);

			const newSlidesMap = {};
			for (const feed of feeds) {
				if (this.fetchTimeTooEarly(feed)) {
					console.log('SKIP(fetchTimeTooEarly):', feed.name, this.fetchTimes[feed.name]);

					continue;
				}

				let feedSlides = await this.fetchFeed(feed);
				if (!feedSlides) {
					continue;
				}

				// add any feed specific data to each slide
				feedSlides = feedSlides.map((slide) => {
					slide.feed = feed;
					slide.allowAudio = feed.allow_audio;

					return slide;
				});
				// newSlidesMap[feed.name] = [...feedSlides];

				newSlidesMap[feed.name] = [];
				for (let i = 0; i < feedSlides.length; i++) {
					const slide = feedSlides[i];
					newSlidesMap[feed.name].push(slide);
				}

				this.fetchTimes[feed.name] = Date.now();
			}

			if (Object.keys(newSlidesMap).length === 0) {
				return;
			}

			const newProviders = [];
			for (let i = 0; i < feeds.length; i++) {
				const feed = feeds[i];
				if (!newSlidesMap[feed.name]) {
					continue;
				}

				let feedSlides = [...newSlidesMap[feed.name]];
				// monarch limit has a higher priority than the feed.limit
				if (isNaN(feed.limit) || (!isNaN(limit) && feed.limit > limit)) {
					feed.limit = limit;
				}

				if (feed.limit && feedSlides.length > feed.limit) {
					limits[feed.name] = feed.limit;

					for (let x = 0; x < feedSlides.length; x++) {
						feedSlides[x].skip = (x >= feed.limit);
					}
				}

				const newProvider = this.makeProvider(feed.name, feedSlides);
				newProviders.push(newProvider);
			}

			this.setState({
				slidesMap: {
					...slidesMap,
					...newSlidesMap
				},
				limits: limits
			}, () => {
				// monarch is sorted by sequential/random per monarch/feed
				// slides.sort(defaultSorter);

				slideshow.updateProvider(...newProviders);
			});
		} catch (err) {
			console.error('BROOD::MONARCH::ERROR_fetchFeeds:', err);
			debugger;
		}
	}

	fetchTimeTooEarly(feed) {
		const now = Date.now();
		const lastFetchTime = this.fetchTimes[feed.name] || -1;
		if (lastFetchTime === -1) {
			return false;
		}

		const timeSinceLastFetch = now - lastFetchTime;
		const nextFetchTime = (feed.cache.life * 60 * 1000) * 2;
		// const nextFetchTime = 15000;

		if (timeSinceLastFetch >= nextFetchTime) {
			return false;
		}

		return true;
	}

	async fetchFeed(feed) {
		const slides = [];

		try {
			const feedRes = await fetch(feed.src);
			const feedData = await feedRes.json();

			if (feedData.retry) {
				this.fetchTimes[feed.name] = -1;
				return false;
			}

			for (const item of feedData.data) {
				const slide = this.makeSlide(feed, item);
				slides.push(slide);
			}

		} catch (err) {
			console.error('BROOD::MONARCH::ERROR_fetchFeed:', err);
			debugger;
		}

		return slides;
	}

	makeSlide(feed, item) {
		const slide = {
			feedName: feed.name,
			data:     {
				src: `${monarchServerENV}/monarch/cache/${item.uri}?tzo=-3`,
				alt: item.title
			},
			options: {
				duration: (feed.duration * 1000)
			},
			slideID:  item.uri,
			type:     item.media_type,
			provider: this
		};

		return slide;
	}

	makeProvider(feedName = '', slides = []) {
		const {
			providerID,
			limit: maxSlides,
			order: providerOrder,
			sort: sortType,
			transition_in: transitionIn,
			transition_out: transitionOut,
			isTouch
			// count, ???
		} = this.props;

		// Allow autoplay in chrome
		// let isMuted = ~window.location.href.indexOf('muted=1');

		return {
			eventID:    providerID,
			providerID: `${providerID}_${feedName}`,
			options:    {
				maxSlides:     maxSlides,
				providerOrder: providerOrder,
				sortType:      sortType || 'sequential'
			},
			// spliter: this.spliter.bind(this),
			skipper:             this.skipper.bind(this),
			beforeLastSlide:     this.beforeLastSlide.bind(this),
			afterLastSlide:      this.afterLastSlide.bind(this),
			slides,
		};
	}

	// TODO: when this is implemented skipped/nonSkipped slides will
	// TODO: need to be tracked/merged just like in Pelican.js
	slideExpired(currSlide) {
		return false;
	}

	skipper(currSlide) {
		if (this.slideExpired(currSlide)) {
			return true;
		}

		return false;
	}

	beforeLastSlide(slides) {
		console.log('BROOD::MONARCH::beforeLastSlide:', slides);
	}

	afterLastSlide(slides) {
		console.log('BROOD::MONARCH::afterLastSlide:', slides);
	}

	beforeNext(currSlide) {
		console.log('BROOD::MONARCH::beforeNext:', currSlide);
	}

	afterNext2(prevSlide, currSlide) {
		console.log('BROOD::PELICAN::afterNext:', prevSlide, currSlide);

		const {
			limit,
			slideshow
		} = this.props;

		const {
			slides
		} = this.state;

		const prevSlideIndex = slides.findIndex((slide) => {
			return slide.slideID === prevSlide.slideID;
		});

		if (prevSlideIndex !== (limit - 1)) {
			return;
		}

		let nextSlides = [...slides];
		if (nextSlides.length <= limit) {
			return;
		}

		nextSlides = nextSlides.map((slide) => {
			return { ...slide, skip: true };
		});

		let l = limit;
		while (l--) {
			nextSlides.push(nextSlides.shift());
		}

		for (let i = 0; i < limit; i++) {
			nextSlides[i].skip = false;
		}

		this.setState({
			slides: nextSlides
		}, () => {
			const newProvider = this.makeProvider(nextSlides);
			slideshow.updateProvider(newProvider);
		});
	}

	afterNext(prevSlides, prevSlide, currSlide) {
		console.log('BROOD::MONARCH::afterNext:', prevSlides, prevSlide, currSlide);

		const {
			slideshow
		} = this.props;

		const {
			limits,
			slidesMap
		} = this.state;

		const {
			feedName
		} = prevSlide;

		const feedSlides = slidesMap[feedName];
		const prevSlideIndex = feedSlides.findIndex((slide) => {
			return slide.slideID === prevSlide.slideID;
		});

		const limit = limits[feedName];
		if (prevSlideIndex !== (limit - 1)) {
			return;
		}

		let nextSlides = [...feedSlides];
		if (nextSlides.length <= limit) {
			return;
		}

		nextSlides = nextSlides.map((slide) => {
			return { ...slide, skip: true };
		});

		let l = limit;
		while (l--) {
			nextSlides.push(nextSlides.shift());
		}

		for (let i = 0; i < limit; i++) {
			nextSlides[i].skip = false;
		}

		this.setState({
			slidesMap: {
				...slidesMap,
				[feedName]: nextSlides
			}
		}, () => {
			const newProvider = this.makeProvider(feedName, nextSlides);
			slideshow.updateProvider(newProvider);
		});
	}

	render() {
		return null;
	}
}

export default MonarchProviderComponent;