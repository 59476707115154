import React, {} from 'react';
import { dlPresenceDetect } from '../DataLayer';
import { mossySwiftPortENV } from '../ENV';

class PresenceDetection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
            distance: 11,
            connected: false,
        };

        this.resetTimer = null;
        this.retryTimer = null
        this.webSocket = null;
	}

	componentDidMount() {
		this.connect();
        // need to retry
	}

    componentDidUpdate(_, prevState) {        
        if (Array.isArray(this.props.active_animation_phases) && !this.props.active_animation_phases.includes(this.props.navProps.animationPhase)) {
            clearTimeout(this.resetTimer);
            this.resetTimer = setTimeout(() => {
                console.log('Timeout went through');
            }, this.props.navProps.idleTimeoutSeconds * 1000);

            console.log('Not in an active phase');
            return;
        }

        if (prevState.distance > this.props.activation_range && this.state.distance <= this.props.activation_range) {
            this.handlePresence();
        } else {
            console.log('Presence didn\'t happen. Why?', {
                prev_distance: prevState.distance,
                activation_range: this.props.activation_range,
                curr_distance: this.state.distance,
            });
        }

        if (this.state.distance === 0) {
            this.handleAirButton();
        }
    }

	connect() {
        if (this.state.connected === true) {
            return;
        }

        console.log('Connecting to the presence detection web socket...');

        this.webSocket = new WebSocket(`ws://localhost:${mossySwiftPortENV}`);

        this.webSocket.onopen = (ev) => {
            console.log('Connected to the Websocket', ev);

            this.setState({
                connected: true,
            });
        };

        this.webSocket.onmessage = (message) => {
            // console.log('Received the message', message.data);
            try {
                const messageJSON = JSON.parse(message.data);
                const messagePayload = messageJSON.Payload;

                const Dz = messagePayload.split('[Dz=')[1].split(']')[0];
                console.log('Dz: ', Dz);

                if (Dz === 'Ab') {
                    this.setState({ distance: 0 });
                }

                else if (Dz !== 'XX') {
                    this.setState({ distance: Number(Dz) });
                }

            } catch (err) {
                console.error(err);
            }
        };

        this.webSocket.onclose = (ev) => {
            console.log('Disconnected from the Websocket', ev);

            this.retry();

            this.setState({
                connected: false,
            });
        };

        this.webSocket.onerror = (ev) => {
            console.error('There was an error trying to connect to the Websocket. Will retry connection.', ev);

            this.retry();

            this.setState({
                connected: false,
            });
        };
    }

    retry() {
        // logic made to avoid stacking retry attempts
        
        if (this.retryTimer !== null) {
            clearTimeout(this.retryTimer);
        }

        this.retryTimer = setTimeout(() => {
            console.log('Trying to reconnect...');
            this.disconnect();
            this.connect();
        }, 3000);
    }

    disconnect() {
        if (this.webSocket) {
            this.webSocket.close();
            this.webSocket = null;
        }

        this.setState({
            connected: false,
        });
    }

    handlePresence() {
        const {
            animation_phase_to_transition,
        } = this.props;

        console.log('Presence detected');

        this.props.navEvents('setPhase', animation_phase_to_transition);

        dlPresenceDetect({
            animationPhase: animation_phase_to_transition,
            currAnimationPhase: this.props.navProps.animationPhase,
        });
    }

    handleAirButton() {
        console.log('Air Button');
    }

	render() {
		return <></>;
	}
}

export default PresenceDetection;
