import React, { useEffect, useState } from 'react';
import {
	ternStaticTokenENV,
	ternUserNameENV,
} from '../ENV';

const userName = ternUserNameENV;
const accessToken = ternStaticTokenENV;

const StaticMap = (props) => {
	const [mapRefreshInterval, setMapRefreshInterval] = useState();

	const {
		latitude,
		longitude,
		static_map_high_res: highResolution,
		static_map_zoom: zoom,
		static_map_bearing: bearing,
		static_map_pitch: pitch,
		static_map_width: width,
		static_map_height: height,
		static_map_style_id: styleId,
		static_map_traffic: traffic,
		traffic_line_color_low,
		traffic_line_color_moderate,
		traffic_line_color_heavy,
		traffic_line_color_severe,
		traffic_line_width,
		traffic_line_before_layer,
		traffic_pin_yah_letter,
		traffic_pin_yah_color,
	} = props;

	const css = props.CSSModules[props.condor_render_name];

	let refreshRate = 1000 * 60 * 60 * 24;

	if (traffic) {
		refreshRate = 1000 * 60 * 10;
	}

	let source = `https://api.mapbox.com/styles/v1/${userName}/${styleId}/static/pin-s-marker-stroked+${traffic_pin_yah_color}(${longitude},${latitude})/${longitude},${latitude},${zoom},${bearing},${pitch}/`;

	if (highResolution) {
		source += `${Math.floor(width/2)}x${Math.floor(height/2)}@2x`;
	} else {
		source += `${width}x${height}`;
	}

	source += `?access_token=${accessToken}`;
	
	if (traffic) {
		source +=`&addlayer={"id":"traffic","type":"line","source":{"type":"vector","url":"mapbox://mapbox.mapbox-traffic-v1"},"source-layer":"traffic","paint":{"line-color":["match",["get", "congestion"],["low"],"${encodeURIComponent(traffic_line_color_low)}",["moderate"],"${encodeURIComponent(traffic_line_color_moderate)}",["heavy"],"${encodeURIComponent(traffic_line_color_heavy)}",["severe"],"${encodeURIComponent(traffic_line_color_severe)}","black"],"line-width":${traffic_line_width}}}&before_layer=${traffic_line_before_layer}`;
	}
	
	// Custom classes
	let customClasses = '';
	if (props.custom_classes && props.custom_classes.length > 0) {
		props.custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				customClasses += ` ${element}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (props.modifier_classes && props.modifier_classes.length > 0) {
		props.modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

	const [imgSource, setImgSource] = useState(source);

	useEffect(() => {
		if (mapRefreshInterval) {
			clearTimeout(mapRefreshInterval);
		}

		const newInterval = setInterval(() => {
			setImgSource('fake_source_to_force_refresh');
			setImgSource(source);
		}, refreshRate);

		setMapRefreshInterval(newInterval);

	}, [refreshRate, source]);

	return (
		<div id={`image_${props.component_name}`} className={`staticMapContainer ${modifierClasses} ${customClasses} ${css.staticMapContainer}`} key={`img${props.keyNum}`}>
			<img
				className={`staticMapImage ${css.staticMapImage}`}
				src={imgSource}
				alt="Traffic Map"
				height={height}
				width={width}
			/>
		</div>
	);
};

export default StaticMap;
