import React, {} from 'react';

import { connect, JSONCodec, StringCodec } from '../../node_modules/nats.ws/lib/src/mod.js';
import { falconWSURLENV } from '../ENV.js';

const sc = StringCodec();
const jc = JSONCodec();

class EmergencyMessage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			css: props.CSSModules[props.condor_render_name],

			falconConnected: false,
			falconToggle: false,
			falconData: {},
		};
	}

	componentDidMount() {
		this.connect();
	}

	connect() {
		const {
			component_name,
		} = this.props;

		const config = {
			servers: falconWSURLENV || 'ws://127.0.0.1:8080',
		};

		connect(config)
			.then((nc) => {
				const js = nc.jetstream();

				this.nc = nc;
				this.js = js;

				(async () => {
					for await (const s of nc.status()) {
						if (s.type === 'disconnect') {
							this.setState({
								falconConnected: false,
							});
						}

						if (s.type === 'reconnect') {
							this.setState({
								falconConnected: true,
							});
						}
					}
				})().then();

				js.views.kv('bronco_toggle')
					.then(async (kv) => {
						const toggles = await kv.watch({
							key: component_name,
						});
						(async () => {
							for await (let toggle of toggles) {
								this.setState({
									falconToggle: Boolean(parseInt(sc.decode(toggle.value))),
								});
							}
						})();
					})
					.catch((err) => {
						console.error(err);
					});

				js.views.kv('emergency_message')
					.then(async (kv) => {
						const messageData = await kv.watch({
							key: component_name,
						});
						(async () => {
							for await (let message of messageData) {
								this.setState({
									falconData: jc.decode(message.value),
								});
							}
						})();
					})
					.catch((err) => {
						console.error(err);
					});

				this.setState({
					falconConnected: true,
				});
			})
			.catch((err) => {
				if (err) {
					console.error(err);

					window.setTimeout(this.connect.bind(this), 1000);
					return;
				}
			});
	}

	render() {
		const {
			custom_classes,
			modifier_classes,
			component_name,
			processor_power,
			format,
		} = this.props;

		const {
			css,
			falconToggle,
			falconData,
		} = this.state;

		const {
			title,
			message,
			type: messageType,
		} = falconData;

		// Custom classes
		let customClasses = '';
		if (custom_classes && custom_classes.length > 0) {
			custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (modifier_classes && modifier_classes.length > 0) {
			modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		let animation = 'slide';
		if (processor_power === 1) {
			animation = 'fade';
		} else if (processor_power === 0) {
			animation = '';
		}

		if (falconToggle) {
			return (
				<div id={`emergencyMessage_${component_name}`} className={`emergencyMessage_container`}>
					<div className={css.container}>
						<div className={`${css[`${messageType}Background`]} ${css[`${format}Background`]} ${modifierClasses} ${customClasses}`} />
					</div>
					<div className={css[`${format}Content`]}>
						<div className={`${css[`${format}Icon`]} ${css[`${messageType}Icon`]}`}>
							<i className={`${css[`${format}FaIcon`]} fa fa-exclamation-triangle`} />
						</div>
						<div className={css[`${format}Text`]}>
							<h1 className={`${css[`${format}TextH1`]}`}>{title}</h1>
							<p className={`${css[`${format}TextP`]}`}>{message}</p>
						</div>
					</div>
				</div>
			);
		}

		return (
			<>
			</>
		);
	}
}

export default EmergencyMessage;
