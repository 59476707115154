import React, {Component} from 'react';

class Mobile extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let keyValue = `mobile${this.props.key_num}`;
		let style = {
			display: 'none',
			position: 'absolute',
			zIndex: -10
		}
		return (
			<div id={`mobile_${this.props.component_name}`} key={keyValue} style={style}></div>
		);
	}
}

export default Mobile;
