import React, {Component} from 'react';
import { tsLiveServerENV } from '../ENV';

const secondInMs = 1000;
const minuteInMs = 60 * secondInMs;
const expoBackoffMin = 1000;
const expoBackoffMax = minuteInMs * 15;

class Stocks extends Component {
	constructor(props) {
		super(props);
		let reqRate = minuteInMs * 5;
		if (this.props.request_rate && typeof this.props.request_rate === "number") {
			reqRate = this.props.request_rate * secondInMs;
		}

		this.state = {
			animation:       'animate1',
			animDisplay:     1,
			css:             props.CSSModules[props.condor_render_name],
			data:            [],
			refresh:         '',
			request_rate:    reqRate,
			request_timer:   false,
			request_backoff: expoBackoffMin,
		};
	}

	componentDidMount() {
		console.log('Stocks props', this.props)
		this.getData();

		// setInterval(() => {
		// 	if (this.state.animation === 'animate1') {
		// 		this.stocksScroller();
		// 	}
		// }, 500);
		this.startAnimation();
		this.refreshInterval = setInterval(() => {
			this.refresh();
		}, 	600000);
	}

	componentWillUnmount() {
		console.log('Stocks unmounted');
		clearInterval(this.refreshInterval);
		clearTimeout(this.state.request_timer);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.request_rate !== this.props.request_rate && typeof this.props.request_rate === "number") {
			let request_rate = this.props.request_rate * secondInMs;

			this.setState({ request_rate });
		}
	}

	startAnimation = () => {
		if (this.props.format && this.props.format === 'animated') {
			this.financeTimer = setTimeout(() => {
				// this.stopAnimation();
			}, 15000);
			this.financeInterval = setInterval(() => {
				let animDisplay = this.state.animDisplay; // 1
				console.log('animDisplay');
				if (animDisplay === 3) {
					console.log('stock 0');
					animDisplay = 1;
				} else {
					animDisplay++;
				}
				this.setState({
					animDisplay: animDisplay
				})
			}, 5000);
		}
	}

	stopAnimation = () => {
		clearInterval(this.financeInterval);
	}

	getData = () => {
		const target = `${tsLiveServerENV}/finance`;

		const getFileFail = (error) => {
			console.error(error);

			let currentBackoff = this.state.request_backoff;
			let nextBackoff = currentBackoff * 2;
			if (nextBackoff > expoBackoffMax) {
				nextBackoff = expoBackoffMax;
			}

			let timer = setTimeout(this.getData, currentBackoff);

			this.setState({
				request_backoff: nextBackoff,
				request_timer: timer
			});
		}

		const getFileSuccess = (jsonData) => {			
			if (!Array.isArray(jsonData)) {
				getFileFail(new Error('Can\'t handle this format of data', jsonData));
				return;
			}

			let timer = setTimeout(this.getData, this.state.request_rate);

			let data_values = '//';
			jsonData.forEach((element) => {
				data_values += ` ${element.title}: ${element.market_value} //`;
			});
			console.log(data_values);

			this.setState({
				data: jsonData,
				request_backoff: expoBackoffMin,
				request_timer: timer,
			});
		}

		try {
			fetch(target)
				.then((response) => {
					return response.json();
				})
				.then((jsonData) => {
					return getFileSuccess(jsonData);
				})
				.catch((err) => {
					getFileFail(err);
				});
		} catch(err) {
			getFileFail(err);
		}
	}

	refresh() {
		this.setState({refresh: this.state.refresh});
	}

	render() {
		let css = this.state.css;

		// Custom classes
		let customClasses = '';
		if (this.props.custom_classes && this.props.custom_classes.length > 0) {
			this.props.custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (this.props.modifier_classes && this.props.modifier_classes.length > 0) {
			this.props.modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}


		let format = this.props.format;

		let stocks = this.state.data.map((element, i) => {

			let title = element.title;
			let marketValue = element.market_value;
			let change = element.change;
			let reportTime = element.report_time;

			let decimal = change / marketValue;
			let percentage = decimal.toFixed(4) * 100;
			percentage = percentage.toFixed(2);


			let status = '';
			let icon = '';
			if (change.includes('+')) {
				status = 'up';
				icon = <i className={`${css.up} fas fa-chevron-up`} />;
			}
			if (change.includes('-')) {
				status = 'down';
				icon = <i className={`${css.down} fas fa-chevron-down`} />;
			}

			let arrow = '';
			if (change.includes('+')) {
				arrow = <i className={`${css.arrow} fas fa-arrow-up`} />;
			}
			if (change.includes('-')) {
				arrow = <i className={`${css.arrow} fas fa-arrow-down`} />;
			}

			if (this.props.format === 'horizontal1') {
				return (
					<div key={title} className={css.item_h1}>
						<div className={css.content_h1}>
							<div className={css.left_h1}>
								<div className={`${css.title_h1}`}>
									{title}
								</div>
								<div className={`${css.marketValue_h1}`}>
									{marketValue}
								</div>
								<div className={`${css.change_h1} ${css[status]}`}>
									{change}
									<span className={`${this.props.percentage === true ? `${css.visible}` : `${css.hidden}`} ${css.percentage_h1} ${css[status]}`}>
										({percentage})%
									</span>
								</div>
							</div>
							<div className={css.right_h1}>
								<div className={css.icon_h1}>{icon}</div>
							</div>
						</div>
					</div>
				);
			}

			if (this.props.format === 'horizontal2') {
				return (
					<div key={title} className={css.item_h2}>
						<div className={css.content_h2}>
							<div className={css.top_h2}>
								<div className={`${css.title_h2}`}>
									{title}
								</div>
							</div>
							<div className={css.bottom}>
								<div className={`${css.marketValue_h2}`}>
									{marketValue}
								</div>
								<div className={`${css.change_h2} ${css[status]}`}>
									{change}
									<span className={`${this.props.percentage === true ? `${css.visible}` : `${css.hidden}`} ${css.percentage_h2} ${css[status]}`}>
										({percentage})%
									</span>
									<div className={css.icon_h2}>{icon}</div>
								</div>
							</div>
						</div>
					</div>
				);
			}

			if (this.props.format === 'vertical1') {
				return (
					<div key={title} className={css.item_v1}>
						<div className={css.content_v1}>
							<div className={css.left_v1}>
								<div className={`${css.title_v1}`}>
									{title}
								</div>
								<div className={`${css.marketValue_v1}`}>
									{marketValue}
								</div>
								<div className={`${css.change_v1} ${css[status]}`}>
									{change}
									<span className={`${this.props.percentage === true ? css.visible : css.hidden} ${css.percentage_v1} ${css[status]}`}>
										({percentage})%
									</span>
								</div>
							</div>
							<div className={css.right_v1}>
								<div className={css.icon_v1}>{icon}</div>
							</div>
						</div>
					</div>
				);
			}

			if (this.props.format === 'vertical2') {
				return (
					<div key={title} className={css.item_v2}>
						<div className={css.content_v2}>
							<div className={`${css.title_v2}`}>
								{title}
							</div>
							<div>
								<div className={`${css.marketValue_v2}`}>
									{marketValue}
								</div>
								<div className={`${css.change_v2} ${css[status]}`}>
									{change}
									<span className={`${this.props.percentage === true ? css.visible : css.hidden} ${css.percentage_v2} ${css[status]}`}>
										({percentage})%
									</span>
									<div className={css.icon_v2}>{icon}</div>
								</div>
							</div>
						</div>
					</div>
				);
			}

			if (this.props.format === 'vertical3') { // default version in animated scroller
				return (
					<div key={title} className={css.item_v3}>
						<div className={css.content_v3}>
							<div className={css.title_v3}>
								{title}
							</div>
							<div className={css.change_v3}>
								{change}
							</div>
							{arrow}
							<div className={css.marketValue_v3}>
								{marketValue}
							</div>
						</div>
					</div>
				);
			}

			if (this.props.format === 'animated') {
				return (
					<div key={`market${i}`} className={`${css.animatedContainer} ${this.state.animDisplay === i + 1 ? css.animFadeIn : css.animFadeOut}`}>
					{/* <div className={`${css.animatedContainer} ${css[`${this.state.animation}`]}`}> */}
						<div key={title} className={css.animItem}>
							<div className={css.animContent}>
								<div className={css.left}>
									<div className={`${css.animTitle}`}>
										{title}
									</div>
									<div className={`${css.animMarketValue}`}>
										<div className={css.valueText}>{marketValue}</div><div className={css.animIcon}>{icon}</div>
									</div>
									<div className={`${css.animChange} ${css[`${status}`]}`}>
										{change}
										<span className={`${this.props.percentage === true ? css.visible : css.hidden} ${css.animPercentage} ${css[`${status}`]}`}>
											({percentage})%
										</span>
									</div>
									<div className={`${css.animReportTime}`}>
										{reportTime}
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
			return '';
		});

		return (
			<div id={`stocks_${this.props.component_name}`} className={`stocks_container ${css.stocks} ${css[`${format}`]} ${modifierClasses} ${customClasses}`}>
				{stocks}
			</div>
		);

	}
}

export default Stocks;