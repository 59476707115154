import React, { Component } from 'react'
import { dlNavigationSwapPhases } from '../DataLayer';
class NavigationButton extends Component {
	constructor(props) {
		super(props)

		this.state = {
			css: props.CSSModules[props.condor_render_name],
			iconFamily: props.icon_family,
			iconType: props.icon_type,
		}
		this.buttonDelayTimer = null;
	}

	componentDidMount() {}

	componentWillUnmount() {
		clearTimeout(this.buttonDelayTimer);
	}

	componentDidUpdate(prevProps) {
		if(prevProps.icon_type !== this.props.icon_type || prevProps.icon_family !== this.props.icon_family) {
			this.setState({
				iconFamily: this.props.icon_family,
				iconType: this.props.icon_type
			});
		}
	}

	swapPhases = (label, touchType) => {
		console.log(`Clicked Nav: ${label} - ${touchType}}`);
		clearTimeout(this.buttonDelayTimer);
		
		// delay allows button feedback before changing animation phases
		this.buttonDelayTimer = setTimeout(() => {
			this.props.navEvents('swapPhases', this.props.animation_phase,  this.props.show_menu)
		}, 200);
	}
	

	useIcon = () => {
		let css = this.state.css;
		let icon = '';
		let family = '';
		
		switch(this.state.iconType) {
			case 0:
				icon = ''
				break;
			case 1:
				icon = 'announcements';
				break;
			case 2:
				icon = 'amenitiesArea';
				break;
			case 3:
				icon = 'amenitiesBldg';
				break;
			case 4:
				icon = 'document';
				break;
			case 5:
				icon = 'floorplan';
				break;
			case 6:
				icon = 'home';
				break;
			case 7:
				icon = 'individuals';
				break;
			case 8:
				icon = 'info';
				break;
			case 9:
				icon = 'info2';
				break;
			case 10:
				icon = 'search';
				break;
			case 11:
				icon = 'spaceAvailable';
				break;
			case 12:
				icon = 'tenants';
				break;
			case 13:
				icon = 'traffic';
				break;
			case 14:
				icon = 'transit';
				break;
			case 15:
				icon = 'url';
				break;
			case 16:
				icon = 'video';
				break;
			case 17:
				icon = 'dining';
				break;
			case 18:
				icon = 'eventsCal';
				break;
			case 19:
				icon = 'fun';
				break;
			case 20:
				icon = 'homeOutline';
				break;
			case 21:
				icon = 'mapOutline';
				break;
			case 22:
				icon = 'services';
				break;
			case 23:
				icon = 'shopping';
				break;
			case 24:
				icon = 'searchAll';
				break;
			case 25:
				icon = 'airplane';
				break;
			case 26:
				icon = 'anchor';
				break;
			case 27:
				icon = 'announcements2';
				break;
			case 28:
				icon = 'brochure';
				break;
			case 29:
				icon = 'conferenceRoom';
				break;
			case 30:
				icon = 'directoryHierarchy';
				break;
			case 31:
				icon = 'directoryPerson';
				break;
			case 32:
				icon = 'doctor';
				break;
			case 33:
				icon = 'eventsCalendar';
				break;
			case 34:
				icon = 'FAQQuestionMark';
				break;
			case 35:
				icon = 'floorplan3';
				break;
			case 36:
				icon = 'gym';
				break;
			case 37:
				icon = 'homePlate';
				break;
			case 38:
				icon = 'hospitalCross';
				break;
			case 39:
				icon = 'info3';
				break;
			case 40:
				icon = 'lightBulb';
				break;
			case 41:
				icon = 'mapMarker';
				break;
			case 42:
				icon = 'restaurant';
				break;
			case 43:
				icon = 'shield';
				break;
			case 44:
				icon = 'urlGlobe2';
				break;
			default:
				icon = '';
		}

		switch(this.state.iconFamily) {
			case 0:
				family = 'default'
				break;
			case 1:
				family = 'retail'
				break;
			default:
				family = 'default'
		}

		let iconCont = '';
		if (this.state.iconType !== 0 && this.state.iconType !== null && this.state.iconType !== undefined) {
			iconCont =  <div className={`navBtnIcon ${css.navBtnIcon}`}>
							<img src={`https://ts-condor-assets.s3.amazonaws.com/images/icons/nav/${family}/${icon}.svg`} className={`navSvgIcon ${css.navSvgIcon}`} draggable="false"/>
						</div>
		}

		return iconCont;
	}

	handleDown = (buttonId) => {
		if (this.props.isButtonActive() === false) {
			this.props.setCurrentButton(this.props.animation_phase);
		}
	}

	handleUp = () => {
		// placeholder
	}

	render() {
		const { css } = this.state;
		const { 
			animation_phase,
			currentButton,
			custom_classes, 
			button_label,
		} = this.props;
		
		let btnHilight = '';
		
		if (currentButton && currentButton === animation_phase) {
			btnHilight = `${css.btnHilight} btnHilight`;
		}
		
		return (
			<button 
				className={`navButton ${css.navBtn} ${custom_classes || ''} ${btnHilight}`}
				onMouseDown={() => { 
					this.handleDown(button_label);
					this.swapPhases(button_label, 'onMouseDown');
				}} 
				onMouseUp={this.handleUp}
				onMouseLeave={this.handleUp}
				onTouchStart={() => { 
					this.handleDown(button_label);
					this.swapPhases(button_label, 'onTouchStart');
				}}
				onTouchEnd={this.handleUp}
				onPointerDown={() => { 
					this.handleDown(button_label);
					this.swapPhases(button_label, 'onPointerDown');
				}}
				onPointerUp={this.handleUp}
				onPointerLeave={this.handleUp}
			>
				{this.useIcon()}
				<div className={`${css.navLabel} navigationButtonNavLabel`}>
					{button_label}
				</div>
			</button>
		);
	}
}

export default NavigationButton
