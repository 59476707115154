import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import {
	ternStaticTokenENV,
	ternUserNameENV,
} from '../ENV';

const userName = ternUserNameENV;

if (!mapboxgl.workerClass) {
	const accessToken = ternStaticTokenENV;
	// eslint-disable-next-line import/no-webpack-loader-syntax
	mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // Wire up loaded worker to be used instead of the default
	mapboxgl.accessToken = accessToken;
}

const createMap = (latitude, longitude, zoom, styleId, component_name, map, setMap) => {
	if (map) {
		map.remove();
	}
	const newMap = new mapboxgl.Map({
		container: `dynamicMapTraffic_${component_name}`, // container ID
		style: `mapbox://styles/${userName}/${styleId}`, // style URL
		center: [longitude, latitude], // starting position [lng, lat]
		zoom, // starting zoom
		interactive: false,
		preserveDrawingBuffer: true,
	});

	newMap.on('load', () => {
		document.querySelectorAll('.mapboxgl-control-container a').forEach((anchor) => {
			anchor.addEventListener("click", (event) => {
				event.preventDefault();
			});
		});

		const marker = document.createElement('div');
		marker.style.backgroundImage = "url('https://ts-condor-assets.s3.amazonaws.com/images/icons/yah-icon.png')";
		marker.style.width = '25px';
		marker.style.height = '41px';

		new mapboxgl.Marker(marker).setLngLat([longitude, latitude]).addTo(newMap).setOffset([0, -20]);

		setMap(newMap);
	});

	newMap.on('error', (error) => {
		const mapTimeout = 15000;
		console.error(`Error loading map. Will retry in ${mapTimeout}ms.`, error);
		setTimeout(() => {
			createMap(latitude, longitude, zoom, styleId, component_name, newMap, setMap);
		}, mapTimeout);
	});
};

const DynamicMapTraffic = (props) => {
	const {
		latitude,
		longitude,
		static_map_zoom: zoom,
		static_map_width: width,
		static_map_height: height,
		static_map_style_id: styleId,
		traffic_line_color_low,
		traffic_line_color_moderate,
		traffic_line_color_heavy,
		traffic_line_color_severe,
		traffic_line_width,
		traffic_line_before_layer,
		component_name,
	} = props;

	const css = props.CSSModules[props.condor_render_name];
	const [ map, setMap, ] = useState();

	useEffect(() => {
		createMap(latitude, longitude, zoom, styleId, component_name, map, setMap);
	}, []);
	
	useEffect(() => {
		if (!map) {
			return;
		}

		map.addLayer({
			id: "traffic",
			type: "line",
			source: {
				type: "vector",
				url:  "mapbox://mapbox.mapbox-traffic-v1"
			},
			"source-layer":"traffic",
			paint: {
				"line-color": [
					"match",
					["get", "congestion"],
					["low"],
					traffic_line_color_low,
					["moderate"],
					traffic_line_color_moderate,
					["heavy"],
					traffic_line_color_heavy,
					["severe"],
					traffic_line_color_severe,
					"black",
				],
				"line-width": traffic_line_width,
			},
		}, traffic_line_before_layer);

	}, [map]);

	useEffect(() => {
		if (!map) {
			return;
		}

		map.setZoom(zoom);
	}, [zoom]);

	useEffect(() => {
		if (!map) {
			return;
		}

		map.setPaintProperty("traffic", "line-color", [
			"match",
			["get", "congestion"],
			["low"],
			traffic_line_color_low,
			["moderate"],
			traffic_line_color_moderate,
			["heavy"],
			traffic_line_color_heavy,
			["severe"],
			traffic_line_color_severe,
			"black",
		]);

	}, [
		traffic_line_color_low,
		traffic_line_color_moderate,
		traffic_line_color_heavy,
		traffic_line_color_severe,
	]);

	useEffect(() => {
		if (!map) {
			return;
		}

		map.setPaintProperty("traffic", "line-width", traffic_line_width);

	}, [ traffic_line_width, ]);

	useEffect(() => {
		if (!map) {
			return;
		}

		map.moveLayer("traffic", traffic_line_before_layer);
	}, [ traffic_line_before_layer, ]);

	useEffect(() => {
		if (!map) {
			return;
		}

		map.setStyle(`mapbox://styles/${userName}/${styleId}`);
	}, [ styleId, ]);

	// Custom classes
	let customClasses = '';
	if (props.custom_classes && props.custom_classes.length > 0) {
		props.custom_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				customClasses += ` ${element}`;
			}
		});
	}

	// Modifier classes
	let modifierClasses = '';
	if (props.modifier_classes && props.modifier_classes.length > 0) {
		props.modifier_classes.forEach((element) => {
			if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
				modifierClasses += ` ${element}`;
			}
		});
	}

	return (
		<div
			className={`${modifierClasses} ${customClasses} ${css.dynamicMapTrafficContainer}`}
			key={`img${props.keyNum}`}
			style={{
				width: `100%`,
				height: `100%`,
			}}
		>
			<div
				id={`dynamicMapTraffic_${component_name}`}
				style={{
					width: `100%`,
					height: `100%`,
				}}
			>
			</div>
		</div>
	);
};

export default DynamicMapTraffic;
