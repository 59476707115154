let SWHeartBeat = null;

export const registerSW = async () => {
	if (!navigator?.serviceWorker) {
		return;
	}

	return navigator.serviceWorker.register('sw.js', {
		scope: './',
	}).then((registration) => {
		return registration.update();
	}).then((registration) => {
		if (registration.installing) {
			console.log('Service worker installing');
		} else if (registration.waiting) {
			console.log('Service worker installed');
		} else if (registration.active) {
			console.log('Service worker active');
		}
	}).catch((error) => {
		console.error(`Registration failed with ${error}`);
	});
};

const messageListener = () => {
	clearTimeout(SWHeartBeat);
	SWHeartBeat = setTimeout(() => {
		console.log('trying to reactivate the service worker');
		registerSW();
	}, 5 * 60 * 1000);
};

export const listenToSW = () => {
	if (!navigator?.serviceWorker) {
		return;
	}

	navigator.serviceWorker.removeEventListener('message', messageListener);
	navigator.serviceWorker.addEventListener('message', messageListener);
};

export const closeListenerToSw = () => {
    clearTimeout(SWHeartBeat);
};

export const deleteFromCache = (urls) => {
	if (!navigator?.serviceWorker?.controller) {
		return;
	}

	let data = urls;
	if (!Array.isArray(urls)) {
		data = [urls];
	}

	return navigator.serviceWorker.controller.postMessage({
		type: 'deleteFromCache',
		data,
	});
};

export const preCache = (assets, cb) => {
	const operationID = Date.now();
	if (!navigator?.serviceWorker?.controller) {
		return;
	}

	let data = assets;
	if (!Array.isArray(assets)) {
		data = [assets];
	}

	navigator.serviceWorker.controller.postMessage({
		type: 'preCache',
		data,
		operationID,
	});

	if (typeof cb === 'function') {
		const dynamicCB = (res) => {
			if (res.data?.msg === 'precache complete' &&
				res.data?.operationID === operationID &&
				res.data?.success) {
				cb(operationID);
				navigator.serviceWorker.removeEventListener('message', dynamicCB);
			}
		};
		navigator.serviceWorker.addEventListener('message', dynamicCB);
	}

	return operationID;
};