import React, {Component} from 'react';
import TimeFmt from '../util/TimeFmt.js'

class DateDisplay extends Component {
	constructor(props) {
		super(props);
		let tick = 900000; // <-- 15 minutes

		this.state = {
			css:    props.CSSModules[props.condor_render_name],
			dateID: false,
			tick:    tick,
			now:     new Date()
		};
	}

	componentDidMount() {
		let dateID = setInterval(this.dateTick, 15000); // Ticks every 30 seconds.
		this.setState({dateID: dateID});
	}

	componentWillUnmount() {
		clearInterval(this.state.dateID);
	}

	dateTick = () => {
		let nextNow = new Date();
		this.setState({now: nextNow});
		this.setState({currentTime: nextNow});
	}

	createHoursAndFmt = (rawHours) => {
		let hours = rawHours;
		if (this.props.time_format === '12_hour') {
			if (hours === 0) {
				hours = 12;
			}

			if (hours > 12) {
				hours = hours - 12;
			}
		}

		return [hours];
	}

	createMinutes = (minutes) => {
		if (minutes >= 0 && minutes < 10) {
			return '0' + minutes;
		}
		return minutes;
	}

	render() {
		let css = this.state.css;

		// Declare classes
		// Custom classes
		let customClasses = '';
		if (this.props.custom_classes && this.props.custom_classes.length > 0) {
			this.props.custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					// customClasses += ` ${this.css()[element]}`;
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (this.props.modifier_classes && this.props.modifier_classes.length > 0) {
			this.props.modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		let date = "";

		switch (this.props.date_format) {
		case 1:
			// Monday, February 24
			date = TimeFmt.makeDayOfWeek(this.state.now) + ', ' + TimeFmt.makeMonthOfYear(this.state.now) + ' ' + this.state.now.getDate();
			break;
		case 2:
			// February 24
			date = TimeFmt.makeMonthOfYear(this.state.now) + ' ' + this.state.now.getDate();
			break;
		case 3:
			// Mon, Feb 24
			date = TimeFmt.makeDayOfWeek(this.state.now, true) + ', ' + TimeFmt.makeMonthOfYear(this.state.now, true) + ' ' + this.state.now.getDate();
			break;
		case 4:
			// Feb 24
			date = TimeFmt.makeMonthOfYear(this.state.now, true) + ' ' + this.state.now.getDate();
			break;
		case 5:
			// 2/24
			date = this.state.now.getMonth() + 1 + '/' + this.state.now.getDate();
			break;
		case 6:
			// Feb 24
			date =   this.state.now.getDate() + ' ' + TimeFmt.makeMonthOfYear(this.state.now);
			break;
		case 7:
			// Feb 24
			date =   this.state.now.getDate() + ' ' + TimeFmt.makeMonthOfYear(this.state.now, true);
			break;
		default:
			// Monday, February 24
			date = TimeFmt.makeDayOfWeek(this.state.now) + ', ' + TimeFmt.makeMonthOfYear(this.state.now) + ' ' + this.state.now.getDate();
		}
        

		return (
            <div id={`date_${this.props.component_name}`} className={`date_container ${css.cont} ${modifierClasses} ${customClasses}`}>
                {date}
            </div>
		);
	}
}

export default DateDisplay;
