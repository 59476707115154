import React, {Component} from 'react';

class AnimatedScroller extends Component {
	constructor(props) {
		super(props);

		this.state = {
			animate:              false,
			animSlider:           '',
			animCont:             '',
			autoScrolling:        false,
			componentNumToShow:   props.chunk_number_shown,
			componentMarginGap:   props.chunk_margin_bottom,
			css:                  props.CSSModules[props.condor_render_name],
			customClasses1:       props.custom_classes_1,
			customClasses2:       props.custom_classes_2,
			customClasses3:       props.custom_classes_3,
			modifierClasses1:     props.modifier_classes_1,
			modifierClasses2:     props.modifier_classes_2,
			modifierClasses3:     props.modifier_classes_3,
			cycleCount:           0,
			scrollByPixelsVert:   0,
			scrollByPixelsHoriz:  0,
			scrollerHeight:       0,
			timerBarHeight:       props.timer_bar_height,
			timerRunning:         false,
			timerInterval:        props.scroll_time_secs,
			transition:           0
		};
		this.startAnimatedScrollerTimer = this.startAnimatedScrollerTimer.bind(this);
		this.stopAnimatedScrollerTimer = this.stopAnimatedScrollerTimer.bind(this);
		this.scrollNext = this.scrollNext.bind(this);
		this.startTimerBar = this.startTimerBar.bind(this);
		this.resetTimerBar = this.resetTimerBar.bind(this);
		this.startScrollTimer = this.startScrollTimer.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.onWheel = this.onWheel.bind(this);

		this.animContainer = React.createRef();
		this.animSlider = React.createRef();
		this.compInnerWrap = {};
	}

	calculateSliderDimensions(componentsToShow) {
		if (this.animContainer.current === null) {
			console.error('this.animContainer has not been assigned', this.animContainer);
			return;
		}

		let timerBarHeight = this.state.timerBarHeight;
		if (this.props.processor_power !== 2) {
			timerBarHeight = 0;
		}
		let animContHeight = this.animContainer.current.clientHeight;
		let animContWidth = this.animContainer.current.clientWidth;
		
		let animContScrollHeight = animContHeight - timerBarHeight;
		let animContScrollWidth = animContWidth;
		
		let scrollByPixelsVert = Math.floor((animContScrollHeight + this.state.componentMarginGap) / componentsToShow);
		let scrollByPixelsHoriz = Math.floor((animContScrollWidth + this.state.componentMarginGap) / componentsToShow);
		
		let animWrapperHeight = (scrollByPixelsVert * componentsToShow) - this.state.componentMarginGap;
		let animWrapperWidth = (scrollByPixelsHoriz * componentsToShow) - this.state.componentMarginGap;

		let heightDiff = animContScrollHeight - animWrapperHeight;

		timerBarHeight += heightDiff;
		let animSlider = this.animSlider.current;

		this.setState({
			animSlider:          animSlider,
			scrollByPixelsVert:  scrollByPixelsVert,
			scrollByPixelsHoriz: scrollByPixelsHoriz,
			scrollerHeight:      animWrapperHeight,
			scrollerWidth:       animWrapperWidth,
			timerBarHeight:      timerBarHeight
		});
	}

	componentDidMount() {
		setTimeout(() => {
			this.calculateSliderDimensions(this.state.componentNumToShow)
			
			let shouldAnimate = false;
			if (this.props.condor_component_list && this.props.condor_component_list.length > this.state.componentNumToShow && this.props.scroll_time_secs > 0) {
				shouldAnimate = true;
			}
			
			this.setState({
				animate: shouldAnimate,
			});
			
			// animate if enough components to do so
			setTimeout(() => {
				if (shouldAnimate) {
					this.startAnimatedScrollerTimer();
				}
			}, 10000);
		}, 1250);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.chunk_number_shown !== this.props.chunk_number_shown || prevProps.condor_component_list !== this.props.condor_component_list) {
			this.calculateSliderDimensions(this.props.chunk_number_shown);

			let shouldAnimate = false

			if (this.props.condor_component_list && this.props.condor_component_list.length > this.props.chunk_number_shown && this.props.scroll_time_secs > 0) {
				shouldAnimate = true;
			}

			this.setState({
				animate: shouldAnimate,
			});

			if (prevProps.scroll_time_secs !== this.props.scroll_time_secs) {
				this.setState({timerInterval: this.props.scroll_time_secs})
			}

			if (this.state.animate !== prevState.animate || prevProps.scroll_time_secs !== this.props.scroll_time_secs) {
				setTimeout(() => {
					if (shouldAnimate) {
						this.startAnimatedScrollerTimer();
					} else {
						this.stopAnimatedScrollerTimer();
					}
				}, 10);
			}
		}
	}

	returnClasses = (array) => {
		let newClassList = '';
		if (Array.isArray(array) && array.length > 0) {
			array.forEach((item) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(item)) {
					newClassList += ` ${item}`;
				}
			});
		}
		return newClassList;
	}

	returnModifierClasses = (array) => {
		let newClassList = '';
		if (array && Array.isArray(array)) {
			array.forEach((item) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(item)) {
					newClassList += ` ${item}`;
				}
			});
		}
		return newClassList;
	}

	// begin countdown to auto animate upward
	startAnimatedScrollerTimer = () => {
		this.setState({
			autoScrolling: true
		});

		if (this.state.cycleCount > 0) { // preventative - does this run?
			console.log('reset timer values');
			clearInterval(this.timer);
			this.setState({
				cycleCount: 0
			});
			setTimeout(() => {
				this.startAnimatedScrollerTimer();
			}, 10);
		} else {
			console.log('start startAnimatedScrollerTimer');
			let cycleCount = this.state.cycleCount;
			let tick = this.state.timerInterval * 1000;
			this.startTimerBar();
			this.timer = setInterval(() => {
				this.resetTimerBar();
				this.scrollNext();
				this.startTimerBar();
				cycleCount++;
				this.setState({
					cycleCount:   cycleCount,
					timerRunning: true
				});
			}, tick);
		}
	}

	// clear scroll timer
	stopAnimatedScrollerTimer = () => {
		clearInterval(this.timer);
		this.setState({
			cycleCount:   0,
			timerRunning: false
		});
	}

	// mouse scrolling
	onWheel = () => {
		if (this.state.animate === true) {
			clearTimeout(this.scrollTimer);
			clearInterval(this.timer);
			this.setState({
				autoScrolling: false
			});
		}
	}

	// screen touch scrolling
	onTouchStart = () => {
		if (this.state.animate === true) {
			clearTimeout(this.scrollTimer);
			clearInterval(this.timer);
			this.setState({
				autoScrolling: false
			});
		}
	}

	// reset, swap, and track position while scroller is moving (from any source)
	onScroll = () => {
		if (this.state.autoScrolling === false && this.state.animate === true) {
			this.stopAnimatedScrollerTimer();
			this.resetTimerBar();
			this.startScrollTimer();
			
			let scrollPosition = this.state.animSlider.scrollTop;
			let scrollPixelValue = this.state.scrollByPixelsVert;

			if (this.props.scroll_type === 1) { // horizontal scrolling
				scrollPosition = this.state.animSlider.scrollLeft;
				scrollPixelValue = this.state.scrollByPixelsHoriz;
			}

			if (scrollPosition >= scrollPixelValue) {
				console.log('on scroll - swap content');
				this.swapContent();
			}
			
		}
	}

	// snaps content up or down when active scrolling completes
	startScrollTimer = () => {
		clearTimeout(this.scrollTimer);
		this.scrollTimer = setTimeout(() => {
			let animScroller = this.state.animSlider;
			let position = this.state.animSlider.scrollTop;
			let scrollAmount = this.state.scrollByPixelsVert;
			if (this.props.scroll_type === 1) {
				position = this.state.animSlider.scrollLeft;
				scrollAmount = this.state.scrollByPixelsHoriz;
			}

			if (position % scrollAmount !== 0) {
				if (position < scrollAmount / 2) {
					if (this.props.scroll_type === 1) {
						animScroller.scrollLeft = 0;
					} else {
						animScroller.scrollTop = 0;
					}
				} else if (position < scrollAmount) {
					if (this.props.scroll_type === 1) {
						animScroller.scrollLeft = scrollAmount;
					} else {
						animScroller.scrollTop = scrollAmount;
					}
					setTimeout(() => {
						this.swapContent();
					}, 400);
				}
			}

			this.startAnimatedScrollerTimer();
		}, 100);
	}

	// scroll content up (auto)
	scrollNext = () => {
		let css = this.state.css;
		let swapContent = this.swapContent;

		let scrollByPixels = this.state.scrollByPixelsVert;
		let scrollX = 0;
		let scrollY = scrollByPixels;
		if (this.props.scroll_type === 1) { // horizontal scrolling
			scrollByPixels = this.state.scrollByPixelsHoriz;
			scrollX = scrollByPixels;
			scrollY = 0;
		}

		if (this.props.processorPower === 1) { // low processor -> not currently used
			let compInnerWrap = Object.values(this.compInnerWrap).map((node) => {
				return node?.current;
			});

			compInnerWrap.forEach((item) => {
				item.classList.add(css.compFadeOut);
			});

			setTimeout(function() {
				if (this.animSlider.current) {
					this.animSlider.current.scrollBy(scrollX, scrollY);
				}
			}, 175);

			setTimeout(function() {
				swapContent();
			}, 450);
			setTimeout(function() {
				compInnerWrap.forEach((item) => {
					item.classList.remove(css.compFadeOut);
				});
			}, 500);
		} else { // standard scrolling
			if (this.animSlider.current) {
				this.animSlider.current.scrollBy(scrollX, scrollY);
			}
			setTimeout(function() {
				swapContent();
			}, 700); // this duration was increased from 500 as child content was getting cut off, due to swapping before scrolling was completed on 4K systems
		}
	}

	// move top component to bottom
	swapContent = () => {
		let animSlider = this.animSlider.current;

		if (!animSlider) {
			return;
		}

		let animSlider1stChild = this.animSlider.current.firstChild;
		let animSliderLastChild = this.animSlider.current.lastChild;

		animSliderLastChild.after(animSlider1stChild);

		animSlider.style.scrollBehavior = 'auto';
		animSlider.scrollTo(0, 0);
		animSlider.style.scrollBehavior = 'smooth';
	}

	// start animated timer bar
	startTimerBar = () => {
		this.setState({
			transition: 1
		});
	}

	// stop timer bar aniamtion
	resetTimerBar = () => {
		this.setState({
			transition: 0
		});
	}

	// add/remove timebar animation styles
	timebarStyles = (isOn) => {
		let style = {
			transition: `${this.state.timerInterval}s all linear`,
			width:      `100%`
		};
		if (isOn === 1) {
			return style;
		}
		return;
	}

	// timebar
	createTimeBar = () => {
		let css = this.state.css;
		let style = {
			marginTop: this.state.timerBarHeight - 3
		};
		if (this.state.animate === true && this.props.processorPower === 2) {
			return <div className={css.timerBar} style={style}><div className={css.timerBarActive} style={this.timebarStyles(this.state.transition)}></div></div>;
		}
	}

	// styles to disable animation
	disableAnimation = () => {
		if (this.state.animate === false) {
			console.log('Animated Scroller has been disabled');
			let style = {
				overflowX: 'hidden',
				overflowY: 'hidden'
			};
			return style;
		}
	}


	render() {
		// console.log(`============ render ============`);
		let css = this.state.css;
		let orientation = 'Vert';
		let animWrapperStyles = {
			height: `${this.state.scrollerHeight}px`
		};

		if (this.props.scroll_type === 1) { // horizontal scrolling
			orientation = 'Horiz';
			animWrapperStyles = {
				width: `${this.state.scrollerWidth}px`
			};
		}

		let animationStyle = css.animationScroll;
		if (this.props.processor_power === 0 || this.props.processor_power === 1) {
			animationStyle = css.animationLow;
		}


		let addComponents = [];
		let animatedScroller = false;
		if (this.props.condor_component_list && this.props.condor_component_list.length > 1) {
			animatedScroller = true;
		}
		
		this.props.condor_component_list.forEach((element, i) => {
			let newElm = React.cloneElement(element, {
				animatedScroller:      animatedScroller,
				animatedScrollerTimer: this.props.scroll_time_secs,
				cycleCount:            this.state.cycleCount
			 });

			addComponents.push(
				<ScrollerChild 
					newElm={newElm}
					key={`childKey_${i}`}
					childNum={i}
					childrenLength={this.props.condor_component_list.length - 1}
					css={this.state.css}
					scrollByPixelsVert={this.state.scrollByPixelsVert}
					scrollByPixelsHoriz={this.state.scrollByPixelsHoriz}
					componentMarginGap={this.state.componentMarginGap}
					animScroller_name={this.props.component_name}
					render_name={element.props.condor_render_name}
					scroll_type={this.props.scroll_type}
					cycleCount={this.state.cycleCount}
					animatedScroller={this}
				/>
			);
		});


		return (
			<div ref={this.animContainer} className={`animScroll_container ${css.animCont} ${css[`animCont${orientation}`]} ${this.returnModifierClasses(this.state.modifierClasses1)} ${this.returnClasses(this.state.customClasses1)}`}>
				<div id={`animWrapper_${this.props.component_name}`} className={`animScroll_wrapper ${css.animWrapper} ${css[`animWrapper${orientation}`]} ${this.returnModifierClasses(this.state.modifierClasses2)} ${this.returnClasses(this.state.customClasses2)}`} style={animWrapperStyles}>
					<div ref={this.animSlider} className={`animScroll_slider ${css.animSlider} ${css[`animSlider${orientation}`]} ${animationStyle} ${this.returnModifierClasses(this.state.modifierClasses3)} ${this.returnClasses(this.state.customClasses3)}`} style={this.disableAnimation()} onScroll={this.onScroll} onTouchStart={this.onTouchStart} onWheel={this.onWheel}>
						{addComponents}
					</div>
				</div>
				{this.createTimeBar()}
			</div>
		);
	}
}

class ScrollerChild extends React.Component {
	constructor(props) {
		super(props);

		props.animatedScroller.compInnerWrap[props.childNum] = React.createRef();
	}

	render() {
		let css = this.props.css;
		let style = {
			height: this.props.scrollByPixelsVert
		};
		let styleInner = {
			marginBottom: this.props.componentMarginGap
		};
		let orientation = 'Vert';


		if (this.props.scroll_type === 1) { // horizontal scrolling
			style = {
				width: this.props.scrollByPixelsHoriz
			};
			styleInner = {
				marginRight: this.props.componentMarginGap
			};
			orientation = 'Horiz';
		}

		let inner =
			<div className={`${`compWrapper_${this.props.animScroller_name}`} animScroll_compWrapper ${css.compWrapper}`} style={styleInner}>
				<div ref={this.props.animatedScroller.compInnerWrap[this.props.childNum]} className={`${css.compInnerWrap} animScroll_innerWrap ${`compInnerWrap_${this.props.animScroller_name}`}`}>
					{this.props.newElm}
				</div>
			</div>
		;
		
		return (
			<div data-child={`${this.props.childNum}-${this.props.render_name}`} className={`${`compContainer_${this.props.animScroller_name}`} ${css.compContainer} ${`animScroll_comp${orientation}`} ${css[`comp${orientation}`]}`} style={style}>{inner}</div>
		)
	}
}

export default AnimatedScroller;
