import React, { PureComponent } from 'react'

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

// import style from './Keyboard.module.css';

class KeyboardComponent extends PureComponent {
	constructor(props) {
		super(props);
        this.keyboard = null;
		this.state = {
            css: props.CSSModules["Keyboard"],
			keyboardLayoutType: this.props.defaultKeyboard || 'letters'
		}
	}

	componentDidMount() {
	}

    componentWillUnmount() {
        this.keyboard.destroy();
	}

    componentDidUpdate(prevProps) {
        if (prevProps.navProps.resetListings !== this.props.navProps.resetListings && this.props.navProps.resetListings === true) {
            this.props.setInputValue('');
            this.keyboard.setInput('');
		}

        if(this.props.searchUpdater) {
            if (prevProps.searchUpdater !== this.props.searchUpdater) {
                this.props.setInputValue('');
                this.keyboard.setInput('');
            }
        }

        if((this.props.navProps.timeoutStatus !== prevProps.navProps.timeoutStatus && !this.props.navProps.timeoutStatus) && (this.props.navProps.animationPhase === this.props.navProps.animationStartPhase)) {
            this.props.setInputValue('');
            this.keyboard.setInput('');
        }
    }

    handleKeyboardOnChange = (input) => {
        this.props.setInputValue(input);
        this.props.closeDetailsPage();
        this.props.setDetailsTitle('');
        this.props.setTieredHeadersAndDetails([], []);
        this.props.setHistory([]);
    }

    handleKeyboardOnKeyPress = (button) => {
        this.props.navEvents('idleTimer', '||| Keyboard.js > handleKeyboardOnKeyPress');
        this.props.navEvents('setHasNavigatedListings', true);
        switch (button) {
            case '{clear}':
                this.props.setInputValue('')
                this.keyboard.setInput('');
                break;
            case '{switchToNumbers}':
                this.setState({ keyboardLayoutType: 'numbers'})
                break;
            case '{switchToLetters}':
                this.setState({ keyboardLayoutType: 'letters'})
                break;
            case '{switchToSpecialChars}':
                this.setState({ keyboardLayoutType: 'specialChars'})
                break;
            default:
                break;
        }
    }

    handleChildElementClick = (e) => {
        e.stopPropagation()
    }

    render() {
        let css = this.state.css;
        let keyboardLayout = []
        switch (this.state.keyboardLayoutType) {
            case "letters":
              keyboardLayout = [
                "q w e r t y u i o p",
                "a s d f g h j k l",
                "z x c v b n m {bksp}",
                "{switchToNumbers} {space} {switchToSpecialChars}",
              ];
              break;
            case "numbers":
              keyboardLayout = [
                "1 2 3 4 5 6 7 8 9 0",
                "- / : ; ( ) $ & @ \"",
                ". , ? ! ' {bksp}",
                "{switchToLetters} {space} {switchToSpecialChars}",
              ];
              break;
            case "specialChars":
              keyboardLayout = [
                "á ä ã à â ú ü û",
                "é è ê ë í î ï ñ",
                "ô ö õ ø ó ç ý {bksp}",
                "{switchToNumbers} {space} {switchToLetters}",
              ];
              break;
            default:
              break;
        }
        return (
            <div className={`keyboardContainer ${css.keyboardContainer} ${this.props.isKeyboardHidden && css.hideKeyboard}`} onClick={(e) => {
                e.preventDefault();
                this.props.hideKeyboard()
            }}>
                <div
                    className={css.normalizeKeyboard}
                    onClick={(e) => this.handleChildElementClick(e)}
                >
                    <Keyboard
                        keyboardRef={r => (this.keyboard = r)}
                        layoutName={'default'}
                        onChange={this.handleKeyboardOnChange}
                        onKeyPress={this.handleKeyboardOnKeyPress}
                        theme={`hg-theme-default hg-layout-default`}
                        display={{
                            '{bksp}': '⌫',
                            '{space}': 'space',
                            '{clear}': 'clear',
                            '{switchToNumbers}' : '123',
                            '{switchToLetters}' : 'abc',
                            '{switchToSpecialChars}' : 'áäã'
                        }}
                        layout={{
                            'default': keyboardLayout
                        }}
                        buttonTheme={[
                            {
                                class: "keyboardSpacebar",
                                buttons: "{space}"
                            }
                        ]}
                    />
                </div>
            </div>
        )
    }
}

export default KeyboardComponent

