/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import ListingGroup from './ListingGroup';

import { applyFilters, buildFilterList, } from '../helper/Filter';

class ListingCollection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			css:            props.CSSModules[props.condor_render_name],
			dataModified:   [],
			data:           props.condor_component_list,
			counter:        0,
			listingGroupID: `listingContainer_${this.props.component_name}`
		};
	}

	componentDidMount() {
		if (this.props.vert_line_color && this.props.vert_line_color.length > 0) {
			document.documentElement.style.setProperty('--vertLineGroupColor', this.props.vert_line_color);
		}
	}

	buildVerticalLine = (styles) => {
		const css = this.state.css;
		// start, middle, and last lines
		const randomNum = Math.floor(Math.random() * 1000);
		if (this.props.vert_line_style === 0) { 
			// gradient line
			return (
				<div className={`${css[`vertLineGradient`]}`} key={randomNum} style={styles}>
					<div className={`${css[`vertLineGradientTop`]}`}></div>
					<div className={`${css[`vertLineGradientBot`]}`}></div>
				</div>
			);
		}
		if (this.props.vert_line_style === 1) { 
			// solid line
			return (
				<div className={`${css[`vertLineSolid`]}`} key={randomNum} style={styles}>
					<div className={`${css[`vertLineSolidTop`]}`}></div>
					<div className={`${css[`vertLineSolidBot`]}`}></div>
				</div>
			);
		}

		let upperStyle = {};
		if (this.props.condor_component_list[0] && this.props.condor_component_list[0].props && this.props.condor_component_list[0].props.header_height) {
			upperStyle = {
				height: this.props.condor_component_list[0].props.header_height
			};
		}

		// seperated line
		return (
			<div className={`${css[`vertLineSpaced`]}`} key={randomNum} style={styles}>
				<div className={`${css[`vertLineSpacedTopWrapper`]}`} style={upperStyle}>
					<div className={css.vertLineSpacedTop}></div>
				</div>
				<div className={`${css[`vertLineSpacedBotWrapper`]}`}>
					<div className={css.vertLineSpacedBot}></div>
				</div>
			</div>
		);
	}

	render() {
		let css = this.state.css;
		const {
			tmp_data_etl: data = { listing_groups: [] },
		} = this.props;

		let customClasses = '';
		if (this.props.custom_classes) {
			this.props.custom_classes.forEach((item) => {
				customClasses += ` ${item}`;
			});
		}

		let modifierClasses = '';
		if (this.props.modifier_classes) {
			this.props.modifier_classes.forEach((item) => {
				modifierClasses += ` ${item}`;
			});
		}

		let count = 1;
		let staticLists = [];
		let colLineWidth = this.props.vert_line_width || 0;
		let gridCount = 1;
		let contGrid = '';
		let contStyles = {};

		let listingGroups = Object.entries(data.listing_groups).map(([listingGroupM1Key, listingGroupM1Val]) => {
			listingGroupM1Val.listingGroupUUID = listingGroupM1Key;
			return listingGroupM1Val;
		});

		listingGroups.sort((a, b) => {
			let aVal = Number(a.settings?.layout_order?.value);
			let bVal = Number(b.settings?.layout_order?.value);

			if (!isNaN(aVal) && !isNaN(bVal)) {
				return aVal - bVal;
			}

			return 0;
		});

		let arrayOfListingGroups = []

		if (listingGroups.length > 0) {
			listingGroups.forEach((listingGroup) => {
				const listingGroupsWithFilters = {...listingGroup, listings: buildFilterList(listingGroup.listings)};
				const filteredListingGroups = {...listingGroupsWithFilters, listings: applyFilters(listingGroupsWithFilters.listings)};
				arrayOfListingGroups.push(filteredListingGroups);
			});
		}

		return (
			<>
				{arrayOfListingGroups.map((listingGroup, i) => {
					let condorProps = {};
					const num = this.props.condor_prop_list.length;

					for (let i = 0, l = this.props.condor_prop_list.length; i < l; i++) {
						if (listingGroup.componentM1Key.endsWith(this.props.condor_prop_list[i]['component_name'])) {
							condorProps = this.props.condor_prop_list[i];
							break;
						}
					}

					// vertical line before 1st ListingGroup
					if (this.props.vert_line_first && count === 1) {
						let firstLineStyles = {
							gridArea: `1 / ${gridCount} / 2 / ${gridCount + 1}`
						};
						staticLists.push(this.buildVerticalLine(firstLineStyles));
						gridCount++;
						contGrid += `${colLineWidth}px`;
					}

					// ListingGroups
					let listingStyles = {
						gridArea: `1 / ${gridCount} / 2 / ${gridCount + 1}`
					};

					staticLists.push(
						<ListingGroup
							num={i + 1}
							key={listingGroup.componentM1Key}
							style={listingStyles}
							listingGroupID={this.state.listingGroupID}
							{...condorProps}
							{...listingGroup}
						/>
					);

					gridCount++;

					if (condorProps.listing_grid_fr === 0) {
						contGrid += ` ${condorProps.listing_grid_fr}fr`;
					} else {
						contGrid += ` ${condorProps.listing_grid_fr}fr`;
					}

					//  lines between ListingGroups
					if (num > 1 && count !== num && this.props.vert_line_middle) {
						let midLineStyles = {
							gridArea: `1 / ${gridCount} / 2 / ${gridCount + 1}`
						};
						staticLists.push(this.buildVerticalLine(midLineStyles));
						gridCount++;
						contGrid += ` ${colLineWidth}px`;
					}

					// vertical line after last ListingGroup
					if (count === num && this.props.vert_line_last) {
						let lastLineStyles = {
							gridArea: `1 / ${gridCount} / 2 / ${gridCount + 1}`
						};
						staticLists.push(this.buildVerticalLine(lastLineStyles));
						gridCount++;
						contGrid += ` ${colLineWidth}px`;
					}
					count++;
					contStyles = {
						gridTemplateColumns: contGrid
					};
				})}
				
				<div id={this.state.listingGroupID} className={`${css[`staticListingsGrp`]} staticListingsGrp dontLook ${modifierClasses} ${customClasses}`} style={contStyles}>
					{staticLists}
				</div>
			</>
		);
	}
}

export default ListingCollection;
