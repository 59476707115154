import React, { PureComponent } from 'react';
import '../styles/modal.css'; // Reuse the same modal CSS

class VideoComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            source: "",
            display: "none",
            loading: true,
            css: props.CSSModules["VideoComponent"],
            activeButton: ''
        };
    }

    componentDidMount() {
        this.setVideoData();
        this.idleInterval = setInterval(() => {
            this.props.navEvents('idleTimer', '');
        }, 2000);
    }

    componentWillUnmount() {
        clearInterval(this.idleInterval);
    }

    setVideoData = () => {
        if (this.props.source && this.props.source !== '') {
            this.setState({
                source: this.props.source,
                display: "",
            });
        } else {
            this.setState({
                display: "none",
            });
        }
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose('video');
        }
    }

    handleDown = (buttonId) => {
        this.setState({
            activeButton: buttonId
        });
    }

    handleUp = () => {
        this.setState({
            activeButton: ''
        });
    }

    render() {
        let css = this.state.css;

        let customClasses = '';
        if (this.props.custom_classes && this.props.custom_classes.length > 0) {
            this.props.custom_classes.forEach((element) => {
                if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
                    customClasses += ` ${element}`;
                }
            });
        }
        return (
            <div className={`modal-main ${customClasses}`} style={{ display: this.state.display }}>
                {this.state.loading && (
                    <div className="loading">Loading...</div>
                )}
                <video
                    className={css?.videoContainer}
                    src={this.state.source}
                    autoPlay={true} 
                    preload="auto"
                    onLoadedData={() => this.setState({ loading: false })}
                    onEnded={() => this.props.handleClose('video')}
                >
                    Your browser does not support the video tag.
                </video>
                <div className={`videoUi ${css?.videoUi}`}>
                    <button
                        className={`modalButton videoButtonClose ${css?.navBtn} ${this.state.activeButton === 'close' ? css?.activeButton : ''}`}
                        type="button"
                        onMouseDown={() => this.handleDown('close')}
                        onMouseUp={this.handleUp}
                        onMouseLeave={this.handleUp}
                        onTouchStart={() => this.handleDown('close')}
                        onTouchEnd={this.handleUp}
                        onPointerDown={() => this.handleDown('close')}
                        onPointerUp={this.handleUp}
                        onPointerLeave={this.handleUp}
                        onClick={this.handleClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        );
    }
}

export default VideoComponent;