class CategoryControl {
    constructor(props) {
        this._categories = props.categories;

        this.setNextCategory = props.setNextCategory;
	}

    initializeSelector (){
        const selector = document.createElement('fieldset', '');
        selector.setAttribute('style', 'border-width: 3px;border-style: groove');
        selector.setAttribute('class', 'amenitiesMapSelector');

        selector.innerHTML = '';
        const legend = document.createElement('legend', '');
        legend.innerHTML = 'Points of interest:';
        legend.setAttribute('style', 'color: black;');
    
        selector.appendChild(legend);
    
        this._categories.split(',').map((category) => {
            return {value: category, label: category};
        }).forEach((optionsInfo) => {
            const div = document.createElement("div");
            
            const input = document.createElement('input');
            input.type = 'radio';
            input.name = 'amenitiesCategorySelector';

            input.addEventListener('mousedown', (e) => {
                input.willUncheck = input.checked;
            });

            
            input.addEventListener('click', (e) => {
                if (input.willUncheck) {
                    input.checked = false;
                }
                this.setNextCategory(optionsInfo.value);
            });
    
            const label = document.createElement('label');
            label.for = optionsInfo.value;
            label.innerHTML = optionsInfo.label;
            label.setAttribute('style', 'color: black;');
    
            div.appendChild(input);
            div.appendChild(label);
    
            selector.appendChild(div);
        });

        this._selector = selector;
    }

    onAdd(map) {
        this.initializeSelector();
        this._map = map;
        this._container = this._selector;
        this._container.className += ' mapboxgl-ctrl';
        return this._container;
    }
     
    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

export default CategoryControl