import React from 'react';

import ListingETLNew from './util/ListingETLNew';

import AmenitiesMap from './components/AmenitiesMap';
import AnimatedScroller from './components/AnimatedScroller';
import BroadsignFeed from './components/BroadsignFeed';
import Brood from './components/Brood';
import Clock from './components/Clock';
import { dlNavigationSwapPhases } from './DataLayer';
import DateDisplay from './components/DateDisplay';
import DynamicMessage from './components/DynamicMessage';
import DynamicMapAmenities from './components/DynamicMapAmenities';
// import { LazyDynamicMapAmenities as DynamicMapAmenities } from './components/LazyComponent';
import DynamicMapTraffic from './components/DynamicMapTraffic';
// import { LazyDynamicMapTraffic as DynamicMapTraffic } from './components/LazyComponent';
import EmergencyMessage from './components/EmergencyMessage';
import Entertainment from './components/Entertainment';
import GenerativeArt from './components/GenerativeArt';
import IdleTimerPrompt from './components/IdleTimerPrompt';
import Iframe from './components/Iframe';
import ImageComponent from './components/ImageComponent';
import InfoBox from './components/InfoBox';
import InfoBoxGroup from './components/InfoBoxGroup';
import InfoBoxHeader from './components/InfoBoxHeader';
import InfoBoxHeaderBlock from './components/InfoBoxHeaderBlock';
import InfoBoxImg from './components/InfoBoxImg';
import InfoBoxText from './components/InfoBoxText';
import LayoutGroup from './components/LayoutGroup';
import LegacyMapAmenities from './components/LegacyMapAmenities';
import LegacyMapTraffic from './components/LegacyMapTraffic';
import Listing from './components/Listing';
import ListingCollection from './components/ListingCollection';
import ListingGroup from './components/ListingGroup';
import ListingsScroller from './components/ListingsScroller';
import LoadingScreen from './components/LoadingScreen';
import Monarch from './components/Monarch';
import Mobile from './components/Mobile';
import Navigation from './components/Navigation';
import NavigationButton from './components/NavigationButton';
import News from './components/News';
import PdfComponent from './components/PdfComponent';
// import { LazyPdfComponent as PdfComponent } from './components/LazyComponent';
import Peacock from './components/Peacock';
import Pelican from './components/Pelican';
import PresenceDetection from './components/PresenceDetection';
import QrCode from './components/QrCode';
import RavenDisplay from './components/RavenDisplay';
import StaticMap from './components/StaticMap';
import Stocks from './components/Stocks';
import TextBox from './components/TextBox';
import TextList from './components/TextList';
import TrafficMap from './components/TrafficMap';
import TsLogo from './components/TsLogo';
import Weather from './components/Weather';
import WeatherIcon from './components/WeatherIcon';

import { digest } from 'json-hash';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/english.json';
import translationES from './locales/spanish.json';
import './App.css';
import './styles/storybook.css';
import './styles/base.css';
import './styles/fonts.css';
import './styles/fonts/font-awesome/css/all.css';

// Load default theme on init.
// Override after making call to /component
// import style from './styles/layout/default/layout1/themes/theme1/configuration.module.css';

import { dlUserSettings } from './DataLayer';
import {
	broncoURLENV,
	condorComponentENV,
	condorRefreshRateENV,
	newCondorComponentsENV,
} from './ENV.js';

import {
	closeListenerToSw,
	listenToSW,
	registerSW,
} from './ServiceWorker.js';

const condorDebugENV = window._getEnv('CONDOR_DEBUG');

// let css = style;
let customCSSClassName = '';

const importMap = {
	AmenitiesMap: AmenitiesMap,
	AnimatedScroller: AnimatedScroller,
	BroadsignFeed: BroadsignFeed,
	Brood: Brood,
	Clock: Clock,
	DateDisplay: DateDisplay,
	DynamicMapAmenities: DynamicMapAmenities,
	DynamicMapTraffic: DynamicMapTraffic,
	DynamicMessage: DynamicMessage,
	EmergencyMessage: EmergencyMessage,
	Entertainment: Entertainment,
	GenerativeArt: GenerativeArt,
	Iframe: Iframe,
	ImageComponent: ImageComponent,
	InfoBox: InfoBox,
	InfoBoxGroup: InfoBoxGroup,
	InfoBoxHeader: InfoBoxHeader,
	InfoBoxHeaderBlock: InfoBoxHeaderBlock,
	InfoBoxImg: InfoBoxImg,
	InfoBoxText: InfoBoxText,
	LayoutGroup: LayoutGroup,
	LegacyMapAmenities: LegacyMapAmenities,
	LegacyMapTraffic: LegacyMapTraffic,
	Listing: Listing,
	ListingCollection: ListingCollection,
	ListingGroup: ListingGroup,
	ListingsScroller: ListingsScroller,
	LoadingScreen: LoadingScreen,
	Monarch: Monarch,
	Mobile: Mobile,
	Navigation: Navigation,
	NavigationButton: NavigationButton,
	News: News,
	PdfComponent: PdfComponent,
	Peacock: Peacock,
	Pelican: Pelican,
	PresenceDetection: PresenceDetection,
	QrCode: QrCode,
	RavenDisplay: RavenDisplay,
	StaticMap: StaticMap,
	Stocks: Stocks,
	TextBox: TextBox,
	TextList: TextList,
	TrafficMap: TrafficMap,
	TsLogo: TsLogo,
	Weather: Weather,
	WeatherIcon: WeatherIcon,
};

const listingConfigToListingCollectionsMap = {};
const listingCollectionComponentToListingCollectionMap = {};



const getFinalComponent = function (props, renderName) {
	props.condor_component_list = props.condor_component_list || [];

	if (renderName === 'ListingCollection') {
		console.log('parent:', props.component_name);

		// listing_collection.93410369-3427-46cf-abb9-f8ea60e9a253
		let m1Key = listingCollectionComponentToListingCollectionMap[`component.${props.component_name}`];
		props.tmp_data_etl = window.tmpDataETL[m1Key];
	}

	if (renderName === 'ListingsScroller') {
		// temporary: sending all listing data
		props.tmp_data_etl = window.tmpDataETL;
		props.configToCollectionMap = listingConfigToListingCollectionsMap;
	}

	if (renderName === 'ListingGroup') {
		// debugger;
	}

	let newComponents = (newCondorComponentsENV || '').toLowerCase().split(',');
	if (newComponents.includes(props.condor_render_name.toLowerCase())) {
		try {
			return [importMap['New' + props.condor_render_name], props];
		} catch (err) {
			return [importMap[props.condor_render_name], props];
		}
	}

	return [importMap[renderName], props];
};

const _CSSModules = {};

const preImportCSSModules = async (defaultProps) => {
	const preImportComponentToCSSMap = {
		IdleTimerPrompt: 'idleTimerPrompt',
	};

	const defaultComponentGroup = 'default';
	const defaultConfiguration = 'layout1'; 
	const defaultTheme = 'theme1';

	const componentGroup = defaultProps.condor_component_group || defaultComponentGroup;
	const configuration = defaultProps.condor_configuration || defaultConfiguration; 
	const theme = defaultProps.condor_theme || defaultTheme;

	const importPromises = [];

	Object.entries(preImportComponentToCSSMap).forEach(([style, keyword]) => {
		if (_CSSModules[style]) {
			return;
		}

		if (!keyword) {
			return;
		}

		importPromises.push(
			import(`./styles/layout/${componentGroup}/${configuration}/themes/${theme}/${keyword}.module.css`)
			.then((res) => {
				_CSSModules[style] = res;
				return res;
			})
			.catch(async () => {
				const res = await import(`./styles/layout/${defaultComponentGroup}/${defaultConfiguration}/themes/${defaultTheme}/${keyword}.module.css`);
				_CSSModules[style] = res;
				return res;
			})
		);
	});

	await Promise.allSettled(importPromises);

	return _CSSModules;
};

const importCSSModules = async (styleMap, defaultProps) => {
	const componentToCSSMap = {
		AmenitiesMap: 'amenitiesMap',
		AnimatedScroller: 'animatedScroller',
		BroadsignFeed: 'broadsignFeed',
		Brood: 'slideshow',
		Clock: 'clock',
		DateDisplay: 'date',
		DynamicMapAmenities: 'dynamicMapAmenities',
		DynamicMapTraffic: 'dynamicMapTraffic',
		DynamicMessage: 'dynamicMessage',
		EmergencyMessage: 'emergencyMessage',
		Iframe: 'iframe',
		ImageComponent: 'image',
		InfoBoxGroup: 'infoBox',
		LayoutGroup: 'layoutGroup',
		LegacyMapAmenities: 'dynamicMapAmenities',
		LegacyMapTraffic: 'dynamicMapTraffic',
		ListingCollection: 'listingsStatic',
		ListingGroup: 'listingsStatic',
		ListingsDetails: 'listingsDetails',
		ListingsScroller: 'listingsScroller',
		LoadingScreen: 'loader',
		Navigation: 'navigation',
		NavigationButton: 'navigation',
		News: 'news',
		PdfComponent: 'pdfComponent',
		QrCode: 'qrCode',
		RavenDisplay: 'raven',
		StaticMap: 'staticMap',
		Stocks: 'stocks',
		TextBox: 'textBox',
		TextList: 'textList',
		TrafficMap: 'trafficMap',
		TsLogo: 'tsLogo',
		VideoComponent: 'videoComponent',
		Weather: 'weather',
	};

	const defaultComponentGroup = 'default';
	const defaultConfiguration = 'layout1'; 
	const defaultTheme = 'theme1';

	const componentGroup = defaultProps.condor_component_group || defaultComponentGroup;
	const configuration = defaultProps.condor_configuration || defaultConfiguration; 
	const theme = defaultProps.condor_theme || defaultTheme;

	const importPromises = [];

	Object.keys(styleMap).forEach((style) => {
		if (_CSSModules[style]) {
			return;
		}

		const keyword = componentToCSSMap[style];
		if (!keyword) {
			return;
		}

		importPromises.push(
			import(`./styles/layout/${componentGroup}/${configuration}/themes/${theme}/${keyword}.module.css`)
			.then((res) => {
				_CSSModules[style] = res;
				return res;
			})
			.catch(async () => {
				const res = await import(`./styles/layout/${defaultComponentGroup}/${defaultConfiguration}/themes/${defaultTheme}/${keyword}.module.css`);
				_CSSModules[style] = res;
				return res;
			})
		);

		if (style === 'ListingsScroller') {
			const childrenComponents = {
				ListingsDetails: 'listingsDetails',
				Listings: 'listings',
				ListingRow: 'listingRow',
				Keyboard: 'keyboard',
				Wayfinding: 'wayfinding',
				PdfComponent: 'pdfComponent',
				VideoComponent: 'videoComponent',
			};

			Object.entries(childrenComponents).forEach(([childStyle, childKeyword]) => {
				importPromises.push(
					import(`./styles/layout/${componentGroup}/${configuration}/themes/${theme}/${childKeyword}.module.css`)
					.then((res) => {
						_CSSModules[childStyle] = res;
						return res;
					})
					.catch(async () => {
						const res = await import(`./styles/layout/${defaultComponentGroup}/${defaultConfiguration}/themes/${defaultTheme}/${childKeyword}.module.css`);
						_CSSModules[childStyle] = res;
						return res;
					})
				);
			});
		}
	});

	await Promise.allSettled(importPromises);

	return _CSSModules;
};

const makeRenderList = async (contents, onlineStatus, offlineMinutes, themeVariation, navEvents, navProps, isTouch, updatedAt, updatedCount, setHasIframeComponent, isButtonActive) => {
	let componentList = contents.component_list;
	let settingList = contents.setting_list;
	let topLevelList = contents.top_level_list;

	// Combine current online status information with DB props
	let defaultProps = await getDefaultProps(settingList);
	defaultProps.online_status = onlineStatus;
	defaultProps.offline_minutes = offlineMinutes;
	defaultProps.theme_variation = themeVariation;
	defaultProps.navEvents = navEvents;
	defaultProps.navProps = navProps;
	defaultProps.isTouch = isTouch;
	defaultProps.last_update = updatedAt;
	defaultProps.count = updatedCount;
	defaultProps.latitude = contents.latitude;
	defaultProps.longitude = contents.longitude;
	defaultProps.zip_code = contents.zip_code;
	defaultProps.monarch_location = contents.monarch_location;
	defaultProps.project_number = contents.project_number;
	defaultProps.directory_number = contents.directory_number;
	defaultProps.system_uuid = contents.system_uuid;

	if (setHasIframeComponent) {
		defaultProps.setHasIframeComponent = setHasIframeComponent
	}

	if (isButtonActive) {
		defaultProps.isButtonActive = isButtonActive
	}

	await preImportCSSModules(defaultProps);

	let renderList = [];
	const styleList = {};

	const propArray = [];
	for (let i = 0, x = componentList.length; i < x; i++) {
		let target = componentList[i];
		let props = await propsFromEntry(target, componentList, defaultProps, true);
		propArray.push(props);
		if (!props) {
			continue;
		}

		styleList[props.condor_render_name] = props.condor_render_name;
	}

	
	/* Placeholder solution */
	styleList['Iframe'] = 'iframe';

	const styleModules = await importCSSModules(styleList, defaultProps);

	for (let i = 0, x = componentList.length; i < x; i++) {
		let target = componentList[i];
		let props = propArray[i];
		if (!props) {
			continue;
		}

		styleList[props.condor_render_name] = props.condor_render_name;

		// Check for top level
		let isTopLevel = false;
		for (let j = 0, y = topLevelList.length; j < y; j++) {
			isTopLevel = target.name === topLevelList[j];
			if (isTopLevel) {
				break;
			}
		}

		props.CSSModules = styleModules;

		// Assign the top level to be rendered.
		if (isTopLevel) {
			let key = props.component_name;
			let [FinalComponent, finalProps] = getFinalComponent(props, props.condor_render_name);
			renderList.push(<FinalComponent {...finalProps} key={key} />);
		}
	}
	
	const sortedRenderList = renderList.sort(sortByOrder);

	return sortedRenderList;
};

const getDefaultProps = async (settingList) => {
	let result = {};

	let componentGroupEntry = getEntryByName('condor_component_group', settingList);
	if (componentGroupEntry) {
		result.condor_component_group = componentGroupEntry.value;
	}

	let configurationEntry = getEntryByName('condor_configuration', settingList);
	if (configurationEntry) {
		result.condor_configuration = configurationEntry.value;
	}

	let themeEntry = getEntryByName('condor_theme', settingList);
	if (themeEntry) {
		result.condor_theme = themeEntry.value;
	}

	let dynamicPath = `${componentGroupEntry.value}/${configurationEntry.value}/themes/${themeEntry.value}`;
	let animationPath = `${componentGroupEntry.value}/${configurationEntry.value}/animation/rtgAnimations.css`;
	await loadStyle(dynamicPath, animationPath);

	return result;
}

const propsFromEntry = async (target, completeComponentList, defaultProps, first) => {
	if (first && !getEntryByName('condor_render_name', target.setting_list)) {
		return false;
	}

	// To allow children to override parents:
	// let props = Object.assign({component_name: target.name}, defaultProps);
	// To allow parents to override children:
	let props = { component_name: target.name };

	if (target.setting_list) {
		props = propsFromSettingList(props, target);
	}

	if (target.component_list) {
		props = await propsFromComponentList(props, target, completeComponentList, defaultProps);
	}

	props = Object.assign(props, defaultProps);

	return props;
}

const propsFromComponentList = async (props, component, completeComponentList, defaultProps) => {
	let componentList = [];
	let propsList = [];

	const styleList = {};

	const targetArray = [];
	const nestedPropsArray = [];

	for (let i = 0, x = component.component_list.length; i < x; i++) {
		let targetName = component.component_list[i];
		let target = getEntryByName(targetName, completeComponentList);
		targetArray.push(target);
		if (!target) {
			continue;
		}

		let nestedProps = await propsFromEntry(target, completeComponentList, defaultProps, false);
		nestedPropsArray[i] = nestedProps;

		styleList[nestedProps.condor_render_name] = nestedProps.condor_render_name;
	}

	const styleModules = await importCSSModules(styleList, defaultProps);

	for (let i = 0, x = component.component_list.length; i < x; i++) {
		let targetName = component.component_list[i];
		let target = targetArray[i];
		if (!target) {
			console.error("Could not find component in list: " + targetName);
			continue;
		}

		let nestedProps = nestedPropsArray[i];
		nestedProps.CSSModules = styleModules;

		propsList.push(nestedProps);

		// NOTE: ARRAY INDICIES WILL NOT LINE UP IF MISSING RENDER NAME
		let renderEntry = getEntryByName('condor_render_name', target.setting_list);
		if (renderEntry.value) {
			//!NOTE THIS WILL FAIL IF NEWER COMPONENT TREES INCLUDE DIFFERENT 
			//! REACT COMPONENTS THAN WAS GIVEN AT GENERATION TIME
			let key = targetName;
			let [FinalComponent, finalProps] = getFinalComponent(nestedProps, renderEntry.value);
			componentList.push(<FinalComponent {...finalProps} key={key} />);
		}
	}

	let sortedComponentList = componentList.sort(sortByOrder);

	props.condor_component_list = sortedComponentList;
	props.condor_prop_list = propsList;

	return props;
}

const getEntryByName = (targetName, targetList) => {
	for (let i = 0, x = targetList.length; i < x; i++) {
		let target = targetList[i];
		if (target.name === targetName) {
			return target;
		}
	}

	return false;
}

const propsFromSettingList = (props, component) => {
	for (let i = 0, x = component.setting_list.length; i < x; i++) {
		let target = component.setting_list[i];
		props[target.name] = target.value;
	}

	return props;
}

const sortByOrder = (a, b) => {
	if (a.props && b.props && a.props.layout_order < b.props.layout_order) {
		return -1;
	}

	if (a.props && b.props && a.props.layout_order > b.props.layout_order) {
		return 1;
	}

	return -1;
};

const swapCustomCSS = async (filename) => {
    return new Promise((resolve, reject) => {
        const existingLink = document.querySelector('link[rel="stylesheet"][data-custom-css="true"]');
        const currentHref = existingLink ? existingLink.getAttribute('href').split('?')[0] : '';
        const newHref = filename.split('?')[0];
	
        // If the new CSS file is the same as the current, resolve without action
        if (currentHref === newHref) {
            console.log("Requested CSS is already loaded.");
            resolve('Already loaded');
            return;
        }
		
        // Create a new link element for the CSS file
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = filename;
        link.setAttribute('data-custom-css', 'true');

        // Attach event listeners
        link.onload = () => {
            console.log(`${filename} loaded successfully.`);
            // Remove previous CSS links after the new one has loaded
            if (existingLink) {
                existingLink.parentNode.removeChild(existingLink);
            }
            resolve('Loaded');
        };

        link.onerror = () => {
            console.error(`An error occurred loading ${filename}`);
            reject('Failed to load');
        };

        // Append the new link element to the document head
        document.head.appendChild(link);
		console.log(filename);
		console.log(link.href);
    });
}

const removeCustomCSS = function() {
    // Select all <link> elements that have the data-custom-css attribute set to true
    const customCSSLinks = document.querySelectorAll('link[data-custom-css="true"]');
    // Iterate over the NodeList and remove each element from the document
    customCSSLinks.forEach(link => {
        if (link.parentNode) {
            link.parentNode.removeChild(link);
        }
    });
    console.log('Custom CSS removed.');
}

const setInlineCSS = (inlineCSS) => {
	const styleId = 'custom-inline-css';
	let styleElement = document.getElementById(styleId);
	
	if (!styleElement) {
	  styleElement = document.createElement('style');
	  styleElement.id = styleId;
	  document.body.appendChild(styleElement);
	}
	
	styleElement.innerHTML = inlineCSS;
}

const loadStyle = async (dynamicPath, animationPath) => {
	// NOTE: https://webpack.js.org/guides/dependency-management/#require-with-expression
	await import(`./styles/layout/${dynamicPath}/configuration.module.css`)
	.catch((err) => {
		console.error(err);
		import(`./styles/layout/default/layout1/themes/theme1/configuration.module.css`)
		.catch((err) => {
			console.error(err);
		});
	});

	await import(`./styles/layout/${animationPath}`)
	.catch((err) => {
		console.error(err);
	});

	await import(`./styles/modifiers.css`)
	.catch((err) => {
		console.error(err);
	});
};

class TopLevelComponent extends React.Component { 
	constructor(props) {
		super(props);

		this.fetchingComponentList = false;
		this.mainIntervalID = null;
		this.retryTimeoutID = null;
		this.customCSSTimeoutID = null;

		this.state = {
			loading: false,
			body: false,
			currentContents: false,
			hasIframeComponent: false,
			id: '',
			mainInterval: Number(condorRefreshRateENV) || (Math.random() * (5.25 - 4.75) + 4.75), // Random number between 4.75 and 5.25 that converts to minutes
			// mainInterval:    0.5, // interval for development
			onlineStatus: true,
			offlineMinutes: 0,
			reqMap: false,
			retryCount: 0,
			retryTimeout: null,
			themeVariation: 'variation1',
			showIdlePrompt: false,
			updatedAt: localStorage['updatedAt'] || null,
			updatedCount: Number(localStorage['updatedCount']) || 0,
			navProps: {
				animationPhase: 'idle',
				loadedCustomCSS: '',
				showMenu: false,
				pdfActive: false,
				iframeActive: false,
				adaActive: false,
				adaTopPad: '0em',
				timeoutStatus: false,
				hasNavigatedListings: false,
				resetListings: false,
				fontLoaded: false,
			},
			isTouch: false,
			buttonActive: false,
		};

		this.isButtonActive = this.isButtonActive.bind(this);
		this.startIdleTimer = this.startIdleTimer.bind(this);
		this.stopIdleTimer = this.stopIdleTimer.bind(this);
		this.pdfDelayTimer = null;
		this.buttonActiveTimer = null;
		this.buttonDelayTimer = null;
	}

	componentDidMount() {
		window.dataLayer = window.dataLayer || [];
		this.initI18N();
		this.getShouldLoad()
		.then((shouldLoad) => {
		  this.setState({ loading: shouldLoad });
		})
		.catch((err) => {
		  console.error(err);
		});

		this.calculateRetryTimeout();
		registerSW()
			.then(() => {
				const useCacheFirst = true;
				this.getUpdatedAt('MOUNT', useCacheFirst);
			});
		listenToSW();
		this.logEnvVars();

		this.mainIntervalID = setInterval(() => {
			this.getUpdatedAt('MAIN');
		}, this.state.mainInterval * 60000);

		let today = new Date();
		let h = today.getHours();

		// Swapping theme variation at 2am
		setInterval(() => {
			if (h === 2) {
				if (this.state.themeVariation === 'variation1') {
					this.setState({ themeVariation: 'variation2' });
				} else {
					this.setState({ themeVariation: 'variation1' });
				}
			}
			console.log(this.state.themeVariation);
		}, 3600000); // <-- Checks every hour


		document.fonts.onloadingdone = () => {
			console.log("Font loading complete!!!!!");

			document.fonts.ready.then((fontFaceSet) => {
				// Fonts finished loading 
				const fontFaces = [...fontFaceSet];
				console.log('Font', fontFaces);
				console.log('Font', document.fonts);
	
				this.setState({
					navProps: {
						...this.state.navProps,
						fontLoaded: true
					}
				})
	
				fontFaces.forEach((fontFace) => {
					console.log('Font:', fontFace.family, fontFace.status);
				});
	
			}).catch((error) => {
				console.error('Failed to load fonts:', error);
			});
		};
	}

	componentWillUnmount() {
		clearInterval(this.loadingTimeout);
	}

	async componentDidUpdate(prevProps, prevState) {
		// If onlineStatus or offlineMinutes has been updated, make a new renderList to pass down updated props
		if (this.state.currentContents && 
				(
					prevState.onlineStatus !== this.state.onlineStatus || 
					prevState.offlineMinutes !== this.state.offlineMinutes || 
					prevState.themeVariation !== this.state.themeVariation ||
					prevState.navProps !== this.state.navProps ||
					prevState.updatedCount !== this.state.updatedCount ||
					prevState.updatedAt !== this.state.updatedAt
				)
			) {
			let nextBody = await makeRenderList(
				this.state.currentContents,
				this.state.onlineStatus,
				this.state.offlineMinutes,
				this.state.themeVariation,
				this.navEvents,
				this.state.navProps,
				this.state.isTouch,
				this.state.updatedAt,
				this.state.updatedCount,
				this.setHasIframeComponent,
				this.isButtonActive,
			);
			this.setState({
				body: nextBody 
			});
		}

		if (this.state.navProps.animationPhase !== prevState.navProps.animationPhase) {
			this.changeLanguageI18N();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.buttonActive !== nextState.buttonActive && this.state.navProps === nextState.navProps) {
			return false;
		}
		return true;
	}

	changeLanguageI18N() {
		const animationPhase = this.state.navProps.animationPhase;
		const languageMatch = animationPhase.match(/(\w+)Lng/);
		const language = languageMatch ? languageMatch[1] : 'english';
		
		i18n.changeLanguage(language);
	}

	initI18N() {
		const resources = {
			english: {
			  	translation: translationEN,
			},
			spanish: {
			  	translation: translationES,
			},
		};
		  
		const animationPhase = this.state.navProps.animationPhase;
		const languageMatch = animationPhase.match(/(\w+)Lng/);
		const language = languageMatch ? languageMatch[1] : 'english';
		
		i18n.use(initReactI18next).init({
			resources,
			lng: language,
			fallbackLng: 'english',
		
			interpolation: {
				escapeValue: false,
			},
		});
	}

	setHasIframeComponent = (value) => {
		this.setState({ hasIframeComponent: value })
	}

	logEnvVars() {
		let env = window._env_;
		Object.keys(env).forEach((key) => {
			console.log(`${key}: ${env[key]}`);
		});
	}

	clearShowIdlePrompt = () => {
		this.setState({
			showIdlePrompt: false,
		})
		this.stopIdleTimer();
		this.startIdleTimer();
	}

	clearShowIdlePromptAfterInterval = () => {
		console.log(`Idle Timer: clearShowIdlePromptAfterInterval`);
		this.setState({
			showIdlePrompt: false,
			hasIframeComponent: false,
		})
		this.stopIdleTimer();
		this.setState({
			navProps: {
				...this.state.navProps,
				adaActive: false,
				animationPhase: this.state.navProps.animationStartPhase,
				iframeActive: false,
				pdfActive: false,
				showMenu: false,
				timeoutStatus: false
			}
		})
	}

	isButtonActive() {
		return this.state.buttonActive;
	}

	startIdleTimer = () => {
		console.log(`Idle Timer: startIdleTimer initialized`);
		let tick = 45000;
		let promptTick = 10000;

		if (this.state.navProps.idleTimeoutSeconds && parseInt(this.state.navProps.idleTimeoutSeconds) > 0) {
			tick = this.state.navProps.idleTimeoutSeconds * 1000
			let promptTickSecs = parseInt(this.state.navProps.idleTimeoutSeconds) < 10 ? parseInt(this.state.navProps.idleTimeoutSeconds) * 0.9 : 10;
			promptTick = 1000 * promptTickSecs; // fixes prompt issues, when timeouts set below 10s
		}
		this.timer = setTimeout(() => {
			if (!this.state.navProps.timeoutStatus) {
				this.setState({
					navProps: {
						...this.state.navProps,
						timeoutStatus: true
					}
				});
			}
		}, 500);
		this.timer = setTimeout(() => {
			if (!this.state.hasIframeComponent) {
				console.log(`Idle Timer: startIdleTimer() - TIMEOUT ENDED - No iframe`);
				this.setState({
					navProps: {
						...this.state.navProps,
						adaActive: false,
						animationPhase: this.state.navProps.animationStartPhase,
						pdfActive: false,
						iframeActive: false,
						showMenu: false,
						timeoutStatus: false
					}
				})
			} else {
				if (this.state.navProps.animationPhase !== this.state.navProps.animationStartPhase ||
					(this.state.navProps.animationPhase === this.state.navProps.animationStartPhase) && this.state.navProps.iframeActive) {
					console.log(`Idle Timer: startIdleTimer() - TIMEOUT ENDED - animationPhase !== animationStartPhase || iframe from listing`);
					this.setState({ 
						showIdlePrompt: true,
						pdfActive: false,
						iframeActive: false,
						showMenu: false,
						timeoutStatus: false
					})
					setTimeout(() => {
						if(this.state.showIdlePrompt) {
							this.clearShowIdlePromptAfterInterval();
						}
					}, promptTick);
				}
			}
		}, tick);
	}

	stopIdleTimer = () => {
		console.log(`Idle Timer: stopIdleTimer`);
		clearTimeout(this.timer);
	}

	calculateRetryTimeout() {
		let mainInterval = this.state.mainInterval;
		let retryTimeout = mainInterval * 1000;

		this.setState({ retryTimeout });
	}

	getTLCSetting(settingList, settingName, defaultValue) {
		let settingValue = defaultValue;
		settingList.forEach((setting) => {
			if (setting.name === settingName && setting.value !== null && setting.value !== undefined && setting.value !== '') {
				console.log('setting.value');
				console.log(setting.value);
				settingValue = setting.value;
			}
		})
		return settingValue;
	}
	
	async getShouldLoad() {
		const uuid = condorComponentENV;
		const url = `${broncoURLENV}/loading`;
		
		try {
		  const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ uuid }),
		  });
		  
		  if (!response.ok) {
			throw new Error(`Request failed with status ${response.status}`);
		  }
		  
		  const data = await response.json();
		  
		  const shouldLoad = !data.hide_loading; 
		  
		  console.log('setting load value to', shouldLoad);
		  return shouldLoad;
		} catch (error) {
		  console.error('Error on /loading response', error);
		  
		  return true;
		}
	  }

	getComponentList = (useCacheFirst = false, useCacheOnly = false, cb = () => {}) => {
		const uuid = condorComponentENV;

		const handleNextContents = async (nextContents) => {
			window.flatData = nextContents.fd || {};
			window.tmpDataETL = new ListingETLNew(window.flatData, listingConfigToListingCollectionsMap, listingCollectionComponentToListingCollectionMap);
			
			if (digest(this.state.currentContents) === digest(nextContents)) {
				return;
			}

			let animationStartPhase = this.getTLCSetting(nextContents.setting_list, 'animation_start_phase', 'idle');
			let isTouch = this.getTLCSetting(nextContents.setting_list, 'is_touch', false);
			let idleTimeoutSeconds = this.getTLCSetting(nextContents.setting_list, 'idle_timeout_seconds', 45);

			let nextBody;

			try {
				const renderList = await makeRenderList(
					nextContents,
					this.state.onlineStatus,
					this.state.offlineMinutes,
					this.state.themeVariation,
					this.navEvents,
					this.state.navProps,
					this.state.isTouch,
					this.state.updatedAt,
					this.state.updatedCount
				);

				const customCSSFile = getEntryByName('custom_css', nextContents.setting_list);

				if (customCSSFile.value) {
					customCSSClassName = 'custom';
					const completePath = `https://ts-condor-custom-css.s3.amazonaws.com/${customCSSFile.value}`;

					try {
						console.log("CSS loaded successfully");
						await swapCustomCSS(completePath);
						// await fetchCustomCSS(customCSSFile.value);
					} catch (error) {
						console.log("Failed to load CSS", error);
					}

					if (this.customCSSTimeoutID) {
						clearTimeout(this.customCSSTimeoutID);
					}

					this.customCSSTimeoutID = setTimeout(() => {
						console.log('App.js Custom CSS found -> setState navProps');
						this.setState({
							navProps: {
								...this.state.navProps,
								loadedCustomCSS: customCSSFile.value
							}
						});
					}, 2000);
				} else {
					this.setState({ 
						navProps: { 
							...this.state.navProps, 
							loadedCustomCSS: ''
						} 
					});
					customCSSClassName = '';
					removeCustomCSS();
				}

				const customInlineCSS = this.getTLCSetting(nextContents.setting_list, 'custom_inline_css', '');
				if (typeof customInlineCSS === 'string') {
					setInlineCSS(customInlineCSS);
				}

				nextBody = renderList;
			} catch (error) {
				console.log('error', error);
			}


			this.setState({
				body: nextBody,
				currentContents: nextContents,
				id: nextContents.id,
				isTouch,
				navProps: {
					...this.state.navProps,
					animationPhase: animationStartPhase,
					animationStartPhase: animationStartPhase,
					idleTimeoutSeconds: idleTimeoutSeconds
				}
			}, () => {
				this.loadingTimeout = setTimeout(() => {
					this.setState({ loading: false });
				}, 1000);
			});

			dlUserSettings({
				account_name: nextContents.account_name,
				directory_number: nextContents.directory_number,
				project_number: nextContents.project_number,
				system_group_name: nextContents.system_group_name,
				system_name: nextContents.system_name,
				system_uuid: nextContents.system_uuid,
			});
		}

		this.fetchingComponentList = true;

		const fetchComponentData = () => {
			let url = `${broncoURLENV}/component`;
			if (useCacheFirst) {
				url = `${broncoURLENV}/component?useCacheFirst=true`;
			}
			if (useCacheOnly) {
				url = `${broncoURLENV}/component?useCacheOnly=true`;
			}

			fetch(url, {
				body: JSON.stringify({ uuid: uuid }),
				headers: { 'Content-Type': 'application/json' },
				method: "POST"
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error(`Request failed with status ${response.status}`);
					}

					return response.json();
				})
				.then((jsonData) => {
					if (typeof cb === 'function') {
						cb();
					}

					if (condorDebugENV === 'true') {
						console.log('CONDOR::DEBUG: Component List:', JSON.stringify(jsonData));
					}

					// this in cleaned up when we get a good response, if
					// we cleaned it up in finally it clears the timer faster
					// than we call retry
					this.fetchingComponentList = false;
					if (this.retryTimeoutID) {
						clearTimeout(this.retryTimeoutID);
					}

					return handleNextContents(jsonData);
				})
				.catch((err) => {
					console.error('Error on /component response', err);
					retry();
				});
		};

		let retryMaxDuration = 10 * 60 * 1000; // 10 minutes

		let retryCount = 0;
		const retry = () => {
			if (retryCount < 3) {
				// what do we want to do here?
				// break out and let the main interval handle it?
			}
			retryCount++;

			let retryDuration = retryCount * 30 * 1000;
			if (retryDuration > retryMaxDuration) {
				retryDuration = retryMaxDuration;
			}

			this.retryTimeoutID = setTimeout(() => {
				if (condorDebugENV === 'true') {
					console.debug(`CONDOR::DEBUG: /component retry attempt ${retryCount}`);
				}

				fetchComponentData();
			}, retryDuration);
		}

		fetchComponentData();
	};

	getUpdatedAt = (str, useCacheFirst = false) => {
		console.debug(str);

		if (this.fetchingComponentList) {
			if (condorDebugENV === 'true') {
				console.debug('CONDOR::DEBUG: Already fetching /component');
			}

			return;
		}

		const url = broncoURLENV + '/last_update';

		const body = {
			'm1': 'component',
			'uuid': condorComponentENV
		}

		const controller = new AbortController();
		if (useCacheFirst) {
			this.timeoutID = setTimeout(() => {
				controller.abort();
				
				return this.getComponentList(useCacheFirst, false);
			}, 10000);
		}

		fetch(url, {
			body: JSON.stringify(body),
			headers: { 'Content-Type': 'application/json' },
			method: 'POST',
			signal: controller.signal
		})
		.then((response) => {
			return response.json();
		})
		.then((contents) => {
			const expireDate = new Date(contents.online_expires);

			// If expired, do not get a new component list
			if (expireDate.getFullYear() !== 0) {
				const currentDate = new Date();

				if (currentDate > expireDate) {
					throw Error(`This system group's online updating has expired`);
				}
			}

			// if updated_at is the same call the callback, aka no new data
			if (useCacheFirst && contents.updated_at === this.state.updatedAt && contents.count === this.state.updatedCount) {
				return this.getComponentList(useCacheFirst, false);
			}

			// if idle and update_at is different, get a new component list
			if (this.state.navProps.animationPhase === this.state.navProps.animationStartPhase || this.state.isTouch === false) {
				if (contents.updated_at !== this.state.updatedAt || contents.count !== this.state.updatedCount) {
					console.debug('New Updated At: ', contents.updated_at, '\nPrev Updated At :', this.state.updatedAt);
					console.debug('New Updated Count: ', contents.count, '\nPrev Updated Count :', this.state.updatedCount);

					const cb = () => {
						this.setState({
							updatedAt: contents.updated_at,
							updatedCount: contents.count
						});

						localStorage['updatedAt'] = contents.updated_at;
						localStorage['updatedCount'] = contents.count;

						if (condorDebugENV === 'true') {
							console.log('CONDOR::DEBUG: Last Update:', JSON.stringify(contents));
						}
					};

					this.getComponentList(false, false, cb);
				}
			}
		})
		.catch((err) => {
			console.error(err);

			// always fallback to the cache only, no network request
			return this.getComponentList(true, true);
		})
		.finally(() => {
			if (this.timeoutID) {
				clearTimeout(this.timeoutID);
			}
		});
	}

	handleStateCallback = (key, val) => {
		this.setState({ [key]: val });
	}

	pdfAction(pdfActive) {
		let classes = '';
		if (pdfActive === true) {
			classes = 'pdfActive'
		}
		return classes;
	}

	iframeAction(iframeActive) {
		let classes = '';
		if (iframeActive === true) {
			classes = 'iframeActive'
		}
		return classes;
	}

	// ============ Navigation functions ==============
	navEvents = (func, value1, value2, value3) => {
		let menuActions = {
			toggleMenu: () => {
				this.navEvents('idleTimer', '||| App.js > navEvents.toggleMenu()');
				this.setState({
					navProps: {
						...this.state.navProps,
						showMenu: !this.state.navProps.showMenu
					}
				});
			},
			togglePdf: () => {
				this.navEvents('idleTimer', '||| App.js > navEvents.togglePdf()');
				this.setState({
					navProps: {
						...this.state.navProps,
						pdfActive: !this.state.navProps.pdfActive
					}
				});
			},
			closePdf: () => {
				this.navEvents('idleTimer', '||| App.js > navEvents.closePdf()');
				setTimeout(() => {
					this.pdfDelayTimer = this.setState({
						navProps: {
							...this.state.navProps,
							pdfActive: false
						}
					});
				}, value1);
			},
			toggleIframe: () => {
				this.navEvents('idleTimer', '||| App.js > navEvents.toggleIframe()');
				this.setState({
					navProps: {
						...this.state.navProps,
						iframeActive: !this.state.navProps.iframeActive,
					}
				});
			},
			setMenu: (value1) => {
				this.navEvents('idleTimer', '||| App.js > navEvents.setMenu()');
				this.setState({
					navProps: {
						...this.state.navProps,
						showMenu: value1
					},
				});
			},
			toggleAda: () => {
				this.navEvents('idleTimer', '||| App.js > navEvents.toggleAda()');
				let adaTopPad = 0;
				if (this.state.adaActive === false) {
					adaTopPad = 500;
				}
				this.setState({
					navProps: {
						...this.state.navProps,
						adaActive: !this.state.navProps.adaActive,
						adaTopPad: adaTopPad,
					}
				});
			},
			setPhase: (value1) => {
				this.navEvents('idleTimer', '||| App.js > navEvents.setPhase()');
				this.setState({
					navProps: {
						...this.state.navProps,
						animationPhase: value1,
						hasNavigatedListings: false,
					},
					buttonActive: true,
				})
				this.navEvents('buttonDebouncer');
			},
			resetAda: () => {
				this.navEvents('idleTimer', '||| App.js > navEvents.resetAda()');
				this.setState({
					navProps: {
						...this.state.navProps,
						adaActive: false,
						adaTopPad: '0em'
					}
				});
			},
			setPhaseSetMenu: (value1, value2, value3) => {
				this.navEvents('idleTimer', '||| App.js > navEvents.setPhaseMenu()');
				let resetListings = value3 || false;
				this.setState({
					navProps: {
						...this.state.navProps,
						animationPhase: value1,
						showMenu: value2,
						pdfActive: false,
						hasNavigatedListings: false,
						resetListings
					},
					buttonActive: true,
				})
				if(value1 === 'idle') {
					this.setState({ hasIframeComponent: false })
				} else if(value1 === 'iframe') {	
					this.setState({ hasIframeComponent: true })
				}
				this.navEvents('buttonDebouncer');
			},
			idleTimer: (value1) => {
				console.log(`Idle Timer: navEvents.idleTimer() ${value1}`);
				this.stopIdleTimer();
				this.startIdleTimer();
			},
			setHasNavigatedListings: (value1) => {
				this.setState({
					navProps: {
						...this.state.navProps,
						hasNavigatedListings: value1,
						resetListings: false
					}
				})
			},
			setButtonActive: () => {
				this.setState({
					buttonActive: true
				});
				this.navEvents('buttonDebouncer');
			},
			buttonDebouncer: () => {
				this.buttonActiveTimer = setTimeout(() => {
					this.setState({
						buttonActive: false
					});
				}, 600);
			},
			btnDebouncer: (btnFunc) => {
				if (this.state.buttonActive === false) {
					clearTimeout(this.buttonDelayTimer);
					// delay allows button feedback before changing animation phases
					this.buttonDelayTimer = setTimeout(() => {
						this.navEvents('setButtonActive');
						btnFunc();
					}, 200);
				}
			},
			swapPhases: (animationPhase, showMenu) => {
				if (this.state.buttonActive === false) {
					dlNavigationSwapPhases({
						animationPhase: animationPhase,
						currAnimationPhase: this.state.navProps.animationPhase,
						showMenu: showMenu,
						label: this.state.button_label,
					});
		
					if (this.state.navProps.hasNavigatedListings && animationPhase === this.state.navProps.animationPhase) {
						this.navEvents('setPhaseSetMenu', animationPhase, showMenu, true);
					} else {
						this.navEvents('setPhaseSetMenu', animationPhase, showMenu);
					}
				}
			}
		};
		menuActions[func](value1, value2, value3);
	}

	resetIdleTimer = (value) => {
		console.log('Idle Timer: resetIdleTimer()', `${value}`)
		this.navEvents('idleTimer', '||| App.js > resetIdleTimer()');
	}

	render() {
		const { loading, body } = this.state;
	
		let adaActiveClass = '';
		if (this.state.navProps.adaActive) {
			adaActiveClass = 'adaActive';
		} 

		return (
			<div className={`App ${customCSSClassName} ${this.state.themeVariation}`}>
				{/* <div className={'App ' + css[this.state.themeVariation]}> */}
				<div
					className={`page ${this.state.navProps.animationPhase} ${adaActiveClass} ${this.pdfAction(this.state.navProps.pdfActive)} ${this.iframeAction(this.state.navProps.iframeActive)}`}
					onClick={() => this.resetIdleTimer('||| App.js > [onClick]page')}
				>
					<WrappedRenderList
						reqMap={this.state.reqMap}
						body={this.state.body}
						// NOTE: THIS COULD BE A SETTING OF THE TLC!
						internetInterval={1}
						handleStateCallback={this.handleStateCallback}
						showIdlePrompt={this.state.showIdlePrompt}
						clearShowIdlePrompt={this.clearShowIdlePrompt}
						navProps={this.state.navProps}
					/>
					
					{loading && <LoadingScreen />}

				</div>
			</div>
		);
	}
}

// Check for internet, update top-level state, then pass down.
const statusIteration = async function (onlineHandler, minuteHandler, currentMinutes, minutesInc) {
	const site = 'aws.amazon.com';
	const src =  "https://" + site + "/favicon.ico";
	let online = false;

	console.log('testing connectivity...');

	try {
        const response = await fetch(src, {
            method: 'HEAD',
			mode: 'no-cors',
            cache: 'no-cache'
        });

        // An opaque response has status 0
		if (response.status === 0 || response.status === 200) {
			online = true;
		}
    } catch (error) {
		console.error('failed connectivity check:', error);
        online = false;
    }

	if (!online) {
		console.error('failed connectivity check');
		onlineHandler(online);
		minuteHandler(currentMinutes + minutesInc);
		return;
	}

	minuteHandler(0);
	onlineHandler(true);
}	

//! NOTE THIS DOES NOT WRAP THE COMPONENT IN A LYERBIRD LISTENER
//! CORRECT APPROACH AVAILABLE IN ui/app/Lyrebird/Example
class WrappedRenderList extends React.Component {
	render() {
		if (!this.props.body) {
			return '';
		}

		// TODO: If this.props.reqMap, then clone each component here so it only happens once.
		// Attach to Lyrebird Events once cloned.
		return (
			<InternetStatus
				body={this.props.body}
				handleStateCallback={this.props.handleStateCallback}
				internetInterval={this.props.internetInterval}
				showIdlePrompt={this.props.showIdlePrompt}
				clearShowIdlePrompt={this.props.clearShowIdlePrompt}
				navProps={this.props.navProps}
			/>
		);
	}
}

class InternetStatus extends React.Component {
	constructor(props) {
		super(props);

		let nextInternetInterval = props.internetInterval;
		if (!nextInternetInterval || typeof nextInternetInterval !== 'number') {
			nextInternetInterval = 1;
		}

		this.state = {
			body: false,
			online: false,
			disconnectedMinutes: 0,
			internetInterval: nextInternetInterval,
			intervalID: false
		}
	}

	onlineUpdater = (online) => {
		this.props.handleStateCallback('onlineStatus', online);
		this.setState({ online: online });
	};

	minutesUpdater = (disconnectedMinutes) => {
		this.props.handleStateCallback('offlineMinutes', disconnectedMinutes);
		this.setState({ disconnectedMinutes: disconnectedMinutes });
	};

	iter = (interval) => {
		statusIteration(
			this.onlineUpdater,
			this.minutesUpdater,
			this.state.disconnectedMinutes,
			interval,
		)
	};

	componentDidMount() {
		this.iter(0);
		let nextInterval = setInterval(() => {
			this.iter(this.props.internetInterval);
		}, this.state.internetInterval * 60000)

		this.setState({ intervalID: nextInterval });
	}

	componentWillUnmount() {
		closeListenerToSw();	
		clearInterval(this.state.intervalID);
	}

	showIdleIframePrompt = () => {
		if (Array.isArray(this.props?.body) && this.props.body.length > 0
		&& (this.props.navProps.iframeActive || this.props.navProps.animationPhase === 'iframe')) {
			const condor_component_group = this.props.body[0].props.condor_component_group || null;
			const condor_configuration = this.props.body[0].props.condor_configuration || null;
			const condor_theme = this.props.body[0].props.condor_theme || null;

			return (
				<IdleTimerPrompt 
					clearShowIdlePrompt={this.props.clearShowIdlePrompt}
					condor_component_group={condor_component_group}
					condor_configuration={condor_configuration}
					condor_theme={condor_theme}
					showIdlePrompt={this.props.showIdlePrompt}
					CSSModules={_CSSModules}
				/>
			);
		}
	}

	render() {
		return (
			<>
				{this.props.body.map((NextComponent) => {
					return React.cloneElement(NextComponent, {
						condor_online: this.state.online,
						condor_disconnected_minutes: this.state.disconnectedMinutes,
					})
				})}
				{this.showIdleIframePrompt()}
			</>
		)
	}
}

export default TopLevelComponent;
