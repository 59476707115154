import React from 'react';

const imageFormats = ['jpg', 'jpeg', 'png'];

const videoFormats = ['mp4', 'mov', 'webm'];

class BroadsignProviderComponent extends React.Component {
	static providerName = 'broadsign';

	constructor(props) {
		super(props);

		this.state = {
			slides: [],
			proofOfPlayQueue: [],
		};

		this.retryTimeout = null;
		this.fetchInterval = null;
		this.fetchSlidesThrottler = null;

		this.providerID = `PROVIDER_${props.component_name}`;
	}

	componentDidMount() {
		const {
			slideshow,
			polling_rate,
		} = this.props;

		// don't initialize if we're not in a slideshow
		if (!slideshow) {
			return;
		}

		this.initUpdate();
		this.fetchInterval = setInterval(() => {
			this.initUpdate();
		}, polling_rate * 60 * 1000);
	}

	componentWillUnmount() {
		clearTimeout(this.retryTimeout);
		clearInterval(this.fetchInterval);
		clearTimeout(this.fetchSlidesThrottler);
	}

	componentDidUpdate(prevProps) {
		const {
			count,
			last_update,
			ad_provider,
			player_identifier,
			screen_identifier,
			playlist_duration,
			polling_rate,
		} = this.props;

		if (prevProps.count !== count ||
			prevProps.last_update !== last_update ||
			prevProps.ad_provider !== ad_provider || 
			prevProps.player_identifier !== player_identifier || 
			prevProps.screen_identifier !== screen_identifier || 
			prevProps.playlist_duration !== playlist_duration ||
			prevProps.polling_rate !== polling_rate) {
			clearTimeout(this.retryTimeout);
			clearInterval(this.fetchInterval);
			clearTimeout(this.fetchSlidesThrottler);

			this.initUpdate();
			this.fetchInterval = setInterval(() => {
				this.initUpdate();
			}, polling_rate * 60 * 1000);
		}
	}

	initUpdate() {
		clearTimeout(this.fetchSlidesThrottler);
		this.fetchSlidesThrottler = setTimeout(() => {
			try {
				this.fetchSlides();
			} catch (err) {
				console.error('BROOD::BROADSIGN::ERROR_initUpdate:', err);
				debugger;
			}
		}, 5000);
	}

	buildAds(playlist) {
		const ads = playlist.items
			.filter((rawAd) => {
				const content = playlist.contents[rawAd.contentIndex];
				if (!('contentIndex' in rawAd)) {
					return false;
				} else if (imageFormats.includes(content.mimeType)) {
					return true;
				} else if (videoFormats.includes(content.mimeType)) {
					return true;
				} else {
					console.warn('Slide type is not image or video:', rawAd.mime_type);
				}
				return false;
			})
			.map((rawAd) => {
				const content = playlist.contents[rawAd.contentIndex];

				let type = '';

				if (imageFormats.includes(content.mimeType)) {
					type = 'image';
				} else if (videoFormats.includes(content.mimeType)) {
					type = 'video';
				}

				const processedAd = { ...rawAd };
				processedAd.description = content.name;
				processedAd.duration = rawAd.duration.slice(0,-1);
				processedAd.kind = content.mimeType;
				processedAd.type = type;
				processedAd.resolution = `1080px x 1920px`;
				processedAd.src = content.uri;
				processedAd.allowAudio = this.props.allow_audio;
				processedAd.slideID = rawAd.token;
				processedAd.provider = this;

				processedAd.data = {
					src: processedAd.src,
					alt: processedAd.description,
				};

				processedAd.options = {
					duration: processedAd.duration * 1000,
				};

				return processedAd;
			})
			.filter((ad) => {
				return imageFormats.includes(ad.kind) || videoFormats.includes(ad.kind);
			});

		return ads;
	};

	async fetchSlides(retryCount = 0) {
		const {
			ad_provider,
			player_identifier,
			screen_identifier,
			playlist_duration,
			polling_rate,
			slideshow,
		} = this.props;

		const headers = {
			'Content-Type': 'application/json'
		};

		const now = new Date();
		const nexPlaylist = new Date(now.getTime() + playlist_duration * 60 * 1000);
	
		const body = {
			provider: ad_provider,
			player_identifier: player_identifier,
			screen_identifier: screen_identifier,
			duration: String(playlist_duration * 60) + "s",
			next_expected_poll: nexPlaylist.toISOString(),
		};

		const url = `${window._getEnv('BRONCO_URL')}/broadsign_pl`;

		try {
			const response = await fetch(url, {
				body: JSON.stringify(body),
				headers: headers,
				method: 'POST'
			});

			if (response.status === 429) {
				throw new Error(response.statusText);
			}

			const playlist = await response.json();					

			console.log('Setting Broadsign playlist', playlist);
			const slides = this.buildAds(playlist);

			this.setState({
				slides,
			});
	
			const newProvider = this.makeProvider(slides);
			slideshow.updateProvider(newProvider);
		} catch(error) {
			console.error('BROOD::BROADSIGN::ERROR_fetchSlides:', error);
	
			if (retryCount < 3) {
				this.retryTimeout = setTimeout(() => {
					this.fetchSlides(retryCount + 1);
				}, polling_rate * 60 * 1000 / 5);
			}
		}
	}

	makeProvider(slides = []) {
		const {
			providerID,
			limit: maxSlides,
			order: providerOrder,
			sort: sortType,
		} = this.props;

		return {
			providerID:   providerID,
			providerType: 'ads',
			options:      {
				maxSlides:     maxSlides,
				providerOrder: providerOrder,
				sortType:      sortType || 'sequential'
			},
			skipper:             this.skipper.bind(this),
			beforeLastSlide:     this.beforeLastSlide.bind(this),
			afterLastSlide:      this.afterLastSlide.bind(this),
			slides,
		};
	}

	skipper(currSlide) {
		const {
			slides
		} = this.state;

		// currSlide is the brood slide, not the broadsign slide
		const statefulCurrSlide = slides.find((slide) => {
			return slide.slideID === currSlide.slideID;
		});

		if (!statefulCurrSlide) {
			return true;
		}

		return false;
	}

	beforeLastSlide(slides = []) {
		console.log('BROOD::BROADSIGN::beforeLastSlide:', slides);
	}

	afterLastSlide(_slides = []) {
		console.log('BROOD::BROADSIGN::afterLastSlide:', _slides);

		const {
			slideshow
		} = this.props;

		const {
			slides,
		} = this.state;

		new Promise((resolve) => {
			resolve(this.sendProofOfPlay());
		});

		const newProvider = this.makeProvider(slides);
		slideshow.updateProvider(newProvider);
	}

	sendProofOfPlay() {
		const {
			proofOfPlayQueue,
		} = this.state;

		if (proofOfPlayQueue.length === 0) {
			return;
		}

		const {
			ad_provider,
			player_identifier,
			screen_identifier,
		} = this.props;

		const headers = {
			'Content-Type': 'application/json'
		};
	
		const body = {
			provider: ad_provider,
			player_identifier: player_identifier,
			screen_identifier: screen_identifier,
			confirmed_items: proofOfPlayQueue,
		};

		const url = `${window._getEnv('BRONCO_URL')}/broadsign_pop`;
	
		try {
			fetch(url, {
				body: JSON.stringify(body),
				headers: headers,
				method: 'POST'
			})
			.then((response) => {
				return response.json();
			})
			.then((confirmation) => {
				console.log('BROOD::BROADSIGN::sendProofOfPlay:', confirmation);
				this.setState({
					proofOfPlayQueue: [],
				});
			});
		} catch(error) {
			console.error('BROOD::BROADSIGN::ERROR_sendProofOfPlay:', error);
		}
	};

	beforeNext(currSlide) {
		console.log('BROOD::BROADSIGN::beforeNext:', currSlide);
	}

	afterNext(prevSlides, prevSlide, currSlide) {
		console.log('BROOD::BROADSIGN::afterNext:', prevSlides, prevSlide, currSlide);

		const {
			slides,
			proofOfPlayQueue,
		} = this.state;

		let prevSlideIndex = slides.findIndex((slide) => {
			return slide.slideID === prevSlide.slideID;
		});

		if (~prevSlideIndex) {
			slides.splice(prevSlideIndex, 1);

			this.setState({
				slides,
				proofOfPlayQueue: [...proofOfPlayQueue, { playlist_item_token: prevSlide.token }],
			});
		}
	}

	render() {
		return null;
	}
}

export default BroadsignProviderComponent;