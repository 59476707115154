import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import WeatherIcon from './WeatherIcon.js';

const WeatherItem = (props) => {
	const css = props.css;
	const { t } = useTranslation();

	// Set theme
	const componentGroup = props.componentGroup;
	const configuration = props.configuration;
	const theme = props.theme;

	let condition = props.showCurrentWeather ? props.currentSkyConditions : props.skyConditions;

	let skyConditions = '';
	if (props.showSkyConditions === true) {
		skyConditions = props.skyConditions;
	}

	let lineColor = props.lineColor;

	let itemStyles = {
		width: `${props.itemWidth}px`
	};

	if (props.dayConfig === 2) { // margin right only -> create gap setting ======================
		itemStyles = {
			...itemStyles,
			marginRight: props.boxGap,
			backgroundColor: props.boxColor,
			border:          `${props.boxBorderWidth}px solid ${props.boxBorderColor}`
		};
	}

	let lineStyles = {
		width:      `${props.lineWidthPx}px`,
		background: props.borderColors
	};
	let lineSeparator = <div style={lineStyles} className={`weather_line ${css.lineSeparator} ${`line_${props.name}`}`}></div>;
	let lineSeparatorLast = <div id={`lineSeparatorLast_${props.name}`} style={lineStyles} className={`weather_line_last ${css.lineSeparator} ${`line_${props.name}`}`}></div>;

	if (props.dayConfig === 0 || props.dayConfig === 2) {
		lineSeparator = '';
		lineSeparatorLast = '';
	}
	if (props.animation === false) {
		lineSeparatorLast = '';
	}

	let icon =
		<div className={`weather_icon ${css[`${props.itemFormat}Icon`]}`}>
			<WeatherIcon componentGroup={componentGroup} configuration={configuration} theme={theme} icon={condition} lineColor={lineColor} />
		</div>;
	if (props.icons === false) {
		icon = '';
	}

	function handleCurrentWeather() {
		if (props.showCurrentWeather) {	
			return (
				<div className={`weather_temp ${css[`${props.itemFormat}Temperatures`]}`}>
					<div className={`weather_temp_high ${css[`${props.itemFormat}TempHigh`]}`}><i className={`fas fa-long-arrow-alt-up ${css[`${props.itemFormat}TempHighIcon`]}`}></i>{props.tempCurrentF}</div>
				</div>
			)
		} else {
			return (
				<div className={`weather_temp ${css[`${props.itemFormat}Temperatures`]}`}>
					<div className={`weather_temp_high ${css[`${props.itemFormat}TempHigh`]}`}><i className={`fas fa-long-arrow-alt-up ${css[`${props.itemFormat}TempHighIcon`]}`}></i>{props.tempHigh}</div>
					<span className={`${css[`${props.itemFormat}TemperatureSeparator`]}`}>|</span>
					<div className={`weather_temp_low ${css[`${props.itemFormat}TempLow`]}`}><i className={`fas fa-long-arrow-alt-down ${css[`${props.itemFormat}TempLowIcon`]}`}></i> {props.tempLow}</div>
				</div>
			)
		}
	}


	// first day
	if (props.index === 0) {
		let chunkId = `firstWeatherChunk_${props.name}`;
		if (props.showCurrentWeather) {
			chunkId = `currentWeatherChunk_${props.name}`;
		}
		return (
			<>
				<div id={chunkId} className={`weather_day weatherDay_${props.name} ${css.weatherItem} ${css[`weatherItemDayCount${props.dayCount}`]}`} style={itemStyles}>
					{icon}
					<div className={`weather_name ${css[`${props.itemFormat}Day`]}`}>{props.showCurrentWeather ? t("weatherCurrentLabel") : t("weatherTodayLabel")}</div>
					<div className={`weather_condition ${css[`${props.itemFormat}SkyConditions`]}`}>{skyConditions}</div>
					{handleCurrentWeather()}
					<div className={`weather_stats ${css[`${props.itemFormat}Stats`]}`}>
						<div className={`weather_humidity ${css[`${props.itemFormat}Humidity`]}`}>{props.humidity}</div>
						<div className={`weather_percip ${css[`${props.itemFormat}ChanceOfPercip`]}`}>{props.chanceOfPercip}</div>
						<div className={`weather_feel ${css[`${props.itemFormat}FeelsLike`]}`}>{props.feelsLike}</div>
						<div className={`weather_wind_chill ${css[`${props.itemFormat}WindChill`]}`}>{props.windChill}</div>
					</div>
				</div>
				{lineSeparator}
			</>
		);
	}

	// last day
	if (props.index === parseInt(props.dayCount - 1)) {
		return (
			<>
				<div id={`lastWeatherChunk_${props.name}`} className={`weather_day weatherDay_${props.name} ${css.weatherItem} ${css[`weatherItemDayCount${props.dayCount}`]}`} style={itemStyles}>
					{icon}
					<div className={`weather_name ${css[`${props.itemFormat}Day`]}`}>{props.day}</div>
					<div className={`weather_condition ${css[`${props.itemFormat}SkyConditions`]}`}>{skyConditions}</div>
					<div className={`weather_temp ${css[`${props.itemFormat}Temperatures`]}`}>
						<div  className={`weather_temp_high ${css[`${props.itemFormat}TempHigh`]}`}><i className={`fas fa-long-arrow-alt-up ${css[`${props.itemFormat}TempHighIcon`]}`}></i> {props.tempHigh}</div> <span className={`${css[`${props.itemFormat}TemperatureSeparator`]}`}>|</span>
						<div className={`weather_temp_low ${css[`${props.itemFormat}TempLow`]}`}><i className={`fas fa-long-arrow-alt-down ${css[`${props.itemFormat}TempLowIcon`]}`}></i> {props.tempLow}</div>
					</div>
					<div className={`weather_stats ${css[`${props.itemFormat}Stats`]}`}>
						<div className={`weather_humidity ${css[`${props.itemFormat}Humidity`]}`}>{props.humidity}</div>
						<div className={`weather_percip ${css[`${props.itemFormat}ChanceOfPercip`]}`}>{props.chanceOfPercip}</div>
						<div className={`weather_feel ${css[`${props.itemFormat}FeelsLike`]}`}>{props.feelsLike}</div>
						<div className={`weather_wind_chill ${css[`${props.itemFormat}WindChill`]}`}>{props.windChill}</div>
					</div>
				</div>
				{lineSeparatorLast}
			</>
		);
	}

	// Other days
	return (
		<>
			<div className={`weather_day weatherDay_${props.name} ${css.weatherItem} ${css[`weatherItemDayCount${props.dayCount}`]}`} style={itemStyles}>
				{icon}
				<div className={`weather_name ${css[`${props.itemFormat}Day`]}`}>{props.day}</div>
					<div className={`weather_condition ${css[`${props.itemFormat}SkyConditions`]}`}>{skyConditions}</div>
					<div className={`weather_temp ${css[`${props.itemFormat}Temperatures`]}`}>
						<div  className={`weather_temp_high ${css[`${props.itemFormat}TempHigh`]}`}><i className={`fas fa-long-arrow-alt-up ${css[`${props.itemFormat}TempHighIcon`]}`}></i> {props.tempHigh}</div> <span className={`${css[`${props.itemFormat}TemperatureSeparator`]}`}>|</span>
						<div className={`weather_temp_low ${css[`${props.itemFormat}TempLow`]}`}><i className={`fas fa-long-arrow-alt-down ${css[`${props.itemFormat}TempLowIcon`]}`}></i> {props.tempLow}</div>
					</div>
					<div className={`weather_stats ${css[`${props.itemFormat}Stats`]}`}>
						<div className={`weather_humidity ${css[`${props.itemFormat}Humidity`]}`}>{props.humidity}</div>
						<div className={`weather_percip ${css[`${props.itemFormat}ChanceOfPercip`]}`}>{props.chanceOfPercip}</div>
						<div className={`weather_feel ${css[`${props.itemFormat}FeelsLike`]}`}>{props.feelsLike}</div>
						<div className={`weather_wind_chill ${css[`${props.itemFormat}WindChill`]}`}>{props.windChill}</div>
					</div>
			</div>
			{lineSeparator}
		</>
	);

};

export default WeatherItem;
