export const newCondorComponentsENV = window._getEnv('NEW_CONDOR_COMPONENTS');
export const condorComponentENV = window._getEnv('CONDOR_COMPONENT');
export const broncoURLENV = window._getEnv('BRONCO_URL');
export const condorRefreshRateENV = window._getEnv('CONDOR_REFRESH_RATE');
export const ternUserNameENV = window._getEnv('TERN_USER_NAME');
export const ternFSQTokenENV = window._getEnv('TERN_FSQ_TOKEN');
export const ternStaticTokenENV = window._getEnv('TERN_STATIC_TOKEN');
export const monarchServerENV = window._getEnv('MONARCH_SERVER');
export const falconWSURLENV = window._getEnv('FALCON_WS_URL');
export const herciniaURLENV = window._getEnv('HERCINIA_URL');
export const latENV = window._getEnv('LAT');
export const lonENV = window._getEnv('LON');
export const zoomENV = window._getEnv('ZOOM');
export const heightENV = window._getEnv('HEIGHT');
export const widthENV = window._getEnv('WIDTH');
export const zlENV = window._getEnv('ZL');
export const tsLiveServerENV = window._getEnv('TSLIVE_SERVER');
export const mossySwiftPortENV = window._getEnv('MOSSYSWIFT_PORT');
export const envENV = window._getEnv('ENV');