import React, {Component} from 'react';

class Clock extends Component {
	constructor(props) {
		super(props);
		let tick = 1000;
		if (props.tick && props.tick > 0) {
			tick = props.tick;
		}

		this.state = {
			clockID: false,
			css:     props.CSSModules[props.condor_render_name],
			tick:    tick,
			now:     new Date()
		};
	}

	componentDidMount() {
		let clockID = setInterval(this.clockTick, 15000); // Ticks every 30 seconds.
		this.setState({clockID: clockID});
	}

	componentWillUnmount() {
		clearInterval(this.state.clockID);
	}

	clockTick = () => {
		let nextNow = new Date();
		this.setState({now: nextNow});
		this.setState({currentTime: nextNow});
	}

	createHoursAndFmt = (rawHours) => {
		let timeFmt = '';
		if (this.props.time_format === '12_hour' && this.props.time_period === true) {
			timeFmt = 'AM';
			if (rawHours >= 12) {
				timeFmt = 'PM';
			}
		}

		let hours = rawHours;
		if (this.props.time_format === '12_hour') {
			if (hours === 0) {
				hours = 12;
			}

			if (hours > 12) {
				hours = hours - 12;
			}
		}

		return [hours, timeFmt];
	}

	createMinutes = (minutes) => {
		if (minutes >= 0 && minutes < 10) {
			return '0' + minutes;
		}
		return minutes;
	}

	render() {
		let css = this.state.css;

		// Declare classes
		// Custom classes
		let customClasses = '';
		if (this.props.custom_classes && this.props.custom_classes.length > 0) {
			this.props.custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (this.props.modifier_classes && this.props.modifier_classes.length > 0) {
			this.props.modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		let connectionIcon = '';
			
		if (!this.props.online_status && this.props.offline_minutes >= 5) {
			connectionIcon = <svg className={css.noWifiIcon} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100" >
								<path d="M36.2,61.6l-5.8-5.8c3.3-3.3,7.3-5.6,11.5-6.9l0.3,8.7C39.9,58.5,37.9,59.8,36.2,61.6z M25.2,50.6  c4.7-4.7,10.4-7.7,16.4-9.2L41.4,33c-8.1,1.6-15.7,5.6-22,11.8L25.2,50.6z M58.6,33l-0.3,8.4c6,1.5,11.7,4.5,16.4,9.2l5.8-5.8  C74.4,38.6,66.7,34.6,58.6,33z M58.1,48.8l-0.3,8.7c2.2,1,4.2,2.3,6,4.1l5.8-5.8C66.3,52.4,62.4,50.1,58.1,48.8z M50,74.8  c2.8,0,5-2.2,5-5s-2.2-5-5-5c-2.8,0-5,2.2-5,5S47.2,74.8,50,74.8z M48,60.7h4c1,0,1.9-0.8,1.9-1.9l1-31.7c0-1.1-0.8-2-1.9-2h-5.9  c-1.1,0-1.9,0.9-1.9,2l0.9,31.7C46.1,59.9,47,60.7,48,60.7z" />
							</svg>;
		}

		let rawHours = this.state.now.getHours();
		let rawMinutes = this.state.now.getMinutes();
		let [hours, timeFmt] = this.createHoursAndFmt(rawHours);
		let minutes = this.createMinutes(rawMinutes);

		return (
			<div id={`clock_${this.props.component_name}`} className={`clock_container ${css.timeCont} ${modifierClasses} ${customClasses}`}>
				<div className={`clock_text ${css.timeText}`}>
					{hours}:{minutes}<span className={css.period}>{timeFmt}</span><div className={css.noConnection}>{connectionIcon}</div>
				</div>
			</div>
		);
	}
}

export default Clock;
