export const dlUserSettings = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Define User Settings',
	});
}

export const dlNavigationSwapPhases = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Navigation Swap Phases',
	});
};

export const dlToggleADA = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Toggle ADA',
	});
};

export const dlToggleMenu = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Toggle Menu',
	});
};

export const dlClosePage = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Close Page',
	});
};

export const dlShowChildren = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Show Children',
	});
};

export const dlRowClick = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Row Click',
	});
};

export const dlSearch = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Search',
	});
};

export const dlListingSelect = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Listing Select',
	});
};

export const dlMapToggleCategories = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Map Toggle Categories',
	});
};

export const dlPresenceDetect = (payload = {}) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
        ...payload,
	    event: 'Presence Detected',
	});
};