import React, {Component} from 'react';

// Components
import WeatherItem from './WeatherItem.js';
import { tsLiveServerENV } from '../ENV.js';

const secondInMs = 1000;
const minuteInMs = 60 * secondInMs;
const expoBackoffMin = 1000;
const expoBackoffMax = minuteInMs * 15;
class Weather extends Component {
	constructor(props) {
		super(props);

		let reqRate = minuteInMs * 60;
		if (this.props.request_rate && typeof this.props.request_rate === "number") {
			reqRate = this.props.request_rate * secondInMs;
		}

		this.state = {
			animated:      '',
			boxGap:        this.props.box_gap,
			content:       'hidden',
			css:           props.CSSModules[props.condor_render_name],
			data:          [],
			itemWidth:     '',
			network:       1,
			offlineMins:   0,
			request_rate:  reqRate,
			request_timer: false,
			request_backoff: expoBackoffMin,
			showCurrentWeather: this.props.show_current_weather,
			timerInterval: this.props.animation_interval,
			timerRunning:  false,
			weatherScrollCount:   0,
			wrapperWidth:  ''
		};
		this.startAnimatedWeatherTimer = this.startAnimatedWeatherTimer.bind(this);
		this.stopAnimatedWeatherTimer = this.stopAnimatedWeatherTimer.bind(this);
	}

	componentDidMount() {
		setTimeout(() => {
			this.getData();
		}, 1500);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
		clearInterval(this.state.request_timer);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.request_rate !== this.props.request_rate && typeof this.props.request_rate === "number") {
			let request_rate = this.props.request_rate * secondInMs;

			this.setState({ request_rate });
		}
		if (prevProps.cycleCount !== this.props.cycleCount && this.props.animatedScroller === true && this.props.animation === true) {
			this.animateBetweenAnimatedScroller();
		}

		if(prevProps.days_to_show !== this.props.days_to_show) {
			let wrapper = document.getElementById(`wrapper_${this.props.component_name}`);
			let contWidth = wrapper.offsetWidth;
			let newContainerWidth = wrapper.offsetWidth;
			let numDays = this.props.days_to_show;
			let scrollAmount = 0;
			let modifier = 0;
	
			if (this.props.day_config === 1) {
				modifier = this.props.line_width_px;
			} else if (this.props.day_config === 2) {
				modifier = this.props.box_gap;
			}
	
			let adjustedContWidth = contWidth - ((numDays - 1) * modifier);
			let dayWidth = Math.floor(adjustedContWidth / numDays);
			newContainerWidth = (dayWidth * numDays) + ((numDays - 1) * modifier);
			scrollAmount = dayWidth + modifier;
	
			this.setState({
				wrapperWidth: newContainerWidth,
				itemWidth:    dayWidth,
				scrollAmount: scrollAmount,
			});
		}
	}

	getData = () => {
		const target = `${tsLiveServerENV}/weather?zip=${this.props.zip_code}`;

		const getFileSuccess = (jsonData) => {
			if (!Array.isArray(jsonData)) {
				getFileFail(new Error('Can\'t handle this format of data', jsonData));
				return;
			}

			let timer = setTimeout(this.getData, this.state.request_rate);
			
			this.setState({
				data: jsonData,
				// In case of success, revert to min
				request_backoff: expoBackoffMin,
				request_timer: timer,
			});

			this.stopAnimatedWeatherTimer();
			this.startAnimatedWeatherTimer();
		}

		const getFileFail = (error) => {
			console.error(error);
			this.stopAnimatedWeatherTimer();

			let currentBackoff = this.state.request_backoff;
			let nextBackoff = currentBackoff * 2;
			if (nextBackoff > expoBackoffMax) {
				nextBackoff = expoBackoffMax;
			}

			let timer = setTimeout(this.getData, currentBackoff);

			this.setState({
				request_backoff: nextBackoff,
				request_timer: timer,
			});
		};

		try {
			fetch(target)
				.then((response) => {
					return response.json();
				})
				.then((jsonData) => {
					getFileSuccess(jsonData);
				})
				.catch((err) => {
					getFileFail(err);
				});
		} catch(err) {
			getFileFail(err);
		}
	}

	initializeScrollingContent = () => {
		// get container widths, calculate scrolling distance, and set state to set container values
		let wrapper = document.getElementById(`wrapper_${this.props.component_name}`);
		let contWidth = wrapper.offsetWidth;
		let newContainerWidth = wrapper.offsetWidth;
		let numDays = this.props.days_to_show;
		let scrollAmount = 0;
		let modifier = 0;

		if (this.props.day_config === 1) {
			modifier = this.props.line_width_px;
		} else if (this.props.day_config === 2) {
			modifier = this.props.box_gap;
		}

		let adjustedContWidth = contWidth - ((numDays - 1) * modifier);
		let dayWidth = Math.floor(adjustedContWidth / numDays);
		newContainerWidth = (dayWidth * numDays) + ((numDays - 1) * modifier);
		scrollAmount = dayWidth + modifier;

		this.setState({
			animated:     'animated',
			content:      'visible',
			wrapperWidth: newContainerWidth,
			itemWidth:    dayWidth,
			scrollAmount: scrollAmount,
		});
	}

	animateContent = () => {
		this.scrollContent();
		this.setState({
			cycleCount:   this.props.cycleCount,
			weatherScrollCount:  this.state.weatherScrollCount + 1,
			timerRunning: true,
		});
	}

	animateBetweenAnimatedScroller = () => {
		let animatedScrollingDuration = 400;
		let animatedScrollerTimer = this.props.animatedScrollerTimer * 1000;

		// scroll immediately after animated scroller is done cycling
		setTimeout(() => {
			this.animateContent();
		}, animatedScrollingDuration);

		// scroll halfway between the next animated scroller cycle
		setTimeout(() => {
			this.animateContent();
		}, animatedScrollerTimer / 2);
	}

	startAnimatedWeatherTimer = () => {
		if (this.props.animation && this.props.day_count <= this.props.days_to_show) {
			// not enough days to animate
			this.stopAnimatedWeatherTimer();
			this.initializeScrollingContent();
			console.log("Day count is less than or equal to the desired days to animate");
		} else if (this.state.weatherScrollCount > 0 && this.props.animation && this.props.day_count > this.props.days_to_show) {
			// this condition may never run, when component reloads state is set to 0
			console.log(`%cContact Craig if you see this - Reset timer values`, 'background: #1b41af; color: white; padding: 5px 103px;');
			
			clearInterval(this.timer);
			this.setState({
				weatherScrollCount: 0,
			});
			setTimeout(() => {
				this.startAnimatedWeatherTimer();
			}, 10);

		} else if (this.props.animation) {
			// standard animition cycle
			const tick = this.state.timerInterval * 1000;

			this.initializeScrollingContent();

			if ((!this.props.animatedScroller || this.props.animatedScroller === false) || (this.props.animatedScroller && this.props.animatedScrollerTimer === 0)) {
				this.timer = setInterval(() => {
					this.animateContent();
				}, tick);
			}

		} else {
			this.setState({
				content: 'visible',
			});
		}
	}

	stopAnimatedWeatherTimer = () => {
		clearInterval(this.timer);
		this.setState({
			weatherScrollCount:  0,
			timerRunning: false,
		});
	}

	scrollContent = () => {
		if (this.state.network === 1 || (this.state.network === 0 && this.state.offlineMins <= (1440))) {
			if (this.props.day_config === 1) {
				let lineSeparatorClass = `line_${this.props.component_name}`;
				let lineSeparators = document.querySelectorAll(`.${lineSeparatorClass}`);
				if (lineSeparators && lineSeparators.length && lineSeparators[0] && lineSeparators[0].classList) {
					lineSeparators[0].classList.add(`fadeWeather`);
				}
			}

			if (document.getElementById(`wrapper_${this.props.component_name}`)) {
				document.getElementById(`wrapper_${this.props.component_name}`).scrollBy(this.state.scrollAmount, 0); // scroll to new position
			}
			setTimeout(() => {
				this.swapFirstDayToEnd();
			}, 500);
		}
	};

	swapFirstDayToEnd = () => {
		// move first day to end of the day cycle and update class names
		if (!document.getElementById(`wrapper_${this.props.component_name}`)) {
			console.error("Weather: wrapper not found!");
			return;
		}
		document.getElementById(`wrapper_${this.props.component_name}`).style.scrollBehavior = 'auto';

		if (!document.getElementById(`firstWeatherChunk_${this.props.component_name}`)) {
			console.error("Weather: First day not found!");
			return;
		}
		let firstWeatherChunk = document.getElementById(`firstWeatherChunk_${this.props.component_name}`);

		if (!document.getElementById(`lastWeatherChunk_${this.props.component_name}`)) {
			console.error("Weather: Last day not found!");
			return;
		}
		let lastWeatherChunk = document.getElementById(`lastWeatherChunk_${this.props.component_name}`);
		

		if (this.props.day_config === 1 && document.getElementById(`lineSeparatorLast_${this.props.component_name}`)) { // lines
			document.getElementById(`lineSeparatorLast_${this.props.component_name}`).after(firstWeatherChunk);
			document.getElementById(`lineSeparatorLast_${this.props.component_name}`).removeAttribute('id');
		} else if(lastWeatherChunk){
			lastWeatherChunk.after(firstWeatherChunk);
		}

		lastWeatherChunk.removeAttribute('id');
		firstWeatherChunk.setAttribute('id', `lastWeatherChunk_${this.props.component_name}`);

		if (this.props.day_config === 1 && document.getElementById(`lastWeatherChunk_${this.props.component_name}`)) { // lines
			let lineSeparatorClass2 = `line_${this.props.component_name}`;
			let lineSeparators = document.querySelectorAll(`.${lineSeparatorClass2}`);
			if (lineSeparators && lineSeparators.length && lineSeparators[0] && lineSeparators[0].classList) {
				lineSeparators[0].setAttribute('id', `lineSeparatorLast_${this.props.component_name}`);
				lineSeparators[0].classList.remove(`fadeWeather`);
			}

			document.getElementById(`lastWeatherChunk_${this.props.component_name}`).after(lineSeparators[0]);
		}

		const targetClass = `weatherDay_${this.props.component_name}`;
		let item = document.querySelectorAll(`.${targetClass}`);

		if (item && item.length && item[0]) {
			item[0].setAttribute('id', `firstWeatherChunk_${this.props.component_name}`);
		}

		if (!document.getElementById(`wrapper_${this.props.component_name}`)) {
			console.error("Weather: Wrapper not found!");
			return;
		}
		document.getElementById(`wrapper_${this.props.component_name}`).scrollTo(0, 0);
		document.getElementById(`wrapper_${this.props.component_name}`).style.scrollBehavior = 'smooth';
	};

	render() {
		const css = this.state.css;
		let weatherNew = [];

		// Custom classes
		let customClasses = '';
		if (this.props.custom_classes && this.props.custom_classes.length > 0) {
			this.props.custom_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					customClasses += ` ${element}`;
				}
			});
		}

		// Modifier classes
		let modifierClasses = '';
		if (this.props.modifier_classes && this.props.modifier_classes.length > 0) {
			this.props.modifier_classes.forEach((element) => {
				if ((/^([a-z_]|-[a-z_-])[a-z\d_-]*$/i).test(element)) {
					modifierClasses += ` ${element}`;
				}
			});
		}

		let weather = this.state.data.map((element, index) => {
			let day = element.Name;
			if (!this.props.day) {
				day = '';
			}
			let currentSkyConditions = element.CurrentSkyCondition;
			let skyConditions = element.SkyCondition;

			let tempHigh = `${element.Temperature.HighF}°`;
			let tempLow = `${element.Temperature.LowF}°`;
			if (!this.props.high_low) {
				tempHigh = '';
				tempLow = '';
			}
			let humidity = '87%'; // Needs to be dynamic
			if (!this.props.humidity) {
				humidity = '';
			}
			let chanceOfPercip = '8%'; // Needs to be dynamic
			if (!this.props.chance_of_percip) {
				chanceOfPercip = '';
			}
			let feelsLike = '83°'; // Needs to be dynamic
			if (!this.props.feels_like) {
				feelsLike = '';
			}
			let windChill = '25°'; // Needs to be dynamic
			if (!this.props.wind_chill) {
				windChill = '';
			}
			if (this.props.celsius) {
				tempHigh = element.Temperature.HighC;
				tempLow = element.Temperature.LowC;
				feelsLike = '-28'; // Needs to be dynamic
			}

			let weatherItemProps = {
				animation:         this.props.animation,
				componentGroup:    this.props.condor_component_group,
				configuration:     this.props.condor_configuration,
				theme: 			   this.props.condor_theme,
				name:              this.props.component_name,
				index:             index,
				icons:             this.props.icons,
				dayCount:          this.props.day_count,
				itemWidth:         this.state.itemWidth,
				borders:           this.props.line,
				borderColors:      this.props.line_sep_color,
				box:               this.props.box,
				boxBorderColor:    this.props.box_border_color,
				boxBorderWidth:    this.props.box_border_width,
				boxColor:          this.props.box_color,
				boxGap:            this.props.box_gap,
				itemFormat:        this.props.item_format,
				day:               day,
				dayConfig:         this.props.day_config,
				showSkyConditions: this.props.sky_conditions,
				currentSkyConditions: currentSkyConditions,
				skyConditions:     skyConditions,
				tempHigh:          tempHigh,
				tempLow:           tempLow,
				humidity:          humidity,
				chanceOfPercip:    chanceOfPercip,
				feelsLike:         feelsLike,
				windChill:         windChill,
				animatedIcon:      this.props.animated_icon, // not used, placeholder for future icons
				iconType:          this.props.icon_type,
				line:              this.props.line,
				lineWidthPx:       this.props.line_width_px,
				lineColor:         this.props.line_color,
				iconColor1:        this.props.icon_color_1,
				iconColor2:        this.props.icon_color_2,
				iconColor3:        this.props.icon_color_3,
				tempCurrentC:      `${element.Temperature.CurrC}°` || '°',
				tempCurrentF:      `${element.Temperature.CurrF}°` || '°'
			};

			if (element.Temperature.CurrC) {
				weatherItemProps = {
					...weatherItemProps,
					tempCurrentC: `${element.Temperature.CurrC}°`,
					tempCurrentF: `${element.Temperature.CurrF}°`,
				};
			}

			return (
				<WeatherItem key={day} {...weatherItemProps} css={css} />
			);

		});

		for (let i = 0; i < this.props.day_count; i++) {
			weatherNew.push(weather[i]);
		}

		if (this.state.showCurrentWeather && weather.length > 0) {
			if(weatherNew.length === 1) {
				weatherNew.pop()
			}
			weatherNew.unshift(<WeatherItem key={'current'} {...weather[0].props} isCurrentDay={true} showCurrentWeather css={css} />);
		}
		
		let wrapperStyle = {
			width: `${this.state.wrapperWidth}px`
		};

		if (this.state.network === 0 && this.state.offlineMins >= (1440)) {
			return (
				<div id={`weather_${this.props.component_name}`} className={`weather ${css.cont} ${css[`${this.state.content}`]} ${modifierClasses} ${customClasses} ${css[this.props.theme_variation]}`}>
					<div id={`wrapper_${this.props.component_name}`} className={`weather_container ${css.container} ${css[`${this.state.animated}`]}`}>
						<div className={`weather_wrapper ${css.wrapper}`}/>
					</div>
				</div>
			);
		}

		return (
			<div id={`weather_${this.props.component_name}`} className={`weather ${css.cont} ${modifierClasses} ${customClasses} ${css[`${this.state.content}`]} ${css[this.props.theme_variation]}`}>
				<div id={`wrapper_${this.props.component_name}`} style={wrapperStyle} className={`weather_container ${css.container} ${css[`${this.state.animated}`]}`}>
					<div className={`weather_wrapper ${css.wrapper}`}>
						{weatherNew}
					</div>
				</div>
			</div>
		);

	}
}

export default Weather;
